import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { NavButton } from '../SideBarButton';
import { Svg } from '@rs-ui/components/Svg';
import Icon from '@rs-ui/components/Icon';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { useAuth } from '@rs-core/context/UserAuthContext';
import PopupUserSettings from '@rs-ui/components/PopupUserSettings';
import _ from 'lodash';
import MuiAvatar from '@mui/material/Avatar';
import Avatar from '@rs-ui/components/Avatar';
import AppsIcon from '@mui/icons-material/Apps';
import SideBarApps from '../SideBarApps/SideBarApps';
import { useNavigate } from 'react-router-dom';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SideBarAdmin = ({ className, activeClassName, buttons, sidebarWidth, patientImg, t, hideProfile = false }) => {
	const __config = useConfig();
	const { appMode } = useAppModeContext();
	const navigate = useNavigate();
	const [showProfile, setShowProfile] = useState(false);
	const [isAppsOpen, setIsAppsOpen] = useState(false);
	const { userInfo, resetAndLogOut, loggedInUser, userInOmegaAI, userIdP, globalPermissionsForLoggedUser } =
		useAuth();

	const sprinterBlumeStageingpolicy = useBooleanFlagValue('sprinter-blume-stageingpolicy');
	const sprinterOaiStageingpolicy = useBooleanFlagValue('sprinter-oai-stageingpolicy');

	const hasMarketplacePermission = globalPermissionsForLoggedUser?.Marketplace;
	const hasRootPermisson = globalPermissionsForLoggedUser?.Root;

	const appsButtonRef = useRef();

	useEffect(() => {
		if (appsButtonRef.current) {
			if (isAppsOpen) {
				appsButtonRef.current.classList.add('nav-link-active');
			} else {
				appsButtonRef.current.classList.remove('nav-link-active');
			}
		}
	}, [isAppsOpen]);

	const apps = useMemo(
		() =>
			appMode == 'worklist' || appMode == 'marketplace' || appMode == 'helpCenter'
				? [
						{
							id: 2,
							name: 'Root',
							endpoint: '/root',
							svg: 'rootlogo',
							type: 'internal',
							hidden: !(__config.enable_Root && hasRootPermisson),
						},
						{
							id: 1,
							name: 'Blume',
							endpoint: `${
								sprinterBlumeStageingpolicy
									? __config.redirect_to_blume_url_stage
									: __config.redirect_to_blume_url
							}&domain_hint=${userIdP}&login_hint=${sessionStorage.getItem('login_hint')}&redirect_uri=${
								__config.blume_url
							}&sitename=pp`,
							svg: 'blumelogo',
							type: 'external',
						},
				  ]
				: [
						{
							name: 'OmegaAI',
							endpoint: `${
								sprinterOaiStageingpolicy
									? __config.redirect_to_omegaai_url_stage
									: __config.redirect_to_omegaai_url
							}&domain_hint=${userIdP}&login_hint=${sessionStorage.getItem('login_hint')}&redirect_uri=${
								__config.omegaai_url
							}`,
							svg: 'omegaailogo',
							type: 'external',
							hidden: !(
								(loggedInUser?.email &&
									loggedInUser?.email?.split('@')[1]?.toLowerCase()?.includes('ramsoft')) ||
								userInfo?.domain === 'ramsoft.com'
							),
						},
				  ],
		[appMode, hasRootPermisson, userInOmegaAI]
	);

	const handleClose = useCallback(setStateFunc => setStateFunc(false), []);
	const makeButton = useCallback(
		btn => {
			if (btn.path === 'apps') {
				return <AppsIcon />;
			}
			if (btn.path === 'profile') {
				btn.text = loggedInUser?.fullName || '';
				return patientImg ? (
					<MuiAvatar src={patientImg} sx={{ width: '34px', height: '34px' }} />
				) : (
					<Avatar label={loggedInUser?.fullName} transparent={false} />
				);
			}
			if (btn.iconType === 'svg') {
				return <Svg htmlColor="#ffffff99;" name={btn.icon} />;
			}
			if (btn.iconType === 'png') {
				return <Icon alt={btn.text} name={btn.icon} />;
			}
			// TODO: replace this with a better default, I guess
			return <Icon name={btn.icon} />;
		},
		[loggedInUser]
	);

	const navButtons = useMemo(
		() =>
			_.map(buttons, (btn, idx) =>
				btn.path == 'profile' && hideProfile ? null : (
					<NavButton
						key={idx}
						activeClassName={activeClassName}
						ariaLabel={`${btn.text} button`}
						className={classnames(`nav-link-icon-${btn.path}`, className)}
						icon={makeButton(btn)}
						path={btn.path}
						text={btn.text || ''}
						tooltipTxt={btn.tooltipTxt}
						onClick={e => {
							`${btn.path}`.split('/').includes('help') &&
								sessionStorage.setItem(
									'stuck-point',
									_.replace(window.location.pathname, __config.router_basename, '/')
								);
							if (btn.path == 'profile') {
								e.preventDefault();

								if (appMode === 'patientPortal') {
									return navigate('/profile');
								}

								setShowProfile(!showProfile);
							}
							if (btn.path === 'apps') {
								e.preventDefault();
								appsButtonRef.current = e.currentTarget;
								setIsAppsOpen(prev => !prev);
							} else if (btn.onClick) {
								btn.onClick();
							}
						}}
					/>
				)
			),
		[buttons, loggedInUser]
	);

	return (
		<ul>
			{navButtons}
			{apps?.length > 0 && (
				<SideBarApps
					apps={apps}
					enableMarketplace={
						hasMarketplacePermission &&
						(appMode == 'worklist' ||
							appMode == 'marketplace' ||
							appMode == 'helpCenter' ||
							appMode === 'patientPortal')
					}
					isOpen={isAppsOpen}
					t={t}
					x={sidebarWidth}
					y={20}
					onClose={() => {
						handleClose(setIsAppsOpen);
					}}
				/>
			)}
			<PopupUserSettings
				isOpen={showProfile}
				x={sidebarWidth}
				y={20}
				onClickSignOut={() => resetAndLogOut()}
				onClose={() => {
					handleClose(setShowProfile);
				}}
			/>
		</ul>
	);
};

SideBarAdmin.propTypes = {
	/**
	 * The name of the class to be applied to a NavButton when the button is active
	 */
	activeClassName: PropTypes.string,
	/**
	 * A list of buttons to be displayed on the component
	 */
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			iconType: PropTypes.string,
			text: PropTypes.string,
			icon: PropTypes.string,
			path: PropTypes.string,
		})
	),
	/**
	 * The width of the sidebar
	 */
	sidebarWidth: PropTypes.number,
};

export default SideBarAdmin;
