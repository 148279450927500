import React, { useEffect, useMemo, useState } from 'react';
import { ChevronRight, InfoOutlined, Language, OpenInNew, Phone } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import location from '@worklist-2/ui/src/assets/img/homepage/location.png';
import noImagingCenter from '@worklist-2/ui/src/assets/img/homepage/noImagingCenter.png';
import getValidURL from '@worklist-2/patientPortal/src/utils/getValidURL';
import { useIsTablet } from '@rs-core/utils/responsiveUtils';

export const getJsonValue = (fac, property) => {
	if (!fac || !property) return 'N/A';
	let parsedValues = {};
	try {
		if (fac?.extJson) {
			parsedValues = JSON.parse(fac?.extJson);
		}
	} catch (_err) {
		parsedValues = {};
	}

	if (property === 'phone') {
		const { telecom } = parsedValues;
		if (!telecom?.length) return 'N/A';
		return telecom?.find(d => d.system === 'phone')?.value || 'N/A';
	}
	return parsedValues[property] || 'N/A';
};

const FacilityListV2 = ({
	facilities = [],
	onSelectFacility = Function.prototype,
	facility,
	fullWidth,
	isSelfScheduling,
}) => {
	const [hasOfflineFacilities, setHasOfflineFacilities] = useState(false);
	const [expandOffline, setExpandOffline] = useState(false);

	const { t } = useTranslation(['scheduling', 'appointments']);

	const isTablet = useIsTablet();

	useEffect(() => {
		setExpandOffline(facilities?.length && facilities.every(d => !d?.isAppointmentBookingAllowed));
		setHasOfflineFacilities(facilities?.some(d => !d?.isAppointmentBookingAllowed));
	}, [facilities]);

	return (
		<Box
			sx={{
				width: fullWidth ? '100%' : 'min(550px,100%)',
				maxHeight: '700px',
				pb: isTablet ? 2 : 10,
				overflowY: 'auto',
				height: 'auto',
				overflowX: 'hidden',
				mt: 1.5,
			}}
		>
			{!facilities?.length ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						padding: { xs: '0 5px', sm: '0px 16%' },
						mt: 4,
					}}
				>
					<Box
						sx={{
							marginBottom: '12px',
						}}
					>
						<img alt="search" src={noImagingCenter} width="140px" />
					</Box>
					<Typography
						fontSize="16px"
						fontWeight={600}
						lineHeight="24px"
						marginBottom="8px"
						textAlign="center"
					>
						{t('No Imaging Centers Available')}
					</Typography>
					<Typography fontSize="14px" fontWeight={400} lineHeight="20px" textAlign="center">
						{t(
							'No Imaging Centers are available within 50 km of your selected location. Please try a different location to continue.'
						)}
					</Typography>
				</Box>
			) : (
				facilities
					.filter(d => Boolean(d?.isAppointmentBookingAllowed))
					.map(fac => (
						<FacilityItem
							key={fac?.internalOrganizationID}
							disabled={false}
							fac={fac}
							isSelected={
								isSelfScheduling
									? facility === fac?.organizationName
									: facility?.internalOrganizationID === fac?.internalOrganizationID
							}
							isSelfScheduling={isSelfScheduling}
							onClick={onSelectFacility(fac)}
						/>
					))
			)}
			{hasOfflineFacilities ? (
				expandOffline ? (
					<>
						<Divider sx={{ mt: 2 }} variant="fullWidth" />
						<Typography
							color="#475467"
							display="block"
							fontSize={14}
							fontWeight={500}
							mb={1}
							mt={2}
							variant="p"
						>
							{t('Other Healthcare Providers')}
						</Typography>
						<Box
							alignItems="center"
							border="2px solid #FEDF89"
							borderRadius="6px"
							display="flex"
							mb={1.5}
							pr={{ md: '32px' }}
							py={1}
							sx={{ background: '#FFFAEB' }}
						>
							<InfoOutlined sx={{ mx: 1, color: '#DC6803' }} />{' '}
							<Typography
								color="#DC6803"
								flex={1}
								fontSize={13}
								fontWeight={500}
								lineHeight={1.2}
								textAlign="left"
							>
								{t(
									'These facilities doesnt support online booking through blume. Please contact the facility for more details'
								)}
							</Typography>
						</Box>
						{facilities
							.filter(d => !d?.isAppointmentBookingAllowed)
							.map(fac => (
								<FacilityItem
									key={fac?.internalOrganizationID}
									disabled
									fac={fac}
									isSelected={false}
									isSelfScheduling={false}
									onClick={null}
								/>
							))}
					</>
				) : (
					<Box
						alignItems="center"
						border="2px solid #D0D5DD"
						borderRadius="6px"
						display="flex"
						justifyContent="space-between"
						mt={4}
						sx={{ background: '#F2F4F7' }}
					>
						<InfoOutlined sx={{ mx: 1, color: '#667085' }} />{' '}
						<Typography
							color="#121926"
							flex={1}
							fontSize="13px"
							fontWeight={500}
							sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
							textAlign="left"
							width={{ xs: '70%', md: '100%' }}
						>
							{t('offlineHealthcareProvidersAvailableCountDescription', {
								count: facilities.filter(d => !d?.isAppointmentBookingAllowed)?.length,
								ns: 'appointments',
							})}
						</Typography>
						<Button
							color="primary"
							sx={{ color: 'var(--color-primary)', textTransform: 'none' }}
							variant="text"
							onClick={() => setExpandOffline(true)}
						>
							{t('View')} <ChevronRight />
						</Button>
					</Box>
				)
			) : null}
		</Box>
	);
};

export default FacilityListV2;

export const FacilityItem = ({ fac, disabled, onClick, isSelfScheduling, isSelected }) => {
	const { t } = useTranslation(['scheduling', 'appointments']);

	const parsedExtension = useMemo(() => JSON.parse(fac.extJson), [fac]);

	const facilityPhone = useMemo(() => fac?.organizationPhone, [fac?.organizationPhone]);

	const facilityAddress = useMemo(() => (parsedExtension?.address ? parsedExtension?.address : []), []);

	const displayAddress = useMemo(
		() =>
			isSelfScheduling
				? `${facilityAddress[0]?.line[0]}, ${facilityAddress[0]?.city}, ${facilityAddress[0]?.state} ${facilityAddress[0]?.postalCode}`
				: fac.address,
		[fac?.address, facilityAddress, isSelfScheduling]
	);

	return (
		<Box
			key={fac?.internalOrganizationID}
			data-testid={`facility${fac?.internalOrganizationID}`}
			sx={{
				borderRadius: '8px',
				padding: 1,
				mb: 1.4,
				display: 'flex',
				alignItems: 'start',
				cursor: !disabled && 'pointer',
				background: disabled ? '#F2F4F7' : isSelected ? '#F3FAFF' : '#FCFCFD',
				border: isSelected ? '2.5px solid var(--color-primary)' : '1px solid #D0D5DD',
			}}
			onClick={onClick}
		>
			<Box sx={{ flex: 1, mx: 1, width: { xs: '160px', lg: 'auto' } }}>
				<Typography color="#101828" fontSize="14px" fontWeight={500}>
					{fac?.organizationName}
				</Typography>
				<Typography color="#697586" fontSize="13px" mt={0.2}>
					{displayAddress}
				</Typography>
				<Typography
					mt={0.5}
					sx={{
						display: 'flex',
						alignItems: 'center',
						color: '#697586',
						fontSize: '13px',
						gap: '8px',
					}}
				>
					{facilityPhone ? (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Phone fontSize="small" sx={{ mr: '5px' }} />
							<Box
								component="a"
								href={`tel:${facilityPhone}`}
								sx={{
									color: '#42A5F5',
									textDecoration: 'none',
									fontSize: '14px',
									whiteSpace: 'nowrap',
								}}
							>
								{facilityPhone}
							</Box>
						</Box>
					) : null}
					{getValidURL(fac?.websiteUrl) ? (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								component="a"
								href={`${getValidURL(fac?.websiteUrl)}`}
								rel="noopener"
								sx={{
									display: 'flex',
									alignItems: 'center',
									color: '#42A5F5',
									fontSize: '14px',
									textDecoration: 'none',
								}}
								target="_blank"
							>
								<Language fontSize="small" sx={{ mx: '5px' }} />
								<Box>{fac?.websiteUrl}</Box>
								<OpenInNew
									sx={{ ml: '5px', color: 'var(--color-primary)', opacity: 0.5, fontSize: '14px' }}
								/>
							</Box>
						</Box>
					) : null}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
					justifyContent: 'space-evenly',
				}}
			>
				{Boolean(fac?.distance) && (
					<Stack
						alignItems="center"
						direction="row"
						justifyContent="flex-end"
						mt={1}
						sx={{ color: '#344054', whiteSpace: 'nowrap' }}
					>
						<img alt="location" height="14px" src={location} />
						<Typography fontSize={14} mx={0.3}>
							{t('Distance:')}
						</Typography>
						<Typography fontSize={14} fontWeight="bold">
							{fac?.distance} {t('away')}
						</Typography>
					</Stack>
				)}
				<Button
					color="primary"
					component="a"
					href={`https://www.google.com/maps/place/${encodeURIComponent(fac?.address)}`}
					rel="noopener"
					sx={{ color: '#42A5F5', textTransform: 'none', textAlign: 'right', whiteSpace: 'nowrap' }}
					target="_blank"
					variant="text"
				>
					{t('View in maps')}
				</Button>
			</Box>
		</Box>
	);
};
