import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import _ from 'lodash';
import ButtonBase from '@mui/material/ButtonBase';

// eslint-disable-next-line import/no-unresolved
import { useConfig } from '@rs-core/context/ConfigContext';
// eslint-disable-next-line import/no-unresolved
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import MobileGallery from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileGallery/MobileGallery';
import MobileSharedWithSection from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailSections/MobileSharedWithSection';
import MobileReportsSection from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailSections/MobileReportsSection';
import MobileStudyInformationSection from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailSections/MobileStudyInformationSection';
import FormsView from '@worklist-2/patientPortal/src/views/StudyInfoView/StudyDetailedView/FormsView';
import getStudyReports from '@worklist-2/patientPortal/src/api/getStudyReports';
import useStudyReportsQuery from '@worklist-2/patientPortal/src/queries/useStudyReportsQuery';

import { REPORT_DATA } from '../../../__mocks__/mockData';
import { Skeleton } from '@mui/material';

const MobileStudyDetailCompleted = ({ study, fullScreenMode, studyAdditionalInfo, onShareClick }) => {
	const [reportDetails, setReportDetails] = useState([]);
	const [showMobileGallery, setShowMobileGallery] = useState(false);

	const { data: reports, isLoading } = useStudyReportsQuery({ studyId: study.id });
	const { t } = useTranslation('studyDetail');
	const phoenixBlumeMobileStudyDetailOptimization = useBooleanFlagValue(
		'phoenix-blume-mobile-study-detail-optimization'
	);
	const __config = useConfig();

	useEffect(() => {
		if (!study?.id || phoenixBlumeMobileStudyDetailOptimization) return;

		getReports();
	}, [getReports, phoenixBlumeMobileStudyDetailOptimization, study?.id]);

	const getReports = useCallback(async () => {
		if (_.has(REPORT_DATA, study?.id)) {
			setReportDetails(REPORT_DATA[study?.id]);
			return;
		}

		getStudyReports({ __config, id: study.id }).then(result => {
			if (!result?.entry) return;

			const reportList = result?.entry.map((elem, index) => ({
				id: index,
				selected: false,
				reportName: elem?.resource?.presentedForm[0]?.title || '',
				doctorName: elem?.resource?.resultsInterpreter?.[0]?.display || '',
				reportPath: `${__config.data_sources.blume}Study/report/${elem?.resource?.id}/content`,
				reportId: elem?.resource?.id || '',
			}));

			setReportDetails(reportList);
		});
	}, [__config, study?.id]);

	return (
		<Box className="notranslate" data-testid="completedDetailMobile">
			{showMobileGallery && <MobileGallery closeGallery={() => setShowMobileGallery(false)} study={study} />}

			<Box sx={{ padding: '16px' }}>
				<MobileStudyInformationSection study={study} />

				{study?.studyThumbnail?.seriesUID && (
					<ButtonBase
						data-testid="view-images-btn"
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							padding: '22px 12px',
							backgroundColor: 'rgba(250, 250, 250, 1)',
							marginBottom: '24px',
						}}
						onClick={() => setShowMobileGallery(true)}
					>
						<Typography
							sx={{
								color: 'rgba(66, 165, 245, 1)',
								fontFamily: 'Roboto',
								fontSize: '16px',
								fontWeight: '500',
							}}
						>
							{t('View images')}
						</Typography>
						<KeyboardArrowRightIcon sx={{ fill: 'rgba(66, 165, 245, 1)' }} />
					</ButtonBase>
				)}

				{phoenixBlumeMobileStudyDetailOptimization && isLoading ? (
					<Skeleton
						sx={{
							height: '60px',
							width: '100%',
							borderRadius: '12px',
							margin: '0 0 1.5em 0',
						}}
						variant="rectangular"
					/>
				) : (
					<MobileReportsSection
						fullScreenMode={fullScreenMode === 'reportViewer'}
						reports={phoenixBlumeMobileStudyDetailOptimization ? reports : reportDetails}
					/>
				)}
				{study?.patientId && <FormsView patientId={study?.patientId} studyId={study?.id} />}
				<MobileSharedWithSection
					study={study}
					studyAdditionalInfo={studyAdditionalInfo}
					sx={{ marginBottom: '50px' }}
					onShareClick={onShareClick}
				/>
			</Box>
		</Box>
	);
};

export default MobileStudyDetailCompleted;
