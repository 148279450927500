import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// components
import { useImportTabContext } from '@rs-core/context/ImportTabContext';
import fhirEndpoints from '@rs-core/fhir/fhirEndpoints';
import { GenericDrawer } from '..';
import TeachingFolder from '@worklist-2/worklist/src/routes/TeachingFolder';
import { DrawerTabSettings } from '../DrawerTabs';
import { useForm, Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import SecondaryButton from '@rs-ui/components/SecondaryButton';
import PrimaryButton from '@rs-ui/components/PrimaryButton';

// Utils
import { updateTemporaryFilters, updateTemporarySort } from '@worklist-2/ui/src/components/utils/gridUtils';

// Libraries
import _ from 'lodash';

const TeachingFolderDrawer = ({ anchor, title, columns, filters, sort, isAddingStudies, width, ...otherProps }) => {
	const { t } = useTranslation('teaching');
	const { sideBarIsOpen } = useImportTabContext();

	// Grid column configuration
	const [componentColumnList, setComponentColumnList] = useState([]);
	const [columnData, setColumnData] = useState({ columns, filters, sort });

	const closeDrawer = () => {
		otherProps.setDrawerOpen(false);
	};

	/**
	 * Form to handle the column settings
	 */
	const { handleSubmit, control, reset } = useForm({
		defaultValues: useMemo(
			() => ({
				practitioner: {
					id: '1',
					reference: 'Practitioner/1',
					display: 'ADMIN',
				},
				columns,
			}),
			[columns]
		),
	});

	/**
	 * Reset the form when the columns change
	 */
	useEffect(() => {
		reset({
			columns,
		});
	}, [columns]);

	useEffect(() => {
		setColumnData({ columns, filters, sort });
	}, [columns, filters, sort]);

	/**
	 * Submit the form
	 */
	const onSubmit = useCallback(() => {
		const tempColumnData = _.cloneDeep(columnData);
		if (columnData.columns) {
			tempColumnData.filters = updateTemporaryFilters(tempColumnData.columns);
			tempColumnData.sort = updateTemporarySort(tempColumnData.columns);
		}
		otherProps.onDataSubmit(tempColumnData);
	}, [columnData, otherProps]);

	return (
		<GenericDrawer
			anchor={isAddingStudies ? 'left' : anchor}
			showHeader={!isAddingStudies}
			{...otherProps}
			PaperProps={
				isAddingStudies
					? {
							sx: { left: sideBarIsOpen ? '280px' : '64px' },
					  }
					: {}
			}
			title={!isAddingStudies ? title : ''}
			width={!isAddingStudies ? width : 'fit-content'}
		>
			{isAddingStudies ? (
				<TeachingFolder isAddingStudies closeDrawer={closeDrawer} />
			) : (
				<>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							control={control}
							name="columns"
							render={({ field }) => (
								<DrawerTabSettings
									{...field}
									isLarge
									columnBuilderHeight="calc(100vh - 300px)"
									componentColumnList={componentColumnList}
									endpoint={fhirEndpoints.imagingStudyWorklist}
									filters={filters}
									setComponentColumnList={setComponentColumnList}
									userSavedColumnList={columns}
									onChange={data => {
										setColumnData({ ...columnData, columns: data });
									}}
								/>
							)}
						/>
					</form>
					<Box
						sx={{
							position: 'fixed',
							bottom: 0,
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: 391,
							pb: '20px',
							px: '48px',
						}}
					>
						<SecondaryButton label={t('Cancel')} onClick={closeDrawer} />
						<PrimaryButton label={t('Save')} placement="top-left" onClick={handleSubmit(onSubmit)} />
					</Box>
				</>
			)}
		</GenericDrawer>
	);
};

TeachingFolderDrawer.propTypes = {
	anchor: PropTypes.string,
	title: PropTypes.string,
	columns: PropTypes.array,
	filters: PropTypes.array,
	sort: PropTypes.array,
	isAddingStudies: PropTypes.bool,
	width: PropTypes.number,
};

TeachingFolderDrawer.defaultProps = {
	anchor: 'right',
	title: '',
	isAddingStudies: false,
	width: 300,
	columns: [],
	filters: [],
	sort: [],
};

export default TeachingFolderDrawer;
