// Core

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

// MUI

import { Box, Button, Divider, Grid, Icon, IconButton, Stack, Typography, Tooltip } from '@mui/material';

import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import AddIcon from '@mui/icons-material/Add';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';

// Custom
import ChatCallDuration from './ChatCallDuration';
import ChatConversationDelete from './ChatConversationDelete';
import { useChatContext } from './ChatContext';
import ChatList, { ChatListItemUser } from './ChatList';
import ChatHeader, { ChatHeaderButton, CHAT_HEADER_HEIGHT } from './ChatHeader';
import { ChatHeading2 } from './ChatHeading';
import ChatConversationMessaging from './ChatConversationMessaging';
import ChatConversationCall from './ChatConversationCall';
import ChatConversationFiles from './ChatConversationFiles';
import ChatConversationAddUsers from './ChatConversationAddUsers';
import ChatSearch from './ChatSearch';
import ChatRemoveButton from './ChatRemoveButton';
import CHAT_CONVERSATION_TYPES from './CHAT_CONVERSATION_TYPES';
import CHAT_SECTIONS, { CHAT_SECTIONS_default, DEFAULT_SUPPORTER } from './CHAT_SECTIONS';
import { useTranslation } from 'react-i18next';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useAuth } from '@rs-core/context/UserAuthContext';

import { joinCall, endCall, muteAudio, shareScreen } from './ConversationHelper';

import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ChatConversation = ({ className, sx, conversation, commonHeaderIcons, onHeaderMouseDown, isAiChat = false }) => {
	const {
		state,
		section,
		setSection,
		conversationType,
		setConversationType,
		expanded,
		showNavigation,
		showHeaderInCall,
		showFiles,
		dispatch,
		callData,
		setCallData,
		callRoom,
		setCallRoom,
		setCallParticipants,
		callParticipants,
		screenShare,
		setScreenShare,
		smallView,
		appBlumeMode,
		search,
		setSearch,
	} = useChatContext();

	const phoenixEnableVoiceCallBetweenOaiAndBlume = useBooleanFlagValue(
		'phoenix-enable-voice-call-between-oai-and-blume'
	);
	const proactEnableVoiceCalling = useBooleanFlagValue('proact-enable-voice-calling');
	const proactEnableVideoCalling = useBooleanFlagValue('proact-enable-video-calling');
	const proactEnableGroupVoiceCalling = useBooleanFlagValue('proact-enable-group-voice-calling');
	const proactEnableChatFiles = useBooleanFlagValue('proact-enable-chat-files');

	const { t } = useTranslation('chat');
	if (section.addUsers && !expanded) {
		return <ChatConversationAddUsers />;
	}

	// Temporary fake data
	const files = useMemo(
		() =>
			Math.random() > 0.1
				? [
						{
							name: 'Lorem',
							date: '22/22/2022',
							user: 'Ipsum',
							type: 'pdf',
						},
						{
							name: 'Lorem',
							date: '22/22/2022',
							user: 'Ipsum',
							type: 'image',
						},
						{
							name: 'Lorem',
							date: '22/22/2022',
							user: 'Ipsum',
							type: 'video',
						},
				  ]
				: null,
		[]
	);

	const [convTitle, setConvTitle] = useState(DEFAULT_SUPPORTER.name); // adding support as a default label for the Avatar fix PRO-3801
	const __config = useConfig();
	const { loggedInUser, setChatMsgReceived, chatMsgReceived, contacts } = useAuth();

	const loggedInEmail = loggedInUser?.email?.toLowerCase();
	const typeMessaging = conversationType === CHAT_CONVERSATION_TYPES.MESSAGING;
	const typeCall = conversationType === CHAT_CONVERSATION_TYPES.CALL;

	const header = showHeaderInCall || !typeCall;
	const headerFiles = showFiles && files && !typeCall;
	const headerFilesActive = headerFiles && section.files;

	const callConversationSid = callData?.conversation?.sid ?? callData?.callNotification?.conversationSid;

	const group = state ? state[conversation?.sid]?.participantCount > 2 : false;
	const [groupMembersVisible, setGroupMembersVisible] = useState(false);
	const { appMode } = useAppModeContext();

	const classList = [
		'chat-conversation',
		typeMessaging ? 'chat-conversation--type-messaging' : '',
		typeCall ? 'chat-conversation--type-call' : '',
		className,
	].filter(Boolean);

	useEffect(() => {
		// Clear the search value in case of opening the Chat Conversation screen from the Chat Contact list and seach is not empty
		!!search && setSearch(null);
	}, []);

	useEffect(() => {
		// Stop sharing screen when clicking on Stop sharing button on the floating widget provided by the browser
		if (screenShare?.localScreenTrack) {
			screenShare?.localScreenTrack.addEventListener('ended', handleScreenShare);
		}

		return () => {
			if (screenShare?.localScreenTrack) {
				screenShare?.localScreenTrack.removeEventListener('ended', handleScreenShare);
			}
		};
	}, [screenShare?.localScreenTrack]);

	useEffect(() => {
		if (state && conversation) {
			const { name } = getParticipantInfo(section, conversation, state);

			setConvTitle(name || '');
		}
	}, [state, conversation]);

	useEffect(() => {
		// Show/hide blue dot on the chat icon on feature bar
		if (state) {
			const unreadConvs = Object.entries(state).filter(item => item[1].unread);
			const currUnreadConv = unreadConvs?.filter(conv => conv[1]?.conversation?.sid == conversation?.sid);
			if (chatMsgReceived && currUnreadConv?.length > 0) {
				currUnreadConv[0][1].conversation.setAllMessagesRead();
				dispatch({
					msgtype: 'MESSAGE_READ',
					payload: currUnreadConv[0][1].conversation,
				});
				// If the unread message is from the conversation that is being opened and only one conversation has unread message,
				// set chatMsgReceived to false, so it does not show the blue dot on the chat icon at this point
				if (unreadConvs?.length == 1) {
					setChatMsgReceived(false);
				}
			}
		}
	}, [state, chatMsgReceived]);

	// refactoring new method for the PR comments on https://github.com/ramsoft-inc/OmegaAI-Mono/pull/15147
	const getParticipantInfo = (section, conversation, state) => {
		if (section?.name === CHAT_SECTIONS.SUPPORT) {
			const isSupporterOnline = conversation?.participantOnline || DEFAULT_SUPPORTER.isOnline;

			const participantName =
				conversation && state[conversation?.sid]?.name
					? state[conversation?.sid]?.name
					: DEFAULT_SUPPORTER.name;

			return { isOnline: isSupporterOnline, name: participantName };
		}
		const participantName = state[conversation?.sid]?.name;
		return { name: participantName, isOnline: conversation?.participantOnline };
	};

	const _backClickHandler = useCallback(() => {
		if (expanded || !showNavigation || !showHeaderInCall) {
			return null;
		}

		return async () => {
			setSection(CHAT_SECTIONS_default);
			setConversationType(CHAT_CONVERSATION_TYPES.MESSAGING);
			// Remove Conversation
			const count = await conversation.getMessagesCount();
			const participantCount = await conversation.getParticipantsCount();
			if (count === 0 && participantCount < 3) {
				// Dispatch state removal payload
				dispatch({
					msgtype: 'CONVERSATION_LEFT',
					payload: conversation,
				});
				// delete conversation
				conversation.delete();
			}
		};
	}, [expanded, showNavigation, showHeaderInCall, setSection, conversation]);

	const _groupMembersClickHandler = useMemo(() => {
		if (!group) {
			return;
		}

		return () => {
			setSection({
				name: CHAT_SECTIONS.GROUP_REMOVE_PARTICIPANTS,
				conversation,
			});
		};
	}, [group, setGroupMembersVisible]);

	const _filesClickHandler = useMemo(() => {
		if (!files) {
			return null;
		}

		return () => {
			setSection(value => ({ ...value, files: !value?.files }));
		};
	}, [files, setSection]);

	const _addUsersClickHandler = useCallback(
		e => {
			e.stopPropagation();
			setSection({
				name: CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS,
				conversation,
			});
		},
		[setSection]
	);

	const remoteParticipants = conversation?.attributes?.participants?.filter(user => user.email !== loggedInEmail);

	const handleMuteAudio = e => {
		e.stopPropagation();
		muteAudio(setCallData, callRoom, callData);
	};

	const handleScreenShare = () => {
		shareScreen(callRoom, screenShare, setScreenShare);
	};

	const _callClickHandler = e => {
		e.stopPropagation();
		joinCall(
			appBlumeMode,
			callData,
			setCallData,
			state,
			setSection,
			setConversationType,
			__config,
			loggedInUser,
			callRoom,
			setCallRoom,
			proactEnableVideoCalling,
			setCallParticipants,
			callParticipants,
			setScreenShare,
			true,
			null,
			null,
			conversation,
			contacts
		);
	};

	const _endCallClickHandler = e => {
		e.stopPropagation();
		endCall(
			callRoom,
			setCallRoom,
			setCallData,
			setCallParticipants,
			setSection,
			setConversationType,
			setScreenShare,
			callParticipants,
			conversation
		);
	};

	const _acceptCallClickHandler = e => {
		e.stopPropagation();
		joinCall(
			appBlumeMode,
			callData,
			setCallData,
			state,
			setSection,
			setConversationType,
			__config,
			loggedInUser,
			callRoom,
			setCallRoom,
			proactEnableVideoCalling,
			setCallParticipants,
			callParticipants,
			setScreenShare
		);
	};

	const onCallHeaderClick = () => {
		setConversationType(CHAT_CONVERSATION_TYPES.CALL);
	};

	const tooltip = (element, title) => <Tooltip title={title ?? ''}>{element}</Tooltip>;

	return section.addUsers && !expanded ? (
		<ChatConversationAddUsers />
	) : (
		<Grid
			className={classList.join(' ')}
			flex="auto"
			sx={{
				display: 'grid',
				gridTemplateRows: headerFilesActive
					? 'min-content min-content 1fr'
					: header
					? 'min-content 1fr'
					: '100%',
				overflow: 'hidden',
				position: 'relative',

				'.chat-layout--expanded &': {
					borderRadius: 'var(--border-radius-base)',
				},

				...sx,
			}}
		>
			{(header && conversation != null) || (header && section?.name === CHAT_SECTIONS.SUPPORT) ? (
				<ChatHeader
					commonHeaderIcons={commonHeaderIcons}
					conversation={conversation}
					isAiChat={isAiChat}
					isOnline={getParticipantInfo(section, conversation, state)?.isOnline}
					text={
						isAiChat
							? ''
							: group
							? `${conversation?._participants?.size || state[conversation?.sid]?.participantCount} ${t(
									'chatConversation.participants'
							  )}`
							: conversation
							? conversation?.participantOnline
								? t('chatConversation.online')
								: t('chatConversation.offline')
							: section?.name === CHAT_SECTIONS.SUPPORT
							? t('chatWithSupport.onQueue')
							: null
					}
					title={convTitle}
					userCount={state[conversation?.sid]?.participantCount}
					onBackClick={_backClickHandler(conversation)}
					onCallHeaderClick={
						conversationType === CHAT_CONVERSATION_TYPES.MESSAGING &&
						conversation?.sid === callData?.conversation?.sid
							? onCallHeaderClick
							: null
					}
					onContactClick={_groupMembersClickHandler}
					onHeaderMouseDown={onHeaderMouseDown}
				>
					{(!isAiChat && !typeCall) || (typeCall && conversation?.sid !== callConversationSid) ? (
						<>
							{proactEnableChatFiles && headerFiles && section?.name !== CHAT_SECTIONS.SUPPORT ? (
								<>
									<Button
										sx={{
											borderRadius: '15px',
											color: 'currentColor',
											flex: 'none',
											fontWeight: 400,
											height: '30px',
											lineHeight: 'normal',
											minWidth: 0,
											paddingLeft: '10px',
											paddingRight: '10px',
											textTransform: 'none',
										}}
										onClick={_filesClickHandler}
									>
										2 files
									</Button>

									<Divider
										orientation="vertical"
										sx={{
											alignSelf: 'center',
											height: '20px',
										}}
									/>
								</>
							) : null}

							{section?.name !== CHAT_SECTIONS.SUPPORT &&
								conversationType === CHAT_CONVERSATION_TYPES.MESSAGING &&
								conversation?.sid !== callConversationSid && (
									<ChatHeaderButton title={t('icon.addParticipants')} onClick={_addUsersClickHandler}>
										<Icon
											component={appMode != 'patientPortal' ? PersonAddOutlinedIcon : AddIcon}
										/>
									</ChatHeaderButton>
								)}
							{(group ? proactEnableGroupVoiceCalling : proactEnableVoiceCalling) &&
								!(
									conversationType === CHAT_CONVERSATION_TYPES.MESSAGING &&
									callData?.isCall &&
									conversation?.sid === callConversationSid
								) &&
								!group &&
								(phoenixEnableVoiceCallBetweenOaiAndBlume
									? true
									: !Object.keys(state)
											.filter(key => key === conversation?.sid)
											.reduce((obj, key) => {
												obj[key] = state[key];
												return obj;
											}, {})[conversation?.sid]?.isBlumeConversation) &&
								section?.name !== CHAT_SECTIONS.SUPPORT && (
									<ChatHeaderButton
										disabled={callData?.status?.calling || callData?.status?.onCall}
										sx={{ mr: '10px' }}
										title={t('icon.call')}
										onClick={_callClickHandler}
									>
										<Icon component={PhoneOutlinedIcon} />
									</ChatHeaderButton>
								)}

							{callData?.isCall && conversation?.sid === callConversationSid ? (
								<>
									{(callData?.status?.calling || callData?.status?.incoming) && (
										<Typography
											sx={{
												color: 'rgba(255, 255, 255, 0.8)',
												fontSize: '12px',
												lineHeight: '14px',
												fontWeight: '500px',
												mt: '3px',
												mr: '8px',
											}}
										>
											{callData?.status?.calling
												? `${t('call.ringing')}...`
												: `${t('call.incomingCall')}...`}
										</Typography>
									)}
									{callData?.status?.onCall && callRoom?.participants?.size >= 1 && (
										<>
											<ChatCallDuration
												textStyle={{
													fontSize: '14px',
													lineHeight: '16px',
													color: 'rgba(255, 255, 255, 0.8)',
													mr: '10px',
												}}
											/>
											{tooltip(
												<IconButton
													sx={{
														borderRadius: '5px',
														height: '32px',
														width: '32px',
														backgroundColor: 'rgba(255, 255, 255, 0.2)',
														mr: '10px',
													}}
													onClick={handleMuteAudio}
												>
													{callData?.audioIsMute ? (
														<MicOffOutlinedIcon
															data-testid="micOffIcon"
															sx={{ color: appBlumeMode && 'white' }}
														/>
													) : (
														<MicNoneOutlinedIcon
															data-testid="micOnIcon"
															sx={{ color: appBlumeMode && 'white' }}
														/>
													)}
												</IconButton>,
												callData?.audioIsMute ? t('icon.unmute') : t('icon.mute')
											)}
										</>
									)}
									{tooltip(
										<IconButton
											data-testid="endCallIcon"
											sx={{
												borderRadius: '5px',
												height: '30px',
												width: '30px',
												backgroundColor: '#FF6666',
												mr: '5px',
											}}
											onClick={_endCallClickHandler}
										>
											<CallEndOutlinedIcon sx={{ color: appBlumeMode && 'white' }} />
										</IconButton>,
										callData?.status?.incoming ? t('icon.reject') : t('icon.endCall')
									)}
									{callData?.status?.incoming &&
										tooltip(
											<IconButton
												data-testid="acceptCallIcon"
												sx={{
													borderRadius: '5px',
													height: '30px',
													width: '30px',
													backgroundColor: '#42A5F5',
													mr: '5px',
													'&:hover': {
														backgroundColor: '#42A5F5',
													},
												}}
												onClick={_acceptCallClickHandler}
											>
												<CallOutlinedIcon
													sx={{
														height: '20px',
														width: '20px',
														color: appBlumeMode && 'white',
													}}
												/>
											</IconButton>,
											t('icon.answer')
										)}
								</>
							) : null}

							{section?.name === CHAT_SECTIONS.SUPPORT && (
								<ChatConversationDelete conversation={conversation} />
							)}
						</>
					) : null}
					{smallView && commonHeaderIcons}
				</ChatHeader>
			) : null}
			{headerFilesActive && proactEnableChatFiles ? <ChatConversationFiles data={files} /> : null}

			{(typeMessaging || (typeCall && conversation?.sid !== callData?.conversation?.sid)) &&
			conversation != null ? (
				<ChatConversationMessaging conversation={conversation} isAiChat={isAiChat} isGroupChat={group} />
			) : typeCall ? (
				<ChatConversationCall
					handleMuteAudio={handleMuteAudio}
					handleScreenShare={handleScreenShare}
					loggedInEmail={loggedInEmail}
					onAcceptCallClick={_acceptCallClickHandler}
					onAddUsersClick={_addUsersClickHandler}
					onEndCallClick={_endCallClickHandler}
				/>
			) : null}

			{typeMessaging && section.name === CHAT_SECTIONS.SUPPORT && !conversation && (
				<ChatConversationMessaging conversation={conversation} />
			)}
			{group && groupMembersVisible ? (
				<Box
					className={classList.join(' ')}
					flex="auto"
					sx={{
						display: 'grid',
						gridTemplateRows: headerFilesActive
							? 'min-content min-content 1fr'
							: header
							? 'min-content 1fr'
							: '100%',
						overflow: 'hidden',
						position: 'relative',

						'.chat-layout--expanded &': {
							borderRadius: 'var(--border-radius-base)',
						},

						...sx,
					}}
				>
					<Stack
						alignItems="center"
						className="chat-conversation-members-header"
						direction="row"
						sx={{
							'--element-background-color': 'var(--chat-background-color)',
							background: 'var(--element-background-color)',
							height: `${CHAT_HEADER_HEIGHT}px`,
							padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
						}}
					>
						<ChatHeading2 sx={{ flex: 'auto' }}>{t('chatConversation.chatParticipants')}</ChatHeading2>

						<IconButton
							sx={{
								color: 'var(--element-foreground-color)',
								flex: 'none',
							}}
							onClick={() => setGroupMembersVisible(false)}
						>
							<Icon component={CloseIcon} />
						</IconButton>
					</Stack>

					<Stack>
						<ChatSearch placeholder={t('chatSearch.searchContactsPlaceholder')} t={t} />

						<ChatList>
							{remoteParticipants.map((item, index) => (
								<ChatListItemUser key={index} data={item} text={item.text}>
									<ChatRemoveButton onClick={() => {}} />
								</ChatListItemUser>
							))}
						</ChatList>
					</Stack>
				</Box>
			) : null}
		</Grid>
	);
};

ChatConversation.propTypes = {
	className: PropTypes.string,
	sx: PropTypes.object,
	conversation: PropTypes.object,
	commonHeaderIcons: PropTypes.object,
	onHeaderMouseDown: PropTypes.func,
};

export default ChatConversation;
