// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

// Custom
import ChatSearchHighlightMatches from './ChatSearchHighlightMatches';
import ChatCallingAvatar from './ChatCallingAvatar';
import { CHAT_AVATAR_SIZE } from './ChatAvatar';
import ChatContact, { CHAT_CONTACT_SPACING } from './ChatContact';
import { FormatChatDateTime } from './ChatDateHelper';

import { useChatContext } from './ChatContext';
import ChatCallDuration from './ChatCallDuration';
import { joinCall, endCall, muteAudio } from './ConversationHelper';
import CHAT_SECTIONS from './CHAT_SECTIONS';

import { useAuth } from '@rs-core/context/UserAuthContext';
import { useConfig } from '@rs-core/context/ConfigContext';

import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// Styled components
const ChatListHeader = styled(ListSubheader)({
	background: 'var(--element-background-color)',
	alignItems: 'center',
	display: 'flex',
	gap: '10px',
	height: '50px',
	justifyContent: 'space-between',
	padding: 0,
});

const ChatListUnread = styled(Box)({
	backgroundColor: 'var(--color-primary, #42A5F5)',
	borderRadius: '50%',
	flex: 'none',
	height: '8px',
	width: '8px',
});

// List Datetime component

const ChatListDatetime = ({ value, sx }) => (
	<Typography
		component="time"
		dateTime={value}
		sx={{
			color: 'var(--element-foreground-color-secondary)',
			flex: 'none',
			fontSize: '14px',
			fontStyle: 'italic',
			fontWeight: 300,
			...sx,
		}}
	>
		{FormatChatDateTime(value)}
	</Typography>
);

ChatListDatetime.propTypes = {
	value: PropTypes.any.isRequired, // Specify later,
	sx: PropTypes.object,
};

// List Contact component

const ChatListContact = ({ title, userCount, text, isOnline, isBlumeUser }) => {
	const { search } = useChatContext();

	return (
		<ChatContact
			isBlumeUser={isBlumeUser}
			isOnline={isOnline}
			sx={{ flex: 'auto', minWidth: 0 }}
			title={title}
			userCount={userCount}
		>
			{text ? (
				<Typography
					data-testid="chat-list-contact-text"
					sx={{
						color: 'var(--element-foreground-color-secondary, #717171)',
						fontSize: '12px',
						fontStyle: 'italic',
						lineHeight: `${CHAT_AVATAR_SIZE / 2}px`,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{search != null && (search.main?.searchString || search.new?.searchString) !== '' ? (
						<ChatSearchHighlightMatches
							searchString={search.main?.searchString ?? search.new?.searchString}
							text={text}
						/>
					) : (
						text
					)}
				</Typography>
			) : null}
		</ChatContact>
	);
};

ChatListContact.propTypes = {
	title: PropTypes.string.isRequired,
	userCount: PropTypes.number,
	text: PropTypes.string,
	isOnline: PropTypes.bool,
};

// List Item component

const chatListItemButtonSx = {
	background: 'transparent',
	color: 'var(--color-primary)',
	alignItems: 'stretch',
	flex: 'auto',
	minWidth: 0,
	padding: 0,

	'&:hover': {
		background: 'transparent',
	},
};

const chatListItemInnerSx = {
	background: 'transparent',
	color: 'var(--element-foreground-color, #414141)',
	alignItems: 'center',
	flex: 'auto',
	overflow: 'hidden',
	padding: '10px',

	'&:hover': {
		background: 'transparent',
	},
};

const ChatListItem = ({
	children = null,
	current = false,
	className = '',
	sx,
	innerComponent = 'div',
	innerSx,
	onMouseDown,
	onClick,
}) => {
	const classList = ['chat-list-item', current ? 'chat-list-item--current' : '', className].filter(Boolean);

	const content = (
		<Stack component={innerComponent} direction="row" sx={{ ...chatListItemInnerSx, ...innerSx }}>
			{children}
		</Stack>
	);

	return (
		<ListItem
			className={classList.join(' ')}
			data-testid="chat-list-item"
			sx={{
				'--element-background-color': 'transparent',
				backgroundColor: 'var(--element-background-color)',
				color: 'var(--element-foreground-color)',
				padding: '0 0 1px',
				position: 'relative',
				width: 'auto',

				'&:hover, &.chat-list-item--checked': {
					'--element-background-color': '#42a5f51a',
				},

				'&::after': {
					background: 'var(--element-content-border-color)',
					bottom: '0px',
					content: '""',
					height: '1px',
					left: '0px',
					position: 'absolute',
					right: '0px',
				},

				...sx,
			}}
		>
			{typeof onMouseDown === 'function' || typeof onClick === 'function' ? (
				<ListItemButton
					className="chat-list-item-button"
					sx={chatListItemButtonSx}
					onClick={onClick}
					onMouseDown={onMouseDown}
				>
					{content}
				</ListItemButton>
			) : (
				content
			)}
		</ListItem>
	);
};

ChatListItem.propTypes = {
	children: PropTypes.node,
	current: PropTypes.bool,
	className: PropTypes.string,
	sx: PropTypes.object,
	innerComponent: PropTypes.any,
	innerSx: PropTypes.object,
	onClick: PropTypes.func,
	onMouseDown: PropTypes.func,
};

// Voice call item

const ChatListCallItem = ({ onMouseDown, setIsClickAction }) => {
	const { t } = useTranslation('chat');
	const {
		callData,
		callRoom,
		setCallRoom,
		setCallData,
		callParticipants,
		setCallParticipants,
		setSection,
		setConversationType,
		state,
		expanded,
		smallView,
		setScreenShare,
		appBlumeMode,
		search,
	} = useChatContext();
	const __config = useConfig();
	const { loggedInUser } = useAuth();
	const proactEnableVideoCalling = useBooleanFlagValue('proact-enable-video-calling');

	const handleMuteAudio = e => {
		e.stopPropagation();
		!!search && setIsClickAction(true);
		muteAudio(setCallData, callRoom, callData);
	};

	const handleEndCall = e => {
		e.stopPropagation();
		!!search && setIsClickAction(true);
		endCall(
			callRoom,
			setCallRoom,
			setCallData,
			setCallParticipants,
			setSection,
			setConversationType,
			setScreenShare,
			callParticipants
		);
	};

	const handleAcceptCall = e => {
		e.stopPropagation();
		!!search && setIsClickAction(true);
		joinCall(
			appBlumeMode,
			callData,
			setCallData,
			state,
			setSection,
			setConversationType,
			__config,
			loggedInUser,
			callRoom,
			setCallRoom,
			proactEnableVideoCalling,
			setCallParticipants,
			callParticipants,
			setScreenShare
		);
	};

	const remoteParticipantName = callData?.callNotification
		? callData?.callNotification?.caller?.name
		: callParticipants?.length > 1
		? 'Group'
		: callData?.conversation?.attributes.participants.filter(
				participant =>
					participant.email.toLowerCase() === callParticipants[0]?.identity?.toLowerCase() ||
					participant.email.toLowerCase() !== callData?.caller?.email?.toLowerCase()
		  )[0]?.name;

	const tooltip = (element, title) => <Tooltip title={title ?? ''}>{element}</Tooltip>;

	return (
		<ListItem
			data-testid="chatCallItem"
			sx={{
				padding: '0px',
				width: 'auto',
			}}
		>
			<ListItemButton
				sx={{
					borderRadius: '10px',
					backgroundColor: callData?.status?.onCall ? '#42A5F5' : '#28C75D',
					width: '100%',
					height: '60px',
					'&:hover': {
						backgroundColor: callData?.status?.onCall ? '#42A5F5' : '#24B554',
					},
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
				onMouseDown={onMouseDown}
			>
				<ChatCallingAvatar
					avatarSx={{
						width: '40px',
						height: '40px',
						label: remoteParticipantName,
						backgroundColor: '#808080',
						color: appBlumeMode && 'white',
					}}
					outsideBoxSx={{
						width: '40px',
						height: '40px',
						border: '1px solid #FFFFFF',
					}}
				/>
				<Box
					flexGrow={1}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'left',
						pl: '10px',
					}}
				>
					<Typography
						sx={{
							color: '#FFFFFF',
							fontSize: '16px',
							lineHeight: '16px',
							fontWeight: '500px',
							mb: '3px',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							width: callData?.status?.onCall
								? appBlumeMode
									? '85px'
									: '95px'
								: expanded || smallView
								? appBlumeMode
									? '96px'
									: '108px'
								: appBlumeMode
								? '156px'
								: '200px',
							whiteSpace: 'nowrap',
						}}
					>
						{remoteParticipantName}
					</Typography>
					<Typography
						sx={{
							color: 'rgba(255, 255, 255, 0.8)',
							fontSize: '12px',
							lineHeight: '14px',
							fontWeight: '500px',
							mt: '3px',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{callData?.status?.calling
							? `${t('call.ringing')}...`
							: callData?.status?.onCall
							? t('call.inCallWithYou')
							: callData?.status?.incoming
							? `${t('call.incomingCall')}...`
							: ''}
					</Typography>
				</Box>
				{callData?.status?.onCall && callRoom?.participants?.size >= 1 && !(appBlumeMode && expanded) && (
					<ChatCallDuration
						textStyle={{
							fontSize: '14px',
							lineHeight: '16px',
							color: 'rgba(255, 255, 255, 0.8)',
							mr: '10px',
							maxWidth: '70px',
						}}
					/>
				)}
				<Box>
					{callData?.status?.onCall &&
						tooltip(
							<IconButton
								sx={{
									borderRadius: '5px',
									height: '32px',
									width: '32px',
									backgroundColor: 'rgba(255, 255, 255, 0.2)',
									mr: '10px',
								}}
								onMouseDown={handleMuteAudio}
							>
								{callData?.audioIsMute ? (
									<MicOffOutlinedIcon
										data-testid="micOffIcon"
										sx={{ color: appBlumeMode && 'white' }}
									/>
								) : (
									<MicNoneOutlinedIcon
										data-testid="micOnIcon"
										sx={{ color: appBlumeMode && 'white' }}
									/>
								)}
							</IconButton>,
							callData?.audioIsMute ? t('icon.unmute') : t('icon.mute')
						)}
					{tooltip(
						<IconButton
							data-testid="endCallIcon"
							sx={{
								borderRadius: '5px',
								height: '30px',
								width: '30px',
								backgroundColor: '#FF6666',
								mr: '5px',
								'&:hover': {
									backgroundColor: '#FF6666',
								},
							}}
							onMouseDown={handleEndCall}
						>
							<CallEndOutlinedIcon sx={{ color: appBlumeMode && 'white' }} />
						</IconButton>,
						callData?.status?.incoming ? t('icon.reject') : t('icon.endCall')
					)}
					{callData?.status?.incoming &&
						tooltip(
							<IconButton
								data-testid="acceptCallIcon"
								sx={{
									borderRadius: '5px',
									height: '30px',
									width: '30px',
									backgroundColor: '#42A5F5',
									mr: '5px',
									'&:hover': {
										backgroundColor: '#42A5F5',
									},
								}}
								onMouseDown={handleAcceptCall}
							>
								<CallOutlinedIcon
									sx={{ height: '20px', width: '20px', color: appBlumeMode && 'white' }}
								/>
							</IconButton>,
							t('icon.answer')
						)}
				</Box>
			</ListItemButton>
		</ListItem>
	);
};

// List Item User component

const ChatListItemUser = ({ data, text, isOnline, checked = false, menuItems, children, onMouseDown, onChange }) => {
	const mouseDownDefined = typeof onMouseDown === 'function';
	const changeDefined = !mouseDownDefined && typeof onChange === 'function';
	const [checkedContact, setCheckedContact] = React.useState(checked);
	const { selectedContacts, section } = useChatContext();

	const onContactSelect = () => {
		onChange(data, checkedContact ? 'remove' : 'add');
		setCheckedContact(!checkedContact);
	};

	React.useEffect(() => {
		setCheckedContact(!!selectedContacts?.includes(data));
	}, [selectedContacts]);

	return (
		<ChatListItem
			className={checked ? 'chat-list-item--checked' : undefined}
			innerComponent={changeDefined ? 'label' : undefined}
			innerSx={
				changeDefined
					? {
							cursor: 'pointer',
							gap: '10px',
					  }
					: undefined
			}
			sx={{
				'--element-border-offset-left': `${CHAT_AVATAR_SIZE + CHAT_CONTACT_SPACING}px`,
			}}
			onMouseDown={mouseDownDefined ? onMouseDown : undefined}
		>
			<ChatListContact
				isBlumeUser={data?.isBlumeUser}
				isOnline={isOnline}
				text={data?.user?.name ? text : ''}
				title={data?.user?.name ? data?.user?.name : text}
			/>

			{Array.isArray(menuItems) ? (
				<ChatListMenu
					buttonId={`chat-list-item-menu-button-${data.text}`}
					id={`chat-list-item-menu-${data.text}`}
					items={menuItems}
				/>
			) : null}

			{section?.name === CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS ||
			section?.name === CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS ? (
				<Checkbox
					checked={checkedContact}
					checkedIcon={<RadioButtonCheckedOutlinedIcon color="rsPrimary" />}
					icon={<RadioButtonUncheckedOutlinedIcon color="rsPrimary" />}
					id={`chat-contact-list-checkbox-${data?.internalId}`}
					sx={{
						padding: 0,
					}}
					value={data?.user?.name}
					onChange={onContactSelect}
				/>
			) : null}

			{children}
		</ChatListItem>
	);
};

ChatListItemUser.propTypes = {
	data: PropTypes.object.isRequired,
	text: PropTypes.string,
	isOnline: PropTypes.bool,
	checked: PropTypes.bool,
	menuItems: PropTypes.array,
	children: PropTypes.node,
	onMouseDown: PropTypes.func,
	onChange: PropTypes.func,
};

// List Menu component

const ChatListMenu = ({ id, buttonId, items = [], iconButtonSx, onMouseDown }) => {
	const [anchor, setAnchor] = React.useState(null);
	const open = anchor?.id === buttonId;

	const buttonClassList = ['chat-list-menu-button', open ? 'chat-list-menu-button--open' : ''].filter(Boolean);

	const itemsLength = items.length;

	return (
		<>
			<IconButton
				aria-controls={open ? id : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				aria-label="Options"
				className={buttonClassList.join(' ')}
				data-testid="chat-list-expand-menu-btn"
				id={buttonId}
				size="small"
				sx={{ color: 'currentColor', ...iconButtonSx }}
				onMouseDown={event => {
					const target = event.currentTarget;

					setAnchor(anchor !== target ? target : null);

					if (typeof onMouseDown === 'function') {
						onMouseDown(event);
					}
				}}
			>
				<Icon component={ExpandMoreIcon} sx={{ color: 'currentColor' }} />
			</IconButton>

			<Menu
				MenuListProps={{
					sx: {
						background: 'var(--chat-menu-background-color)',
						color: 'var(--chat-menu-foreground-color)',
					},
				}}
				anchorEl={anchor}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				id={id}
				open={open}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				onClose={event => {
					event.stopPropagation();

					setAnchor(null);
				}}
				onMouseDown={event => event.stopPropagation()}
			>
				{items.map((item, index) => (
					<MenuItem
						key={index}
						divider={index + 1 < itemsLength}
						onMouseDown={event => {
							setAnchor(null);

							if (typeof item.onMouseDown === 'function') {
								item.onMouseDown();
							}
						}}
					>
						<Typography component="div" sx={{ fontSize: '12px' }}>
							{item.text}
						</Typography>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

ChatListMenu.propTypes = {
	id: PropTypes.string.isRequired,
	buttonId: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	iconButtonSx: PropTypes.object,
	onMouseDown: PropTypes.func,
};

// Container component

const ChatListContainer = ({ sx, children }) => (
	<Box
		data-testid="chat-list-container"
		sx={{
			display: 'grid',
			gridTemplateRows: '100%',
			overflow: 'hidden',
			position: 'relative',
			...sx,
		}}
	>
		{children}
	</Box>
);

ChatListContainer.propTypes = {
	sx: PropTypes.object,
	children: PropTypes.node,
};

// Container Options component

const ChatListContainerOptions = ({ children }) => (
	<Stack
		alignItems="center"
		direction="row"
		sx={{
			position: 'absolute',
			height: '50px',
			top: 0,
			right: 'var(--element-content-spacing-right)',
			zIndex: 2,
		}}
	>
		{children}
	</Stack>
);

ChatListContainerOptions.propTypes = {
	children: PropTypes.node,
};

// Component

const ChatList = ({ overflow = 'auto', sx, children }) => (
	<List
		data-testid="chat-conversation-list"
		style={{ maxHeight: 'calc(100vh - 288px)', overflow: 'auto' }}
		sx={{
			overflow,
			padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
			...sx,
		}}
	>
		{children}
	</List>
);

ChatList.propTypes = {
	overflow: PropTypes.string,
	sx: PropTypes.object,
	children: PropTypes.node,
};

export default ChatList;
export {
	ChatListHeader,
	ChatListUnread,
	ChatListDatetime,
	ChatListContact,
	ChatListItem,
	ChatListItemUser,
	ChatListContainer,
	ChatListContainerOptions,
	ChatListMenu,
	ChatListCallItem,
};
