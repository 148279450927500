import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import HelpQuestions from '@worklist-2/ui/src/components/Help/Questions.json';
import _ from 'lodash';

export const HelpCenterContext = createContext();

export const HelpCenterContextProvider = ({ PopOverComponent, children }) => {
	const [view, setView] = useState('faq');
	const [showPopover, togglePopover] = useState(false);
	const [showTip, toggleShowTip] = useState(false);
	const [popOverContent, setPopOverContent] = useState('helpContent');
	const [searchQuestion, handleQuestionChange] = useState('');
	const [searchKey, setSearchKey] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [currentSideTab, setCurrentSideTab] = useState(null);
	const [currentPiPNav, setCurrentPiPNav] = useState(null);

	const showingMenu = useMemo(() => popOverContent === 'menu', [popOverContent]);

	// handle showing help tip
	useEffect(() => {
		const shown = localStorage.getItem('help-popover-shown');
		if (showPopover) {
			toggleShowTip(!shown);
			setPopOverContent('helpContent');
			setCurrentPiPNav(null);
		}
		localStorage.setItem('help-popover-shown', 'toggled');
	}, [showPopover]);

	// handle rendering search results
	useEffect(() => {
		if (searchQuestion?.trim().length > 0) {
			setPopOverContent('searchResults');
		}
	}, [searchQuestion, searchResults]);

	const value = {
		searchQuestion,
		handleQuestionChange,
		searchResults,
		setSearchResults,
		setView,
		togglePopover,
		showTip,
		openMenu: v => setPopOverContent(v),
		dismissTip: () => toggleShowTip(false),
		Questions: HelpQuestions,
		searchKey,
		setSearchKey,
		showPopover,
		currentSideTab,
		setCurrentSideTab,
		currentPiPNav,
		setCurrentPiPNav,
		popOverContent,
		showingMenu,
	};

	return (
		<HelpCenterContext.Provider value={value}>
			{children}
			{/* help center popover widget */}
			{showPopover && (
				<PopOverComponent
					dismiss={() => togglePopover(false)}
					dismissTip={() => toggleShowTip(false)}
					openMenu={v => setPopOverContent(v)}
					showTip={showTip}
					showingMenu={showingMenu}
				/>
			)}
		</HelpCenterContext.Provider>
	);
};

export const useHelpCenter = () => {
	const helpCenterContext = useContext(HelpCenterContext);
	const {
		searchQuestion,
		handleQuestionChange,
		searchResults,
		setSearchResults,
		setView,
		keyword,
		setKeyword,
		togglePopover,
		showTip,
		dismissTip,
		Questions,
		searchKey,
		setSearchKey,
		showPopover,
		currentSideTab,
		setCurrentSideTab,
		openMenu,
		popOverContent,
		showingMenu,
		setCurrentPiPNav,
	} = helpCenterContext;

	return {
		searchQuestion,
		handleQuestionChange,
		searchResults,
		setSearchResults,
		setView,
		keyword,
		setKeyword,
		togglePopover,
		showTip,
		dismissTip,
		Questions,
		searchKey,
		setSearchKey,
		showPopover,
		currentSideTab,
		setCurrentSideTab,
		openMenu,
		popOverContent,
		showingMenu,
		setCurrentPiPNav,
	};
};

export default HelpCenterContextProvider;
