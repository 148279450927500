import axios from 'axios';
import { connect, createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import CHAT_CONVERSATION_TYPES, { DEFAULT_CALL_DATA } from './CHAT_CONVERSATION_TYPES';
import CHAT_SECTIONS from './CHAT_SECTIONS';
import { v4 as uuid } from 'uuid';

export async function addParticipant(email, friendlyName, chatParticipant, convo, internalId, role, isBlumeUser) {
	if (convo === undefined) {
		return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
	}

	if (chatParticipant && email.length > 0) {
		try {
			const result = await convo.add(email, {
				friendlyName: friendlyName,
				isBlumeUser: isBlumeUser,
			});

			let participantsAttr = convo.attributes?.participants ?? [];
			if (!participantsAttr.some(e => e.email === email)) {
				participantsAttr = [
					...participantsAttr,
					{
						email: email,
						name: friendlyName,
						internalId: internalId,
						role: role,
						isBlumeUser: isBlumeUser,
					},
				];

				await convo.updateAttributes({
					...convo.attributes,
					participants: participantsAttr,
				});
			}

			return result;
		} catch (e) {
			return Promise.reject(e);
		}
	}
	return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function removeParticipant(email, convo) {
	if (convo === undefined) {
		return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
	}

	if (email) {
		try {
			const result = await convo.removeParticipant(email);
			return result;
		} catch (e) {
			return Promise.reject(e);
		}
	}
	return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export const getConversationParticipants = async conversation => await conversation?.getParticipants();

export const getMessages = async conversation => await conversation.getMessages(32);

export async function getSubscribedConversations(client) {
	let conversations = [];
	let subscribedConversations = await client?.getSubscribedConversations();
	if (subscribedConversations) {
		conversations = subscribedConversations.items;

		while (subscribedConversations.hasNextPage) {
			subscribedConversations = await subscribedConversations.nextPage();
			conversations = [...conversations, ...subscribedConversations.items];
		}
	}

	return conversations;
}

export async function createConversation(client, friendlyName, userEmail, userName, internalId, role, isBlumeUser) {
	let attributes = {
		participants: [
			{
				name: userName,
				email: userEmail,
				internalId: internalId,
				role: role,
				isBlumeUser: isBlumeUser,
				isCreator: true,
			},
		],
	};

	let conversation = await client.createConversation({
		friendlyName: friendlyName,
		attributes: attributes,
	});

	try {
		await conversation.join();
	} catch (e) {
		console.error(e);
	}
	return conversation;
}

export function getAvatar(name) {
	return name
		?.toUpperCase()
		.replace('^', ' ')
		.split(' ')
		.slice(0, 2)
		.map(digit => digit[0])
		.join('');
}

export function createVideoTokenAndJoinRoom(partialUrl, payload, proactEnableVideoCalling) {
	const mediaErrors = ['NotAllowedError', 'NotFoundError', 'NotReadableError', 'OverconstrainedError', 'TypeError'];

	function handleMediaError(error) {
		console.error('Failed to acquire media:', error.name, error.message);
	}

	return new Promise((resolve, reject) => {
		const url = `${partialUrl}/Conversation/video/token`;
		axios.post(url, payload).then(async result => {
			if (result) {
				let audioTrack = null;
				let videoTrack = null;

				await createLocalAudioTrack({
					name: 'john-audio-track',
				})
					.then(track => {
						audioTrack = track;
					})
					.catch(error => handleMediaError(error));
				// TODO - Chat - revert to this flag and fix the video and screen share issue when we want to enable video
				// proactEnableVideoCalling &&
				false &&
					(await createLocalVideoTrack({
						name: `${payload?.name}-webcam`,
					})
						.then(track => (videoTrack = track))
						.catch(error => handleMediaError(error)));

				// videoTrack is not passed into tracks if it's null due to mediaErrors
				connect(result?.data?.accessToken, {
					name: result?.data?.roomName,
					tracks: videoTrack ? [audioTrack, videoTrack] : [audioTrack],
				})
					.then(room => resolve(room))
					.catch(error => {
						if (mediaErrors.includes(error.name)) {
							handleMediaError(error);
							reject(error);
						}
					});
			}
		});
	});
}

export function endCall(
	callRoom,
	setCallRoom,
	setCallData,
	setCallParticipants,
	setSection,
	setConversationType,
	setScreenShare,
	callParticipants,
	conversation
) {
	const stopLocalParticipantTracks = currRoom => {
		currRoom?.localParticipant?.tracks?.forEach(track => {
			track?.track?.mediaStreamTrack?.stop();
			track?.track?.stop();
			track?.track?.detach();
		});
	};

	// This is when the LocalParticipant ends the call
	if (callRoom) {
		stopLocalParticipantTracks(callRoom);
		// room.disconnect() : the LocalParticipant will be disconnected from the Room,
		// and all other RemoteParticipants will be automatically disconnected from the room
		if (callParticipants.length <= 1) {
			callRoom.disconnect();
		}
	} else {
		// This is when the room was ended by a remote participant (callRoom state is null at this point)
		// Get prevCallRoom and stop the audio and screen share tracks -> Fix PRO-1818, PRO-2707
		let room;
		setCallRoom(prev => {
			room = prev;
		});
		if (room) {
			stopLocalParticipantTracks(room);
		}
	}

	if (setScreenShare) {
		setScreenShare({});
	}

	setCallData(JSON.parse(JSON.stringify(DEFAULT_CALL_DATA)));

	setCallParticipants([]);

	conversation &&
		setSection({
			name: CHAT_SECTIONS.CONVERSATION,
			conversation: conversation,
		});

	setConversationType(CHAT_CONVERSATION_TYPES.MESSAGING);
}

async function getPractitionerRoles(userId, config) {
	const url = `${config.data_sources.fhir}/PractitionerRole?practitioner=${userId}`;
	let role = '';

	await axios
		.get(url)
		.then(result => {
			if (result.status === 200) {
				role =
					result?.data?.entry[0]?.resource?.extension?.find(
						ext => ext.url == fhirExtensionUrls.practitionerRole.role
					)?.valueReference?.display || '';
			}
		})
		.catch(e => {
			console.error('Chat - Error on getting practitioner roles', e);
		});

	return role
		? role
				.split(' ')
				.map(word => {
					return word[0].toUpperCase() + word.substring(1)?.toLowerCase();
				})
				.join(' ')
		: role;
}

export async function getUserInternalId({ email, __config }) {
	const url = `${__config.data_sources.blume}User/ContactIdByEmail?email=${email}`;
	const response = await axios.get(url);
	if (response.status === 200) {
		return response.data;
	}
	return '';
}

const getUserInternalIdBlumeUserFromContacts = ({ contacts, email }) => {
	if (contacts) {
		const contact = contacts.filter(user => user.email.toLowerCase() == email && user.isBlumeUser);
		if (contact[0]) {
			return contact[0]?.internalId || '';
		}
	}
	return '';
};

export async function joinCall(
	appBlumeMode,
	callData,
	setCallData,
	state,
	setSection,
	setConversationType,
	__config,
	loggedInUser,
	callRoom,
	setCallRoom,
	proactEnableVideoCalling,
	setCallParticipants,
	callParticipants,
	setScreenShare,
	isCaller,
	chatIsOpen,
	onChatClick,
	conversation,
	contacts
) {
	const remoteParticipants = conversation?.attributes?.participants?.filter(
		user => user.email.toLowerCase() !== loggedInUser?.email?.toLowerCase()
	);

	setCallData(prev => {
		if (isCaller) {
			// Set data when initializing a call
			prev.isCall = true;
			prev.status.calling = true;
			prev.conversation = conversation;
			prev.caller = loggedInUser;
		} else {
			// Set data when receiving the call
			const conv = Object.entries(state).filter(
				item => item[1]?.conversation?.sid === callData?.callNotification?.conversationSid
			);
			prev.status.calling = false;
			prev.status.onCall = true;
			prev.status.incoming = false;
			prev.conversation = conv[0] && conv[0][1]?.conversation;
		}

		return { ...prev };
	});

	setSection({
		name: CHAT_SECTIONS.CONVERSATION,
		conversation: callData?.conversation,
	});

	setConversationType(CHAT_CONVERSATION_TYPES.CALL);

	// Receiver side: open ChatConversationCall screen if the Chat drawer is not being opened
	!isCaller && !chatIsOpen && onChatClick && onChatClick();

	const partialUrl = appBlumeMode ? __config.data_sources.blume + 'User' : __config.data_sources.fhir;
	const unique_id = uuid();
	const payload = {
		identity: loggedInUser?.email?.toLowerCase(),
		name: loggedInUser?.fullName,
		internalId: loggedInUser?.id,
		role: appBlumeMode ? '' : loggedInUser?.role ?? (await getPractitionerRoles(loggedInUser?.id, __config)),
		roomName: isCaller
			? `${loggedInUser?.fullName} ${unique_id}`
			: callData?.callNotification
			? callData?.callNotification?.roomName
			: '',
		receiverId:
			(isCaller && !appBlumeMode) ||
			(isCaller && appBlumeMode && !remoteParticipants[0]?.isBlumeUser && remoteParticipants[0]?.internalId)
				? remoteParticipants[0]?.internalId ||
				  getUserInternalIdBlumeUserFromContacts({ contacts, email: remoteParticipants[0]?.email })
				: isCaller && appBlumeMode
				? await getUserInternalId({ email: remoteParticipants[0]?.email, __config })
				: '',
		conversationSid: isCaller ? conversation?.sid : callData?.callNotification?.conversationSid,
		caller: isCaller ? '' : callData?.callNotification?.caller,
	};

	const participantConnected = participant => {
		setCallData(prev => {
			if (isCaller) {
				prev.status.calling = false;
				prev.status.incoming = false;
				prev.status.onCall = true;
			}

			if (prev.callStartTime == 0) {
				prev.callStartTime = Date.now();
			}

			return { ...prev };
		});

		setCallParticipants(prevParticipants => {
			return [...prevParticipants, participant];
		});
	};

	const participantDisconnected = async participant => {
		participant.tracks.forEach(track => {
			track?.track?.stop();
			track?.track?.detach();
			track?.track?.mediaStreamTrack?.stop();
		});

		await setCallParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));

		// End the call if there is no remote participant
		if (callParticipants.length == 0) {
			endCall(
				callRoom,
				setCallRoom,
				setCallData,
				setCallParticipants,
				setSection,
				setConversationType,
				setScreenShare,
				callParticipants,
				callData?.conversation
			);
		}
	};

	await createVideoTokenAndJoinRoom(partialUrl, payload, proactEnableVideoCalling).then(room => {
		setCallRoom(room);
		room.participants.forEach(participantConnected);
		room.on('participantConnected', participantConnected);
		room.on('participantDisconnected', participantDisconnected);
		room.on('disconnected', (currRoom, error) => {
			if (error) {
				console.error('Voice call - Unexpectedly disconnected:', error);
			}
			currRoom.localParticipant.tracks.forEach(track => {
				track.track.stop();
				track.track.detach();
				track.track.mediaStreamTrack.stop();
			});
		});
	});
}

export function muteAudio(setCallData, callRoom, callData) {
	setCallData(prev => {
		prev.audioIsMute = !prev?.audioIsMute;
		return { ...prev };
	});

	callRoom?.localParticipant.audioTracks.forEach(track => {
		callData?.audioIsMute ? track.track.disable() : track.track.enable();
	});
}

export function shareScreen(callRoom, screenShare, setScreenShare) {
	let screenTrack = null;
	if (!screenShare?.localScreenTrack) {
		// Capture screen contents as a live MediaStream returning a promise that resolves to a stream containing the live screen contents.
		navigator.mediaDevices
			.getDisplayMedia({
				video: { cursor: 'always' },
			})
			.then(async stream => {
				screenTrack = stream.getVideoTracks()[0];
				setScreenShare(prev => {
					prev.localScreenTrack = screenTrack;
					prev.remoteScreenShared = false;
					return { ...prev };
				});
				callRoom.localParticipant.publishTrack(screenTrack);
			})
			.catch(() => {
				console.error('Voice call - Could not share the screen.');
			});
	} else {
		callRoom.localParticipant.unpublishTrack(screenShare?.localScreenTrack);
		screenShare?.localScreenTrack.stop();
		setScreenShare(prev => {
			prev.localScreenTrack = null;
			return { ...prev };
		});
	}
}
