import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import React from 'react';
import RegionSelector from './RegionSelector';
import { BackDrop, boxStyle, header, mainBox, Hint } from './regionStyles';
import PropTypes from 'prop-types';
import { mediaMobile } from '@rs-core/utils/responsiveUtils';

const RegionModalContent = () => (
	<Box sx={boxStyle}>
		<Box sx={header}>Choose Your Region</Box>
		<Paper
			sx={{
				p: '2px 4px',
				display: 'flex',
				alignItems: 'center',
				background: '#FFF',
				minWidth: '500px',
				border: '1px solid',
				'&:hover': {
					borderColor: '#42A5F5',
				},
				[mediaMobile]: {
					width: '100%',
					minWidth: 'unset',
					boxSizing: 'border-box',
				},
			}}
		>
			<div style={{ width: '100%' }}>
				<Hint>CURRENTLY SELECTED</Hint>
				<RegionSelector />
			</div>
		</Paper>
	</Box>
);

const RegionModal = ({ open, onClose }) => (
	<Modal
		BackdropComponent={() => (
			<BackDrop>
				<Box sx={mainBox}>
					<IconButton sx={{ position: 'absolute', top: 10, right: 20 }} onClick={onClose}>
						<CloseIcon sx={{ color: '#000' }} />
					</IconButton>

					<RegionModalContent />
				</Box>
			</BackDrop>
		)}
		open={open}
		onClose={onClose}
	>
		<></>
	</Modal>
);

export default RegionModal;
export { RegionModalContent };

RegionModal.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func,
};
