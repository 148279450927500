import { create } from 'zustand';
import commonMiddlewares from './middlewares';

import { useGlobalStore, fhirExtensionUrls } from '@worklist-2/core/src';
import getStudies from '../api/getStudies';
import getLinkedPatientsStudies from '../api/getLinkedPatientsStudies';
import getDiagnosticReports from '../api/getDiagnosticReports';
import getDocumentReferences from '../api/getDocumentReferences';
import { getUserFullName } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import getDiagnosticReportStudyId from '../utils/getDiagnosticReportStudyId';

export const useStudiesStore = create(
	commonMiddlewares((set, get) => ({
		// State
		studyList: [],
		diagnosticReportsList: [],
		isDiagnosticReportsListLoaded: false,
		documentReferencesList: [],
		formsList: [],
		isLoaded: false,
		isLoading: false,
		currentStudy: null,
		studiesStates: [],
		sharedUserList: [],
		reportContentLoaded: false,
		openNextStudyTrigger: false,

		getStudies: async ({
			__config,
			patientId,
			internalManagingOrganizationID,
			studyId,
			galaxyDvEnableDirectAccessLinkFix = false,
			galaxyDvLimitsReportsFetchToFifty = false,
			galaxyDvdiagnosticReportsCounts = 50,
		}) => {
			try {
				const { fetchStudyData, isLoaded } = get();
				if (isLoaded) return;

				set(() => ({ isLoading: true }), false, 'studies/getStudies');
				const currentStudyInfo = useGlobalStore.getState().currentStudyInfo;
				const setCurrentStudyInfo = useGlobalStore.getState().setCurrentStudyInfo;

				let currentStudy = [];
				let priorStudies = [];
				let priorStudiesStates = [];
				if (currentStudyInfo?.id === studyId) {
					currentStudy = currentStudyInfo;
				} else {
					const result = await getStudies({
						__config,
						patientId,
						internalManagingOrganizationID,
						studyId,
					});

					currentStudy = result.find(study => study?.id === studyId);
					if (galaxyDvEnableDirectAccessLinkFix) {
						setCurrentStudyInfo(currentStudy);
					}
					priorStudies = [
						...result
							?.filter(study => study?.id !== studyId)
							?.sort((a, b) => new Date(b.started) - new Date(a.started)),
					];
					priorStudiesStates = priorStudies.map(study => ({
						studyId: study?.id,
						isExpanded: false,
						dataLoading: false,
						dataLoaded: false,
					}));
				}

				const sharedUser = currentStudy?.extension
					?.filter(eachObj => eachObj?.url === fhirExtensionUrls.imagingStudy.studyShare.practioner)
					?.map(item => ({
						id: item?.valueReference?.id,
						name: getUserFullName(item.valueReference.display),
						source: null,
						isOwner: null,
					}));

				set(
					() => ({
						isLoading: false,
						isLoaded: true,
						studyList: [currentStudy, ...priorStudies],
						currentStudy: currentStudy,
						studiesStates: [
							{
								studyId: currentStudy?.id,
								isExpanded: true,
								dataLoading: false,
								dataLoaded: false,
							},
							...priorStudiesStates,
						],
						sharedUserList: sharedUser,
					}),
					false,
					'studies/getStudies'
				);

				await fetchStudyData({
					__config,
					patientId,
					studyId,
					fetchDocumentReferences: false,
					galaxyDvLimitsReportsFetchToFifty,
					galaxyDvdiagnosticReportsCounts,
				});
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		getPriorStudies: async ({
			__config,
			patientId,
			internalManagingOrganizationID,
			studyId,
			patientData,
			galaxyDvEnableLinkedPatientReports = false,
		}) => {
			try {
				const { studyList, studiesStates } = get();

				if (studyList.length > 1 || (studyList.length && !studyList.find(study => study?.id === studyId)))
					return;

				const result = await getStudies({
					__config,
					patientId,
					internalManagingOrganizationID,
					studyId,
					patientData,
					galaxyDvEnableLinkedPatientReports,
				});

				let priorStudies = [];
				let currentStudy = [];
				currentStudy = result.find(study => study?.id === studyId);
				priorStudies = [
					...result
						?.filter(study => study?.id !== studyId)
						?.sort((a, b) => new Date(b.started) - new Date(a.started)),
				];

				const priorStudiesStates = priorStudies.map(study => ({
					studyId: study?.id,
					isExpanded: false,
					dataLoading: false,
					dataLoaded: false,
				}));

				set(
					() => ({
						studyList: [currentStudy, ...priorStudies],
						studiesStates: [studiesStates.find(study => study?.studyId === studyId), ...priorStudiesStates],
					}),
					false,
					'studies/getPriorStudies'
				);
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		getLinkedPatientsStudies: async ({ __config, patientData }) => {
			try {
				const { studyList, studiesStates } = get();
				const result = await getLinkedPatientsStudies({
					__config,
					patientData,
				});
				if (result.length === 0) return;

				// Sort the linked patients' studies by the `started` date
				const linkedPatientsStudies = [...result.sort((a, b) => new Date(b.started) - new Date(a.started))];

				// Map linked patients' studies to their states
				const linkedPatientsStudiesStates = linkedPatientsStudies.map(study => ({
					studyId: study?.id,
					isExpanded: false,
					dataLoading: false,
					dataLoaded: false,
				}));

				// Merge linked patients' studies with the current study list
				const updatedStudyList = [...studyList, ...linkedPatientsStudies];

				// Merge linked patients' studies states with existing studies states
				const updatedStudiesStates = [...studiesStates, ...linkedPatientsStudiesStates];

				// Update the store with the merged studies and states
				set(
					() => ({
						studyList: updatedStudyList,
						studiesStates: updatedStudiesStates,
					}),
					false,
					'studies/getLinkedPatientsStudies'
				);
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		setOpenNextStudyTrigger: openNextStudyTrigger =>
			set(() => ({ openNextStudyTrigger: openNextStudyTrigger }), false, 'studies/setOpenNextStudyTrigger'),

		setStudiesStates: studiesStates =>
			set(() => ({ studiesStates: studiesStates }), false, 'studies/setStudiesStates'),
		setDiagnosticReportsList: diagnosticReports => {
			set(
				() => ({ diagnosticReportsList: diagnosticReports, isDiagnosticReportsListLoaded: true }),
				false,
				'studies/setDiagnosticReports'
			);
		},

		setReportContentLoaded: contentLoaded =>
			set(() => ({ reportContentLoaded: contentLoaded }), false, 'studies/setReportContentLoaded'),

		setFormsList: forms => {
			set(() => ({ formsList: forms }), false, 'studies/setFormsList');
		},
		getDiagnosticReportsBystudyId: async ({
			__config,
			patientId,
			studyId,
			galaxyDvLimitsReportsFetchToFifty,
			galaxyDvdiagnosticReportsCounts,
		}) => {
			try {
				const { diagnosticReportsList } = get();

				const result = await getDiagnosticReports({
					__config,
					patientId,
					studyId,
					galaxyDvLimitsReportsFetchToFifty,
					galaxyDvdiagnosticReportsCounts,
				});
				const diagnosticReportsListOfOtherStudies =
					diagnosticReportsList?.filter(diagnosticReport => {
						const diagnosticReportStudyId = getDiagnosticReportStudyId(diagnosticReport);
						return studyId !== diagnosticReportStudyId;
					}) || [];

				set(
					() => ({
						diagnosticReportsList: [...diagnosticReportsListOfOtherStudies, ...result],
						isDiagnosticReportsListLoaded: true,
					}),
					false,
					'studies/getDiagnosticReportBystudyId'
				);
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
		setdocumentReferencesList: documentReferences =>
			set(() => ({ documentReferencesList: documentReferences }), false, 'studies/setdocumentReferences'),

		getDocumentReferencesBystudyId: async ({ __config, patientId, studyId }) => {
			try {
				const { documentReferencesList } = get();

				const result = await getDocumentReferences({ __config, patientId, studyId });
				set(
					() => ({
						documentReferencesList: [...documentReferencesList, ...result],
					}),
					false,
					'studies/getDiagnosticReportBystudyId'
				);
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
		fetchStudyData: async ({
			__config,
			patientId,
			studyId,
			fetchDocumentReferences = true,
			galaxyDvLimitsReportsFetchToFifty = false,
			galaxyDvdiagnosticReportsCounts = 50,
		}) => {
			const { studiesStates, setStudiesStates, getDiagnosticReportsBystudyId, getDocumentReferencesBystudyId } =
				get();
			if (studiesStates.find(study => study?.studyId === studyId)?.dataLoaded === false) {
				setStudiesStates(
					studiesStates.map(study => {
						if (study.studyId === studyId) {
							return {
								...study,
								dataLoading: true,
							};
						}
						return study;
					})
				);
				await getDiagnosticReportsBystudyId({
					__config,
					patientId,
					studyId,
					galaxyDvLimitsReportsFetchToFifty,
					galaxyDvdiagnosticReportsCounts,
				});
				fetchDocumentReferences && (await getDocumentReferencesBystudyId({ __config, patientId, studyId }));
				setStudiesStates(
					studiesStates.map(study => {
						if (study.studyId === studyId) {
							return {
								...study,
								dataLoading: false,
								dataLoaded: true,
							};
						}
						return study;
					})
				);
			}
		},
		setStudyIsExpanded: async ({
			__config,
			patientIdToUse,
			studyId,
			galaxyDvLimitsReportsFetchToFifty,
			galaxyDvdiagnosticReportsCounts,
		}) => {
			const { studiesStates, setStudiesStates, fetchStudyData } = get();
			setStudiesStates(
				studiesStates.map(study => {
					if (study.studyId === studyId) {
						return {
							...study,
							isExpanded: !study.isExpanded,
						};
					}
					return study;
				})
			);
			if (studiesStates.find(study => study?.studyId === studyId).dataLoaded === false) {
				await fetchStudyData({
					__config,
					patientId: patientIdToUse,
					studyId,
					galaxyDvLimitsReportsFetchToFifty,
					galaxyDvdiagnosticReportsCounts,
				});
			}
		},
		setSharedUserList: sharedUser => {
			set(() => ({ sharedUserList: sharedUser }), false, 'studies/setSharedUserList');
		},

		resetStudyStore: () =>
			set({
				studyList: [],
				diagnosticReportsList: [],
				documentReferencesList: [],
				isDiagnosticReportsListLoaded: false,
				formsList: [],
				isLoaded: false,
				isLoading: false,
				currentStudy: null,
				studiesStates: [],
				sharedUserList: [],
				reportContentLoaded: false,
			}),
	}))
);
