// Core
import React from 'react';

// libraries
import { Controller } from 'react-hook-form';

// Components
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import FormControlLabel from '@mui/material/FormControlLabel';

interface IFormCheckboxProps {
	formHook?: string;
	name?: Object;
	prop?: Object;
}

export const FormCheckbox = ({ formHook, name, props }: IFormCheckboxProps): Node => {
	const { control } = formHook;
	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => (
				<FormControlLabel
					control={
						<Checkbox
							key={`${field?.name}-field`}
							checked={field?.value}
							checkedIcon={
								<Box sx={props?.sx?.checkbox ?? SX.checkbox}>
									<CheckIcon sx={props?.sx?.checkIcon ?? SX.checkIcon} />
								</Box>
							}
							data-testid={`form-field-${field?.name}`}
							icon={<Box sx={props?.sx?.iconBox ?? SX.iconBox} />}
							onChange={e => {
								field.onChange(e?.target?.checked);
							}}
						/>
					}
					label={props?.label}
				/>
			)}
		/>
	);
};

const SX = {
	checkbox: {
		height: '24px',
		width: '24px',
		borderRadius: '7.2px',
		opacity: 0.6,
		background: 'transparent',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '1.2px solid rgba(66, 165, 245, 1)',
	},
	checkIcon: {
		color: 'rsPrimary.main',
		width: '22px',
		height: '22px',
	},
	iconBox: {
		height: '24px',
		width: '24px',
		borderRadius: '7.2px',
		border: '1.2px solid rgba(255,255,255,.2)',
	},
};
