import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import { loadValueSet } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { SKIP_TRANSLATION_LIST } from '@rs-ui/helpers/constants.js';

const SearchableSingleSelectColumnFilter = ({
	valueSetType,
	onSelect,
	capitalizeOptions = false,
	fieldRef,
	displayValue = 'display',
	labelAlwaysShrunk = false,
	label,
	defaultValue = null,
	testId,
	style,
	width,
	fullWidth = false,
	hideIcon = true,
	options,
	placeholder = 'Search',
	customFilterOptions,
	onFocus,
	onBlur,
	popperProps,
	valueSetExtraParam,
}) => {
	const { t } = useTranslation('codePool');
	const notTranslate = SKIP_TRANSLATION_LIST.includes(valueSetType) || !valueSetType;
	const [optionsList, setOptionsList] = useState([]);
	const fhirDataLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});
	const [selectedValue, setSelectedValue] = useState([]);

	useEffect(() => {
		setSelectedValue(_.isArray(defaultValue) ? defaultValue[0] : defaultValue);
	}, [defaultValue]);

	// For Grid columns, get valueSet from API when component loads
	useEffect(() => {
		if (!_.isEmpty(customFilterOptions)) {
			setOptionsList(customFilterOptions);
		} else if (options) {
			setOptionsList(options);
		} else if (valueSetType) {
			const getValueSet = async () =>
				loadValueSet(
					fhirDataLoader,
					valueSetType,
					displayValue,
					capitalizeOptions,
					['display'],
					valueSetExtraParam
				);
			getValueSet()
				.then(output => setOptionsList(output))
				.catch(console.error);
		}
	}, [options, customFilterOptions]);

	const handleOnChange = (event, value) => {
		if (onFocus) {
			onFocus();
		}
		setSelectedValue(value);
		if (onBlur) {
			setTimeout(() => {
				onBlur();
			}, 10000);
		}

		handleSelect(event, value);
	};

	const handleSelect = (event, value) => {
		onSelect(
			{
				selectedOptions: event.target.textContent,
				fullOptionsList: optionsList,
			},
			value,
			valueSetType
		);
	};

	return (
		<Autocomplete
			PopperComponent={({ ...props }) => <Popper {...props} {...popperProps} />}
			getOptionLabel={option => (notTranslate ? option : t(`codePool:${valueSetType}.${option}`))}
			noOptionsText={t('noOptionsText')}
			options={optionsList.map(option => option[displayValue])}
			renderInput={params => (
				<TextField
					data-cy={testId}
					{...params}
					InputLabelProps={labelAlwaysShrunk && label ? { shrink: true } : undefined}
					InputProps={{
						...params.InputProps,
						startAdornment: !hideIcon && (
							<InputAdornment position="start">
								<SearchIcon />
								{params.InputProps.startAdornment ? (
									<label style={{ marginLeft: 12 }}>{params.InputProps.startAdornment}</label>
								) : (
									''
								)}
							</InputAdornment>
						),
					}}
					inputRef={fieldRef}
					label={label}
					placeholder={placeholder}
					onBlur={() => (onBlur ? onBlur() : '')}
					onFocus={() => (onFocus ? onFocus() : '')}
				/>
			)}
			sx={{ ...(fullWidth ? '' : { width }), ...style }}
			value={selectedValue}
			onChange={handleOnChange}
		/>
	);
};

export default SearchableSingleSelectColumnFilter;

SearchableSingleSelectColumnFilter.propTypes = {
	/**
	 * Whether the input label should be explicitly in shrink mode
	 */
	labelAlwaysShrunk: PropTypes.bool,
	/**
	 * The type of valueSet that will be used to populate the list of options
	 */
	valueSetType: PropTypes.string,
	/**
	 * Handler for when a menu item is selected
	 */
	onSelect: PropTypes.func,
	/**
	 * The valueSet property that will be displayed
	 */
	displayValue: PropTypes.string,
	/**
	 * Whether or not the options should be capitalized
	 */
	capitalizeOptions: PropTypes.bool,
	/**
	 * Use this option if you want to pass custom filter options instead of a search set.
	 */
	customFilterOptions: PropTypes.array,
};
