import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment';
import Typography from '@mui/material/Typography';

import { useConfig } from '@rs-core/context/ConfigContext';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

import { useScheduleStore } from '@worklist-2/patientPortal/src/stores/schedule';
import { getTimezoneWithOffset } from '@worklist-2/patientPortal/src/utils/getTimezoneWithOffset';
import Calendar from '@worklist-2/patientPortal/src/components/WhiteLabel/Schedule/BookSlots/Calendar';
import TimeSlots from '@worklist-2/patientPortal/src/components/WhiteLabel/Schedule/BookSlots/TimeSlots';

const BookSlots = ({ form }, ref) => {
	const __config = useConfig();
	const nextStep = useScheduleStore(state => state.nextStep);
	const jumpToStep = useScheduleStore(state => state.jumpToStep);
	const modalities = useScheduleStore(state => state.modalities);
	const orgTimezoneIANA = useScheduleStore(state => state.orgDetails.timezoneIANA);
	const isLoading = useScheduleStore(state => state.isLoading);
	const slots = useScheduleStore(state => state.timeslots);

	const getAvailableTimeslots = useScheduleStore(state => state.getAvailableTimeslots);
	const selectedFacility = useScheduleStore(state => state.selectedFacility);
	const [date, setDate] = useState(new Date());
	const [selectedSlot, setSelectedSlot] = useState();
	const [verified, setVerified] = useState(false);
	const { getValues } = form;

	const [searchParams] = useSearchParams();
	const orgId = selectedFacility?.internalOrganizationID;
	const { t } = useTranslation('scheduling');
	const phoenixBlumeselfSchedulingSkipRecaptcha = useBooleanFlagValue('phoenix-blume-self-scheduling-recapcha');

	const reCaptchaRef = useRef();

	useEffect(() => {
		if (phoenixBlumeselfSchedulingSkipRecaptcha) {
			setVerified(phoenixBlumeselfSchedulingSkipRecaptcha);
		}
	}, [phoenixBlumeselfSchedulingSkipRecaptcha, searchParams]);

	useEffect(() => {
		if (!date) return;

		getAvailableTimeslots({ orgId, date: moment(date).format('YYYY-MM-DD') });
	}, [date, getAvailableTimeslots, getValues, modalities, orgId]);

	const handleSubmit = useCallback(() => {
		const startDateTime = moment(date).startOf('day').add(selectedSlot.start, 'minutes').tz(orgTimezoneIANA, true);
		const endDateTime = moment(date).startOf('day').add(selectedSlot.end, 'minutes').tz(orgTimezoneIANA, true);

		form.setValue('startTime', startDateTime);
		form.setValue('endTime', endDateTime);
		// TODO: Check what need to be done if there are multiple Ids
		form.setValue('healthcareServiceId', selectedSlot.healthcareServices[0]);

		nextStep();
	}, [date, form, nextStep, orgTimezoneIANA, selectedSlot]);

	const parsedExtension = useMemo(
		() => (selectedFacility?.extJson ? JSON.parse(selectedFacility.extJson) : {}),
		[selectedFacility]
	);
	const facilityAddress = useMemo(() => (parsedExtension?.address ? parsedExtension?.address : []), []);

	const gotToOrderSetScreen = useCallback(() => {
		form.setValue('location', '');
		form.setValue('userLocation', '');
		jumpToStep(0);
	}, [jumpToStep]);

	return (
		<Box ref={ref}>
			<Box
				maxWidth={{ md: '700px' }}
				padding={{
					xs: '16px',
					md: '32px 32px 36px 32px',
				}}
				sx={{
					margin: 'auto',
					background: '#fff',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
					borderRadius: '8px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
				width={{ xs: '80%' }}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<Box
						component="p"
						fontSize={{
							xs: '12px',
							md: '16px',
						}}
						fontWeight={400}
						lineHeight={{
							xs: '14px',
							md: '18px',
						}}
						sx={{
							color: '#475467',
							margin: '0 0 8px !important',
						}}
					>
						{t('Selected Imaging Procedure and Imaging center')}
					</Box>
					<Box
						component="p"
						fontSize={{
							xs: '14px',
							md: '20px',
						}}
						fontWeight={500}
						lineHeight={{
							xs: '16px',
							md: '23px',
						}}
						margin={{
							xs: '0 0 4px',
							md: '8px 0',
						}}
					>
						<b>
							{getValues().description} {t('at')}{' '}
							<span style={{ color: '#42A5F5' }}>{getValues().location}</span>
						</b>
					</Box>
					<Box
						component="p"
						fontSize={{
							xs: '12px',
							md: '16px',
						}}
						fontWeight={400}
						lineHeight={{
							xs: '18px',
							md: '18px',
						}}
						margin={0}
					>
						{facilityAddress[0]?.line[0]}, {facilityAddress[0]?.city}, {facilityAddress[0]?.state}{' '}
						{facilityAddress[0]?.postalCode}
					</Box>
				</Box>
				<Button color="info" sx={{ color: '#42A5F5' }} variant="text" onClick={gotToOrderSetScreen}>
					{t('CHANGE')}
				</Button>
			</Box>
			<Box
				maxWidth={{ md: '700px' }}
				padding={{
					xs: '20px 16px',
					md: '32px 32px 36px 32px',
				}}
				sx={{
					margin: '30px auto 0',
					background: '#fff',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
					borderRadius: '8px',
				}}
				width={{ xs: '80%' }}
			>
				<p>
					<b>{t('Select date and time')}</b>
					<p>{t('Choose a date from the calendar and a convenient timeslot')}</p>
				</p>
				<Calendar date={date} setDate={setDate} />
				<TimeSlots
					loading={isLoading}
					selectedSlot={selectedSlot}
					setSelectedSlot={setSelectedSlot}
					slots={slots}
				/>

				<Typography
					sx={{
						textAlign: 'center',
						fontSize: '12px',
						fontWeight: 400,
						color: '#4B5565',
						margin: '16px 0 36px !important',
					}}
				>
					All timeslots are in the {getTimezoneWithOffset(orgTimezoneIANA)}.
				</Typography>
				<Box sx={{ margin: '30px auto 10px auto', display: 'grid', placeItems: 'center' }}>
					<ReCAPTCHA
						ref={reCaptchaRef}
						sitekey={__config.patient_portal.recaptcha_verification_key}
						theme="light"
						onChange={setVerified}
					/>
					<Box sx={{ height: { xs: '60px', md: 'auto' } }}>
						<Button
							color="info"
							data-testid="bookslot-next-btn"
							disabled={!verified || !date || !selectedSlot}
							sx={{
								marginTop: { md: '48px' },
								textTransform: 'uppercase',
								boxShadow: 'none',
								position: { xs: 'fixed', md: 'relative' },
								bottom: '32px',
								width: { xs: '90%', md: '320px' },
								left: { xs: '5%', md: 0 },

								'&.Mui-disabled': {
									backgroundColor: '#e5e2e2',
								},
							}}
							type="button"
							variant="contained"
							onClick={handleSubmit}
						>
							{t('Next')}
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default forwardRef(BookSlots);
