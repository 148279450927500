import { useSelector } from '@rs-core/context/SelectorContext';
import React from 'react';
import { Select, StyledOption } from './CustomSelect';

export default function RegionSelector() {
	const { region, regions, updateValue } = useSelector();

	return (
		<Select value={region} onChange={v => updateValue('region', v)}>
			{regions.map(c => (
				<StyledOption key={c.code} value={c.code}>
					<div className="flag">
						<img
							alt={`Flag of ${c.label}`}
							loading="lazy"
							src={`https://flagcdn.com/${c.code.toLowerCase()}.svg`}
							srcSet={`https://flagcdn.com/${c.code.toLowerCase()}.svg`}
							width="35"
						/>
					</div>
					<div className="country">{c.label}</div>
				</StyledOption>
			))}
		</Select>
	);
}
