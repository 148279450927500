import React from 'react';
import Divider from '@mui/material/Divider';
import SecondaryButton from '@rs-ui/components/SecondaryButton';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@rs-ui/components/Avatar';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { mediaTablet } from '@rs-core/utils/responsiveUtils';
import { useRouter } from '@rs-core/hooks/useRouter';
import { useAuth } from '@rs-core/context/UserAuthContext';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

const PopupUserSettings = ({ isOpen, x, y, onClickSignOut, onClose, patientImg }) => {
	const { goTo } = useRouter();
	const { userInfo, loggedInUser } = useAuth();
	const { t } = useTranslation('profile');

	const handleUserSetting = () => {
		goTo.userInfo();
		onClose();
	};

	return (
		<Popper
			open={isOpen}
			sx={{
				zIndex: '1061',
			}}
			onClose={onClose}
		>
			<ClickAwayListener onClickAway={onClose}>
				<Paper
					elevation={20}
					sx={{
						zIndex: 999,
						position: 'fixed',
						left: x,
						bottom: y,
						flexDirection: 'column',
						display: 'flex',
						justifyContent: 'start',
						alignItems: 'center',
						width: 247,
						pt: '25px',
						pb: '20px',
						px: '26px',
						[mediaTablet]: {
							left: '20px',
							bottom: '83px',
						},
					}}
				>
					<Stack alignItems="center" justifyContent="center" sx={{ marginBottom: '25px' }}>
						{patientImg ? (
							<IconButton>
								<Avatar
									src={patientImg}
									sx={{
										width: '77px',
										height: '77px',
										mb: '15px',
									}}
								/>
							</IconButton>
						) : (
							<IconButton
								sx={{
									backgroundColor: 'rsPrimary.main',
									width: '77px',
									height: '77px',
									mb: '15px',
									'&:hover': {
										backgroundColor: 'rsPrimary.main',
									},
								}}
							>
								<Avatar fontSize={16} label={loggedInUser?.fullName} />
							</IconButton>
						)}
						<Typography sx={{ opacity: 0.6 }} variant="subtitle2">
							{loggedInUser?.fullName}
						</Typography>
						<Typography sx={{ opacity: 0.6 }} variant="subtitle2">
							{userInfo?.username}
						</Typography>
					</Stack>
					<Stack spacing="10px">
						<SecondaryButton
							height={40}
							label={t('User Settings')}
							width={247}
							onClick={handleUserSetting}
						/>
					</Stack>
					<Box sx={{ pt: '25px', pb: '25px' }}>
						<Divider width={247} />
					</Box>
					<Box>
						<SecondaryButton height={40} label={t('Logout')} width={247} onClick={onClickSignOut} />
					</Box>
					<Link
						color="#42A5F5"
						href="https://www.ramsoft.com"
						sx={{
							fontFamily: 'Roboto',
							fontSize: 8,
							marginTop: '16px',
						}}
						target="_blank"
						underline="none"
					>
						www.ramsoft.com
					</Link>
				</Paper>
			</ClickAwayListener>
		</Popper>
	);
};

PopupUserSettings.propTypes = {
	/**
	 * Determine whether or not the popup window is open.
	 */
	isOpen: PropTypes.bool,

	/**
	 * x-coordinate of where the popup window should appear
	 */
	x: PropTypes.number,

	/**
	 * y-coordinate of where the popup window should appear
	 */
	y: PropTypes.number,

	/**
	 * callback handler for when the signOut button is clicked
	 */
	onClickSignOut: PropTypes.func,

	/**
	 * callback handler for when the popup window closes due to clicking away
	 */
	onClose: PropTypes.func,
};

export default PopupUserSettings;
