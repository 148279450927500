import React, { useMemo, useState, useCallback, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import { loadValueSet } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import _ from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MAX_MENU_ITEMS = 7;

const MultiSelect = ({
	hideIcon,
	itemList,
	label,
	size,
	style,
	width,
	optionId,
	fullWidth,
	onClose,
	onOpen,
	onSelect,
	preSelectedValue,
	valueSetType,
	capitalizeOptions = false,
	displayValue = 'display',
	testId,
	fieldRef,
}) => {
	const [itemName, setItemName] = useState(preSelectedValue && preSelectedValue.length > 0 ? preSelectedValue : []);
	const [selectableItems, setSelectableItems] = useState([]);

	const [optionsList, setOptionsList] = useState(itemList.length > 0 ? itemList : []);
	const fhirDataLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});

	// For multiSelect options, get valueSet from API when component loads
	useEffect(() => {
		const getValueSet = async () => {
			if (!valueSetType || (optionsList && optionsList.length > 0)) {
				return optionsList;
			}
			return loadValueSet(fhirDataLoader, valueSetType, displayValue, capitalizeOptions);
		};
		getValueSet()
			.then(output => {
				const tempArr = [];
				const updatedItemList = [];
				_.map(output, item => {
					tempArr.push(item);
					if (_.find(itemName, selectedItem => selectedItem === item.display)) {
						updatedItemList.push(item);
					}
				});
				setOptionsList(tempArr || []);
				setItemName(updatedItemList);
			})
			.catch(console.error);
	}, []);

	const menuProps = useMemo(
		() => ({
			sx: { ml: -3 },
			PaperProps: {
				variant: 'outlined',
				elevation: 0,
				style: {
					maxHeight: ITEM_HEIGHT * MAX_MENU_ITEMS + ITEM_PADDING_TOP,
					width,
					...style,
				},
			},
		}),
		[width]
	);

	useEffect(() => {
		if (!!optionsList && !_.isEmpty(optionsList)) {
			setSelectableItems(optionsList);
		}
	}, [optionsList]);

	const handleChange = useCallback(
		event => {
			const changedValues = event.target.value;

			setItemName(changedValues);

			if (onSelect) {
				onSelect(
					_.map(changedValues, field => field.id),
					_.map(changedValues, field => field.display)
				);
			}
		},
		[onSelect, itemName]
	);

	const menuItemList = useMemo(
		() =>
			_.map(selectableItems, item => (
				<MenuItem key={_.camelCase(item.id)} name={_.camelCase(item.display)} sx={{ px: '6px' }} value={item}>
					<Checkbox
						checked={_.findIndex(itemName, elem => elem?.display === item.display) > -1}
						sx={{ py: '6px' }}
					/>
					<ListItemText primary={item.display} />
				</MenuItem>
			)),
		[selectableItems, itemName]
	);

	return (
		<FormControl fullWidth={fullWidth} sx={{ ...(!fullWidth && { width }), ...style }}>
			{label ? (
				<InputLabel shrink id="multiple-checkbox-label">
					{label}
				</InputLabel>
			) : null}
			<Select
				ref={fieldRef}
				displayEmpty
				multiple
				MenuProps={menuProps}
				data-cy={testId}
				id="multiple-checkbox"
				input={<OutlinedInput label={label} />}
				label={label}
				labelId="multiple-checkbox-label"
				notched={!!label}
				renderValue={selectedValues =>
					selectedValues.length ? (
						_.join(
							_.map(selectedValues, value => value?.display || value),
							'|'
						)
					) : (
						<label style={{ opacity: 0.5 }}>Search</label>
					)
				}
				size={size || 'medium'}
				startAdornment={
					!hideIcon && (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}
				value={itemName}
				onChange={handleChange}
				onClose={onClose}
				onOpen={onOpen}
			>
				{menuItemList}
			</Select>
		</FormControl>
	);
};

MultiSelect.propTypes = {
	/**
	 * Hides the magnifier icon
	 */
	hideIcon: PropTypes.bool,

	/**
	 * List of items to be populated into the multi-select.
	 */
	itemList: PropTypes.array,

	/**
	 * Label for the component
	 */
	label: PropTypes.string,

	/**
	 * The initial field value. Used when a filter is pre-set
	 */
	selected: PropTypes.arrayOf(PropTypes.string),

	/**
	 * Size of the component. Can be `small` or `medium`.
	 */
	size: PropTypes.oneOf(['small', 'medium']),

	/**
	 * Custom style that can be passed in and be inserted into the sx prop.
	 */
	style: PropTypes.object,

	/**
	 * Controls whether the component should take up the full width of the container or not.
	 */
	fullWidth: PropTypes.bool,

	/**
	 * Controls the width of the component. If `fullWidth` is set to true, then this property will not take effect.
	 */
	width: PropTypes.number,

	/**
	 * Callback for when the multi-select component closes.
	 */
	onClose: PropTypes.func,

	/**
	 * Callback for when the multi-select component opens.
	 */
	onOpen: PropTypes.func,

	/**
	 * Callback for when an item in the multi-select is selected.
	 */
	onSelect: PropTypes.func,
	/**
	 * Search parameter for the API. The data that needs to be fetched.
	 */
	valueSetType: PropTypes.string,
	/**
	 * Key in the API result Array of objects.
	 */
	displayValue: PropTypes.string,
	/**
	 * A reference to the input field of the component; used for clearing the value when a filter is removed
	 */
	fieldRef: PropTypes.shape({ current: PropTypes.any }),
};

MultiSelect.defaultProps = {
	width: 310,
	fullWidth: false,
	style: {},
	itemList: [],
};

/** A multi-select combo box component which allows the user to select multiple items from a pre-defined list */
export default MultiSelect;
