// core
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getDateHintText, getFormattedDateRange } from '@rs-ui/components/utils/dateUtils';
import isArray from 'lodash/isArray';
// MUI
import TextField from '@mui/material/TextField';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StaticTimePicker from '@mui/lab/StaticTimePicker';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';

// partials
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import DateTimePillPicker from './DateTimePillPicker';

import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-CA';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			aria-labelledby={`simple-tab-${index}`}
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			role="tabpanel"
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 1, padding: '0 14px', height: 386, width: 626 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const DateTimeRangePicker = ({
	width,
	onChange,
	label,
	labelColor,
	defaultValue,
	filterDate,
	withFilter,
	clearFilter,
	bgColor,
	padding,
	openedFromOutside = false,
	customAnchor = null,
	customAnchorOrigin = null,
	setDateRangeOpened = () => {},
	withCustomHandlers = false,
	dateRange = null,
	disableTabs,
	hasPillPicker = false,
	pillPickerOption,
	hasClear = false,
	prefixText = '',
	dateTimeFormat = 'MM/DD/YYYY hh:mm:ss a',
	dateRangeRef,
	onFocus,
	onBlur,
	isTimeHidden,
	placeholder,
	helperText,
}) => {
	const { i18n } = useTranslation();

	const getLocale = () => {
		switch (i18n.language || 'en') {
			case 'fr':
				return fr;
			case 'en':
			default:
				return en;
		}
	};

	const { t } = useTranslation('datetimerangepicker');

	const useStyles = makeStyles()({
		main: {
			color: '#42A5F5',
		},
	});

	const { classes } = useStyles();

	const [inputDateTime, setInputDateTime] = useState(prefixText);

	const [inputHours, setInputHours] = useState('');
	const [inputMinutes, setInputMinutes] = useState('');
	const [isAm, setIsAm] = useState(false);
	const [previousInputDateTime, setPreviousInputDateTime] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [pillAnchorEl, setPillAnchorEl] = useState(null);
	const [isError, setIsError] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const [clockTabValue, setClockTabValue] = useState(0);
	const [openedDisplay, setOpenedDisplay] = useState('hours');
	const [dateValue, setDateValue] = useState([null, null]);
	const [previousDateValue, setPreviousDateValue] = useState([null, null]);
	const [firstTimeValue, setFirstTimeValue] = useState({
		hours: 0,
		minutes: 0,
		isAm: true,
	});
	const [secondTimeValue, setSecondTimeValue] = useState({
		hours: 11,
		minutes: 59,
		isAm: false,
	});
	const [formDateValue, setFormDateValue] = useState([null, null]);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;
	const pillOpen = Boolean(pillAnchorEl);
	const pillId = pillOpen ? 'pill-popper' : undefined;
	const previousMonth = new Date();
	previousMonth.setMonth(previousMonth.getMonth() - 1);

	const [selectedPill, setSelectedPill] = useState();

	useEffect(() => {
		if (filterDate) {
			setInputDateTime(filterDate);
			setDateValue(filterDate);
		}
	}, [filterDate]);

	useEffect(() => {
		if (withCustomHandlers && !dateRange?.length) {
			setDateValue([null, null]);
		}
	}, [withCustomHandlers, dateRange]);

	//Tab event
	const handleChange = (event, newValue) => {
		setClockTabValue(0);
		if (dateValue[0] && dateValue[1]) {
			setTabValue(newValue);
		} else {
			setIsError(true);
			setTimeout(() => setIsError(false), 2000);
		}
	};

	//Clock event
	const handleClockChange = (event, newValue) => {
		setClockTabValue(newValue);
		setOpenedDisplay('hours');
	};

	const handleInitValueSet = (date, state, setState) => {
		const rawDate = moment(date);
		let hours = rawDate.hours();
		const minutes = rawDate.minutes();

		if (hours >= 12) {
			hours -= 12;
			setState({ ...state, hours, minutes, isAm: false });
		} else {
			setState({ ...state, hours, minutes, isAm: true });
		}
	};

	const handleDateFormat = () => {
		let startDateTime;
		let endDateTime;
		let dateInput = '';
		if (dateValue[0]) {
			startDateTime = moment(dateValue[0])
				.hours(firstTimeValue.isAm ? firstTimeValue.hours : firstTimeValue.hours + 12)
				.minutes(firstTimeValue.minutes)
				.format(dateTimeFormat);

			dateInput = prefixText + startDateTime;
		}
		if (dateValue[1]) {
			endDateTime = moment(dateValue[1])
				.hours(secondTimeValue.isAm ? secondTimeValue.hours : secondTimeValue.hours + 12)
				.minutes(secondTimeValue.minutes)
				.seconds(59)
				.format(dateTimeFormat);

			dateInput = `${prefixText + startDateTime} - ${endDateTime}`;
		}
		// set both start and end date to be the same
		if (dateValue[0] && !dateValue[1]) {
			endDateTime = moment(new Date(dateValue[0])).hours(23).minutes(59).seconds(59).format(dateTimeFormat);
			dateInput = `${prefixText + startDateTime} - ${endDateTime}`;
		}
		setInputDateTime(dateInput);
		setPreviousInputDateTime(dateInput);
		return [startDateTime, endDateTime];
	};

	const handleHoursChange = event => {
		const { value } = event.target;
		const regex = /^[0-9\b]+$/;
		if (event.target.value == '' || (event.target.value.length <= 2 && regex.test(event.target.value))) {
			setInputHours(value);
		}
	};

	const handleMinutesChange = event => {
		const regex = /^[0-9\b]+$/;
		if (event.target.value == '' || (event.target.value.length <= 2 && regex.test(event.target.value))) {
			setInputMinutes(event.target.value);
		}
	};

	const handleHoursOnBlur = rawHours => {
		let hours = Number(rawHours);
		if (hours > 0 && hours <= 12 && rawHours !== '') {
			hours = hours === 12 ? 0 : hours;
			if (clockTabValue === 0) {
				setFirstTimeValue({ ...firstTimeValue, hours });
			} else {
				setSecondTimeValue({ ...secondTimeValue, hours });
			}
		} else if (clockTabValue === 0) {
			setInputHours(firstTimeValue.hours === 0 ? 12 : firstTimeValue.hours);
		} else {
			setInputHours(secondTimeValue.hours === 0 ? 12 : secondTimeValue.hours);
		}
	};

	const handleMinutesOnBlur = minutes => {
		if (Number(minutes) < 60 && minutes !== '') {
			if (clockTabValue === 0) {
				setFirstTimeValue({ ...firstTimeValue, minutes });
			} else {
				setSecondTimeValue({ ...secondTimeValue, minutes });
			}
		} else if (clockTabValue === 0) {
			setInputMinutes(firstTimeValue.minutes);
		} else {
			setInputMinutes(secondTimeValue.minutes);
		}
	};

	const handleTimePickerOnChange = date => {
		let hours = moment(date).hours();
		const minutes = moment(date).minutes();
		if (hours >= 12) {
			hours -= 12;
		}

		if (clockTabValue === 0) {
			setInputHours(hours === 0 ? 12 : hours);
			setInputMinutes(minutes);
			setFirstTimeValue({ ...firstTimeValue, hours, minutes });
		} else {
			setInputHours(hours === 0 ? 12 : hours);
			setInputMinutes(minutes);
			setSecondTimeValue({ ...secondTimeValue, hours, minutes });
		}
	};

	useEffect(() => {
		if (openedFromOutside) {
			setPreviousDateValue(dateValue);
			setPreviousInputDateTime(inputDateTime);
			setAnchorEl(customAnchor);
		} else {
			setAnchorEl(null);
		}
	}, [openedFromOutside, customAnchor]);

	//Calendar event
	const handleClick = event => {
		setPreviousDateValue(dateValue);
		setPreviousInputDateTime(inputDateTime);
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handlePillClick = event => {
		event.stopPropagation();
		setAnchorEl(null);
		setPillAnchorEl(pillAnchorEl ? null : event.currentTarget);
	};

	const handlePillOptionClick = pillType => {
		onChange(pillType);
		setInputDateTime(prefixText + t(pillType));
		setDateValue([null, null]);
		setDateRangeOpened(false);
		setPreviousInputDateTime(prefixText + t(pillType));
		setPillAnchorEl(null);
	};

	const handleDateChange = newValue => {
		if (newValue[0] && newValue[1]) {
			setDateValue([
				moment(newValue[0]).startOf('day').format(dateTimeFormat),
				moment(newValue[1]).endOf('day').format(dateTimeFormat),
			]);
		} else {
			setDateValue(newValue);
		}
	};

	// Buttons event
	const handleClose = event => {
		setDateRangeOpened(false);
		setAnchorEl(null);
		setPillAnchorEl(null);
		setDateValue(previousDateValue);
		setInputDateTime(previousInputDateTime || prefixText);
		handleInitValueSet(dateValue[0], firstTimeValue, setFirstTimeValue);
		handleInitValueSet(dateValue[1], secondTimeValue, setSecondTimeValue);
		setTabValue(0);
	};

	const handleReset = (event, stopPropagation = false) => {
		if (stopPropagation) {
			event.stopPropagation();
		}

		setSelectedPill();
		if (tabValue === 0) {
			setInputDateTime(prefixText);
			setPreviousInputDateTime('');
			setDateValue([null, null]);
			setFormDateValue([null, null]);
			setPreviousDateValue([null, null]);
			onChange([]);
			setTabValue(0);
			if (clearFilter) {
				clearFilter();
			}
		} else {
			setFirstTimeValue({ hours: 0, minutes: 0, isAm: true });
			setSecondTimeValue({ hours: 11, minutes: 59, isAm: false });
			if (clockTabValue === 0) {
				setInputHours(12);
				setInputMinutes(0);
				setIsAm(true);
			} else {
				setInputHours(11);
				setInputMinutes(59);
				setIsAm(false);
			}
		}
	};

	const handleDone = () => {
		if (dateValue[0]) {
			const dateRangeValue = handleDateFormat();
			const newDates = [new Date(dateRangeValue[0]), new Date(dateRangeValue[1])];
			onChange(newDates);
			setDateValue(newDates);
		}
		setSelectedPill();
		setDateRangeOpened(false);
		setAnchorEl(null);
		setTabValue(0);
	};

	//Effects
	useEffect(() => {
		if (clockTabValue === 0) {
			setFirstTimeValue(dateValue[0] ? { ...firstTimeValue, isAm } : firstTimeValue);
		} else {
			setSecondTimeValue({ ...secondTimeValue, isAm });
		}
	}, [isAm]);

	useEffect(() => {
		if (clockTabValue === 0) {
			setInputHours(firstTimeValue.hours === 0 ? 12 : firstTimeValue.hours);
			setInputMinutes(firstTimeValue.minutes);
			setIsAm(firstTimeValue.isAm);
		} else {
			setInputHours(secondTimeValue.hours === 0 ? 12 : secondTimeValue.hours);
			setInputMinutes(secondTimeValue.minutes);
			setIsAm(secondTimeValue.isAm);
		}
	}, [clockTabValue]);

	useEffect(() => {
		let startDateTime;
		let endDateTime;
		if (dateValue[0] && dateValue[1] && tabValue === 0) {
			startDateTime = moment(dateValue[0]).format(dateTimeFormat);
			handleInitValueSet(startDateTime, firstTimeValue, setFirstTimeValue);
			endDateTime = moment(dateValue[1]).format(dateTimeFormat);
			handleInitValueSet(endDateTime, secondTimeValue, setSecondTimeValue);
		}
	}, [dateValue, t]);

	//Effects set default value for picker
	useEffect(() => {
		//if dafault value is present along with datetimeformat
		//set the value and display value accordingly
		if (defaultValue && dateTimeFormat) {
			const formattedDateRange = getFormattedDateRange(defaultValue, dateTimeFormat);

			if (formattedDateRange) {
				setInputDateTime(formattedDateRange);
				setFormDateValue(defaultValue);
				return;
			}
		}
		if (defaultValue) {
			if (isArray(defaultValue)) {
				setInputDateTime(`${prefixText}${getDateHintText(t, defaultValue)}`);
				setDateValue(defaultValue);
			} else {
				// Pill picker
				const pillValue = pillPickerOption?.find(element => element.display === defaultValue);
				setSelectedPill(pillValue?.id);
				setDateValue([defaultValue]);

				const displayDatetime = prefixText + t(defaultValue);
				setInputDateTime(displayDatetime);
				setPreviousInputDateTime(displayDatetime);
			}
		}
	}, [defaultValue]);

	//function to get valid Date if date value or form date value is present
	//used to set the pill picker to show selecetd date in date picker
	function getValidDateArray(dateValueState, formDateValueState) {
		const isValidArray = arr => Array.isArray(arr) && arr.length > 1 && arr.every(value => value !== null);

		if (isValidArray(dateValueState)) {
			return dateValueState;
		}
		if (isValidArray(formDateValueState)) {
			return formDateValueState;
		}
		return [null, null];
	}

	const TimePicker = () => (
		<Box
			sx={{
				'& .MuiButtonBase-root': {
					display: 'none',
				},
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
				<StaticTimePicker
					components={{
						LeftArrowButton: Boolean,
						RightArrowButton: Boolean,
					}}
					displayStaticWrapperAs="desktop"
					openTo={openedDisplay}
					renderInput={params => <TextField {...params} />}
					showToolbar={false}
					value={
						clockTabValue === 0
							? moment().hours(firstTimeValue.hours).minutes(firstTimeValue.minutes)
							: moment().hours(secondTimeValue.hours).minutes(secondTimeValue.minutes).seconds(59)
					}
					onChange={newValue => handleTimePickerOnChange(newValue)}
				/>
			</LocalizationProvider>
		</Box>
	);

	//UI elements
	const ResetDoneButtons = () => (
		<Box
			sx={{
				position: 'absolute',
				bottom: 0,
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: 'auto',
				pb: '12px',
				gap: '10px',
				right: '30px',
			}}
		>
			<SecondaryButton label={t('CANCEL')} testId="cancelDateTime" width="auto" onClick={handleClose} />
			<SecondaryButton label={t('RESET')} testId="resetDateTime" width="auto" onClick={handleReset} />
			<PrimaryButton label={t('DONE')} testId="saveDateTime" width="auto" onClick={handleDone} />
		</Box>
	);

	const CalendarWithArrow = ({ first, selected }) => (
		<div style={{ position: 'relative' }}>
			<CalendarTodayIcon />
			{!selected && (
				<ArrowBackIcon
					sx={{
						position: 'absolute',
						bottom: 0,
						right: 0,
						width: '12px',
						height: '12px',
						backgroundColor: '#393939',
						transform: first && 'rotate(-180deg)',
					}}
				/>
			)}
		</div>
	);

	const AmPmButton = ({ style, title, isActive, onClick, testid }) => (
		<Button
			sx={{
				minWidth: 'unset',
				width: '33px',
				height: '33px',
				borderRadius: '50%',
				backgroundColor: isActive ? 'rgba(66, 165, 245, 0.1)' : 'none',
				fontSize: '14px',
				fontWeight: 500,
				lineHeight: '20px',
				color: isActive ? '#42A5F5' : 'rgba(255, 255, 255, 0.6)',
				...style,
			}}
			testid={testid}
			onClick={onClick}
		>
			{title}
		</Button>
	);

	return (
		<Box ref={dateRangeRef} data-testid="date-time-range-picker">
			<Tooltip
				arrow
				disableFocusListener
				disableInteractive
				disableTouchListener
				placement="top-end"
				title={!isTimeHidden ? getDateHintText(t, dateValue) || inputDateTime : ''}
			>
				<TextField
					InputProps={{
						readOnly: true,
						disableUnderline: withFilter,
						startAdornment: (
							<InputAdornment position="start" sx={{ color: withFilter && '#42A5F5' }}>
								<CalendarMonthIcon />
							</InputAdornment>
						),
						endAdornment: (
							<>
								{hasClear && inputDateTime !== '' && (
									<IconButton
										data-cy={`${label}_dateTimeReset`}
										edge="end"
										sx={{ display: 'none' }}
										onClick={e => {
											handleReset(e, true);
										}}
										onMouseDown={event => event.preventDefault()}
									>
										<CancelIcon />
									</IconButton>
								)}
								<Tooltip title={t('datetimePresets')}>
									<IconButton
										edge="end"
										sx={{ color: withFilter && '#42A5F5' }}
										onClick={e => {
											hasPillPicker && handlePillClick(e);
										}}
										onMouseDown={event => event.preventDefault()}
									>
										<MoreVertIcon />
									</IconButton>
								</Tooltip>
							</>
						),
						classes: {
							input: withFilter ? classes.main : null,
						},
					}}
					data-cy={label ? `${label}_filter` : 'dateTimeLabelField'}
					helperText={helperText}
					label={label}
					placeholder={placeholder || ''}
					sx={{
						minWidth: width || inputDateTime.length * 8.1 + 63,
						borderRadius: withFilter && '18px',
						'& fieldset': {
							borderColor: open || pillOpen ? '#42a5f5 !important' : 'rgba(255, 255, 255, 0.23)',
							borderWidth: open || pillOpen ? '2px' : '1px',
						},
						'& label': {
							color: open || pillOpen ? '#42a5f5' : labelColor || 'inherit',
						},
						// background: withFilter && bgColor ? bgColor : '#343739',
						// padding: withFilter && padding ? padding : '5px',
					}}
					value={inputDateTime}
					variant={withFilter && 'standard'}
					onBlur={() => (onBlur ? onBlur() : null)}
					onClick={handleClick}
					onFocus={() => (onFocus ? onFocus() : null)}
				/>
			</Tooltip>
			<Popover
				anchorEl={anchorEl?.current ? anchorEl.current : anchorEl}
				anchorOrigin={
					customAnchorOrigin || {
						vertical: customAnchor ? 'center' : 'bottom',
						horizontal: customAnchor ? 'right' : 'left',
					}
				}
				id={id}
				open={open}
				sx={{
					'.MuiPaper-root': {
						overflow: 'hidden',
						margin: customAnchorOrigin ? '0' : customAnchor ? '30px 0 0 -103px' : '0',
					},
				}}
				onClick={() => (onFocus ? onFocus() : null)}
				onClose={handleClose}
			>
				<TabPanel index={0} value={tabValue}>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
						<StaticDateRangePicker
							defaultCalendarMonth={previousMonth}
							displayStaticWrapperAs="desktop"
							openTo="Days"
							renderInput={(startProps, endProps) => (
								<>
									<TextField {...startProps} />
									<Box sx={{ mx: 2 }}> to </Box>
									<TextField {...endProps} />
								</>
							)}
							sx={{
								'.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
									backgroundColor: 'rgba(66, 165, 245, 0.1)',
								},
							}}
							value={getValidDateArray(dateValue, formDateValue)}
							onChange={handleDateChange}
						/>
					</LocalizationProvider>
				</TabPanel>
				<TabPanel index={1} value={tabValue}>
					<Tabs
						TabIndicatorProps={{
							style: {
								display: 'none',
							},
						}}
						aria-label="clock toolbar"
						value={clockTabValue}
						onChange={handleClockChange}
					>
						<Tab
							aria-label="first date"
							icon={<CalendarWithArrow first selected={clockTabValue === 0} />}
							label={moment(dateValue[0]).format('MM/DD/YYYY')}
							sx={{
								fontSize: '10px',
								textTransform: 'none',
								minWidth: 0,
								justifyContent: 'space-around',
								borderRadius: '0px 0px 10px 10px',
								'&:hover': {
									color: 'rsPrimary.main',
									opacity: 1,
								},
								'&.Mui-selected': {
									color: 'rsPrimary.main',
									backgroundColor: 'rgba(66, 165, 245, 0.1)',
								},
							}}
						/>
						<Tab
							aria-label="second date"
							icon={<CalendarWithArrow selected={clockTabValue === 1} />}
							label={moment(dateValue[1]).format('MM/DD/YYYY')}
							sx={{
								fontSize: '10px',
								textTransform: 'none',
								minWidth: 0,
								justifyContent: 'space-around',
								borderRadius: '0px 0px 10px 10px',
								'&:hover': {
									color: 'rsPrimary.main',
									opacity: 1,
								},
								'&.Mui-selected': {
									color: 'rsPrimary.main',
									backgroundColor: 'rgba(66, 165, 245, 0.1)',
								},
							}}
						/>
					</Tabs>
					{tabValue === 1 && (
						<div>
							<TimePicker />
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<AmPmButton
									isActive={isAm}
									style={{ marginRight: '40px' }}
									testid="AmButton"
									title="am"
									onClick={() => setIsAm(true)}
								/>
								<Box
									sx={{
										margin: '0 2px',
										padding: '7px 8px 6px 10px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										backgroundColor:
											openedDisplay === 'hours' ? 'rgba(66, 165, 245, 0.1)' : '#454545',
										borderRadius: '10px',
										cursor: 'pointer',
									}}
									onClick={() => setOpenedDisplay('hours')}
								>
									<Typography
										sx={{
											fontSize: '14px',
											fontWeight: 500,
											lineHeight: '20px',
											color: openedDisplay === 'hours' ? '#42A5F5' : '#FFFFFF',
											opacity: 0.6,
										}}
									>
										H:
									</Typography>
									<InputBase
										sx={{
											width: '20px',
											height: '20px',
											marginLeft: '5px',
											padding: '0',
											border: 'none',
											backgroundColor: 'unset',
											fontSize: '14px',
											fontWeight: 500,
											lineHeight: '20px',
											caretColor: '#42A5F5',
										}}
										value={inputHours}
										onBlur={() => handleHoursOnBlur(inputHours)}
										onChange={handleHoursChange}
									/>
								</Box>
								<Box
									sx={{
										margin: '0 2px',
										padding: '7px 8px 6px 10px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										backgroundColor:
											openedDisplay === 'minutes' ? 'rgba(66, 165, 245, 0.1)' : '#454545',
										borderRadius: '10px',
										cursor: 'pointer',
									}}
									onClick={() => setOpenedDisplay('minutes')}
								>
									<Typography
										sx={{
											fontSize: '14px',
											fontWeight: 500,
											lineHeight: '20px',
											color: openedDisplay === 'minutes' ? '#42A5F5' : '#FFFFFF',
											opacity: 0.6,
										}}
									>
										M:
									</Typography>
									<InputBase
										sx={{
											width: '20px',
											height: '20px',
											marginLeft: '5px',
											padding: '0',
											border: 'none',
											backgroundColor: 'unset',
											fontSize: '14px',
											fontWeight: 500,
											lineHeight: '20px',
											caretColor: '#42A5F5',
										}}
										value={inputMinutes}
										onBlur={() => handleMinutesOnBlur(inputMinutes)}
										onChange={handleMinutesChange}
									/>
								</Box>
								<AmPmButton
									isActive={!isAm}
									style={{ marginLeft: '40px' }}
									testid="PmButton"
									title="pm"
									onClick={() => setIsAm(false)}
								/>
							</Box>
						</div>
					)}
				</TabPanel>
				<Divider variant="fullwidth" />
				<Grid container spacing={2}>
					{disableTabs ? null : (
						<Grid item xs={6}>
							<Tabs
								aria-label="main toolbar"
								sx={{
									'& .MuiTabs-indicator': {
										backgroundColor: 'rsPrimary.main',
										top: '1px',
										height: '5px',
									},
								}}
								value={tabValue}
								onChange={handleChange}
							>
								<Tab
									aria-label="dateTab"
									icon={<CalendarTodayIcon />}
									sx={{
										textTransform: 'none',
										minWidth: 0,
										width: '87px',
										marginTop: '10px',
										'&:hover': {
											color: 'rsPrimary.main',
											opacity: 1,
										},
										'&.Mui-selected': {
											color: 'rsPrimary.main',
										},
									}}
								/>
								{!isTimeHidden && (
									<Tab
										aria-label="timeTab"
										icon={<AccessTimeIcon />}
										sx={{
											textTransform: 'none',
											minWidth: 0,
											width: '87px',
											marginTop: '10px',
											'&:hover': {
												color: 'rsPrimary.main',
												opacity: 1,
											},
											'&.Mui-selected': {
												color: 'rsPrimary.main',
											},
										}}
									/>
								)}
							</Tabs>
						</Grid>
					)}
					<Grid item xs={6}>
						<Box sx={{ display: 'flex', alignItems: 'center', height: '58px' }}>
							<ResetDoneButtons />
						</Box>
					</Grid>
				</Grid>
				<Box
					sx={{
						position: 'absolute',
						bottom: 0,
						width: '100%',
						height: '58px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#393939',
						color: '#ef5350',
						transform: `translateY(${isError ? '0' : '100%'})`,
						transition: 'transform 0.3s ease-in-out',
					}}
				>
					<Typography>{t('Selection of date is mandatory to select the time')}</Typography>
				</Box>
			</Popover>
			<Popover
				anchorEl={pillAnchorEl?.current ? pillAnchorEl.current : pillAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				id={pillId}
				open={pillOpen}
				sx={{
					'.MuiPaper-root': {
						overflow: 'hidden',
						margin: customAnchor ? '30px 0 0 -103px' : '0',
					},
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={handleClose}
			>
				<DateTimePillPicker
					pillPickerOption={pillPickerOption}
					selectedPill={selectedPill}
					setSelectedPill={setSelectedPill}
					onPillClick={handlePillOptionClick}
				/>
			</Popover>
		</Box>
	);
};

DateTimeRangePicker.propTypes = {
	/**
	 * The function to be called when user selects the value of the datepicker
	 */
	onChange: PropTypes.func,
	/**
	 * width of the component
	 */
	width: PropTypes.number,
};

export default DateTimeRangePicker;
