import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useDelayedRun from '@rs-core/hooks/useDelayedRun';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
//libraries
import { useTranslation } from 'react-i18next';
import SearchableSingleSelectColumnFilter from '@worklist-2/ui/src/components/SearchableSingleSelectColumnFilter';
import SearchableCheckboxSingleSelectColumnFilter from '@worklist-2/ui/src/components/SearchableCheckboxSingleSelectColumnFilter';
import SearchableMultiSelectColumnFilter from '@worklist-2/ui/src/components/SearchableMultiSelectColumnFilter';
import SearchableCheckboxMultiSelectColumnFilter from '@worklist-2/ui/src/components/SearchableCheckboxMultiSelectColumnFilter';
import SuggestMultipleValueInfiniteScroll from '@worklist-2/ui/src/components/SuggestMultipleValueInfiniteScroll';
import Suggest from '@worklist-2/ui/src/components/Suggest';
import TextSearch from '@worklist-2/ui/src/components/TextSearch';
import DateTimeRangePicker from '../../../DateTimeRangePicker/DateTimeRangePicker';
import DatePicker from '../../../DatePicker';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const calculateColumnWidth = (type, width) => {
	if (['date-range', 'date-time'].includes(type)) {
		return 'fit-content';
	}
	return width ?? '200px';
};

const ColumnFilter = ({
	forceShowRef,
	fieldRef,
	itemList,
	label,
	searchId,
	multiParam,
	displayValue,
	capitalizeOptions,
	size,
	onClose,
	onOpen,
	onSelected,
	filterType,
	suggestScope,
	preSelectedValues,
	style,
	hideTags,
	labelAlwaysShrunk = true,
	options,
	type,
	hideIcon = true,
	otherProps,
	customFilterOptions,
	mask,
	valueField,
	dateRangeRef,
	showResetButtonOnDatePicker,
	setHoverClassnameActive,
	columnWidth,
	valueSetExtraParam,
	managingOrgId,
	searchComponentConfig,
	isAdvanceFilter,
	multipleTags = false,
	testId,
}) => {
	const [forceShow, setForceShow] = useState(false);
	const onSelectedFn = useCallback(onSelected, [onSelected]);
	const codeLoader = useFhirDataLoader({ scope: searchComponentConfig?.scope });

	const { t } = useTranslation('organization');
	const onCloseFn = useCallback(
		() =>
			onClose ||
			(() => {
				useDelayedRun(() => {
					!!forceShow && (forceShowRef.current = false);
				}, 100);
				setForceShow(forceShowRef.current);
			})
	);
	const onOpenFn = useCallback(
		() =>
			onOpen ||
			(() => {
				!forceShow && (forceShowRef.current = true);
				setForceShow(forceShowRef.current);
			})
	);

	const sizeVal = size || 'medium';

	const proactEnableSearchChildrenOrganizationStudies = useBooleanFlagValue(
		'proact-enable-search-children-organization-studies'
	);

	const popperProps = {
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 5],
				},
			},
		],
		style: {
			zIndex: isAdvanceFilter ? 1301 : 200,
			maxWidth: calculateColumnWidth(filterType, columnWidth),
			width: '100%',
			border: '1px solid transparent',
			boxShadow: '0px 8px 30px 0px #00000080',
			borderRadius: '6px',
			background:
				'linear-gradient(white, white) padding-box, linear-gradient(to bottom, #42A5F5, transparent) border-box',
		},
	};

	const getPreSelectedValuesForSAYT = () => {
		if (typeof preSelectedValues === 'string') {
			return preSelectedValues?.split('|').map(e => ({ tagDisplay: e }));
		}
		if (isAdvanceFilter) {
			return preSelectedValues.map(e => ({ tagDisplay: e }));
		}
		return preSelectedValues;
	};

	switch (filterType) {
		case 'single-select':
			return (
				<SearchableSingleSelectColumnFilter
					capitalizeOptions={capitalizeOptions}
					customFilterOptions={customFilterOptions}
					defaultValue={preSelectedValues}
					fieldRef={fieldRef}
					label={label}
					labelAlwaysShrunk={labelAlwaysShrunk}
					options={options}
					placeholder={t('Search')}
					popperProps={popperProps}
					testId={testId || `${label}_filter`}
					valueSetExtraParam={valueSetExtraParam}
					valueSetType={searchId}
					width="100%"
					onBlur={() => (setHoverClassnameActive ? setHoverClassnameActive(false) : '')}
					onFocus={() => (setHoverClassnameActive ? setHoverClassnameActive(true) : '')}
					onSelect={onSelectedFn}
				/>
			);
		case 'checkbox-single-select':
			return (
				<SearchableCheckboxSingleSelectColumnFilter
					capitalizeOptions={capitalizeOptions}
					customFilterOptions={customFilterOptions}
					defaultValue={displayValue}
					fieldRef={fieldRef}
					label={label}
					labelAlwaysShrunk={labelAlwaysShrunk}
					options={options}
					placeholder={t('Search')}
					popperProps={popperProps}
					testId={testId || `${label}_filter`}
					valueSetExtraParam={valueSetExtraParam}
					valueSetType={searchId}
					width="100%"
					onBlur={() => (setHoverClassnameActive ? setHoverClassnameActive(false) : '')}
					onFocus={() => (setHoverClassnameActive ? setHoverClassnameActive(true) : '')}
					onSelect={onSelectedFn}
				/>
			);
		case 'multi-select':
			return (
				<SearchableMultiSelectColumnFilter
					fullWidth
					capitalizeOptions={capitalizeOptions}
					columnWidth={columnWidth}
					displayValue={displayValue}
					fieldRef={fieldRef}
					hideTags={hideTags}
					label={label}
					labelAlwaysShrunk={labelAlwaysShrunk}
					multipleTags={multipleTags}
					options={options || customFilterOptions || itemList}
					placeholder={t('Search')}
					popperProps={popperProps}
					preSelectedValues={preSelectedValues}
					style={style}
					testId={testId || `${label}_filter`}
					valueSetExtraParam={valueSetExtraParam}
					valueSetType={searchId}
					onBlur={() => (setHoverClassnameActive ? setHoverClassnameActive(false) : '')}
					onFocus={() => (setHoverClassnameActive ? setHoverClassnameActive(true) : '')}
					onSelectFilter={onSelectedFn}
				/>
			);
		case 'checkbox-multi-select':
			return (
				<SearchableCheckboxMultiSelectColumnFilter
					fullWidth
					capitalizeOptions={capitalizeOptions}
					columnWidth={columnWidth}
					customFilterOptions={customFilterOptions}
					displayValue={displayValue}
					fieldRef={fieldRef}
					hideTags={hideTags}
					label={label}
					labelAlwaysShrunk={labelAlwaysShrunk}
					multipleTags={multipleTags}
					options={options || itemList}
					placeholder={t('Search')}
					popperProps={popperProps}
					preSelectedValues={preSelectedValues}
					style={style}
					testId={testId || `${label}_filter`}
					valueSetExtraParam={valueSetExtraParam}
					valueSetType={searchId}
					onBlur={() => (setHoverClassnameActive ? setHoverClassnameActive(false) : '')}
					onFocus={() => (setHoverClassnameActive ? setHoverClassnameActive(true) : '')}
					onSelectFilter={onSelectedFn}
				/>
			);
		case 'infinite-scroll-multi-select-suggest':
			return (
				<SuggestMultipleValueInfiniteScroll
					disableClearable
					compareOption={searchComponentConfig?.compareOptionFunc}
					countPerPage={searchComponentConfig?.countPerPage || 20}
					dataLoader={codeLoader}
					label={label}
					limitTag={isAdvanceFilter ? -1 : 0}
					placeHolderText={t('Search')}
					renderOptionlist={searchComponentConfig?.renderOptionFunc}
					renderTag={searchComponentConfig?.renderTagFunc}
					renderTags={!!isAdvanceFilter}
					searchExistingOptions={searchComponentConfig?.searchExistingOptions}
					searchParams={{
						...searchComponentConfig?.searchParameters,
						organizationId: managingOrgId,
					}}
					selectedValue={getPreSelectedValuesForSAYT()}
					size={sizeVal}
					startAdornment={<></>}
					testId={testId || `${label}_filter`}
					width="100%"
					onBlur={() => (setHoverClassnameActive ? setHoverClassnameActive(false) : '')}
					onFocus={() => (setHoverClassnameActive ? setHoverClassnameActive(true) : '')}
					onSelectItem={onSelectedFn}
				/>
			);
		case 'suggest':
			return (
				<Suggest
					fullWidth
					data={itemList}
					enableSearchChildrenOrganizationStudies={proactEnableSearchChildrenOrganizationStudies}
					fieldRef={fieldRef}
					hideIcon={hideIcon}
					labelAlwaysShrunk={labelAlwaysShrunk}
					listSearchScope={suggestScope}
					multiParam={multiParam}
					optionId={searchId}
					placeholder={t('Search')}
					popperProps={popperProps}
					size={sizeVal}
					testId={testId || `${label}_filter`}
					testid={testId}
					text={label}
					value={preSelectedValues}
					valueField={valueField}
					onBlur={() => (setHoverClassnameActive ? setHoverClassnameActive(false) : '')}
					onClose={onCloseFn}
					onFocus={() => (setHoverClassnameActive ? setHoverClassnameActive(true) : '')}
					onOpen={onOpenFn}
					onSelect={onSelectedFn}
				/>
			);
		case 'text-search':
			return (
				<TextSearch
					fullWidth
					defaultValue={preSelectedValues}
					fieldRef={fieldRef}
					hideIcon={hideIcon}
					label={label}
					labelAlwaysShrunk={labelAlwaysShrunk}
					mask={mask}
					otherProps={otherProps}
					placeholder={t('Search')}
					style={style}
					testId={testId || `${label}_filter`}
					type={type}
					onBlur={() => (setHoverClassnameActive ? setHoverClassnameActive(false) : '')}
					onFocus={() => (setHoverClassnameActive ? setHoverClassnameActive(true) : '')}
					onSearch={onSelectedFn}
				/>
			);
		case 'date-range':
			return (
				<DateTimeRangePicker
					hasClear
					hasPillPicker
					dateRangeRef={dateRangeRef}
					defaultValue={preSelectedValues}
					label={label}
					testId={testId || `${label}_filter`}
					width="100%"
					onBlur={() => (setHoverClassnameActive ? setHoverClassnameActive(false) : '')}
					onChange={onSelectedFn}
					onFocus={() => (setHoverClassnameActive ? setHoverClassnameActive(true) : '')}
				/>
			);
		case 'date-time':
			return (
				<DatePicker
					fullWidth
					defaultValue={preSelectedValues}
					label={label}
					popperProps={popperProps}
					shouldShowResetButton={showResetButtonOnDatePicker}
					testId={testId || `${label}_filter`}
					width={null}
					onChange={onSelectedFn}
				/>
			);
		default:
			return null;
	}
};

ColumnFilter.propTypes = {
	/**
	 * A reference used to persist the visible state of the filter, and to set the class
	 */
	forceShowRef: PropTypes.shape({ current: PropTypes.bool }).isRequired,
	/**
	 * A list of values that are available to be selected
	 */
	itemList: PropTypes.any,
	/**
	 * The label that appears when hovering over the column header
	 */
	label: PropTypes.node.isRequired,
	/**
	 * An id used by the search filter and grid to identify from which field the search value originated
	 */
	searchId: PropTypes.string,
	/**
	 * Size of the component. Can be `small` or `medium`.
	 */
	size: PropTypes.oneOf(['small', 'medium']),

	/**
	 * Column width to calculate component size
	 */
	columnWidth: PropTypes.number,

	/**
	 * Callback for when an item in the multi-select is selected.
	 */
	onSelected: PropTypes.func,
	/**
	 * Callback for the filter is opened.
	 */
	onOpen: PropTypes.func,
	/**
	 * Callback for when the filter is closed.
	 */
	onClose: PropTypes.func,
	/**
	 * The type of filter to use. One of 'text-search', 'single-select', 'checkbox-single-select', 'multi-select', 'suggest'
	 */
	filterType: PropTypes.oneOf([
		'text-search',
		'single-select',
		'checkbox-single-select',
		'multi-select',
		'checkbox-multi-select',
		'suggest',
		'date-range',
		'date-time',
		'none',
	]),

	/**
	 * For Suggest filters, this is the search scope that defines which API endpoint the list is populated from
	 * resource - The name of the FHIR resource/endpoint
	 * label - Which field to read from (e.g.: name, id, etc.)
	 */
	suggestScope: PropTypes.shape({
		resource: PropTypes.string,
		label: PropTypes.string,
	}),
	/**
	 * The values that should already be selected when the component loads
	 */
	preSelectedValues: PropTypes.string,

	/**
	 * Extra parameters to load value set
	 */
	valueSetExtraParam: PropTypes.any,

	/**
	 * Pass this function to a checkbox-single-select or checkbox-multi-select filter if you want to use custom options
	 * instead of value sets. This is useful, for example, if you want to pass an object that contains options with icons.
	 */
	customFilterOptions: PropTypes.array,
	mask: PropTypes.string,

	searchComponentConfig: PropTypes.any,
};

export default ColumnFilter;
