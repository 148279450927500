// Core
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/system';
import { searchScopes } from '@rs-core/context/consts/searchScopes';

// MUI components
import useToast from '@rs-core/hooks/useToast';
import { useSelection } from '@rs-core/context/SelectionContext';
import { useImportTabContext } from '@rs-core/context/ImportTabContext';
import { useAuth } from '@rs-core/context/UserAuthContext';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Components
import TeachingFolderContainer from '@worklist-2/ui/src/components/TeachingFolder';
import TreeView from '@worklist-2/ui/src/components/TreeView';
import { createTreeDataMapping, validateFolderName, searchDFS } from '@worklist-2/ui/src/components/utils/treeUtils';
import { WidthResizeTool } from '@worklist-2/ui/src/components/ResizeTool';
import ShareDrawer from '@worklist-2/ui/src/components/Drawers/ShareDrawer';

// Context
import { useTreeContext } from '@worklist-2/ui/src/context/TreeContext';
import SecondaryButton from '@rs-ui/components/SecondaryButton';
// Libs
import _ from 'lodash';

const folderTabs = ['myfolder', 'sharedfolder'];

const defaultColumns = [
	'flagColTF',
	'studyDateTime',
	'modality',
	'accessionNumber',
	'bodyPart',
	'studyDescription',
	'gender',
	'patientAge',
	'numReports',
	'numImages',
	'clinicalNotes',
];

// Teaching Folder view port
const TeachingFolderView = ({ children, isAddingStudies, closeDrawer }) => {
	// Translation
	const { t } = useTranslation('teaching');

	// Toast message
	const { showToast } = useToast();

	// Folder structure created from response data
	const [data, setData] = useState([]);

	// Folder data in json format - saved in FHIR server
	const [dataJson, setDataJson] = useState([]);

	// Filters and sort
	const [filters, setFilters] = useState([]);
	const [sort, setSort] = useState([]);

	// Current selected studies by folder
	const [selectedFolderStudies, setSelectedFolderStudies] = useState([]);

	// Current display search text
	const [searchTextDisplay, setSearchTextDisplay] = useState('');

	// Fetch data loaded flag
	const [dataLoaded, setDataLoaded] = useState(false);

	// Theme
	const theme = useTheme();

	// Columns of worklist table
	const [columns, setColumns] = useState(defaultColumns);

	// Context values
	const {
		state,
		dispatch,
		treeWidth,
		setTreeWidth,
		setErrorCode,
		setIsUpdating,
		selectedFolderId,
		setSelectedFolderId,
		openShareDrawer,
		setOpenShareDrawer,
		selectedFolderData,
		setSelectedFolderData,
		folderTab,
		setFolderTab,
		userCanEdit,
		setUserCanEdit,
		searchText,
		setSearchText,
	} = useTreeContext();

	const { selectedStudies, resetStudiesSelection } = useSelection();

	// Auth
	const { loggedInUser, authorized } = useAuth();

	// FHIR data loader
	const fhirDataLoaderTF = useFhirDataLoader({
		scope: searchScopes.teachingFolder,
	});

	// Data saver
	const [dataSaverTF] = useState(fhirDataLoaderTF);

	// Import tab context
	const { sideBarIsOpen } = useImportTabContext();

	// Store refs
	const treeWidthRef = useRef(treeWidth);
	const folderTabRef = useRef(folderTab);
	const sortRef = useRef(sort);
	const filtersRef = useRef(filters);
	const tabInfo = useRef({});

	// Set tab info to local storage before leave the page
	useEffect(
		() => () => {
			if (loggedInUser?.id) {
				const gridLayoutFromLocal = localStorage.getItem(
					`TeachingFolderLayout ${loggedInUser?.id}${isAddingStudies ? ' Add' : ''}`
				);
				let gridObject = {};
				if (gridLayoutFromLocal) {
					gridObject = JSON.parse(gridLayoutFromLocal);
				}
				gridObject.treeWidth = treeWidthRef.current;
				gridObject.tabInfo = tabInfo.current;
				gridObject.folderTab = folderTabRef.current;

				// save latest filder and sort
				if (gridObject.gridLayout) {
					gridObject.gridLayout.sort = sortRef.current;
					gridObject.gridLayout.filters = filtersRef.current;
				} else {
					gridObject.gridLayout = { sort: sortRef.current, filters: filtersRef.current };
				}
				localStorage.setItem(
					`TeachingFolderLayout ${loggedInUser?.id}${isAddingStudies ? ' Add' : ''}`,
					JSON.stringify(gridObject)
				);
			}
		},
		[]
	);

	// Set tree width to ref
	useEffect(() => {
		treeWidthRef.current = treeWidth;
	}, [treeWidth]);

	// Set state of folder
	useEffect(() => {
		if (dataLoaded && tabInfo.current && tabInfo.current[folderTab]) {
			tabInfo.current[folderTab].state = state;
		}
	}, [state]);

	/**
	 * Sync data from localstorage
	 */
	useEffect(() => {
		if (!loggedInUser?.id) {
			return;
		}
		const gridLayoutFromLocal = localStorage.getItem(
			`TeachingFolderLayout ${loggedInUser?.id}${isAddingStudies ? ' Add' : ''}`
		);
		if (gridLayoutFromLocal) {
			const gridObject = JSON.parse(gridLayoutFromLocal);
			if (gridObject.folderTab) {
				setFolderTab(gridObject.folderTab);
			}
			if (gridObject.tabInfo) {
				tabInfo.current = gridObject.tabInfo;
			}
			if (gridObject.treeWidth) {
				setTreeWidth(gridObject.treeWidth);
			}
		} else {
			setFolderTab(folderTabs[0]);
		}
	}, [loggedInUser]);

	/**
	 * Fetch folder data on tab change
	 */
	useEffect(() => {
		if (!loggedInUser?.id || !folderTab) {
			return;
		}
		folderTabRef.current = folderTab;
		setSelectedFolderId(tabInfo.current[folderTab]?.selectedFolderId);
		fetchFolderData(tabInfo.current[folderTab]?.selectedFolderId);
	}, [folderTab]);

	/**
	 * Sync filters and sort
	 */
	useEffect(() => {
		filtersRef.current = filters;
		sortRef.current = sort;
	}, [filters, sort]);

	/**
	 * Fetch folder data on search text change
	 */
	useEffect(() => {
		if (state?.length > 0 && dataLoaded) {
			const selectedNode =
				selectedFolderId &&
				searchDFS({
					data: state,
					cond: item => item.id === selectedFolderId,
				});

			if (!selectedNode?.item && state?.length > 0 && !state[0].isNewFolder) {
				// Auto select first folder
				setSelectedFolderId(state[0].id);
			} else {
				// Set current selected folder info
				if (tabInfo.current && tabInfo.current[folderTab]) {
					tabInfo.current[folderTab].selectedFolderId = selectedFolderId;
				} else {
					folderTabs.forEach(tab => {
						tabInfo.current[tab] = { selectedFolderId: folderTab === tab ? selectedFolderId : null };
					});
				}

				// Set selected folder studies
				setSelectedFolderStudies(selectedNode?.item?.studies?.map(study => study.id) || []);

				if (selectedFolderId && selectedNode) {
					const canEdit = selectedNode.item?.canEdit;
					setUserCanEdit(canEdit);
					!canEdit && setOpenShareDrawer(false);

					setSelectedFolderData(getFolderData(selectedFolderId));
				}

				// Reset studies selection
				if (!isAddingStudies) {
					resetStudiesSelection();
				}
			}
			return;
		}
		if (dataLoaded) {
			// Reset all states
			setSelectedFolderId(null);
			setSelectedFolderStudies([]);
			setSelectedFolderData(null);
			if (!isAddingStudies) {
				resetStudiesSelection();
			}
		}
	}, [selectedFolderId, state, dataLoaded]);

	/**
	 * Handle show add folder component
	 * @returns
	 */
	const handleAddFolder = () => {
		const editElement = document.querySelector('.tree__input');
		if (editElement) return;
		dispatch({ type: 'SHOW_ADD_FOLDER', payload: { name: '', level: 0 } });
	};

	/**
	 * Handle clear search text
	 * @returns
	 */
	const handleClearSearchText = () => {
		setSearchTextDisplay('');
		dispatch({ type: 'SEARCH_FOLDER', payload: { searchText: '' } });
		setSearchText('');
	};

	/**
	 * Default folder data structure in json format
	 */
	const getFolderData = useCallback(
		folderId => {
			let folderData = dataJson.find(item => item.id === folderId);
			if (folderData) {
				folderData = dataJson.find(item => item.id === folderId);
				folderData.level = Number(folderData?.level || 1);
				folderData.studies = folderData?.studies || [];
			} else {
				folderData = {
					id: `-1`,
					resourceType: `TeachingFolder`,
					lastModified: `${new Date().toISOString()}`,
					folderName: `${t('New Folder')}`,
					studies: [],
					partOfId: null,
					level: 1,
				};
			}
			return folderData;
		},
		[dataJson, t]
	);

	/**
	 * Fetch folder data
	 */
	const fetchFolderData = useCallback(
		async folderId => {
			const params =
				folderTab == folderTabs[0]
					? { owner: loggedInUser?.id, _sort: 'level' }
					: { isShared: true, _owner: loggedInUser?.id, _sort: 'level' };
			if (isAddingStudies && folderTab === folderTabs[1]) {
				// Fetch shared folder data for adding studies
				params.privilege = 'admin';
			}
			fhirDataLoaderTF.load(params, true).then(result => {
				if (result && result.entry) {
					const resources = result.entry.map(item => item.resource);
					setData(
						createTreeDataMapping(
							resources,
							loggedInUser?.id,
							searchText,
							folderId,
							tabInfo.current[folderTab]?.state
						)
					);
					setDataJson(resources);
				}

				setDataLoaded(true);
			});
		},
		[fhirDataLoaderTF, loggedInUser, setData, setDataJson, folderTab, searchText]
	);

	/**
	 * Save data
	 */
	const saveData = useCallback(
		async (loader, props, payload) => {
			let result = {};
			if (authorized) {
				try {
					result = await loader.save(props, payload).then(res => res.data);
				} catch (e) {
					console.log(e);
				}
			} else {
				console.log('User unauthenticated');
			}
			return result;
		},
		[authorized]
	);

	/**
	 * Handle create folder / sub folder
	 * @param {*} folderId
	 * @param {*} folderName
	 * @param {*} parentId
	 * @param {*} folderLevel
	 */
	const handleFolderSubmit = useCallback(
		async (folderId, folderName, parentId, folderLevel) => {
			// Validate folder creation
			const errorCode = validateFolderName(state, folderId, folderName);
			setErrorCode(errorCode);
			if (errorCode !== 0) {
				return;
			}
			const tfPayload = getFolderData();
			tfPayload.id = folderId;
			tfPayload.folderName = folderName;
			tfPayload.level = folderLevel || 1;
			if (parentId) {
				// Add child folder
				tfPayload.partOfId = parentId;
			}

			// Add root folder
			setIsUpdating(true);
			const res = await saveData(dataSaverTF, {}, tfPayload);

			if (res && res.resourceType === 'TeachingFolder') {
				dataJson.push(res);
				setDataJson(dataJson);
				setData(createTreeDataMapping(dataJson, loggedInUser?.id, searchText, selectedFolderId, state));
				if (isAddingStudies) {
					setSelectedFolderId(res.id);
				}
			} else {
				console.log('error saving folder');
			}
			setIsUpdating(false);
		},
		[
			dataJson,
			getFolderData,
			setErrorCode,
			dataSaverTF,
			saveData,
			setIsUpdating,
			state,
			loggedInUser,
			searchText,
			selectedFolderId,
			state,
			isAddingStudies,
		]
	);

	/**
	 * Handle delete folder
	 */
	const handleDeleteFolder = useCallback(
		async folderId => {
			fhirDataLoaderTF.delete(folderId).then(() => {
				dispatch({ type: 'DELETE_FOLDER', payload: { id: folderId } });

				// Remove child folders from dataJson
				const findChildFolders = dataJson.filter(item => item.path?.includes(folderId));
				if (findChildFolders) {
					findChildFolders.forEach(childFolder => {
						dataJson.splice(dataJson.indexOf(childFolder), 1);
					});
				}

				// Remove folder from dataJson
				const findResource = dataJson.find(item => item.id === folderId);
				if (findResource) {
					dataJson.splice(dataJson.indexOf(findResource), 1);
				}
				if (folderId === selectedFolderId) {
					tabInfo.current[folderTab].selectedFolderId = null;
				}
				setDataJson(dataJson);
				setData(createTreeDataMapping(dataJson, loggedInUser?.id, searchText, selectedFolderId, state));
				showToast(t('Folder deleted successfully'));
			});
		},
		[fhirDataLoaderTF, dispatch, dataJson, loggedInUser, searchText, selectedFolderId, state]
	);

	/**
	 * Handle add studies to folder
	 */
	const handleAddStudiesToFolders = useCallback(async () => {
		if (!selectedFolderId) {
			return;
		}
		const tfPayload = getFolderData(selectedFolderId);
		const studiesAddToTF = selectedStudies?.map(study => ({ id: study.id }));

		if (tfPayload) {
			const ids = tfPayload.studies?.map(d => d.id) || [];
			const newStudies = studiesAddToTF?.filter(d => !ids.includes(d.id)) || [];
			tfPayload.studies = [...tfPayload.studies, ...newStudies];
			const res = await saveData(dataSaverTF, { id: selectedFolderId }, tfPayload);
			if (res && res.resourceType === 'TeachingFolder') {
				showToast(t('Studies added to teaching folder successfully'));
			} else {
				showToast(t('Failed to add studies to teaching folder'));
			}

			// Close drawer
			resetStudiesSelection();
			closeDrawer();
		}
	}, [
		selectedFolderId,
		getFolderData,
		selectedStudies,
		saveData,
		dataSaverTF,
		closeDrawer,
		showToast,
		t,
		resetStudiesSelection,
	]);

	const handleRemoveStudies = useCallback(
		async selectedRecords => {
			if (!selectedRecords || !selectedRecords.length) {
				return;
			}
			const studyIds = selectedRecords.map(d => d.id);
			const tfPayload = getFolderData(selectedFolderId);
			if (tfPayload) {
				tfPayload.studies = tfPayload.studies.filter(d => !studyIds.includes(d.id));
				const res = await saveData(dataSaverTF, { id: selectedFolderId }, tfPayload);
				if (res && res.resourceType === 'TeachingFolder') {
					const findResource = dataJson.find(item => item.id === selectedFolderId);
					if (findResource) {
						findResource.studies = res.studies;
					}
					setDataJson(dataJson);
					setData(createTreeDataMapping(dataJson, loggedInUser?.id, searchText, selectedFolderId, state));
					resetStudiesSelection();
					showToast(t('Studies removed from teaching folder successfully'));
				}
			}
		},
		[
			selectedFolderId,
			getFolderData,
			saveData,
			dataSaverTF,
			showToast,
			t,
			dataJson,
			resetStudiesSelection,
			loggedInUser,
		]
	);

	const handleShareFolder = useCallback(
		(updatedAccessControl, operation) => {
			const payLoad = { ...selectedFolderData };

			if (payLoad) {
				payLoad.accessControl = updatedAccessControl;
				payLoad.isUpdateAccess = true;
				saveData(dataSaverTF, { id: payLoad.id }, payLoad).then(res => {
					if (res && res.resourceType === 'TeachingFolder') {
						if (operation === 'remove') {
							showToast(t('Shared access removed.'));
						} else {
							showToast(t('Share successful.'));
						}

						// Update selected folder access control
						selectedFolderData.accessControl = updatedAccessControl;

						// Refetch data
						const params =
							folderTab == folderTabs[0]
								? { owner: loggedInUser?.id, _sort: 'level' }
								: { isShared: true, _owner: loggedInUser?.id, _sort: 'level' };

						fhirDataLoaderTF.load(params, true).then(result => {
							if (result && result.entry) {
								const resources = result.entry.map(item => item.resource);
								setDataJson(resources);
							}
						});
					} else {
						showToast(t('Failed to share folder'));
					}
				});
			}
		},
		[selectedFolderData, saveData, dataSaverTF, showToast, t, folderTab, fhirDataLoaderTF, loggedInUser]
	);

	/**
	 * Search folder debounce
	 */
	const searchFolderDebounce = _.debounce(event => {
		dispatch({ type: 'SEARCH_FOLDER', payload: { searchText: event.target.value } });
		setSearchText(event.target.value);
	}, 300);

	/**
	 * Change tab
	 * @param {*} tab
	 */
	const onChangeTab = tab => {
		if (folderTab === tab) {
			return;
		}
		setDataLoaded(false);
		setFolderTab(tab);
		setData([]);
		setDataJson([]);
		setSelectedFolderId(null);
		setSelectedFolderData(null);
	};

	/**
	 * Handle save name
	 * @param {*} itemId
	 * @param {*} newValue
	 * @returns {void}
	 * */
	const handleSaveName = useCallback(
		(itemId, newValue) => {
			const tfPayload = dataJson.find(item => item.id === itemId);

			if (tfPayload) {
				tfPayload.folderName = newValue;
				saveData(dataSaverTF, { id: itemId }, tfPayload).then(res => {
					if (res && res.resourceType === 'TeachingFolder') {
						showToast(t('Save the name successfully'));
					} else {
						showToast(t('Failed to save the name'));
					}
				});
			}
		},
		[dataJson, saveData, dataSaverTF, showToast, t]
	);

	return (
		<div
			style={{
				height: isAddingStudies ? '100%' : 'calc(100vh - 64px)',
				overflowY: 'hidden',
				display: 'block',
				fontFamily: theme.typography.fontFamily,
			}}
		>
			<Box
				m={3}
				mt={2.25}
				sx={{
					float: 'left',
					width: 'fit-content',
					marginRight: '8px',
				}}
			>
				<Box
					id="teaching-folder-title"
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mb: '22px',
					}}
				>
					<Typography
						sx={{
							color: 'primary.contrastText',
							fontSize: '24px !important',
							fontWeight: '400',
						}}
						variant="h1"
					>
						{t('Teaching Folder')}
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						width: 'auto',
						height: `calc(100vh - ${isAddingStudies ? 75 : 160}px)`,
					}}
				>
					<Box
						sx={{
							background: 'rgb(57, 57, 57)',
							borderRadius: '5px',
							height: '100%',
							width: treeWidth,
						}}
					>
						{/* Group of button */}
						<Box
							sx={{
								padding: '20px 12px 16px 12px',
								display: 'flex',
								gap: '12px',
							}}
						>
							<Button
								sx={{
									width: '50%',
									backgroundColor:
										folderTab == folderTabs[0] ? 'rgba(60, 163, 245, 1)' : 'rgba(65, 65, 65, 1)',
									textTransform: 'none',
									borderRadius: '20px',
									color: 'rgba(255, 255, 255, 0.87)',
									'&:hover': {
										backgroundColor: 'rgba(60, 163, 245, 1)',
									},
								}}
								type="button"
								onClick={() => onChangeTab(folderTabs[0])}
							>
								{t('My folders')}
							</Button>
							<Button
								sx={{
									width: '50%',
									textTransform: 'none',
									backgroundColor:
										folderTab == folderTabs[1] ? 'rgba(60, 163, 245, 1)' : 'rgba(65, 65, 65, 1)',
									borderRadius: '20px',
									color: 'rgba(255, 255, 255, 0.87)',
									'&:hover': {
										backgroundColor: 'rgba(60, 163, 245, 1)',
									},
								}}
								type="button"
								onClick={() => onChangeTab(folderTabs[1])}
							>
								{t('Shared with me')}
							</Button>
						</Box>

						{/* text box search start with search icon */}
						<Box
							sx={{
								display: 'flex',
								backgroundColor: 'rgba(0, 0, 0, 0.12)',
							}}
						>
							<TextField
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position="end">
											{folderTab == folderTabs[0] && !searchText && (
												<Tooltip title={t('Add Folder')}>
													<AddOutlinedIcon
														data-cy="add-folder-button"
														sx={{
															color: 'rgba(255, 255, 255, 0.6)',
															cursor: 'pointer',
															':hover': {
																color: 'rgba(255, 255, 255, 1)',
															},
														}}
														onClick={handleAddFolder}
													/>
												</Tooltip>
											)}
											{searchText && (
												<Tooltip title={t('Clear Search')}>
													<CloseIcon
														sx={{
															color: 'rgba(255, 255, 255, 0.6)',
															cursor: 'pointer',
															':hover': {
																color: 'rgba(255, 255, 255, 1)',
															},
														}}
														onClick={handleClearSearchText}
													/>
												</Tooltip>
											)}
										</InputAdornment>
									),
								}}
								autoComplete="off"
								data-testid="search-folder-input"
								placeholder={t('Search')}
								sx={{
									width: '100%',
									color: 'rgba(255, 255, 255, 0.87)',
									'& fieldset': {
										border: 'none',
									},
									'& .MuiOutlinedInput-root': {
										height: '36px',
										fontSize: '0.875rem',
										fontWeight: '400',
									},
								}}
								value={searchTextDisplay}
								onChange={e => setSearchTextDisplay(e.target.value)}
								onKeyDown={searchFolderDebounce}
							/>
						</Box>

						{/* Tree component */}
						<TreeView
							data={data}
							handleDeleteFolder={handleDeleteFolder}
							handleEditName={handleSaveName}
							handleSubmit={handleFolderSubmit}
							sx={{
								overflowY: 'auto',
								overflowX: 'hidden',
								maxHeight: `calc(100% - ${isAddingStudies ? 175 : 109}px)`,
							}}
						/>

						{/* Group of button add studies */}
						{isAddingStudies && (
							<Box
								sx={{
									flexDirection: 'row',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '20px 12px 16px 12px',
									gap: '12px',
								}}
							>
								<SecondaryButton
									label={t('Cancel')}
									testId="cancel-add-studies-to-folder"
									onClick={closeDrawer}
								/>
								<Button
									color="rsPrimary"
									data-testid="add-studies-to-folder"
									disabled={!selectedFolderId}
									placeholder={t('Search')}
									sx={{
										minWidth: '84px!important',
										color: '#FFFFFF',
										backgroundColor: '#42A5F5',
									}}
									type="button"
									variant="contained"
									onClick={handleAddStudiesToFolders}
								>
									{t('Save')}
								</Button>
							</Box>
						)}
					</Box>
					<WidthResizeTool
						isRightSide
						cursorAdjustment={sideBarIsOpen ? -304 : -84}
						maxWidth={900}
						minWidth={300}
						setWidth={setTreeWidth}
						sx={{ marginLeft: '4px', zIndex: 4 }}
					/>
				</Box>
			</Box>
			{/* Studies list component */}
			{!isAddingStudies && (
				<Box
					sx={{
						float: 'left',
						width: `calc(100% - ${treeWidth + 42}px)`,
						position: 'relative',
					}}
				>
					<TeachingFolderContainer
						className="worklist-table"
						columns={columns}
						filters={filters}
						folderId={selectedFolderId}
						name="teaching-folder"
						readyForData={dataLoaded}
						removeStudies={handleRemoveStudies}
						setColumns={setColumns}
						setFilters={setFilters}
						setReadyForData={setDataLoaded}
						setSort={setSort}
						sort={sort}
						studies={selectedFolderStudies}
					>
						{children}
					</TeachingFolderContainer>
				</Box>
			)}
			{!isAddingStudies && selectedFolderData && openShareDrawer && (
				<ShareDrawer
					key={selectedFolderData.id}
					accessData={selectedFolderData?.accessControl}
					drawerOpen={openShareDrawer}
					isReadOnly={!userCanEdit}
					itemName={selectedFolderData?.folderName}
					owner={selectedFolderData?.owner}
					resourceName="folder"
					setDrawerOpen={setOpenShareDrawer}
					onChangeAccessData={handleShareFolder}
					onClose={() => setOpenShareDrawer(false)}
					onSave={() => setOpenShareDrawer(false)}
				/>
			)}
		</div>
	);
};

// TeachingFolderView PropTypes
TeachingFolderView.propTypes = {
	children: PropTypes.node,
	isAddingStudies: PropTypes.bool,
	closeDrawer: PropTypes.func,
};

// TeachingFolderView default props
TeachingFolderView.defaultProps = {
	children: null,
	isAddingStudies: false,
	closeDrawer: () => {},
};
export default TeachingFolderView;
