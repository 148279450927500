import { useModal } from '@rs-core/hooks/useModal';
import React, { useEffect, useMemo } from 'react';
import PrivacyModal from '../../components/PrivacyPolicy/PrivacyPolicyModal';
import TermsModal from '../../components/PrivacyPolicy/TermsModal';
import TermsModalBlume from '../../components/PrivacyPolicy/TermsModalBlume';
import RegionModal from '../../components/RegionSelect/RegionModal';
import LanguageModal from '../../components/LanguageSelect/LanguageModal';
import NewsModal from '../../components/News/NewsModal';
import { useAppModeContext } from '@rs-core/context/AppModeContext';

const UnauthenticatedView = ({ page }) => {
	const { closeModal } = useModal();
	const { isPatientPortalMode } = useAppModeContext();

	const Component = useMemo(() => {
		switch (page.page) {
			case 'terms':
				if (isPatientPortalMode) {
					return TermsModalBlume;
				}
				return TermsModal;

			case 'privacy':
				return PrivacyModal;
			case 'region':
				return RegionModal;
			case 'language':
				return LanguageModal;
			case 'news':
				return NewsModal;
			default:
				return null;
		}
	}, [page]);

	//make background transparent for terms of service and privacy policy
	useEffect(() => {
		const element = document.getElementsByTagName('body')[0];

		element.style.backgroundColor = 'transparent';
	}, []);

	return <>{Component ? <Component open onClose={closeModal} /> : <h2>Given Page is not Available!</h2>}</>;
};

export default UnauthenticatedView;
