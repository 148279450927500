// @flow

// core
import React, { Node } from 'react';

// components
import { ObservationPill } from '@worklist-2/ui/src/views/PatientInformationView/components/ObservationPill';
import { Loader } from '@rs-ui/components/Loader/Loader';
import PregnancyIcon from '@worklist-2/ui/src/assets/icons/pregnancy.svg';
// libs
import { Box } from '@mui/material';
import VapingRoomsIcon from '@mui/icons-material/VapingRooms';
import LocalBarOutlinedIcon from '@mui/icons-material/LocalBarOutlined';
import { useTranslation } from 'react-i18next';

// types
import { ISmokingAlcoholObservationsProps } from '@rs-ui/views/PatientInformationView/types/types';

export const SmokingAlcoholObservations = ({
	alcoholStatus,
	isLoading,
	smokingStatus,
	pregnancyStatus,
}: ISmokingAlcoholObservationsProps): Node => {
	const { t } = useTranslation('patientInfo');

	return (
		<Loader.SmokingAlcoholObservations isLoading={isLoading}>
			<Box data-testid="smoking-observations" sx={SX.observationsWrapper}>
				{smokingStatus ? (
					<ObservationPill
						icon={VapingRoomsIcon}
						title={t(smokingStatus)}
						toolTipTitle={t('Smoking Status')}
					/>
				) : null}
				{alcoholStatus ? (
					<ObservationPill
						icon={LocalBarOutlinedIcon}
						title={t(alcoholStatus)}
						toolTipTitle={t('Alcohol Use')}
					/>
				) : null}
				{pregnancyStatus ? (
					<ObservationPill
						icon={PregnancyIcon}
						title={t(pregnancyStatus)}
						toolTipTitle={t('Pregnancy Status')}
					/>
				) : null}
			</Box>
		</Loader.SmokingAlcoholObservations>
	);
};

const SX = {
	observationsWrapper: {
		display: 'flex',
		columnGap: '8px',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'center',
	},
};
