import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';

import FilterCapsuleMenuItems from './FilterCapsuleMenuItems';
import SuggestMultipleValueInfiniteScroll from '@worklist-2/ui/src/components/SuggestMultipleValueInfiniteScroll';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';

const FilterCapsuleMenu = ({
	menuItems,
	selectedItems,
	menuWidth,
	anchorEl,
	filterType,
	open,
	onClose,
	onMenuChecked,
	isStack,
	openMenu,
	searchValueSet,
	columnMapping,
	label,
	displayVal,
	openMenuOnload,
	t,
}) => {
	// Stop default event and enable textfield to accept character.
	const onKeyDownHandle = event => {
		event.stopPropagation();
	};
	const [searchValue, setSearchValue] = useState('');
	const [filteredMenuItems, setFilteredMenuItems] = useState(menuItems);
	const onChangeHandle = event => {
		setSearchValue(event.target.value);
	};

	const codeLoader = useFhirDataLoader({ scope: columnMapping?.searchComponentConfig?.scope });

	// Maintain the values stored in menu checkboxes throughout the rendering process
	// based on search too
	useEffect(() => {
		if (searchValue.length > 0) {
			const tempMenuItems = _.filter(menuItems, item => item.toUpperCase().includes(searchValue.toUpperCase()));
			setFilteredMenuItems(tempMenuItems);
		} else {
			setFilteredMenuItems(menuItems);
		}
	}, [searchValue]);

	return (
		<div>
			{isStack ? (
				<Collapse unmountOnExit in={openMenu} timeout="auto">
					<Stack
						data-testid="filterCapsuleMenuStack"
						direction={{ xs: 'column', sm: 'column' }}
						spacing={{ xs: 0, sm: 0, md: 0 }}
						sx={{
							backgroundColor: '#343739',
							maxHeight: 300,
							overflow: 'auto',
						}}
						width={menuWidth}
					>
						<FilterCapsuleMenuItems
							key="menuItemsStack"
							filterType={filterType}
							filteredMenuItems={filteredMenuItems}
							searchValueSet={searchValueSet}
							selectedItems={selectedItems}
							t={t}
							onChange={onChangeHandle}
							onKeyDown={onKeyDownHandle}
							onMenuChecked={onMenuChecked}
						/>
					</Stack>
				</Collapse>
			) : filterType === 'infinite-scroll-multi-select-suggest' ? (
				<Backdrop open={open} sx={{ zIndex: 99, background: 'transparent' }}>
					<Box
						sx={{
							width: menuWidth,
							zIndex: 100,
							position: 'absolute',
							backgroundColor: '#343739',
							left: anchorEl?.getBoundingClientRect().left,
							top: anchorEl?.getBoundingClientRect().bottom,
						}}
					>
						<SuggestMultipleValueInfiniteScroll
							disableClearable
							fullMenuWidth
							compareOption={columnMapping?.searchComponentConfig?.compareOptionFunc}
							countPerPage={columnMapping?.searchComponentConfig?.countPerPage || 20}
							dataLoader={codeLoader}
							label={label}
							limitTag={0}
							openMenuOnload={openMenuOnload}
							placeHolderText={t('Search')}
							renderOptionlist={columnMapping?.searchComponentConfig?.renderOptionFunc}
							renderTag={columnMapping?.searchComponentConfig?.renderTagFunc}
							renderTags={false}
							searchExistingOptions={columnMapping?.searchComponentConfig?.searchExistingOptions}
							searchParams={{
								...columnMapping?.searchComponentConfig?.searchParameters,
							}}
							selectedValue={displayVal?.length > 0 ? displayVal?.map(e => ({ tagDisplay: e })) : []}
							showLabel={false}
							size="small"
							startAdornment={<></>}
							width="100%"
							onClose={onClose}
							onSelectItem={onMenuChecked}
						/>
					</Box>
				</Backdrop>
			) : (
				<Menu
					PaperProps={{
						style: {
							width: menuWidth,
							backgroundColor: '#343739',
							borderRadius: '0 0 16px 16px',
							borderTop: 'none',
							marginTop: 0,
							maxHeight: 300,
							overflow: 'auto',
						},
					}}
					TransitionComponent={Collapse}
					anchorEl={anchorEl}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					data-testid="filterCapsuleMenuMenu"
					elevation={0}
					open={open}
					sx={{
						position: 'absolute',
					}}
					onClose={onClose}
				>
					<FilterCapsuleMenuItems
						key="menuItems"
						filterType={filterType}
						filteredMenuItems={filteredMenuItems}
						searchValueSet={searchValueSet}
						selectedItems={selectedItems}
						t={t}
						onChange={onChangeHandle}
						onKeyDown={onKeyDownHandle}
						onMenuChecked={onMenuChecked}
					/>
				</Menu>
			)}
		</div>
	);
};
FilterCapsuleMenu.propTypes = {
	/**
	 * List of unique items of a table column, e.g for Modality, menuItems=['CT', 'CR]
	 */
	menuItems: PropTypes.array,

	/**
	 * An object which contains a mapping of the menu item and whether or not it is checked
	 * ie: [{ 'CT', true}, { 'CR', false }]
	 */
	selectedItems: PropTypes.object,

	/**
	 * The component where the menu will be anchored to.
	 */
	anchorEl: PropTypes.object,

	/**
	 * Filter type
	 */
	filterType: PropTypes.string,

	/**
	 * Whether or not the menu is open
	 */
	open: PropTypes.bool,

	/**
	 * Callback function when the menu closes.
	 */
	onClose: PropTypes.func,

	/**
	 * Callback function to handle onClick event on MenuItem.
	 */
	onMenuChecked: PropTypes.func,

	/**
	 * If true, then Menu is replaced by Stack and the dropdown is child component of ShowMore component.
	 */
	isStack: PropTypes.bool,

	/**
	 * If true, then Menu is opened , else closed.
	 */
	openMenu: PropTypes.bool,
	/**
	 * Value set name
	 */
	searchValueSet: PropTypes.string,
	/**
	 * Translation function
	 */
	t: PropTypes.func,
};
export default FilterCapsuleMenu;
