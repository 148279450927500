// core
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { getDateHintText } from '@rs-ui/components/utils/dateUtils';

// MUI
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

// Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { SKIP_TRANSLATION_LIST } from '@rs-ui/helpers/constants';
import FilterCapsuleMenu from './FilterCapsuleMenu';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import moment from 'moment';

// Components
import AdvanceFilter from '@rs-ui/components/AdvanceFilter/AdvanceFilter';
import { getDefaultOperator } from '@worklist-2/ui/src/components/utils/gridUtils';
import { worklistFilterOptions } from '@worklist-2/ui/src/views/utils/worklistFilterOptions';

const FilterCapsule = ({
	label,
	columnLabel,
	columnIdentity,
	menuItems,
	capsuleMenuItems,
	values,
	displayValue,
	size,
	capsuleMaxWidth,
	showMore,
	onRemoveCapsule,
	onCapsuleFilter,
	searchParameter,
	toolTip,
	searchValueSet,
	filterType,
	columnMapping,
	isActive,
	onActiveCapsule,
	itemList,
}) => {
	const displayVal = displayValue ?? values;
	const elementRef = useRef();
	const [currentLabels, setCurrentLabels] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuWidth, setMenuWidth] = useState(150);
	const [openMenu, setOpenMenu] = useState(false);
	const [capsuleLabel, setCapsuleLabel] = useState(label);
	const [chipLabelMap, setChipLabelMap] = useState({});
	const [selectedOperator, setSelectedOperator] = useState(getDefaultOperator(columnMapping));
	const selectedValues = useRef(values);
	const [inputValues, setInputValues] = useState([]);
	const newDisplayValue = useRef(displayVal);
	const isFirstRender = useRef(true);
	const { t } = useTranslation(['datetimerangepicker', 'codePool', 'workList']);
	const proactEnableCustomStudyPriority = useBooleanFlagValue('proact-enable-custom-study-priority');
	const mavenWorklistAdvanceFilter = useBooleanFlagValue('maven-worklist-advance-filter');
	const isWorklistColumn = mavenWorklistAdvanceFilter && columnMapping?.type;
	if (proactEnableCustomStudyPriority) {
		SKIP_TRANSLATION_LIST.push('priority');
	}

	const composedCapsuleLabel = useMemo(() => {
		// For date-range columns, displayVal is an array and array elements are date string values
		const isDateValue =
			_.isArray(displayVal) &&
			(Number.isNaN(Number(displayVal[0])) || displayVal[0] instanceof Date) &&
			new Date(displayVal[0])?.toString() !== 'Invalid Date';
		// Skip translation for text-search columns (displayVal is not an array), date-range columns and columns in SKIP_TRANSLATION_LIST
		// Translate multi-select columns (displayVal is an array)
		let tempValues =
			columnMapping?.isTranslatable === false ||
			SKIP_TRANSLATION_LIST.includes(searchValueSet) ||
			!_.isArray(displayVal) ||
			isDateValue ||
			(isWorklistColumn && menuItems?.length === 0)
				? displayVal
				: displayVal.map(value => t(`codePool:${searchValueSet}.${value}`));

		const separator = isDateValue ? ' - ' : ' | ';
		if (isDateValue && displayVal[0] instanceof Date) {
			// Handle format if item is date object
			tempValues = getDateHintText(t, tempValues);
		}

		if (isWorklistColumn && columnMapping.type === 'number') {
			const shortOperator = worklistFilterOptions.find(option => option.name === selectedOperator)?.shortName;
			return tempValues?.length > 0
				? `${columnLabel}: ${shortOperator} ${_.isArray(tempValues) ? tempValues.join(separator) : tempValues}`
				: columnLabel;
		}
		return tempValues?.length > 0
			? `${columnLabel}: ${_.isArray(tempValues) ? tempValues.join(separator) : tempValues}`
			: columnLabel;
	}, [columnLabel, displayVal, capsuleLabel, t, selectedOperator]);

	const selectedMenuItems = {};
	if (displayVal?.length > 0) {
		_.map(displayVal, item => {
			const index = _.indexOf(_.flatten([displayVal]), item);
			if (index > -1) {
				selectedMenuItems[item] = true;
			} else {
				selectedMenuItems[item] = false;
			}
		});
	}

	useEffect(() => {
		onActiveCapsule && onActiveCapsule(null);
		isFirstRender.current = true;
	}, []);

	useEffect(() => {
		setCapsuleLabel(composedCapsuleLabel);
		setChipLabelMap(selectedMenuItems);
		setCurrentLabels(displayVal);
	}, [columnLabel, displayVal, composedCapsuleLabel]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return; // Skip the first render
		}
		if (inputValues?.length > 0) {
			onCapsuleFilter(
				columnIdentity,
				selectedValues.current,
				newDisplayValue.current,
				capsuleMenuItems,
				null,
				selectedOperator
			);
		}
	}, [selectedOperator]);

	useEffect(() => {
		selectedValues.current = values;
	}, [values]);

	useEffect(() => {
		newDisplayValue.current = displayVal;
	}, [displayVal]);

	// Onclick , show the menu
	const handleMenuDropDownClick = event => {
		if (openMenu) {
			setOpenMenu(false);
			setAnchorEl(null);
			return;
		}
		setOpenMenu(true);
		setMenuWidth(elementRef.current ? elementRef.current.offsetWidth : 200);
		setAnchorEl(event && event.currentTarget ? event.currentTarget : null);
	};

	// if clicked somewhere, close the menu
	const handleClose = () => {
		setAnchorEl(null);
		setOpenMenu(false);
	};

	const handleDelete = () => {
		if (onRemoveCapsule) {
			onRemoveCapsule(columnIdentity);
		}
	};

	const filterRecords = (chipMap, shouldFilterTable, newDisplayVal) => {
		const selectedRows = [];
		for (const item in chipMap) {
			if (chipMap[item]) {
				selectedRows.push(item);
			}
		}
		selectedValues.current = selectedRows;
		newDisplayValue.current = newDisplayVal || selectedRows;
		// Updated table rows
		if (onCapsuleFilter && shouldFilterTable) {
			onCapsuleFilter(
				columnIdentity,
				selectedRows,
				newDisplayValue.current,
				capsuleMenuItems,
				null,
				selectedOperator
			);
		}
	};

	const handleSingleSelect = itemName => {
		const newChipLabelMap = { [itemName]: true };
		setChipLabelMap(newChipLabelMap);
		const newCurrentLabels = [itemName];
		setCurrentLabels(newCurrentLabels);
		const newCapsuleLabel = `${columnLabel}: ${
			SKIP_TRANSLATION_LIST.includes(searchValueSet) ? itemName : t(`codePool:${searchValueSet}.${itemName}`)
		}`;
		setCapsuleLabel(newCapsuleLabel);
		filterRecords(newChipLabelMap, true);
		setAnchorEl(null);
		setOpenMenu(false);
	};

	const handleMultiSelectInifiniteScroll = item => {
		const selectedOptions = item?.selectedOptions || [];
		setCurrentLabels(selectedOptions);

		const newChipLableMap = {};

		selectedOptions.map(option => {
			newChipLableMap[option] = true;
		});

		setChipLabelMap(newChipLableMap);

		setCapsuleLabel(
			selectedOptions?.length > 0
				? `${columnLabel}: ${
						SKIP_TRANSLATION_LIST.includes(searchValueSet)
							? selectedOptions.join(' | ')
							: selectedOptions.map(value => t(`codePool:${searchValueSet}.${value}`)).join(' | ')
				  }`
				: columnLabel
		);

		if (onRemoveCapsule && selectedOptions?.length <= 0) {
			onRemoveCapsule(columnIdentity);
		}
		filterRecords(newChipLableMap, true);
	};

	const handleMenuCheckBox = (itemName, event, index) => {
		const checkboxItem = document.getElementById(`checkbox_${index}`);
		let checkBoxValue = !checkboxItem.checked;
		if (event.target === checkboxItem) {
			checkBoxValue = event.target.checked;
		}
		chipLabelMap[itemName] = checkBoxValue;
		setChipLabelMap(chipLabelMap);
		let chipLabelList = currentLabels;
		if (checkBoxValue && !_.find(chipLabelList, item => item === itemName)) {
			chipLabelList.push(itemName);
		} else {
			chipLabelList = _.filter(chipLabelList, item => item != itemName);
		}
		setCurrentLabels(chipLabelList);
		setCapsuleLabel(
			chipLabelList?.length > 0
				? `${columnLabel}: ${
						SKIP_TRANSLATION_LIST.includes(searchValueSet)
							? chipLabelList.join(' | ')
							: chipLabelList.map(value => t(`codePool:${searchValueSet}.${value}`)).join(' | ')
				  }`
				: columnLabel
		);
		if (onRemoveCapsule && chipLabelList?.length <= 0) {
			onRemoveCapsule(columnIdentity);
		}
		filterRecords(chipLabelMap, true);
	};

	const handleSelectFn = (itemName, event, index) => {
		switch (filterType) {
			case 'checkbox-single-select':
			case 'multi-select':
			case 'checkbox-multi-select':
				handleMenuCheckBox(itemName, event, index);
				break;
			case 'single-select':
				handleSingleSelect(itemName);
				break;
			case 'infinite-scroll-multi-select-suggest':
				handleMultiSelectInifiniteScroll(itemName);
				break;
			default:
				break;
		}
	};

	/**
	 * Handle input changed for advance filter
	 * string / number
	 * @param {*} items
	 */
	const handleInputChangedAdvanceFilter = items => {
		setCurrentLabels(items);

		const newChipLableMap = {};

		items.map(option => {
			newChipLableMap[option] = true;
		});

		if (onRemoveCapsule && items?.length <= 0) {
			onRemoveCapsule(columnIdentity);
		}
		filterRecords(newChipLableMap, true);
	};

	/**
	 * Handle menu check box for advance filter
	 * @param {*} item
	 */
	const handleMenuCheckBoxAdvanceFilter = item => {
		const selectedOptions = item?.selectedOptions || [];
		setCurrentLabels(selectedOptions);

		const newValuesSelected = {};

		selectedOptions.map(option => {
			newValuesSelected[option] = true;
		});

		setCapsuleLabel(
			selectedOptions?.length > 0
				? `${columnLabel}: ${
						SKIP_TRANSLATION_LIST.includes(searchValueSet)
							? selectedOptions.join(' | ')
							: selectedOptions.map(value => t(`codePool:${searchValueSet}.${value}`)).join(' | ')
				  }`
				: columnLabel
		);

		if (onRemoveCapsule && selectedOptions?.length <= 0) {
			onRemoveCapsule(columnIdentity);
		}
		filterRecords(newValuesSelected, true);
	};

	/**
	 * Handle single selection advance filter
	 * @param {*} item
	 * @param {*} selectedLabel
	 */
	const handleSingleSelectionAdvanceFilter = (item, selectedLabel) => {
		if (!item) {
			return;
		}
		setCurrentLabels(selectedLabel);

		const newValueSelected = {};
		newValueSelected[item] = true;

		setCapsuleLabel(selectedLabel?.length > 0 ? `${columnLabel}: ${selectedLabel}` : columnLabel);

		if (onRemoveCapsule && selectedLabel?.length <= 0) {
			onRemoveCapsule(columnIdentity);
		}
		filterRecords(newValueSelected, true, selectedLabel);
	};

	/**
	 * Handle date range advance filter
	 * @param {*} items
	 */
	const handleDateRangeAdvanceFilter = items => {
		setCurrentLabels(items);
		if (onRemoveCapsule && items?.length <= 0) {
			onRemoveCapsule(columnIdentity);
		}
		selectedValues.current = items;
		newDisplayValue.current = items;
		onCapsuleFilter(columnIdentity, items, items, capsuleMenuItems, null, selectedOperator);
	};

	/**
	 * Handle date-time advance filter
	 * @param {*} item
	 */
	const handleDateTimeAdvanceFilter = item => {
		if (onRemoveCapsule && !item) {
			onRemoveCapsule(columnIdentity);
		}
		const dateString = moment(item).format('YYYY-MM-DD');
		setCurrentLabels(dateString);
		selectedValues.current = item;
		newDisplayValue.current = dateString;
		onCapsuleFilter(columnIdentity, item, dateString, capsuleMenuItems, null, selectedOperator);
	};

	/**
	 * Handle advance filter select function
	 * @param {*} item
	 * @param {*} itemValue
	 */
	const handleAdvanceFilterSelectFn = (item, itemValue) => {
		switch (filterType) {
			case 'multi-select':
			case 'checkbox-multi-select':
				handleMenuCheckBoxAdvanceFilter(item);
				break;
			case 'infinite-scroll-multi-select-suggest':
				handleMultiSelectInifiniteScroll(item);
				break;
			case 'date-time':
				handleDateTimeAdvanceFilter(item);
				break;
			case 'date-range':
				handleDateRangeAdvanceFilter(item);
				break;
			case 'text-search':
				handleInputChangedAdvanceFilter(item);
				break;
			default:
				handleSingleSelectionAdvanceFilter(item, itemValue);
				break;
		}
	};

	useEffect(() => {
		setMenuWidth(elementRef.current ? elementRef.current.offsetWidth : 200);
	}, [capsuleLabel, size]);

	const chipBackgroundColor = '#293138';
	const chipBackgroundOpenColor = '#343739';

	return (
		<Tooltip
			arrow
			disableFocusListener
			disableInteractive
			disableTouchListener
			placement="top-end"
			title={toolTip && capsuleLabel ? capsuleLabel : ''}
		>
			<Box
				sx={{
					'&:hover': {
						...showMore?.avatarHover,
					},
				}}
			>
				<Chip
					ref={elementRef}
					data-cy={typeof capsuleLabel === 'string' ? `${capsuleLabel.split(':')[0]}_capsule_filter` : ''}
					deleteIcon={
						<CancelIcon
							data-cy={
								typeof capsuleLabel === 'string' ? `${capsuleLabel.split(':')[0]}_capsule-delete` : ''
							}
						/>
					}
					icon={
						menuItems?.length > 0 && !isWorklistColumn ? (
							<ArrowDropDownIcon onClick={handleMenuDropDownClick} />
						) : null
					}
					label={
						<>
							<Typography
								sx={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									fontSize: '12px',
								}}
							>
								{capsuleLabel}
							</Typography>
							{searchParameter === 'internalParentOrganizationID' &&
								columnIdentity === 'managingOrganization' && (
									<AccountTreeOutlinedIcon sx={{ ml: '4px' }} />
								)}
						</>
					}
					size={size}
					sx={{
						maxWidth: showMore?.capsuleMaxWidth || capsuleMaxWidth,
						color: 'rsPrimary.main',
						'& .MuiChip-label': {
							...showMore?.labelProps,
							width: showMore?.capsuleMaxWidth - 100,
							display: 'flex',
							alignItems: 'center',
						},
						width: showMore?.capsuleWidth,
						...(openMenu
							? {
									borderRadius: showMore?.borderRadius || '16px 16px 0px 0px',
									backgroundColor: chipBackgroundOpenColor,
									'&:hover': {
										backgroundColor: chipBackgroundOpenColor,
									},
							  }
							: {
									borderRadius: showMore?.borderRadius || '16px 16px 16px 16px',
									backgroundColor: showMore?.backgroundColor || chipBackgroundColor,
									'&:hover': {
										backgroundColor: chipBackgroundColor,
									},
							  }),
						'& .MuiChip-icon': {
							order: 1,
							paddingRight: '14px',
							color: 'rsPrimary.main',
							opacity: '0.60',
							border: showMore?.deleteIconBorder,
							...showMore?.dropDownIconProps,
							'&:hover': {
								color: 'rsPrimary.hover',
							},
							visibility: showMore?.avatarVisibility || 'visible',
						},
						'& .MuiChip-deleteIcon': {
							order: 3,
							color: 'rsPrimary.main',
							opacity: '0.60',
							'&:hover': {
								color: 'rsPrimary.hover',
							},
							...showMore?.deleteIconProps,
							visibility: showMore?.avatarVisibility || 'visible',
						},
					}}
					onClick={event => {
						if (isWorklistColumn) {
							onActiveCapsule && onActiveCapsule(columnLabel);
						} else if (menuItems?.length > 0) {
							handleMenuDropDownClick(event);
						}
					}}
					onDelete={columnIdentity !== 'faxDirection' ? handleDelete : null}
				/>
				{isWorklistColumn && isActive ? (
					<Box
						sx={{
							backgroundColor: '#343739',
							borderRadius: '16px 16px 16px 16px',
							marginTop: '-50px',
							marginLeft: '-100px',
							marginBottom: '10px',
							position: showMore?.isStack ? 'fixed' : 'absolute',
							zIndex: 90,
							width: '420px',
							padding: '10px 15px 15px 15px',
						}}
					>
						<AdvanceFilter
							chipLabelMap={chipLabelMap}
							columnIdentity={columnIdentity}
							columnMapping={columnMapping}
							displayVal={displayVal}
							filterType={filterType}
							handleClose={currentValues => {
								onActiveCapsule && onActiveCapsule(null);
								if (currentValues?.length === 0 && onRemoveCapsule) {
									onRemoveCapsule(columnIdentity);
								}
							}}
							handleSelectFn={handleAdvanceFilterSelectFn}
							inputValues={inputValues}
							itemList={itemList}
							menuItems={menuItems}
							menuWidth={menuWidth}
							searchValueSet={searchValueSet}
							selectedOperator={selectedOperator}
							setInputValues={setInputValues}
							setSelectedOperator={setSelectedOperator}
							t={t}
						/>
					</Box>
				) : (
					menuItems?.length > 0 && (
						<Box sx={{ visibility: openMenu ? 'visible' : 'hidden' }}>
							<FilterCapsuleMenu
								anchorEl={anchorEl}
								avatarHover={showMore?.avatarHover}
								columnMapping={columnMapping}
								displayVal={displayVal}
								filterType={filterType}
								isStack={showMore?.isStack}
								label={columnIdentity}
								menuItems={menuItems}
								menuWidth={menuWidth}
								open={Boolean(anchorEl)}
								openMenu={openMenu}
								openMenuOnload={openMenu}
								searchValueSet={searchValueSet}
								selectedItems={chipLabelMap}
								t={t}
								onClose={handleClose}
								onMenuChecked={handleSelectFn}
							/>
						</Box>
					)
				)}
				{openMenu && menuItems?.length > 0 && showMore?.isStack && (
					<Divider
						sx={{
							width: showMore?.capsuleWidth - 20,
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
					/>
				)}
			</Box>
		</Tooltip>
	);
};

FilterCapsule.propTypes = {
	/**
	 * Chip label, indicates the column name, e.g Modality
	 */
	columnLabel: PropTypes.string,

	/**
	 * Identity of the capsule, unique for each capsule, e.g modality, managingOrg
	 */
	columnIdentity: PropTypes.string.isRequired,

	/**
	 * List of unique items, e.g for Modality, menuItems=['CT', 'CR]
	 */
	menuItems: PropTypes.array,

	/**
	 * An array of values
	 */
	values: PropTypes.array,

	/**
	 * An array of display values
	 */
	displayValue: PropTypes.array,

	/**
	 * Size of capsule
	 */
	size: PropTypes.oneOf(['small', 'medium']),

	/**
	 * The maximum width of the capsule in pixels. As items are selected, the capsule width will grow
	 * up to this limit.
	 */
	capsuleMaxWidth: PropTypes.number,

	/**
	 * This is a attribute of the filter capsule if the capsule is child component of ShowMore component.
	 */
	showMore: PropTypes.object,

	/**
	 * Callback function to filter table rows.
	 * It accepts columnIdentity and list of items of table column from Menu.
	 * Based on those list of items, table is filtered.
	 */
	onCapsuleFilter: PropTypes.func,

	/**
	 * Callback function to remove capsule from WorklistGrid
	 * It accepts columnIdentity and list of items table column from Menu(Optional)
	 */
	onRemoveCapsule: PropTypes.func,

	/**
	 * A search param string
	 */
	searchParameter: PropTypes.string,

	/**
	 * This is a attribute to display toolTip for capsure panel
	 */
	toolTip: PropTypes.bool,

	/**
	 * Value set name
	 */
	searchValueSet: PropTypes.string,

	/**
	 * Filter type
	 */
	filterType: PropTypes.string,

	/**
	 * This is a attribute to display toolTip for capsure panel
	 */
	isActive: PropTypes.bool,

	/**
	 * Callback function to set active capsule
	 */
	onActiveCapsule: PropTypes.func,

	/**
	 * List of items
	 */
	itemList: PropTypes.array,
};

FilterCapsule.defaultProps = {
	menuItems: [],
	size: 'medium',
	capsuleMaxWidth: 300,
	isActive: false,
	onActiveCapsule: () => {},
	itemList: [],
};

export default FilterCapsule;
