// @flow

import { cloneDeep, trimEnd } from 'lodash';
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import generateRandomString from '@rs-core/utils/generateRandomString';
import findExtensionIndex from '@rs-core/fhir/resource/columnMapping/utils/findExtensionIndex';
import {
	PATIENT_SSN_CODE,
	PATIENT_ID_CODE,
	PATIENT_DRIVER_LICENCE_CODE,
	FILE_ICON,
	patientConfidentialitySystem,
} from '@rs-ui/views/PatientInformationView/utils/constants';
import { type IPatientResource } from '../types/types';
import { vitalObservationInfo } from '../mocks/observations';
import { transformPatientNotes } from '@rs-ui/components/Drawers/OrderDrawer/utils/helperMethods';

const formatName = nameObj => {
	// Format: lastName^firstName^middleName
	const givenNames = nameObj?.given ? nameObj.given.join('^') : '';
	const text = `${nameObj.family || ''}^${givenNames}`.trim();
	return trimEnd(text, '^ ');
};

class Patient {
	constructor(patient: IPatientResource) {
		this.patient = patient;
		this.patientPayload = cloneDeep(patient);
	}

	patient = {};
	patientPayload = {};

	vitalObservationInfo = vitalObservationInfo;

	parseDocument = doc => ({
		id: doc.id,
		fileName: doc.content[0].attachment.title,
		description: doc.description?.replaceAll('<br>', ' ').replaceAll('?', ''),
		extension: FILE_ICON[doc?.content?.[0]?.attachment?.contentType || 'application/pdf'],
	});

	// #region =============   Getter Functions   ============
	getPatientFamilyName = () => this?.patient?.name?.[0]?.family;

	getPatientGivenNames = () => this?.patient?.name?.[0]?.given?.join(' ');

	getPatientNamePrefix = () => this?.patient?.name?.[0]?.prefix?.[0];

	getPatientNameSuffix = () => this?.patient?.name?.[0]?.suffix?.[0];

	getPatientBirthDate = () => this.patient?.birthDate;

	getPatientDeceasedDateTime = () => this.patient?.deceasedDateTime;

	getPatientMaritalStatus = () => this?.patient?.maritalStatus?.coding[0];

	getPatientGender = () => this.patient?.gender;

	getPatientManagingOrganization = () => {
		return this.patient?.managingOrganization;
	};

	getPatientSSN = () => {
		const ssn = this.patient.identifier?.find(item => item.type?.coding?.[0].code === PATIENT_SSN_CODE)?.value;
		if (ssn && /^\d{9}$/.test(ssn)) {
			return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
		}
		return ssn;
	};

	getPatientIdentifier = () => this.patient.identifier?.find(item => item.type?.coding?.[0].code === 'MR')?.value;

	getPatientDriverLicense = () => this.patient.identifier?.find(item => item.type?.coding?.[0].code === 'DL')?.value;

	getPatientConfidentiality = () =>
		this.patient?.meta?.security?.find(item => item.system === patientConfidentialitySystem);

	getPatientLanguage = () =>
		this.patient?.communication?.find(item => item.hasOwnProperty('language'))?.language?.coding;

	getPatientMothersMaidenName = () =>
		this.patient.extension?.find(item => item.url === fhirExtensionUrls.patient.mothersMaidenName)?.valueHumanName;

	getPatientFathersMaidenName = () =>
		this.patient.extension?.find(item => item.url === fhirExtensionUrls.patient.fathersMaidenName)?.valueHumanName;

	getPatientBirthSex = () =>
		this.patient.extension?.find(item => item.url === fhirExtensionUrls.patient.birthSex)?.valueCode;

	getPatientEthnicity = () =>
		this.patient.extension?.find(item => item.url === fhirExtensionUrls.patient.ethnicity)?.extension;

	getPatientRace = () => this.patient.extension?.find(item => item.url === fhirExtensionUrls.patient.race)?.extension;

	getPatientIssuerOfPatientID = () =>
		this.patient.extension?.find(item => item.url === fhirExtensionUrls.organization.issuer)?.valueReference;

	getVitalObservations = () => {
		return this.vitalObservationInfo;
	};

	getPatientLinks = () => {
		return this.patient?.link || [];
	};

	getPatientChangeRequestIndex = () =>
		this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.changeRequestStatus);

	// #endregion

	// #region =============   setter Functions   ============
	setPatient = (newPatient: IPatientResource) => {
		this.patient = newPatient;
		this.patientPayload = cloneDeep(newPatient);
	};

	setPatientEmergencyContact = contacts => {
		this.patientPayload.contact = contacts;
	};

	setPatientFamilyName = (value: string) => {
		this.patientPayload.name[0].family = value;
		this.patientPayload.name[0].text = formatName(this.patientPayload.name[0]);
	};

	setPatientGivenNames = (values: string) => {
		this.patientPayload.name[0].given = [values];
		this.patientPayload.name[0].text = formatName(this.patientPayload.name[0]);
	};

	setPatientNamePrefix = (value: string) => {
		this.patientPayload.name[0].prefix = [value];
	};

	setPatientNameSuffix = (value: string) => {
		this.patientPayload.name[0].suffix = [value];
	};

	setPatientBirthDate = value => {
		if (value) {
			this.patientPayload.birthDate = value;
		} else {
			delete this.patientPayload.birthDate;
		}
	};

	setPatientDeceasedDateTime = value => {
		if (value) {
			this.patientPayload.deceasedDateTime = value;
		} else {
			delete this.patientPayload.deceasedDateTime;
		}
	};

	setPatientMaritalStatus = value => {
		this.patientPayload.maritalStatus = { coding: [value] };
	};

	setPatientGender = value => {
		this.patientPayload.gender = value;
	};

	setPatientManagingOrganization = value => {
		this.patientPayload.managingOrganization = value;
	};

	setPatientSSN = newValue => {
		if (this.notFoundIndex(this.getPatientSSNIndex())) {
			this.patientPayload.identifier.push({
				type: {
					coding: [
						{
							system: 'http://www.ramsoft.com/fhir/v2/0203',
							code: 'SS',
						},
					],
				},
				value: newValue,
			});
		} else {
			this.patientPayload.identifier = this.patientPayload.identifier.map(item => {
				if (item.type?.coding?.[0].code === PATIENT_SSN_CODE) {
					return { ...item, value: newValue };
				}
				return item;
			});
		}
	};

	setPatientIdentifier = newValue => {
		if (this.notFoundIndex(this.getPatientIdIndex())) {
			this.patientPayload.identifier.push({
				type: {
					coding: [
						{
							system: 'http://hl7.org/fhir/ValueSet/identifier-type',
							code: 'MR',
						},
					],
				},
				value: newValue,
			});
		} else {
			this.patientPayload.identifier = this.patientPayload.identifier.map(item => {
				if (item.type?.coding?.[0].code === PATIENT_ID_CODE) {
					return { ...item, value: newValue };
				}
				return item;
			});
		}
	};

	setPatientDriverLicense = newValue => {
		if (this.notFoundIndex(this.getPatientDriverLicenseIndex())) {
			this.patientPayload.identifier.push({
				type: {
					coding: [
						{
							system: 'http://www.ramsoft.com/fhir/v2/0203',
							code: 'DL',
						},
					],
				},
				value: newValue,
			});
		} else {
			this.patientPayload.identifier = this.patientPayload.identifier.map(item => {
				if (item.type?.coding?.[0].code === PATIENT_DRIVER_LICENCE_CODE) {
					return { ...item, value: newValue };
				}
				return item;
			});
		}
	};

	setPatientLanguage = value => {
		if (!this.patientPayload?.communication) {
			this.patientPayload.communication = [];
		}
		if (this.notFoundIndex(this.getPatientLanguageIndex())) {
			this.patientPayload.communication.push({
				language: {
					coding: value,
				},
				preffered: true,
			});
		} else {
			this.patientPayload.communication = this.patientPayload?.communication.map(item => {
				if (item.hasOwnProperty('language')) {
					return { ...item, language: { coding: value } };
				}
				return item;
			});
		}
	};

	updateExtensions = (extensions, url, newValueHumanName, indexCheckFn) => {
		if (indexCheckFn()) {
			extensions.push({
				url: url,
				valueHumanName: newValueHumanName,
			});
		} else {
			extensions = extensions.map(item => {
				if (item.url === url) {
					return {
						...item,
						valueHumanName: newValueHumanName,
					};
				}
				return item;
			});
		}
		return extensions;
	};

	setPatientMothersMaidenName = value => {
		const mothersMaidenName = this.getPatientMothersMaidenName();
		const givenName = mothersMaidenName?.given || '';
		const formattedName = formatName({ family: value, given: [givenName] });

		const newValueHumanName = {
			family: value,
			given: givenName ? [givenName] : [],
			text: formattedName,
		};

		const indexCheckFn = () => this.notFoundIndex(this.getPatientMothersMaidenNameIndex());

		this.patientPayload.extension = this.updateExtensions(
			this.patientPayload.extension || [],
			fhirExtensionUrls.patient.mothersMaidenName,
			newValueHumanName,
			indexCheckFn
		);
		this.patient.extension = this.updateExtensions(
			this.patient.extension || [],
			fhirExtensionUrls.patient.mothersMaidenName,
			newValueHumanName,
			indexCheckFn
		);
	};

	setPatientMothersGivenName = value => {
		const mothersMaidenName = this.getPatientMothersMaidenName();
		const familyName = mothersMaidenName?.family || '';
		const formattedName = formatName({ family: familyName, given: [value] });

		const newValueHumanName = {
			given: [value],
			text: formattedName,
			family: familyName,
		};

		const indexCheckFn = () => this.notFoundIndex(this.getPatientMothersMaidenNameIndex());

		this.patientPayload.extension = this.updateExtensions(
			this.patientPayload.extension || [],
			fhirExtensionUrls.patient.mothersMaidenName,
			newValueHumanName,
			indexCheckFn
		);
		this.patient.extension = this.updateExtensions(
			this.patient.extension || [],
			fhirExtensionUrls.patient.mothersMaidenName,
			newValueHumanName,
			indexCheckFn
		);
	};

	setPatientFathersGivenName = value => {
		const fathersMaidenName = this.getPatientFathersMaidenName();
		const familyName = fathersMaidenName?.family || '';
		const formattedName = formatName({ family: familyName, given: [value] });

		const newValueHumanName = {
			given: [value],
			text: formattedName,
			family: familyName,
		};

		const indexCheckFn = () => this.notFoundIndex(this.getPatientFathersMaidenNameIndex());

		this.patientPayload.extension = this.updateExtensions(
			this.patientPayload.extension || [],
			fhirExtensionUrls.patient.fathersMaidenName,
			newValueHumanName,
			indexCheckFn
		);
		this.patient.extension = this.updateExtensions(
			this.patient.extension || [],
			fhirExtensionUrls.patient.fathersMaidenName,
			newValueHumanName,
			indexCheckFn
		);
	};

	setPatientFathersMaidenName = value => {
		const fathersMaidenName = this.getPatientFathersMaidenName();
		const givenName = fathersMaidenName?.given || '';
		const formattedName = formatName({ family: value, given: [givenName] });

		const newValueHumanName = {
			family: value,
			given: givenName ? [givenName] : [],
			text: formattedName,
		};

		const indexCheckFn = () => this.notFoundIndex(this.getPatientFathersMaidenNameIndex());

		this.patientPayload.extension = this.updateExtensions(
			this.patientPayload.extension || [],
			fhirExtensionUrls.patient.fathersMaidenName,
			newValueHumanName,
			indexCheckFn
		);
		this.patient.extension = this.updateExtensions(
			this.patient.extension || [],
			fhirExtensionUrls.patient.fathersMaidenName,
			newValueHumanName,
			indexCheckFn
		);
	};

	setPatientBirthSex = value => {
		if (this.notFoundIndex(this.getPatientBirthSexIndex())) {
			this.patientPayload.extension.push({
				url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
				valueCode: value,
			});
		} else {
			this.patientPayload.extension = this.patientPayload.extension?.map(item => {
				if (item.url === fhirExtensionUrls.patient.birthSex) {
					return { ...item, valueCode: value };
				}
				return item;
			});
		}
	};

	setPatientEthnicity = value => {
		if (this.notFoundIndex(this.getPatientEthnicityIndex())) {
			this.patientPayload.extension.push({
				extension: value,
				url: fhirExtensionUrls.patient.ethnicity,
			});
		}
		this.patientPayload.extension = this.patientPayload.extension.map(item => {
			if (item.url === fhirExtensionUrls.patient.ethnicity) {
				return { ...item, extension: value };
			}
			return item;
		});
	};

	setPatientRace = value => {
		if (this.notFoundIndex(this.getPatientRaceIndex())) {
			this.patientPayload.extension.push({
				extension: value,
				url: fhirExtensionUrls.patient.race,
			});
		} else {
			this.patientPayload.extension = this.patientPayload.extension.map(item => {
				if (item.url === fhirExtensionUrls.patient.race) {
					return { ...item, extension: value };
				}
				return item;
			});
		}
	};

	setPatientConfidentiality = value => {
		if (!this.patientPayload.meta?.security) {
			this.patientPayload.meta.security = [];
		}
		if (this.notFoundIndex(this.getPatientConfidentialityIndex())) {
			this.patientPayload.meta.security.push(value);
		} else {
			this.patientPayload.meta.security = this.patientPayload.meta.security.map(item => {
				if (item.system === patientConfidentialitySystem) {
					return value;
				}
				return item;
			});
		}
	};

	setPatientAddress = value => {
		this.patientPayload.address = value || [];
	};

	setPatientTelecom = value => {
		const updatedTelecom = value?.filter(item => item.value) || [];
		this.patientPayload.telecom = updatedTelecom;
	};

	setPatientTelecomUniqueId = () => {
		this.patient.telecom = this.patient.telecom.map(item => ({
			...item,
			__uniqueId: generateRandomString(16),
		}));
	};

	setPatientEmployer = () => {
		if (this.notFoundIndex(findExtensionIndex(this.patient.extension, fhirExtensionUrls.patient.employer))) {
			this.patient.extension.push({
				valueString: '',
				url: fhirExtensionUrls.patient.employer,
			});
		}
	};

	setPatientNotes = notes => {
		if (this.notFoundIndex(this.getPatientNotesIndex())) {
			this.patientPayload.extension.push({
				extension: [...transformPatientNotes(notes)],
				url: fhirExtensionUrls.patient.notes,
			});
		} else {
			this.patientPayload.extension[this.getPatientNotesIndex()].extension = transformPatientNotes(notes);
		}
	};

	// #endregion

	// #region =============   Indexes   ============
	findIndexInArray(array, conditionCallback) {
		return array ? array.findIndex(conditionCallback) : -1;
	}

	getPatientIdIndex = () => {
		return this.findIndexInArray(this.patient.identifier, item => item.type.coding?.[0].code === 'MR');
	};

	getPatientSSNIndex = () => {
		return this.findIndexInArray(this.patient.identifier, item => item.type.coding?.[0].code === 'SS');
	};

	getPatientDriverLicenseIndex = () => {
		return this.findIndexInArray(this.patient.identifier, item => item?.type?.coding?.[0]?.code === 'DL');
	};

	getPatientBirthSexIndex = () => {
		return this.findIndexInArray(this.patient.extension, item => item.url === fhirExtensionUrls.patient.birthSex);
	};

	getPatientMothersMaidenNameIndex = () => {
		return this.findIndexInArray(
			this.patient.extension,
			item => item.url === fhirExtensionUrls.patient.mothersMaidenName
		);
	};

	getPatientFathersMaidenNameIndex = () => {
		return this.findIndexInArray(
			this.patient.extension,
			item => item.url === fhirExtensionUrls.patient.fathersMaidenName
		);
	};

	getPatientSpecialCourtesyIndex = () => {
		return this.findIndexInArray(
			this.patient.extension,
			item => item.url === fhirExtensionUrls.patient.specialcourtesy
		);
	};

	getPatientConfidentialityIndex = () => {
		return this.findIndexInArray(this.patient.meta.security, item => item.system === patientConfidentialitySystem);
	};

	getPatientLanguageIndex = () => {
		return this.findIndexInArray(this.patient.communication, item => item.hasOwnProperty('language'));
	};

	getPatientRaceIndex = () => {
		return this.findIndexInArray(this.patient.extension, item => item.url === fhirExtensionUrls.patient.race);
	};

	getPatientEthnicityIndex = () => {
		return this.findIndexInArray(this.patient.extension, item => item.url === fhirExtensionUrls.patient.ethnicity);
	};

	getPatientNotesIndex = () => {
		return this.findIndexInArray(this.patient.extension, item => item.url === fhirExtensionUrls.patient.notes);
	};

	getPatientEncounterClassIndex = () => {
		return this.findIndexInArray(
			this.patient.extension,
			item => item.url === fhirExtensionUrls.patient.encounterClass
		);
	};

	getPatientChangeRequestStatus = field => {
		let changeRequestStatus = null;
		this.patient.extension?.forEach(item => {
			if (item.url === fhirExtensionUrls.patient.changeRequestStatus) {
				item.extension?.forEach(element => {
					if (element.url === field) changeRequestStatus = element.valueString;
				});
			}
		});
		return changeRequestStatus;
	};

	getDefaultPatientIdentifier = code => ({
		type: {
			coding: [
				{
					system: 'http://hl7.org/fhir/ValueSet/identifier-type',
					code,
				},
			],
		},
		system: '',
		value: '',
	});
	// #endregion

	setLinkTypes = items => {
		this.linkTypes = items.map(item => ({
			code: item.code,
			display: item.display,
		}));
	};

	setEmploymentStatus = items => {
		this.employmentStatuses = items;
	};

	notFoundIndex = index => index === -1;
}

export default Patient;
