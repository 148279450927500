import axios from 'axios';
import { getCurrentDate } from './getAppointments';

const getRelevantAppointment = async ({ __config }) => {
	const todayDate = getCurrentDate();

	const response = await axios.post(`${__config.data_sources.blume}Appointment/_search`, {
		patientIDs: null,
		start: todayDate,
		end: '',
		showCancelAppointments: true,
		enrichFacilityLocation: true,
		count: -1,
	});

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getRelevantAppointment;
