/**
 * Formats a duration given in seconds into a human-readable string.
 *
 * @param {number|string} duration - The duration in seconds. Can be a number or a string that can be parsed into a number.
 * @returns {string} A string representing the duration in the format "Xh Ym Zs", where X is hours, Y is minutes, and Z is seconds.
 *                   If the duration is 0 or invalid, it returns "0s".
 */
const formatDuration = duration => {
	let result = '';
	let distance = parseInt(duration);
	if (!isNaN(distance) && distance > 0) {
		const hours = Math.floor(distance / 3600);
		distance -= hours * 3600;
		if (hours > 0) {
			result += `${hours}h `;
		}

		const minutes = Math.floor(distance / 60);
		distance -= minutes * 60;
		result += `${minutes}m `;

		const seconds = Math.floor(distance);
		result += `${seconds}s`;
	} else {
		result += `0s`;
	}
	return result;
};

export default formatDuration;
