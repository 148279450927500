import { create } from 'zustand';

import { DownloadDiscImageBurner } from '@worklist-2/ui/src/views/utils/downloader';
import getStudies from '@worklist-2/patientPortal/src/api/getStudies';
import getStudyImages from '@worklist-2/patientPortal/src/api/getStudyImages';
import { isStudyEmpty } from '@worklist-2/patientPortal/src/views/StudiesV2/helpers';

import { commonMiddlewares } from './middlewares';

export const useStudiesStore = create(
	commonMiddlewares((set, get) => ({
		// state
		__config: null,
		studies: [],
		filteredStudies: [],
		isStudiesLoading: false,
		isLoaded: false,
		studyDownloadStatus: {}, // { [studyId]: { loading: boolean, progress: number }}
		studyDiscBurnerDownloadStatus: {}, // { [studyId]: { loading: boolean, progress: number }}

		// setters
		setStudies: studies => set(() => ({ studies })),
		setFilteredStudies: studies => set(() => ({ filteredStudies: studies })),
		setIsStudiesLoading: bool => set(() => ({ isStudiesLoading: bool })),

		// actions
		fetchStudies: async () => {
			try {
				const { __config } = get();

				set(() => ({ isStudiesLoading: true }));

				const response = await getStudies({ __config, status: 'completed' });

				set(() => ({
					studies: [...response],
					filteredStudies: [...response],
					isStudiesLoading: false,
					isLoaded: true,
				}));
			} catch (err) {
				console.error(err);
				set(() => ({
					isStudiesLoading: false,
					isLoaded: true,
				}));
				throw err;
			}
		},

		downloadStudy: async ({ study, setToastMsg, t }) => {
			const studyID = study?.id;
			const studyUID = study?.studyThumbnail?.studyUID;

			try {
				const { __config } = get();

				if (isStudyEmpty(study)) {
					setToastMsg(t(`Selected Study does not contain any images`));
					return;
				}

				// Set loading state
				set(state => ({
					studyDownloadStatus: { ...state.studyDownloadStatus, [studyID]: { progress: 0, loading: true } },
				}));
				setToastMsg(t(`Your requested study download will commence shortly`));

				const response = await getStudyImages({
					__config,
					studyUID,
					orgId: study?.managingOrganization?.id,
					onDownloadProgress: progressEvent => {
						const loadedBytes = progressEvent.loaded;

						// Convert bytes to megabytes (MB)
						const loadedMB = (loadedBytes / (1024 * 1024)).toFixed(2);

						set(state => ({
							studyDownloadStatus: {
								...state.studyDownloadStatus,
								[studyID]: { progress: loadedMB, loading: true },
							},
						}));
					},
				});

				const urlFile = URL.createObjectURL(new Blob([response]));
				const link = document.createElement('a');

				link.href = urlFile;
				link.setAttribute('download', `${studyUID}.zip`);
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(urlFile);
				// Reset progress and loading state
				set(state => ({
					studyDownloadStatus: { ...state.studyDownloadStatus, [studyID]: { progress: 0, loading: false } },
				}));
			} catch (err) {
				console.error(err);
				setToastMsg(t(`Something went wrong`));
				set(state => ({
					studyDownloadStatus: { ...state.studyDownloadStatus, [studyID]: { progress: 0, loading: false } },
				}));
			}
		},

		downloadDiskBurner: async ({ study, setToastMsg, t }) => {
			const studyID = study?.id;
			const studyUID = study?.studyThumbnail?.studyUID;

			try {
				const { __config } = get();

				if (isStudyEmpty(study)) {
					setToastMsg(t(`Selected Study does not contain any images`));
					return;
				}

				set(state => ({
					studyDiscBurnerDownloadStatus: {
						...state.studyDiscBurnerDownloadStatus,
						[studyID]: { progress: 0, loading: true },
					},
				}));

				const _download = new DownloadDiscImageBurner({
					uniqueId: studyUID,
					authUrl: __config.data_sources.blume,
					accessionNumber: studyID,
					studyIds: studyUID,
					internalManagingOrganizationIds: study?.managingOrganization?.id,
					isBlumeApp: true,
				}).start({
					progressHandler: progressEvent => {
						const loadedBytes = progressEvent.loaded;

						// Convert bytes to megabytes (MB)
						const loadedMB = (loadedBytes / (1024 * 1024)).toFixed(2);

						set(state => ({
							studyDiscBurnerDownloadStatus: {
								...state.studyDiscBurnerDownloadStatus,
								[studyID]: { progress: loadedMB, loading: true },
							},
						}));
					},

					errorHandler: () => {
						setToastMsg(t(`CD Burner Error on Create`));
						set(state => ({
							studyDiscBurnerDownloadStatus: {
								...state.studyDiscBurnerDownloadStatus,
								[studyID]: { progress: 0, loading: false },
							},
						}));
					},
					completeHandler: () => {
						// Reset progress and loading state
						set(state => ({
							studyDiscBurnerDownloadStatus: {
								...state.studyDiscBurnerDownloadStatus,
								[studyID]: { progress: 0, loading: false },
							},
						}));
					},
				});

				if (_download.enqueueStatus === 'ENQUEUED') {
					setToastMsg(t(`Preparing the disc burner...`, { ns: 'downloader' }));
				} else if (_download.enqueueStatus === 'ALREADY-ENQUEUED') {
					setToastMsg(t(`This is being downloaded.`, { ns: 'downloader' }));
				}
			} catch (err) {
				console.error(err);
				setToastMsg(t(`Something went wrong`));
				set(state => ({
					studyDiscBurnerDownloadStatus: {
						...state.studyDiscBurnerDownloadStatus,
						[studyID]: { progress: 0, loading: false },
					},
				}));
			}
		},

		setSharedStates: ({ __config }) => set(() => ({ __config }), false, 'appointment/setSharedStates'),
	}))
);
