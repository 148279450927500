import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Typography from '@mui/material/Typography';
import { mediaMobile, mediaTablet, useIsMobile } from '@rs-core/utils/responsiveUtils';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const CancelSaveButtons = ({ onBackClick, onRequestClick, isRequestLoading, isValid, backDisabled }) => {
	const { t } = useTranslation('appointments');
	const isMobile = useIsMobile();

	return (
		<>
			<Box
				sx={{
					position: isMobile ? 'relative' : 'fixed',
					bottom: isMobile ? 0 : 55,
					padding: isMobile ? '20px 25px 25px 0px' : '0px 25px 25px 25px',
				}}
			>
				<Stack alignItems="center" direction="row" gap={1}>
					<ReportProblemIcon />
					<Typography fontSize="0.75rem" variant="body1">
						{t(
							'Please note that your request is not confirmed. You will recieve a notification once healthcare provider confirms your request.'
						)}
					</Typography>
				</Stack>
			</Box>
			<Box
				sx={{
					position: isMobile ? 'relative' : 'fixed',
					bottom: 0,
					padding: '0px 25px 25px 25px',
					flexDirection: 'row',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '450px',
					[mediaTablet]: {
						width: '350px !important',
					},
					[mediaMobile]: {
						width: 'calc(100% - 40px) !important',
					},
				}}
			>
				<Box>
					<Button
						data-testid="CancelSave"
						disabled={Boolean(backDisabled)}
						sx={{
							width: '110px',
							height: '42px',
							borderRadius: '8px',
							border: '2px solid #42A5F5',
							color: '#42A5F5',
						}}
						variant="outlined"
						onClick={onBackClick}
					>
						<Tooltip title={t('Go Back')}>
							<Typography
								style={{
									fontSize: '16px',
									fontStyle: 'medium',
									fontWeight: '500',
									lineHeight: '18.75px',
									letterSpacing: '0.15em',
								}}
							>
								{t('BACK')}
							</Typography>
						</Tooltip>
					</Button>
				</Box>
				<Box>
					<Button
						data-testid="request"
						disabled={isRequestLoading || !isValid}
						sx={{
							width: '110px',
							height: '42px',
							borderRadius: '8px',
							backgroundColor: '#42A5F5',
						}}
						variant="contained"
						onClick={onRequestClick}
					>
						<Tooltip title={t('Request Appointment')}>
							{isRequestLoading ? (
								<CircularProgress
									size={30}
									sx={{
										color: '#42a5f5',
									}}
								/>
							) : (
								<Typography
									style={{
										fontSize: '16px',
										fontStyle: 'medium',
										fontWeight: '500',
										lineHeight: '18.75px',
										letterSpacing: '0.15em',
									}}
								>
									{t('BOOK')}
								</Typography>
							)}
						</Tooltip>
					</Button>
				</Box>
			</Box>
		</>
	);
};

export default CancelSaveButtons;
