import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Popper from '@mui/material/Popper';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import { useHorizontalScroll } from '@rs-core/hooks/useHorizontalScroll';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { loadValueSet } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PAYMENT_ORGANIZATION_TYPES_PARAM } from '@rs-ui/views/utils/paymentOrganizationTypes';
import { SKIP_TRANSLATION_LIST, WORKLIST_GRID_COLUMN_VALUESET_EXCLUDE_LIST } from '@rs-ui/helpers/constants.js';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ScrollableTagsWrap = ({ children }) => {
	const scrollRef = useHorizontalScroll();
	return (
		<Box
			ref={scrollRef}
			sx={{
				display: 'flex',
				overflowX: 'scroll',
				maxWidth: '100%',
				marginBottom: '-7px',
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: 'transparent',
					display: 'none',
				},
			}}
		>
			{children}
		</Box>
	);
};

const SearchableMultiSelectColumnFilter = ({
	valueSetType,
	onSelectFilter, // for column filters
	onSelectForm, // for form fields
	capitalizeOptions = false,
	fieldRef,
	preSelectedValues,
	displayValue = 'display',
	labelAlwaysShrunk = false,
	label = 'Search',
	placeholder = 'Search',
	testId,
	style,
	width,
	fullWidth = false,
	hideIcon = true,
	hideTags = true,
	options,
	helperText,
	organizationId,
	enableDuplicatedOptions = false,
	onFocus,
	onBlur,
	popperProps,
	columnWidth,
	valueSetExtraParam,
	multipleTags = false,
}) => {
	const { t } = useTranslation(['organization', 'codePool']);
	placeholder = t(placeholder);

	const [optionsList, setOptionsList] = useState([]);
	const [preSelectedOptions, setPreSelectedOptions] = useState([]);
	const newSelectedOptionsRef = useRef([]);
	const newSelectedValuesRef = useRef([]);

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;
	const parsePreSelectOptions = allOptions => {
		if (preSelectedValues && preSelectedValues.length > 0) {
			setPreSelectedOptions(multipleTags ? preSelectedValues : _.map(_.split(preSelectedValues, '|'), _.trim));
		} else {
			setPreSelectedOptions([]);
		}
	};
	const fhirDataLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});
	const proactEnableCustomStudyPriority = useBooleanFlagValue('proact-enable-custom-study-priority');
	if (proactEnableCustomStudyPriority) {
		SKIP_TRANSLATION_LIST.push('priority');
	}

	// For Grid columns, get valueSet from API when component loads
	useEffect(() => {
		if (!_.isEmpty(options)) {
			setOptionsList(options);
		} else if (valueSetType && !WORKLIST_GRID_COLUMN_VALUESET_EXCLUDE_LIST.includes(label)) {
			if (valueSetType?.toLowerCase() == 'healthcareservice') {
				const getHealthcareServices = async () => loadHealthcareServices();
				getHealthcareServices()
					.then(result => {
						if (result) setOptionsList(result);
					})
					.catch(console.error);
			} else if (valueSetType?.toLowerCase() == 'insurancepayer') {
				const getInsurancePayers = async () => loadInsurancePayers();
				getInsurancePayers()
					.then(result => {
						if (result) setOptionsList(result);
					})
					.catch(console.error);
			} else {
				const getValueSet = async () =>
					loadValueSet(
						fhirDataLoader,
						valueSetType,
						displayValue,
						capitalizeOptions,
						['display'],
						valueSetExtraParam
					);
				getValueSet()
					.then(output => {
						setOptionsList(output);
						if (preSelectedValues) {
							parsePreSelectOptions(output);
						}
					})
					.catch(console.error);
			}
		}
	}, [options]);

	useEffect(() => {
		parsePreSelectOptions(optionsList);
	}, [preSelectedValues]);

	const dataLoader = useFhirDataLoader({
		scope: searchScopes.healthcareService,
	});
	const loadHealthcareServices = async () => {
		const healthcareServiceArr = [];
		await dataLoader.load({ count: 500 }, true).then(res => {
			res?.entry?.map(item => {
				healthcareServiceArr.push({
					id: item.resource?.id,
					active: item.resource?.active,
					display: item.resource?.name,
				});
			});
		});
		return healthcareServiceArr;
	};

	const insurancePayerDataLoader = useFhirDataLoader({
		scope: searchScopes.organization,
	});
	const loadInsurancePayers = async () => {
		const insurancePayersArr = [];
		await insurancePayerDataLoader
			.load(
				{
					partOf: organizationId,
					organizationType: PAYMENT_ORGANIZATION_TYPES_PARAM,
				},
				true
			)
			.then(res => {
				res?.entry?.map(item => {
					insurancePayersArr.push({
						id: item.resource?.id,
						original: item.resource,
						active: item.resource?.active,
						display: item.resource?.name,
					});
				});
			});

		return insurancePayersArr;
	};

	const checkOptionEqualToValue = (option, value) => option.id === value.id;

	const handleSelectFilter = newSelectedOptions => {
		// newSelectedOptions != undefined: this function is called when selecting values from column header dropdown
		//   list and newSelectedOptionsRef should be used - it's the latest selected options
		// newSelectedOptions = undefined: this function is called from onBlur event and preSelectedOptions should be used - it's
		//   the latest selected options that can be from filter capsule
		onSelectFilter({
			selectedOptions: newSelectedOptions ? newSelectedOptionsRef.current : preSelectedOptions,
			fullOptionsList: optionsList,
		});
	};

	const handleOnChange = (event, selectedValues) => {
		if (onFocus) {
			onFocus();
		}
		if (onSelectFilter) {
			let newSelectedOptions = [];
			const selectedOptionText =
				event.target.textContent || event.target?.parentElement?.nextSibling?.textContent;
			// isSelected: true  - the newValue should be removed from the selectedValues,
			//             false - the newValue should be added to the selectedValues
			const isSelected = selectedValues?.some(
				item => item && typeof item !== 'object' && getTranslatedValue(item) === selectedOptionText
			);
			if (!_.isEmpty(selectedValues)) {
				newSelectedOptions = isSelected
					? selectedValues?.filter(
							item =>
								// When clicking on a value, the value is added into the list as an object. When unselecting a value,
								// this object and the corresponding string element should be removed from the list.
								getTranslatedValue(item) !== selectedOptionText
					  )
					: _.map(selectedValues, elem => (enableDuplicatedOptions ? elem : getTranslatedValue(elem)));

				newSelectedOptionsRef.current = isSelected
					? [...newSelectedOptions]
					: _.map(selectedValues, elem =>
							!enableDuplicatedOptions && elem[displayValue] ? elem[displayValue] : elem
					  );
			} else {
				// clear filters
				newSelectedOptionsRef.current = [];
				newSelectedOptions = [];
			}

			setPreSelectedOptions(newSelectedOptions);
			handleSelectFilter(newSelectedOptions);
		} else if (onSelectForm) {
			newSelectedValuesRef.current = selectedValues;
		}
		if (onBlur) {
			setTimeout(() => {
				onBlur();
			}, 10000);
		}
	};

	const getTranslatedValue = item => {
		if (!item) {
			return item;
		}
		if (SKIP_TRANSLATION_LIST.includes(valueSetType) || !valueSetType) {
			return item[displayValue] || item;
		}
		return item[displayValue]
			? t(`codePool:${valueSetType}.${item[displayValue]}`)
			: t(`codePool:${valueSetType}.${item}`);
	};

	return (
		<Autocomplete
			disableCloseOnSelect
			multiple
			PopperComponent={({ ...props }) => <Popper {...props} {...popperProps} />}
			fullWidth={fullWidth}
			getOptionLabel={option =>
				SKIP_TRANSLATION_LIST.includes(valueSetType) || !valueSetType
					? option[displayValue]
					: t(`codePool:${valueSetType}.${option[displayValue]}`)
			}
			isOptionEqualToValue={enableDuplicatedOptions ? checkOptionEqualToValue : undefined}
			noOptionsText={t('noOptions')}
			options={optionsList}
			renderInput={params => (
				<TextField
					{...params}
					InputLabelProps={labelAlwaysShrunk ? { shrink: true } : undefined}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<>
								{hideIcon ? null : <SearchIcon sx={{ pr: '2px' }} />}
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										overflow: 'auto',
										height: 'fit-content',
										maxHeight: '75px',
									}}
								>
									{params.InputProps.startAdornment}
								</div>
							</>
						),
						placeholder,
						endAdornment: multipleTags ? null : params.InputProps.endAdornment,
					}}
					data-cy={testId}
					data-testid={testId}
					helperText={helperText}
					inputRef={fieldRef}
					label={label}
					sx={{
						'& .MuiInputBase-input': {
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						},
					}}
					onBlur={() => (onBlur ? onBlur() : '')}
					onFocus={() => (onFocus ? onFocus() : '')}
				/>
			)}
			renderOption={(props, option, { selected }) => (
				<li id={option.id} {...props} key={option.id}>
					<Checkbox
						checked={
							selected ||
							_.findIndex(preSelectedOptions, elem =>
								SKIP_TRANSLATION_LIST.includes(valueSetType) || !valueSetType
									? elem === option[displayValue]
									: t(`codePool:${valueSetType}.${elem}`) ===
									  t(`codePool:${valueSetType}.${option[displayValue]}`)
							) > -1
						}
						checkedIcon={checkedIcon}
						data-testid={`${option.id}-checkbox`}
						icon={icon}
					/>
					{SKIP_TRANSLATION_LIST.includes(valueSetType) || !valueSetType
						? option[displayValue]
						: t(`codePool:${valueSetType}.${option[displayValue]}`)}
				</li>
			)}
			renderTags={(values, getTagProps) => {
				const _values = enableDuplicatedOptions
					? values.map(val =>
							SKIP_TRANSLATION_LIST.includes(valueSetType) || !valueSetType
								? val.display
								: t(`codePool:${valueSetType}.${val.display}`)
					  )
					: values;

				return hideTags ? (
					<Typography
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							maxWidth: `calc(${columnWidth} - 145px)`, // The max width of the selected options needs to be the width of the column minus the input element (50px), the end addorment (52px) and the right-left paddings
						}}
					>
						{SKIP_TRANSLATION_LIST.includes(valueSetType) || !valueSetType
							? _values?.join(' | ')
							: _values?.map(value => t(`codePool:${valueSetType}.${value}`))?.join(' | ')}
					</Typography>
				) : enableDuplicatedOptions ? (
					<ScrollableTagsWrap>
						{_.reverse(_values).map((elem, ind) => (
							<Chip {...getTagProps({ ind })} label={t(elem)} />
						))}
					</ScrollableTagsWrap>
				) : (
					_values.map((elem, ind) => <Chip {...getTagProps({ ind })} label={t(elem)} />)
				);
			}}
			sx={{
				...(fullWidth ? '' : { width }),
				...style,
				'& .MuiAutocomplete-inputRoot': {
					...(multipleTags
						? {
								paddingRight: '0!important',
								flexWrap: 'wrap',
								'& .MuiOutlinedInput-input': {
									display: preSelectedOptions.length === 0 ? 'block' : 'none',
									flexBasis: 'fit-content',
								},
								'&:focus-within .MuiOutlinedInput-input, &:hover .MuiOutlinedInput-input': {
									display: 'block !important',
								},
						  }
						: {
								display: 'flex',
								flexWrap: 'nowrap',
						  }),
				},
			}}
			value={preSelectedOptions}
			onBlur={() => {
				// There was an issue that the dropdown list was closed after every single value select when calling the functions received
				// from the props (onSelectFilter and onSelectForm) inside onChange event. Use onBlur to fix this issue
				if (onSelectFilter) {
					if (newSelectedOptionsRef.current.length === 0 && preSelectedOptions.length !== 0) {
						return;
					}
					handleSelectFilter();
				}

				if (onSelectForm) {
					if (newSelectedValuesRef.current.length === 0 && preSelectedOptions.length !== 0) {
						/* empty */
					} else {
						onSelectForm(newSelectedValuesRef.current);
					}
				}
			}}
			onChange={handleOnChange}
		/>
	);
};

export default SearchableMultiSelectColumnFilter;

SearchableMultiSelectColumnFilter.propTypes = {
	/**
	 * Whether the input label should be explicitly in shrink mode
	 */
	labelAlwaysShrunk: PropTypes.bool,
	/**
	 * The type of valueSet that will be used to populate the list of options
	 */
	valueSetType: PropTypes.string,
	/**
	 * Handler for when a menu item is selected, as part of a column filter
	 */
	onSelectFilter: PropTypes.func,
	/**
	 * Handler for when a menu item is selected, as part of a form field
	 */
	onSelectForm: PropTypes.func,
	/**
	 * The values that should already be selected when the component loads
	 */
	preSelectedValues: PropTypes.string,
	/**
	 * The valueSet property that will be displayed
	 */
	displayValue: PropTypes.string,
	/**
	 * Whether or not the options should be capitalized
	 */
	capitalizeOptions: PropTypes.bool,
	/**
	 * Represents the helperText prop for the input
	 */
	helperText: PropTypes.string,
	/**
	 * Define the mode when we can have multiple options with the same display
	 */
	enableDuplicatedOptions: PropTypes.bool,
	/**
	 * Column width to calculate component size
	 */
	columnWidth: PropTypes.string,
};
