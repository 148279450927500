import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider, SvgIcon } from '@mui/material';

import getValidURL from '@worklist-2/patientPortal/src/utils/getValidURL';
import BlumeNewLogo from '@worklist-2/ui/src/assets/img/blume_new_logo.png';
import PhoneIcon from '@worklist-2/ui/src/assets/icons/phone.svg';
import { useIsMobile } from '@rs-core/utils/responsiveUtils';

const Navbar = ({ orgName, orgLogoSrc, orgWebsite, phone }) => {
	const isMobile = useIsMobile();

	return (
		<AppBar
			position="static"
			sx={{
				padding: '20px 48px',
				color: '#101828',
				boxShadow: 0,
				background: '#fff',
				borderBottom: '1px solid #dod5dd',
			}}
		>
			<Box
				display="flex"
				flexDirection={{ xs: 'column', md: 'row' }}
				gap={{ xs: '12px' }}
				sx={{ justifyContent: { md: 'center', lg: 'space-between' } }}
			>
				<Box display="flex" gap={{ xs: '10px', md: '16px' }} justifyContent={{ xs: 'center', md: 'left' }}>
					<Box>
						<img alt="Blume - Patient Portal" height={24} loading="eager" src={BlumeNewLogo} />
					</Box>
					<Divider
						flexItem
						orientation="vertical"
						sx={{ backgroundColor: '#dadada', mx: 0.5 }}
						variant="fullWidth"
					/>
					<Box
						display="flex"
						style={{ cursor: 'pointer' }}
						onClick={() => window.open(websiteLink, '_blank')}
					>
						{orgLogoSrc ? (
							<img
								alt={orgName}
								data-testid="org-logo"
								height={isMobile ? '20px' : '24px'}
								src={URL.createObjectURL(orgLogoSrc)}
							/>
						) : (
							<Typography data-testid="org-name" fontSize="20px" fontWeight={500} lineHeight="24px">
								{orgName}
							</Typography>
						)}
					</Box>
				</Box>
				<Box justifyContent={{ xs: 'center', md: 'left' }} sx={{ display: 'flex' }}>
					{orgWebsite && (
						<Typography
							component="a"
							href={getValidURL(orgWebsite)}
							rel="noopener"
							sx={{
								fontWeight: 500,
								lineHeight: '24px',
								fontSize: isMobile ? '12px' : '14px',
								color: '#101828',
								textDecoration: 'none',
								whiteSpace: 'nowrap',
							}}
							target="_blank"
						>
							{orgWebsite}
						</Typography>
					)}
					{phone && (
						<>
							<div
								style={{ padding: '0px 8px', marginRight: '12px', borderRight: '1px solid #c4c4c4' }}
							/>
							<Typography
								component="a"
								href={`tel:${phone.trim().replace(/ /g, '')}`}
								rel="noopener"
								sx={{
									fontWeight: 500,
									lineHeight: '24px',
									fontSize: isMobile ? '12px' : '14px',
									display: 'flex',
									alignItems: 'center',
									color: '#4B5565',
									textDecoration: 'none',
									whiteSpace: 'nowrap',
								}}
							>
								<SvgIcon
									component={PhoneIcon}
									sx={{
										width: '16px',
										fill: 'transparent',
										marginRight: '4px',

										path: { stroke: '#4B5565' },
									}}
								/>
								{phone}
							</Typography>
						</>
					)}
				</Box>
			</Box>
		</AppBar>
	);
};

export default Navbar;
