// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Box } from '@mui/material';

// Custom
import { useIsMobile } from '@rs-core/utils/responsiveUtils';
import NoDataAvailable from '@worklist-2/patientPortal/src/components/NoDataAvailable';
import svg from '@worklist-2/ui/src/assets/icons/Chat/no_chats.svg';

const ChatNoDataAvailable = ({ mobileHeight, desktopHeight, message }) => {
	const isMobile = useIsMobile();
	return (
		<Box
			sx={{
				height: isMobile ? mobileHeight : desktopHeight,
				width: '100%',
				borderRadius: '20px',
				background: '#F3F3F3',
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<NoDataAvailable
				direction="column"
				image={{
					imagePath: svg,
					height: '300px',
					width: '100%',
				}}
				primaryText={{
					text: message,
					fontSize: '24px',
					fontWeight: '500',
					color: '#393939DE',
				}}
				secondaryText={{
					text: '',
					fontSize: '',
					fontWeight: '',
					color: '',
				}}
			/>
		</Box>
	);
};

ChatNoDataAvailable.propTypes = {
	mobileHeight: PropTypes.string,
	desktopHeight: PropTypes.string,
	message: PropTypes.string,
};

export default ChatNoDataAvailable;
