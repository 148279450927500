import React, { createContext, useEffect, useContext, useRef, useState, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { compressObjectToArrayBuffer, decompressArrayBufferToObject } from '@rs-core/utils/stringCompressUtils';
import { logDebug, logError, logInfo, logWarn } from '@rs-core/utils/logger';
import { useRouter } from '@rs-core/hooks/useRouter';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { addParameter } from '@worklist-2/core/src/utils/url';
import { BROADCAST_EVENTS, LAYOUT_TYPE } from '@worklist-2/ui/src/views/ImageViewerView3D/contexts/consts/consts';
import addSynchronizedScreen from '@worklist-2/ui/src/views/ImageViewerView3D/utils/addSynchronizedScreen';
import removeSynchronizedScreen from '@worklist-2/ui/src/views/ImageViewerView3D/utils/removeSynchronizedScreen';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useSearchParams } from 'react-router-dom';
import { useGlobalStore } from '@worklist-2/core/src/store';

export const MultiscreenContext = createContext({});

const BROADCAST_CHANNEL_NAME = 'MULTISCREEN';
const IV_BROADCAST_CHANNEL_NAME = 'IV';
const e2eMockingUserScreenDetails = {
	enabled: true,
	setting: [
		{
			numberofScreen: 3,
			displaySetting: [
				{
					display: 'IV',
					screenNumber: 0,
				},
				{
					display: 'IV',
					screenNumber: 1,
				},
				{
					display: 'DV',
					screenNumber: 2,
				},
			],
		},
	],
};
const mockingScreenDetails = {
	availLeft: 0,
	availTop: 0,
	availHeight: 1192,
	availWidth: 1920,
	height: 1200,
	width: 1920,
	orientation: {
		angle: 0,
		type: 'landscape-primary',
	},
};
const e2eMockingSystemScreenDetails = {
	currentScreen: mockingScreenDetails,
	screens: [mockingScreenDetails, mockingScreenDetails, mockingScreenDetails],
};

export const MultiscreenProvider = ({ children }) => {
	const wonIvWindowOpenNoopener = useBooleanFlagValue('won-iv-window-open-noopener');
	const wonMmE2EMocking = useBooleanFlagValue('won-mm-e2e-mocking');

	const { setCurrentStudyInfo } = useGlobalStore();

	const [redirectId, setRedirectId] = useState(uuid());

	const permissionStatus = useRef(null);
	const screenDetails = useRef(null);
	const screenNumber = useRef(null);
	const isCurrentScreen = useRef(false);
	const isExternalRedirect = useRef(false);
	const { displaySettingsRef } = useAuth();
	const { goTo } = useRouter();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		canAccessMultimonitorFunctionality();
	}, []);

	const getScreenNumber = useCallback(() => {
		if (!screenNumber.current) {
			screenNumber.current = searchParams.get('screenNumber');
		}

		return screenNumber.current;
	}, [searchParams]);

	const getIsCurrentScreen = useCallback(() => {
		if (!isCurrentScreen.current) {
			isCurrentScreen.current = searchParams.get('isCurrentScreen');
		}

		return isCurrentScreen.current;
	}, [searchParams]);

	const multiscreenBroadcastChannel = useRef(new BroadcastChannel(BROADCAST_CHANNEL_NAME));
	const ivBroadcastChannel = useRef(new BroadcastChannel(IV_BROADCAST_CHANNEL_NAME));
	const synchronizedScreens = useRef([]);
	const ivSynchronizedScreens = useRef([]);
	const [isOpenedDocumentViewer, setIsOpenedDocumentViewer] = useState(false);
	const [isOpenedImageViewer, setIsOpenedImageViewer] = useState(false);
	const [isOpenedDocument, setIsOpenedDocument] = useState({});
	const [ivLayoutType, setIvLayoutType] = useState(LAYOUT_TYPE.DEFAULT);
	const [isShownIvBackdrop, setIsShownIvBackdrop] = useState(false);

	const onSynchronizedScreensChange = () => {
		setIsOpenedDocumentViewer(
			Boolean(
				synchronizedScreens.current.find(synchronizedScreen =>
					synchronizedScreen.url?.includes('document-viewer')
				)
			)
		);

		setIsOpenedImageViewer(
			Boolean(
				synchronizedScreens.current.find(synchronizedScreen =>
					synchronizedScreen.url?.includes('imageviewer3d')
				)
			)
		);
	};

	const _postMessage = data => {
		try {
			logDebug('Multiscreen Context', '_postMessage Start', { event: data?.event, timestamp: Date.now() });
			const message = {
				screenNumber: getScreenNumber(),
				url: location.href,
				...data,
			};

			// compress message before sending
			const compressedBuffer = compressObjectToArrayBuffer(message);
			multiscreenBroadcastChannel.current?.postMessage(compressedBuffer);
			logDebug('Multiscreen Context', '_postMessage after compressed', {
				compressedBufferLength: compressedBuffer?.length,
				timestamp: Date.now(),
			});
		} catch (error) {
			logError('Multiscreen Context', '_postMessage', error);
		}
	};

	const onMessageReceive = e => {
		let message = e.data;

		// decompress message after receiving
		message = decompressArrayBufferToObject(e.data);
		logDebug('Multiscreen Context', 'onMessageReceive after decompressed', {
			event: message.event,
			screenNumber: message.screenNumber,
			timestamp: Date.now(),
		});

		logDebug('Multiscreen Context', 'onMessageReceive Start', {
			timestamp: Date.now(),
			event: message.event,
			targetScreenNumber: message.targetScreenNumber,
		});

		if (getScreenNumber() === message.screenNumber) {
			return;
		}

		if (message.event === BROADCAST_EVENTS.REQUEST_SYNCHRONIZE) {
			addSynchronizedScreen({
				synchronizedScreens,
				newScreen: message,
			});

			onSynchronizedScreensChange();

			_postMessage({
				event: BROADCAST_EVENTS.RECEIVE_SYNCHRONIZE,
			});

			return;
		}

		if (message.event === BROADCAST_EVENTS.RECEIVE_SYNCHRONIZE) {
			addSynchronizedScreen({
				synchronizedScreens,
				newScreen: message,
			});

			onSynchronizedScreensChange();

			return;
		}

		if (message.event === BROADCAST_EVENTS.WINDOW_CLOSED) {
			removeSynchronizedScreen({ synchronizedScreens, screenNumber: message.screenNumber });

			onSynchronizedScreensChange();

			return;
		}

		if (message.event === BROADCAST_EVENTS.ROUTE_CHANGED) {
			if (message.targetScreenNumber != getScreenNumber()) {
				return;
			}

			const currentStudyInfo = message?.currentStudy ? JSON.parse(message?.currentStudy) : {};
			logInfo('Multiscreen Context', 'Setting currentStudyInfo', {
				studyId: currentStudyInfo?.id,
				screenNumber: getScreenNumber(),
			});
			setCurrentStudyInfo(currentStudyInfo);
			logDebug('Multiscreen Context', 'onMessageReceive studyId', {
				studyId: currentStudyInfo?.id,
				timestamp: Date.now(),
			});

			setRedirectId(uuid());
			goTo.any(message.url);
			setIsShownIvBackdrop(false);

			_postMessage({
				event: BROADCAST_EVENTS.URL_CHANGED,
			});

			return;
		}

		if (message.event === BROADCAST_EVENTS.URL_CHANGED) {
			addSynchronizedScreen({
				synchronizedScreens,
				newScreen: message,
			});

			onSynchronizedScreensChange();
		}

		if (message.event === BROADCAST_EVENTS.DICTATION_DEVICE_ADDED) {
			window.location.reload();
		}

		if (message.event === BROADCAST_EVENTS.EXTERNAL_REDIRECT) {
			isExternalRedirect.current = true;
		}

		if (message.event === BROADCAST_EVENTS.USER_LOGOUT && !!getScreenNumber() && !getIsCurrentScreen()) {
			window.close();
		}

		if (message.event === BROADCAST_EVENTS.RELOAD) {
			setRedirectId(uuid());
			setIsShownIvBackdrop(false);
		}
	};

	const onIvMessageReceive = e => {
		if (e.data.origin !== window.location.origin) {
			return;
		}

		if (getScreenNumber() === e.data.screenNumber) {
			return;
		}

		if (
			e.data.message &&
			e.data.message.studyId &&
			window.location.pathname.toLocaleLowerCase().startsWith('/imageviewer')
		) {
			const study = e.data.message;
			const queryParams = new URLSearchParams(window.location.search);
			queryParams.set('StudyId', study?.studyId);
			queryParams.set('StudyInstanceUIDs', study?.studyInstanceUids);
			queryParams.set('PatientInfo', study?.internalPatientId);
			queryParams.set('IssuerOfPatientID', study?.issuerOfPatientId);
			queryParams.set('PatientID', study?.patientId);
			queryParams.set('internalManagingOrganizationID', study?.internalManagingOrganizationID);
			queryParams.set('referringFacilityId', study?.referringFacilityId);
			goTo.any(`/imageviewer3d?${queryParams.toString()}`);
			if (study?.fullResource) {
				// decompress the full study resource and set it in the global store in IV screen
				const decompressedStudyInfo = decompressArrayBufferToObject(study?.fullResource);
				logInfo('Multiscreen Context', 'Setting currentStudyInfo', {
					studyId: decompressedStudyInfo?.id,
					screenNumber: getScreenNumber(),
				});
				setCurrentStudyInfo(decompressedStudyInfo);
			}
			setIsShownIvBackdrop(false);
		}
	};

	const ON_WINDOW_CLOSED = () => {
		_postMessage({
			event: BROADCAST_EVENTS.WINDOW_CLOSED,
		});
	};

	const addListeners = () => {
		multiscreenBroadcastChannel.current.addEventListener('message', onMessageReceive);
		ivBroadcastChannel.current.addEventListener('message', onIvMessageReceive);
		window.addEventListener('beforeunload', ON_WINDOW_CLOSED);
	};

	const removeListeners = () => {
		multiscreenBroadcastChannel.current.removeEventListener('message', onMessageReceive);
		multiscreenBroadcastChannel.current.close();

		ivBroadcastChannel.current.removeEventListener('message', onIvMessageReceive);
		ivBroadcastChannel.current.close();
	};

	useEffect(() => {
		addListeners();

		_postMessage({
			event: BROADCAST_EVENTS.REQUEST_SYNCHRONIZE,
		});

		return () => {
			removeListeners();
		};
	}, []);

	const numberOfScreenInfo = async () => {
		if (!screenDetails.current) {
			screenDetails.current = await self.getScreenDetails().catch(e => {
				logError('Multiscreen Context', 'numberOfScreenInfo', e);
				return null;
			});
		}

		return screenDetails.current?.screens;
	};

	const canAccessMultimonitorFunctionality = async () => {
		try {
			if (window.screen.isExtended && 'getScreenDetails' in self) {
				permissionStatus.current = await navigator.permissions.query({
					name: 'window-placement',
				});
				return permissionStatus.current && permissionStatus.current.state === 'granted';
			}
		} catch (e) {
			return false;
		}
	};

	const getScreenDetailsWithWarningAndFallback = async (screenNumber, requestPermission = false) => {
		if ('getScreenDetails' in self) {
			if (
				!screenDetails.current &&
				((permissionStatus.current && permissionStatus.current.state === 'granted') ||
					(permissionStatus.current && permissionStatus.current.state === 'prompt' && requestPermission))
			) {
				screenDetails.current = await self.getScreenDetails().catch(e => {
					logError('Multiscreen Context', 'getScreenDetailsWithWarningAndFallback', e);
					return null;
				});
			}

			if (screenDetails.current && screenDetails.current.screens.length > 1)
				logInfo('Multiscreen Context', 'Clear any warning.');
			else if (screenDetails.current && screenDetails.current.screens.length == 1)
				logWarn(
					'Multiscreen Context',
					'Please extend your desktop over multiple screens for full demo functionality'
				);
			else if (requestPermission || permissionStatus.state === 'denied')
				logWarn(
					'Multiscreen Context',
					'Please allow the Window Placement permission for full demo functionality'
				);
			if (screenDetails.current) {
				if (
					Number.isInteger(screenNumber) &&
					screenNumber >= 0 &&
					screenNumber < screenDetails.current.screens.length
				) {
					return screenDetails.current.screens[screenNumber];
				}
				return screenDetails.current.screens[screenDetails.current.screens.length];
			}
		}

		return [window.screen];
	};

	const getScreenDetailsByScreenNumber = async screenNumber =>
		getScreenDetailsWithWarningAndFallback(screenNumber).then(screen => {
			if (screen.length >= 1) {
				if (screen.length <= screenNumber) return screen[screenNumber];
				return screen[screen.length - 1];
			}
			return screen;
		});

	const determineIvLayoutType = (ivScreens, index) => {
		if (!ivScreens?.length || ivScreens.length <= 1) {
			return LAYOUT_TYPE.DEFAULT;
		}

		const currentScreenNumber = index;
		const otherScreenNumbers = ivScreens.map(item => Number(item.screenNumber));
		const isLeft = index === Math.min(currentScreenNumber, ...otherScreenNumbers);
		const isRight = index === Math.max(currentScreenNumber, ...otherScreenNumbers);

		if (isLeft) {
			return LAYOUT_TYPE.LEFT;
		}

		if (isRight) {
			return LAYOUT_TYPE.RIGHT;
		}

		return LAYOUT_TYPE.MIDDLE;
	};

	const openToSettingScreen = async (url, name, externalUrl, isMandatory = false, record) => {
		let result = false;
		logDebug('Multiscreen Context', `Start openToSettingScreen - ${name}`, { timestamp: Date.now() });

		if (!screenDetails.current) {
			screenDetails.current = await self.getScreenDetails().catch(e => {
				logError('Multiscreen Context', 'openToSettingScreen', e);
				return null;
			});
		}

		if (wonMmE2EMocking) {
			// Mcoking for E2E testing - User has 3 screens and setting is IV, IV, DV
			screenDetails.current = e2eMockingSystemScreenDetails;
			displaySettingsRef.current = e2eMockingUserScreenDetails;
		}

		const screensInfo = screenDetails.current;
		if (displaySettingsRef.current?.enabled && screensInfo.screens.length > 1) {
			const myscreen = displaySettingsRef.current?.setting?.find(
				s => s.numberofScreen == screensInfo.screens.length
			);

			if (myscreen) {
				const ViewerScreens = myscreen.displaySetting.filter(ds => ds.display == name);
				const currentscreenNumber =
					getScreenNumber() || screensInfo.screens.findIndex(s => s == screensInfo.currentScreen);
				const ivScreens = myscreen.displaySetting.filter(ds => ds.display == 'IV');
				const numOfIVScreens = ivScreens?.length;

				const updatedURL = addParameter(url, 'numOfIVScreens', numOfIVScreens);
				if (ViewerScreens?.length) {
					_postMessage({
						event: BROADCAST_EVENTS.EXTERNAL_REDIRECT,
					});
					result = true;

					let ivIsCurrentScreen = false;
					ViewerScreens.forEach((ViewerScreen, index) => {
						logDebug('Multiscreen Context', `ViewerScreens - Trigger screen ${name}`, {
							timestamp: Date.now(),
						});

						let fullURL = addParameter(updatedURL, 'screenNumber', ViewerScreen.screenNumber);
						fullURL =
							name === 'IV'
								? addParameter(
										fullURL,
										'ivLayoutType',
										determineIvLayoutType(ViewerScreens, Number(ViewerScreen.screenNumber))
								  )
								: fullURL;
						const fullName = `${name}${ViewerScreen.screenNumber}`;
						const screenIsOpened = synchronizedScreens.current.find(
							screen => screen.screenNumber == ViewerScreen.screenNumber
						);

						if (currentscreenNumber == ViewerScreen.screenNumber) {
							if (externalUrl) {
								window.open(fullURL);
							} else {
								if (name === 'IV') {
									ivIsCurrentScreen = true;
								}
								fullURL = addParameter(fullURL, 'isCurrentScreen', 1);
								goTo.any(fullURL);
							}
						} else {
							if (name === 'IV' && index === ViewerScreens.length - 1 && !ivIsCurrentScreen) {
								// If IV is not the current screen, adding isMainIvScreen parameter to the last IV screen to start loading data
								fullURL = addParameter(fullURL, 'isMainIvScreen', 1);
							}

							if (screenIsOpened) {
								_postMessage({
									event: BROADCAST_EVENTS.ROUTE_CHANGED,
									url: fullURL,
									targetScreenNumber: ViewerScreen.screenNumber,
									currentStudy: JSON.stringify(record || {}),
								});

								return;
							}

							const screen = screensInfo.screens[ViewerScreen.screenNumber];

							const options = {
								x: screen.availLeft,
								y: screen.availTop,
								width: screen.availWidth,
								height: screen.availHeight,
								noopener: wonIvWindowOpenNoopener,
							};
							options.url = fullURL;

							openWindow(options, fullName);
						}
					});
				} else if (isMandatory) {
					result = true;
					const screen = screensInfo.screens[currentscreenNumber];

					const options = {
						x: screen.availLeft,
						y: screen.availTop,
						width: screen.availWidth,
						height: screen.availHeight,
						noopener: wonIvWindowOpenNoopener,
					};
					options.url = updatedURL;
					openWindow(options, name);
				}
			}
		}
		return result;
	};

	const openToAnotherScreen = async (url, name) => {
		if (!screenDetails.current) {
			screenDetails.current = await self.getScreenDetails().catch(e => {
				logError('Multiscreen Context', 'openToAnotherScreen', e);
				return null;
			});
		}

		const screensInfo = screenDetails.current;
		if (screensInfo.screens.length > 1) {
			const screen = screensInfo.screens[screensInfo.screens.findIndex(s => s != screensInfo.currentScreen)];

			const options = {
				x: screen.availLeft,
				y: screen.availTop,
				width: screen.availWidth,
				height: screen.availHeight,
				noopener: wonIvWindowOpenNoopener,
			};
			options.url = url;
			return openWindow(options, name);
		}
		goTo.any(url);
	};

	const openToScreen = (screenNumber, url) =>
		getScreenDetailsByScreenNumber(screenNumber).then(screen => {
			const options = {
				x: screen.availLeft,
				y: screen.availTop,
				width: screen.availWidth,
				height: screen.availHeight,
				noopener: wonIvWindowOpenNoopener,
			};
			options.url = url;
			return openWindow(options);
		});

	const openWindow = (options = null, name = '_blank') => {
		if (!options || !options.url) {
			options = {
				url: openWindowUrlInput.value,
				x: openWindowLeftInput.value,
				y: openWindowTopInput.value,
				width: openWindowWidthInput.value,
				height: openWindowHeightInput.value,
				noopener: wonIvWindowOpenNoopener,
			};
		}

		const features = getFeaturesFromOptions(options);
		const windowProxy = window.open(options.url, name, features);

		logDebug('Multiscreen Context', 'openWindow', { url: options.url, succeed: !!windowProxy });
		return windowProxy;
	};

	const getFeaturesFromOptions = options =>
		`left=${options.x},top=${options.y},width=${options.width},height=${options.height},fullscreen=1,noopener=${
			options.noopener ? 1 : 0
		}`;

	const toggleElementFullscreen = async (element, screenId) =>
		getScreenDetailsWithWarningAndFallback(screenId).then(async screen => {
			if (screen) {
				await element.requestFullscreen({ screen });
			} else if (document.fullscreenElement == element) {
				document.exitFullscreen();
			} else {
				await element.requestFullscreen();
			}
		});

	const fullScreen = async () => {
		await document.documentElement.requestFullscreen();
	};

	const toggleFullscreen = async screenId => toggleElementFullscreen(document.documentElement, screenId);

	/**
	 * Multi screen next if document is next
	 */
	const channel = channelName => {
		try {
			return new BroadcastChannel(channelName);
		} catch (error) {
			return null;
		}
	};

	const postNextMessage = (viewer, postMessage) => {
		try {
			channel(viewer)?.postMessage({
				message: postMessage,
				origin: window.location.origin,
				screenNumber: getScreenNumber(),
			});
			channel(viewer)?.close();
		} catch (error) {
			logError('Multiscreen Context', '_postMessage after Next', error);
		}
	};

	const postMessageToMultiScreenBroadcastChannel = eventType => {
		_postMessage({ event: eventType });
	};

	return (
		<MultiscreenContext.Provider
			value={{
				canAccessMultimonitor: canAccessMultimonitorFunctionality,
				getScreenDetails: getScreenDetailsWithWarningAndFallback,
				toggleFullscreen,
				openToScreen,
				fullScreen,
				openToAnotherScreen,
				openToSettingScreen,
				postNextMessage,
				numberOfScreenInfo,
				getScreenDetailsByScreenNumber,
				isOpenedDocumentViewer,
				setIsOpenedDocumentViewer,
				isOpenedImageViewer,
				postMessageToMultiScreenBroadcastChannel,
				redirectId,
				setRedirectId,
				isOpenedDocument,
				setIsOpenedDocument,
				isExternalRedirect,
				_postMessage,
				ivLayoutType,
				setIvLayoutType,
				ivSynchronizedScreens,
				isShownIvBackdrop,
				setIsShownIvBackdrop,
				onMessageReceive,
				onIvMessageReceive,
			}}
		>
			{children}
		</MultiscreenContext.Provider>
	);
};

export const useMultiscreen = () => useContext(MultiscreenContext);
export default MultiscreenProvider;
