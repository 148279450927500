import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { Svg } from '..';
import {
	BackDrop,
	boxStyle,
	DateText,
	header,
	mainBox,
	NoScrollbar,
	PolicyWrapper,
	SideBarWrapper,
	sideStyle,
	StrongText,
} from './policyStyles';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { mediaMobile, mediaTablet, useIsMobile, useIsTablet } from '@rs-core/utils/responsiveUtils';
import useRouterHash from '@rs-core/hooks/useRouterHash';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const SideBarLinks = ({ onClick }) => {
	const { t } = useTranslation('term');
	const { hash, setHash } = useRouterHash();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const links = [
		{ label: 'Introduction' },
		{ label: 'Interpretation And Definitions' },
		{ label: 'Use And Restrictions' },
		{
			label: 'Third Party Links, Products, and Integrations',
			children: [
				{ label: 'Links to Other Websites' },
				{ label: 'Third Party Accounts' },
				{ label: 'Third Party Products' },
			],
		},
		{ label: 'Social Media' },
		{ label: 'Privacy Policy and AI Disclaimer' },
		{ label: 'Feedback' },
		{ label: 'Prohibited Uses' },
		{ label: 'Termination' },
		{ label: 'Limitation of liability' },
		{ label: 'Medical Decision Making and Clinical Disclaimer' },
		{ label: 'Dispute Resolution' },
		{
			label: 'Severability and Waiver',
			children: [{ label: 'Severability' }, { label: 'Waiver' }],
		},
		{ label: 'Indemnification' },
		{ label: 'Confidentiality' },
		{ label: 'Translation Interpretation' },
		{ label: 'Changes to these Terms and Conditions' },
		{ label: 'Acceptance of these Terms' },
		{ label: 'Contact Us' },
	];

	return (
		<SideBarWrapper>
			{links.map((link, i) => (
				<>
					<Box
						key={i}
						component="li"
						sx={{
							[mediaMobile]: {
								fontSize: '14px',
								lineHeight: '48px',
								color: '#121212',
								letterSpacing: '0.25px',
							},
						}}
						onClick={onClick}
					>
						<a
							className={`${
								hash ==
									link.label
										.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
										.replace(/\s/g, '-')
										.toLowerCase() && 'active'
							}`}
							href={`#${link.label
								.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
								.replace(/\s/g, '-')
								.toLowerCase()}`}
							onClick={() => {
								setHash(
									link.label
										.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
										.replace(/\s/g, '-')
										.toLowerCase()
								);
							}}
						>
							{t(link.label)}
						</a>
					</Box>
					{!isMobile && !isTablet && link.children && (
						<ul>
							{link.children.map((childTerm, j) => (
								<li key={j} className="unblur">
									<a
										className={`${
											hash == childTerm.label.replace(/\s/g, '-').toLowerCase() && 'active'
										}`}
										href={`#${childTerm.label.replace(/\s/g, '-').toLowerCase()}`}
										onClick={() => {
											setHash(childTerm.label.replace(/\s/g, '-').toLowerCase());
										}}
									>
										{t(childTerm.label)}
									</a>
								</li>
							))}
						</ul>
					)}
				</>
			))}
		</SideBarWrapper>
	);
};

const TermsModalBlumeContent = ({ isBlumeInside }) => {
	const { t } = useTranslation('term');
	const { watchScroll } = useRouterHash();
	const handleScroll = _.debounce(e => watchScroll(e), 10);

	return (
		<PolicyWrapper isBlumeInside={Boolean(isBlumeInside)} onScroll={handleScroll}>
			<Box
				sx={{
					[mediaTablet]: {
						marginRight: '0',
					},
				}}
			>
				{isBlumeInside ? (
					<Box className="watched" id="introduction" sx={{ '& p': { lineHeight: '6px !important' } }}>
						<p>{t('Last updated on:')}</p>
						<p>{t('January 30, 2025')}</p>
					</Box>
				) : (
					<DateText className="watched" id="introduction">
						{t('*Last updated: January 30, 2025')}
					</DateText>
				)}
				{isBlumeInside && (
					<Divider
						sx={{
							margin: '20px 15px 15px 0',
							borderColor: 'rgba(100, 120, 192, 0.1)',
						}}
					/>
				)}
				<p>
					{t(
						'At RamSoft, we are committed to transforming radiology through innovative solutions that enhance imaging workflows, improve operational efficiency, and elevate patient care. We believe that every patient deserves easy and secure access to their medical imaging records. That’s why we created Blume™—a patient engagement platform designed to simplify your healthcare journey by giving you direct access to your diagnostic images and reports. Blume™ is our complete medical imaging patient engagement solution, designed to simplify and enhance the patient experience. With Blume™, patients can securely access, view, and share their diagnostic images and reports—all from a single, user-friendly portal available on both web and mobile devices. Please view all features here'
					)}{' '}
					<a href="https://www.ramsoft.com/products/blume">Blume - More than a Patient Portal</a>.
				</p>
				<p>
					{t(
						'By accessing or using Blume™, you agree to be bound by these Terms and represent that you are legally capable of forming a binding contract and complying with all applicable laws. If you do not agree, please refrain from using Blume™. These Terms apply to all users of Blume™, including patients, healthcare providers, and others accessing Blume™. Should you have any questions or need further clarification, feel free to reach out to us at'
					)}{' '}
					<a href="mailto:legal@ramsoft.com">legal@ramsoft.com</a>.
				</p>
				<StrongText className="watched" id="interpretation-and-definitions">
					{t('INTERPRETATION AND DEFINITIONS')}
				</StrongText>
				<p>
					<strong>{t('Definitions')}</strong>
				</p>
				<p>{t('For the purposes of these Terms and Conditions:')}</p>
				<ul>
					<li>
						<strong>&quot;{t('Affiliate')}&quot;</strong>{' '}
						{t(
							'means any entity, including but not limited to subsidiaries, parent companies, joint venture partners, alliances, or any other companies or organizations that are directly or indirectly controlled, owned, or operated by RamSoft, Inc.'
						)}
					</li>
					<li>
						<strong>{t('Company')}</strong> {t('(referred to as either')} &quot;{t('the Company')}&quot;,
						&quot;{t('We')}&quot;, &quot;{t('Us')}&quot; {t('or')} &quot;{t('Our')}&quot;{' '}
						{t('in this Agreement) refers to RamSoft, Inc.')}
					</li>
					<li>
						<strong>
							&quot;{t('Services')}&quot; {t('or')} &quot;{t('Blume')}&quot;
						</strong>{' '}
						{t(
							'refers to RamSoft’s Blume™ software, including its respective web and mobile-based applications.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Software')}&quot;</strong>{' '}
						{t(
							'refers to any intellectual property developed, owned, licensed, trademarked, and copyrighted by the Company.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Terms and Conditions')}&quot;</strong>{' '}
						{t(
							'(also referred as “Terms”) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of these Services.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Third-party')}&quot;</strong>{' '}
						{t(
							'means any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included or made available on Blume™.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Vendor')}&quot;</strong>
						&nbsp;{' '}
						{t(
							'refers to any third-party company which Company partners to integrate, resell, or distribute their software, service, or offering.'
						)}
					</li>
					<li>
						<strong>
							&quot;{t('You')}&quot; {t('or')} &quot;{t('User')}&quot;
						</strong>{' '}
						{t(
							'means the individual accessing or using Blume™, or the company, or other legal entity on behalf of which such individual is accessing or using Blume™, as applicable.'
						)}
					</li>
				</ul>

				<StrongText className="watched" id="use-and-restrictions">
					{t('USE & RESTRICTIONS')}
				</StrongText>
				<p>
					{t(
						'You agree to use Blume™ in compliance with all applicable laws and regulations, including but not limited to those governing medical use, copyright, and intellectual property. All rights not expressly granted are reserved. The Company makes no guarantees or warranties that Blume™ will operate uninterrupted or error-free.'
					)}
				</p>
				<p>
					{t(
						'Restrictions: You may not: (i) modify, translate, reverse engineer, decompile, disassemble, or create derivative works of Blume™, except as expressly permitted by law; (ii) copy Blume™; (iii) remove proprietary notices or labels. Any violation of these restrictions will result in immediate termination of your license. '
					)}
				</p>
				<p>
					{t(
						'You acknowledge that Blume™ facilitates access to electronic Protected Health Information (“ePHI”) for both healthcare providers and patients. Healthcare providers may only access the ePHI of patients solely to the extent necessary for treatment and permissible under the law. Patients may access their own ePHI as permitted by applicable laws and regulations. All access to ePHI within Blume™ may be monitored and audited. Unauthorized access, use, or disclosure of ePHI is strictly prohibited. If it is determined that you have impermissibly accessed or misused ePHI, the operator reserves the right to restrict or terminate your access to Blume™ and take any necessary legal or regulatory action.'
					)}
				</p>
				<StrongText className="watched" id="third-party-links-products-and-integrations">
					{t('THIRD PARTY LINKS, PRODUCTS, AND INTEGRATIONS')}
				</StrongText>
				<p className="watched" id="links-to-other-websites">
					<strong>{t('Links to other Websites')}</strong>
				</p>
				<p>
					{t(
						'Blume™ may include links to third-party websites or services that are not owned, operated, or controlled by the Company. The Company does not endorse, and is not responsible for, the content, privacy policies, terms, or practices of any third-party websites or services. The Company disclaims all liability for any loss or damage, whether direct or indirect, arising from or related to your use of, or reliance on, any content, goods, or services available through such third-party websites. The inclusion of any link does not constitute an endorsement of the linked website, its content, or its sponsoring entity. You are encouraged to review the terms and privacy policies of any third-party websites they visit before engaging with such sites.'
					)}
				</p>
				<p className="watched" id="third-party-accounts">
					<strong>{t('Third Party Accounts')}</strong>
				</p>
				<p>
					{t(
						'By accessing third-party services through Blume™, you acknowledge and agree to the following:'
					)}
				</p>
				<p>
					1.{' '}
					{t(
						'You are solely responsible for maintaining the confidentiality of your login credentials, including usernames, passwords, or any other access credentials required to use the Services. You assume full responsibility for all activities conducted under your credentials and for any data accessed using them. The Company and its vendors disclaim any liability for any loss, expense, or damage resulting from unauthorized use of your credentials. Accordingly, you must take appropriate measures to safeguard your credentials, account, devices and monitor account activity.'
					)}
				</p>

				<p>
					2.{' '}
					{t(
						'You must immediately notify the Company of any unauthorized use, disclosure, loss, or theft of your credentials. You further agree to cooperate in any investigation and implement corrective actions to prevent further unauthorized activity. The Company is not liable for any unauthorized access caused by a user’s failure to safeguard their credentials. Users must report suspected security breaches immediately.'
					)}
				</p>

				<p>
					3.{' '}
					{t(
						'You are solely responsible for any fees associated with third-party accounts and transactions conducted outside Blume™. Your use of such third-party accounts is governed by their respective terms and conditions, which remain separate and unaffected by these Terms. Any disputes or inquiries related to third-party transactions must be directed to the respective account provider.'
					)}
				</p>

				<p className="watched" id="third-party-products">
					<strong>{t('Third Party Products')}</strong>
				</p>
				<p>
					{t(
						'Blume™ may include third-party code, content, features, or components provided “AS IS” without any warranty from the Company. All rights and obligations related to such third-party products or services are governed solely by agreements between the Company and its suppliers. You hereby release the Company from all liability arising from their use.'
					)}
				</p>
				<p>
					{t(
						'Pursuant to Federal Trade Commission’s Guides Concerning the Use of Endorsements and Testimonials in Advertising, 16 C.F.R. Part 255 Sections 255.0 through 255.5, some of our links are affiliated links. This means that Company may receive payment if you purchase products or services through such links.'
					)}
				</p>
				<StrongText className="watched" id="social-media">
					{t('SOCIAL MEDIA')}
				</StrongText>
				<p>
					{t(
						'The Company’s social media presence extends its online activities, including Services and Marketing. We may post content on platforms including but not limited to Facebook®, Twitter®, LinkedIn®, and YouTube®. We are not responsible for the privacy or security practices of third-party websites we link to. Users should review their Terms of Service and Privacy Policies. Our policies ensure patient privacy, and we encourage all users to protect their personal health information in any public or shared settings. Our social media sites are moderated, and we reserve the right to remove content that violates applicable laws, our corporate values, or our published policies.'
					)}
				</p>
				<StrongText className="watched" id="privacy-policy-and-ai-disclaimer">
					{t('PRIVACY POLICY AND AI DISCLAIMER')}
				</StrongText>
				<p>
					{t(
						'Your access to and use of the Services is also conditioned on your acceptance of and compliance with the'
					)}{' '}
					<a href="https://www.ramsoft.com/privacy-policy">Privacy Policy</a> {t('of the Company and the')}{' '}
					<a href="https://www.ramsoft.com/ai-disclaimer">AI Disclaimer</a>.{' '}
					{t(
						'Please understand Our policies and procedures on the collection, use and disclosure of your personal information when You use the Services and tells You about your privacy rights and how the law protects You.'
					)}
				</p>
				<StrongText className="watched" id="feedback">
					{t('FEEDBACK')}
				</StrongText>
				<p>
					{t(
						'The Company shall have the right to use, without payment or restriction, any feedback that the User  provides in any format regarding the functionality and performance of Blume™ or the content of the Documentation (including identifying potential errors and improvements). The Company may also approach Users for feedback.'
					)}
				</p>
				<StrongText className="watched" id="prohibited-uses">
					{t('PROHIBITED USES')}
				</StrongText>
				<p>
					{t(
						'You must not use the Blume™ for any unlawful purpose, to violate any applicable laws or regulations, or to infringe on intellectual property rights. Prohibited activities include, but are not limited to: (a) soliciting unlawful acts; (b) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (c) to submit false or misleading information; (d) to upload or transmit viruses or any other type of malicious code; (e) to collect or track the personal information of others; (f) to spam, phish, pharm, pretext, spider, crawl, or scrape; (g) for any obscene or immoral purpose; or (h) to interfere with or circumvent the security features. We reserve the right to terminate your use of Blume™ or any related Company offering for violating any of the prohibited uses at our discretion.'
					)}
				</p>
				<StrongText className="watched" id="termination">
					{t('TERMINATION')}
				</StrongText>
				<p>
					{t(
						'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms and Conditions, or no reason. Upon termination, your right to use Blume™ and access will cease immediately. However, the Company may retain certain data for compliance with legal, regulatory, or security obligations  and in accordance with applicable data retention laws. Any questions must be sent in writing to'
					)}{' '}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.
				</p>

				<StrongText className="watched" id="limitation-of-liability">
					{t('LIMITATION OF LIABILITY')}
				</StrongText>
				<p>
					{t(
						'To the maximum extent permitted by law, the total liability of the Company and its Affiliates under these Terms shall be limited to the amount you actually paid for Blume™ in the last twelve (12) months. The Company, its Affiliates, and their officers, directors, employees, and agents shall not be liable for any special, incidental, indirect, exemplary, punitive, or consequential damages, including but not limited to loss of profits, data, business interruption, personal injury, or privacy breaches arising from or related to the use or inability to use Blume™, third-party software, or hardware, even if advised of the possibility of such damages. Your sole remedy for dissatisfaction with Blume™ is to discontinue use. The Company provides no indemnification.'
					)}
				</p>
				<StrongText className="watched" id="medical-decision-making-and-clinical-disclaimer">
					{t('MEDICAL DECISION MAKING AND CLINICAL DISCLAIMER')}
				</StrongText>
				<p>
					1.{' '}
					{t(
						'Medical Decisions: You acknowledge and agree that the Company is not engaged in the practice of medicine. The Company does not represent or warrant that the use of any of the Services offered is appropriate for treating or diagnosing any medical condition. Neither the Company, nor Blume™, makes any clinical, medical, or other professional decisions, and neither is it a substitute for licensed medical personnel applying their own professional judgment and analysis in every case. Blume™ is not designed for emergency medical concerns. If you are experiencing a medical emergency, call your healthcare provider or emergency services immediately. Do not use Blume™ for urgent or emergency communications.'
					)}
				</p>
				<p>
					2.{' '}
					{t(
						'Viewing Medical Images and Reports: The Blume application allows users to view medical images and reports as uploaded by their healthcare facility or physician. The Company does not create, modify, or verify the accuracy, completeness, or timeliness of these records. Users acknowledge that the content displayed in Blume is solely determined by their healthcare provider, and the Company bears no responsibility for the quality, accuracy, or availability of such content.'
					)}
				</p>
				<p>
					3.{' '}
					{t(
						'Appointment Scheduling: Users may schedule appointments through the Blume application. However, the Company does not manage, confirm, or guarantee the fulfillment of any scheduled appointment. If an appointment is canceled, rescheduled, or otherwise unavailable, the Company shall bear no liability for any resulting inconvenience, damages, or consequences.'
					)}
				</p>
				<p>
					4.{' '}
					{t(
						'Uploading and Sharing Healthcare Records: Users may upload medical images and documents and share their healthcare records with physicians and other recipients of their choosing. The accuracy of recipient details, including email addresses, is the sole responsibility of the user. The Company does not verify or control the recipients of shared records. Users acknowledge that any errors in entering recipient details may result in unauthorized access to their healthcare information. The Company is not responsible for any misuse, unauthorized disclosure, or unintended access resulting from user actions. Users share their healthcare information at their own risk.'
					)}
				</p>
				<p>
					5.{' '}
					{t(
						'Confidentiality and Security: While the Company takes reasonable measures to ensure data security, users acknowledge that electronic transmission and storage of healthcare records inherently carry risks. The Company shall not be liable for any unauthorized access, data breaches, or unintended disclosures resulting from user actions, including but not limited to incorrect recipient entries or sharing of sensitive information.'
					)}
				</p>
				<StrongText className="watched" id="dispute-resolution">
					{t('DISPUTE RESOLUTION')}
				</StrongText>
				<p>
					{t(
						'These Terms shall be governed by and construed in accordance with the substantive and procedural laws of Ontario, Canada, without regard to conflict of law principles. To the extent applicable, the laws of Canada shall also govern. Any disputes arising from or related to these Terms shall be subject to the exclusive jurisdiction of the courts in Ontario, Canada, and you consent to the personal jurisdiction of such courts. You waive any right to a jury trial in any proceeding related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods shall not apply.'
					)}
				</p>
				<StrongText className="watched" id="severability-and-waiver">
					{t('SEVERABILITY AND WAIVER')}
				</StrongText>
				<p className="watched" id="severability">
					<strong>{t('Severability')}</strong>
				</p>
				<p>
					{t(
						'If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law (unless that modification is not permitted by law, in which case that provision will be disregarded) and the remaining provisions will continue in full force and effect. Any unenforceable provision will continue to apply in all circumstances where it is enforceable.'
					)}
				</p>

				<p className="watched" id="waiver">
					<strong>{t('Waiver')}</strong>
				</p>
				<p>
					{t(
						'Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party’s ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.'
					)}
				</p>

				<StrongText className="watched" id="indemnification">
					{t('INDEMNIFICATION')}
				</StrongText>
				<p>
					{t(
						'You agree to indemnify and hold harmless the Company and its Affiliates, directors, officers, employees, and agents from any liabilities, losses, damages, or costs, including attorneys’ fees, arising from third-party claims related to your negligence or use of Blume™, or any willful misconduct. You also agree to indemnify the Company for any third-party claims arising from your use of the Services.'
					)}
				</p>

				<StrongText className="watched" id="confidentiality">
					{t('CONFIDENTIALITY')}
				</StrongText>
				<p>
					{t(
						'The parties agree to maintain the confidentiality of the Terms and all business-related information exchanged between them. Confidential information shall be used solely for the purpose of fulfilling these Terms or underlying agreements, if any, and shall not be disclosed to third parties without prior consent, except as reasonably required. This confidentiality obligation does not apply to information that is publicly available or becomes public through no breach of this Agreement, or to disclosures required by law, regulatory authorities, or auditors.'
					)}
				</p>

				<StrongText className="watched" id="translation-interpretation">
					{t('TRANSLATION INTERPRETATION')}
				</StrongText>
				<p>
					{t(
						'These Terms and Conditions may have been translated if we have made them available to you in such a translated format. You agree that the original English text shall prevail in the case of a dispute.'
					)}
				</p>

				<StrongText className="watched" id="changes-to-these-terms-and-conditions">
					{t('CHANGES TO THESE TERMS AND CONDITIONS')}
				</StrongText>
				<p>
					{t(
						'We reserve the right to modify or replace these Terms and Conditions at our discretion, with or without notice. If a revision is deemed material, we will make reasonable efforts to notify you at least 30 days before the new terms take effect. Whether a change is material will be determined at our sole discretion. By continuing to use the Service after the modified terms are in effect, you agree to be bound by them. If you do not agree with the new terms, you must stop using the Service. We encourage you to periodically review these Terms for updates.'
					)}
				</p>

				<StrongText className="watched" id="acceptance-of-these-terms">
					{t('ACCEPTANCE OF THESE TERMS')}
				</StrongText>
				<p>
					{t(
						'You acknowledge that you have read and agree to all its terms and conditions. By using Blume™, you agree to be bound by these Terms and any underlying agreement(s). If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access Blume™. These Terms and Conditions may not be transferred or assigned by you but may be assigned by us without restriction.'
					)}
				</p>

				<StrongText className="watched" id="contact-us">
					{t('CONTACT US')}
				</StrongText>
				<p>
					{t('If you have any questions about the Terms and Conditions, please contact us via email:')}{' '}
					<a href="mailto:legal@ramsoft.com">legal@ramsoft.com</a>.
				</p>
				<p>
					{t('For support related questions, please visit ')}
					<a href="https://www.ramsoft.com/support">
						RamSoft Support - 24/7 Help via Phone, Chat, SMS & More
					</a>{' '}
					{t('Depending the request type, validation of your identity may be required.')}
				</p>
				<strong>
					{t(
						'Note: Email is not a secure method of transfer for Protected Health Information (PHI) or Personally Identifiable Information (PII).'
					)}
				</strong>
				<StrongText style={{ height: 200 }} />
			</Box>
		</PolicyWrapper>
	);
};

const TermsModalBlume = ({ open, onClose }) => {
	const isTablet = useIsTablet();
	const { t } = useTranslation('term');
	const { i18n } = useTranslation();
	const [languageInitialized, setLanguageInitialized] = useState(false);

	function getQueryParamValue(paramName, url = window.location.href) {
		const queryString = url.split('#')[1] || url.split('?')[1] || '';
		const regex = new RegExp(`[?&]${paramName}=([^&#]*)`, 'i');
		const matches = regex.exec(queryString);

		if (matches && matches[1]) {
			return decodeURIComponent(matches[1]);
		}

		return '';
	}

	useEffect(() => {
		if (!languageInitialized) {
			const language = getQueryParamValue('languageSelected');

			if (language) {
				i18n.changeLanguage(language);
			}

			setLanguageInitialized(true); // State update happens after the language change
		}
	}, [languageInitialized, i18n]);
	return (
		<Modal
			BackdropComponent={() => (
				<BackDrop>
					<Box sx={mainBox}>
						<NoScrollbar style={sideStyle}>
							<IconButton
								sx={{
									position: 'absolute',
									top: 10,
									right: 20,
								}}
								onClick={onClose}
							>
								<CloseIcon sx={{ color: '#000' }} />
							</IconButton>
							<SideBarLinks />
						</NoScrollbar>
						<Box sx={boxStyle}>
							<Svg
								name="terms"
								style={{
									width: 75,
									height: 75,
									marginTop: isTablet ? 0 : 100,
								}}
								viewBox="-10 -10 75 75"
							/>
							<Box component="h3" sx={header}>
								{t('Terms of Service')}
							</Box>
							<TermsModalBlumeContent />
						</Box>
					</Box>
				</BackDrop>
			)}
			open={open}
			onClose={onClose}
		>
			<></>
		</Modal>
	);
};

export default TermsModalBlume;
export { SideBarLinks, TermsModalBlumeContent };
