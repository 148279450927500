import React, { useMemo, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DrawerTabSettings } from '../DrawerTabs';
import GenericDrawer from '../GenericDrawer';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';
import { useForm, Controller } from 'react-hook-form';
import fhirEndpoints from '@rs-core/fhir/fhirEndpoints';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param anchor - String: "right", "left", "up", "down"
 */
// Should include arg to pass in options for button and drawer contents?
// How to override default options
const AuditLogDrawer = ({
	onDataSubmit,
	anchor,
	name,
	columns,
	filters,
	sort,
	hideUserNameInput,
	drawerOpen,
	setDrawerOpen,
	...rest
}) => {
	const { t } = useTranslation('log');

	const [componentColumnList, setComponentColumnList] = useState([]);
	const [columnData, setColumnData] = useState({ columns, filters, sort });

	const drawerWidth = 487;

	const getFhirEndpointFromName = viewName => {
		let endpoint = '';
		if (viewName === 'Task Log') {
			endpoint = fhirEndpoints.task;
		} else if (viewName === 'Audit Log') {
			endpoint = fhirEndpoints.auditEvent;
		} else if (viewName === 'Activity History') {
			endpoint = fhirEndpoints.activityHistory;
		} else if (viewName === 'Fax Log') {
			endpoint = fhirEndpoints.fax;
		}

		return endpoint;
	};

	const { handleSubmit, control, reset } = useForm({
		defaultValues: useMemo(
			() => ({
				practitioner: {
					id: '1',
					reference: 'Practitioner/1',
					display: 'ADMIN',
				},
				columns,
			}),
			[columns]
		),
	});

	useEffect(() => {
		reset({
			columns,
		});
	}, [columns]);

	// store filter temporarily
	const updateTemporaryFilters = newColumnArray => {
		const newFilterArray = [];
		_.map(newColumnArray, element => {
			if (
				(_.isString(element.filter) && !_.isEmpty(element.filter)) ||
				(_.isArray(element.filter) && element.filter.filter(str => str != '').length > 0)
			) {
				let updatedValues = [];
				if (element.filterType === 'multi-select' || element.filterType === 'date-range') {
					updatedValues = element.filter;
				} else {
					updatedValues = [element.filter];
				}
				newFilterArray.push({
					label: element.label,
					columnIdentity: element.name,
					values: updatedValues,
					displayValue: element?.displayValue ? element.displayValue : updatedValues,
					capsuleMenuItems: element.capsuleMenuItems,
					filterType: element.filterType,
					toolTip: element.toolTip,
					valueSet: element.valueSet,
				});
			}
		});

		return newFilterArray;
	};

	const updateTemporarySort = newColumnArray => {
		const newSort = [];

		_.map(newColumnArray, column => {
			if (column.sort && column.sort != 'none') {
				newSort.push({
					sort: column.sort == 'asc' ? column.name : `-${column.name}`,
					sortOrder: column.sortOrder,
				});
			}
		});

		return _.orderBy(newSort, ['sortOrder'], ['asc']).map(sortObj => sortObj.sort);
	};

	const onSubmit = useCallback(() => {
		const tempColumnData = _.cloneDeep(columnData);
		if (columnData.columns) {
			tempColumnData.filters = updateTemporaryFilters(tempColumnData.columns);
			tempColumnData.sort = updateTemporarySort(tempColumnData.columns);
		}
		onDataSubmit(tempColumnData);
	}, [columnData]);

	const CancelSaveButtons = ({ closeDrawer }) => (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: 391,
				pb: '20px',
				px: '48px',
			}}
		>
			<SecondaryButton label={t('Cancel')} onClick={closeDrawer} />
			<PrimaryButton label={t('Save')} placement="top-left" onClick={handleSubmit(onSubmit)} />
		</Box>
	);

	return (
		<GenericDrawer
			anchor={anchor}
			drawerOpen={drawerOpen}
			setDrawerOpen={setDrawerOpen}
			title={t(`${name} Settings`)}
			width={drawerWidth}
			{...rest}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					control={control}
					name="columns"
					render={({ field }) => (
						<DrawerTabSettings
							{...field}
							columnBuilderHeight="calc(100vh - 300px)"
							componentColumnList={componentColumnList}
							endpoint={getFhirEndpointFromName(name)}
							filters={filters}
							isLarge={hideUserNameInput}
							setComponentColumnList={setComponentColumnList}
							userSavedColumnList={columns}
							onChange={data => {
								//tempdata.columns = data;
								setColumnData({ ...columnData, columns: data });
							}}
						/>
					)}
				/>
			</form>
			<CancelSaveButtons />
		</GenericDrawer>
	);
};

AuditLogDrawer.propTypes = {
	/**
	 * function to be called when user clicks save button on side panel
	 */
	onDataSubmit: PropTypes.func,
	/**
	 * name of the view, used to determine endpoint
	 */
	name: PropTypes.string,
	/**
	 * "right", "left", "up", "down"
	 */
	anchor: PropTypes.string,
	/**
	 * list of columns that will display by default
	 */
	columns: PropTypes.array,
	/**
	 * Array of filter objects
	 */
	filters: PropTypes.arrayOf(PropTypes.object),
};

export default AuditLogDrawer;
