import React from 'react';
import MobileList from '../MobileList/MobileList';
// import { useTranslation } from 'react-i18next';
// import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const MobileShareOptionsList = ({ user, onClick, isSharedStudy }) => {
	// const { t } = useTranslation('sharedHistory');
	// const phoenixBlumeRestrictHomeScreenFunctionality = useBooleanFlagValue(
	// 	'phoenix-blume-restrict-home-screen-functionality'
	// );

	const items = [];

	// HIDE UNSHARE OPTION
	// if (isSharedStudy && phoenixBlumeRestrictHomeScreenFunctionality) {
	// 	items.push({
	// 		primary: t('Unshare'),
	// 		onClick: () => onClick('Unshare', user.email),
	// 		sx: { borderTop: '1px solid rgba(18, 18, 18, 0.1)' },
	// 	});
	// }

	return <MobileList background="transparent" border="1px solid transparent" items={items} />;
};

export default MobileShareOptionsList;
