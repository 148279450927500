// core
import React from 'react';

// mui
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { Avatar as MuiAvatar } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// components
import Avatar from '../../Avatar';
import Grid from '@mui/material/Grid';

//libraries
import { Svg } from '@worklist-2/ui/src/components/Svg';
import { mediaTablet, useIsTablet } from '@rs-core/utils/responsiveUtils';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SideBarApps = ({ apps, isOpen, x, y, enableMarketplace, t: _t, onClose }) => {
	const proactHideMarketplace = useBooleanFlagValue('proact-hide-marketplace');
	const { globalPermissionsForLoggedUser } = useAuth();
	const { t } = useTranslation('sideMenu');
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const onClickApp = (appEndpoint, type) => {
		if (type == 'internal') {
			navigate(appEndpoint);
			onClose();
		} else {
			window.location.href = appEndpoint;
		}
	};
	const isTablet = useIsTablet();
	const canViewBlumeForm = globalPermissionsForLoggedUser?.BlumeForms;

	apps = apps ? apps.filter(app => app.hidden !== true) : [];

	const content = (
		<ClickAwayListener onClickAway={onClose}>
			<Paper
				elevation={20}
				sx={{
					position: 'fixed',
					left: isTablet ? x - 70 : x,
					bottom: isTablet ? y + 95 : y,
					flexDirection: 'column',
					display: 'flex',
					justifyContent: 'start',
					alignItems: 'center',
					width: 340,
					pt: '25px',
					pb: '20px',
					px: '26px',
					[mediaTablet]: {
						width: '190px',
						height: '77px',
						padding: '15px 30px',
						boxSizing: 'border-box',
						background: '#F3F3F3',
						borderRadius: '10px',
						justifyContent: 'center',
					},
				}}
			>
				<Box sx={{ width: '100%' }}>
					<Grid
						container
						item
						columnSpacing={{
							xs: isTablet ? 0 : 3,
							sm: isTablet ? 0 : 3,
							md: isTablet ? 0 : 3,
						}}
						data-cy="myApps"
						rowSpacing={2}
					>
						{apps.length === 0 ? (
							<Typography
								sx={{
									fontSize: '20px',
									color: '#121212',
									alignContent: 'center',
									marginLeft: '80px',
									lineHeight: 5.5,
								}}
							>
								{t('No Apps Available')}
							</Typography>
						) : (
							apps?.map((appItem, index) => (
								<Grid
									key={`appGrid_${index}`}
									container
									item
									md={isTablet ? 6 : 4}
									sm={isTablet ? 6 : 4}
									sx={{
										justifyContent: 'center',
										alignItems: 'center',
									}}
									xs={isTablet ? 6 : 4}
								>
									<IconButton
										sx={{
											height: 68,
											width: 68,
											marginBottom: '10px',
											position: 'relative',
											backgroundColor: 'icon.background',
											'&:hover': {
												backgroundColor: 'icon.background',
											},
											[mediaTablet]: {
												width: '30px',
												height: '30px',
												backgroundColor: 'unset',
												marginBottom: '5px',
											},
										}}
										onClick={() => {
											onClickApp(appItem.endpoint, appItem.type);
										}}
									>
										{appItem.name === 'Blume' && canViewBlumeForm && (
											<IconButton
												sx={{
													position: 'absolute',
													width: 26,
													height: 26,
													boxSizing: 'border-box',
													zIndex: 1,
													background: '#272727',
													right: -3,
													top: '5%',
													'&:hover': {
														background: '#272727',
													},
												}}
												onClick={e => {
													e.stopPropagation();
													navigate('/patient-form', { state: { previousPath: pathname } });
													onClose();
												}}
											>
												<SettingsOutlinedIcon
													sx={{
														width: '17px',
														height: '17px',
														margin: '1px 1px 2px 2px',
													}}
												/>
											</IconButton>
										)}
										{appItem.svg ? (
											<Svg
												name={appItem.svg}
												sx={{
													height: 40,
													width: 40,
													[mediaTablet]: {
														width: '30px',
														height: '30px',
													},
												}}
												viewBox={appItem.viewBox}
											/>
										) : appItem.logo ? (
											<MuiAvatar
												src={appItem.logo}
												sx={{
													width: 68,
													height: 68,
													[mediaTablet]: {
														width: '30px',
														height: '30px',
													},
												}}
											/>
										) : (
											<Avatar label={appItem.name} />
										)}
									</IconButton>
									<Typography
										noWrap
										sx={{
											[mediaTablet]: {
												fontSize: '10px',
												lineHeight: '10px',
												color: '#121212',
											},
										}}
									>
										{appItem.name}
									</Typography>
								</Grid>
							))
						)}
					</Grid>
				</Box>
				{enableMarketplace ? (
					!proactHideMarketplace ? (
						<>
							<Box sx={{ pt: '25px', pb: '25px' }}>
								<Divider
									sx={{
										width: 380,
									}}
								/>
							</Box>

							<Typography>
								<Link
									data-cy="marketPlaceLink"
									sx={{
										color: 'rsPrimary.main',
										textDecoration: 'none',
										cursor: 'pointer',
										textTransform: 'uppercase',
									}}
									onClick={() => {
										onClose();
										navigate('/marketplace');
									}}
								>
									{_t('moreMarketplace')}
								</Link>
							</Typography>
						</>
					) : null
				) : null}
			</Paper>
		</ClickAwayListener>
	);

	return (
		<Popper open={isOpen} sx={{ zIndex: 999 }} onClose={onClose}>
			{content}
		</Popper>
	);
};
SideBarApps.propTypes = {
	/**
	 * List of apps shown on popup menu.
	 */
	apps: PropTypes.array,
	/**
	 * Determine whether or not the popup window is open.
	 */
	isOpen: PropTypes.bool,

	/**
	 * x-coordinate of where the popup window should appear
	 */
	x: PropTypes.number,

	/**
	 * y-coordinate of where the popup window should appear
	 */
	y: PropTypes.number,

	/**
	 * Whether or not the Marketplace link is enabled
	 */
	enableMarketplace: PropTypes.bool,

	/**
	 * callback handler for when the popup window closes due to clicking away
	 */
	onClose: PropTypes.func,
};
export default SideBarApps;
