import React, { useCallback, useState } from 'react';
import useMeasure from 'react-use-measure';

import Box from '@mui/material/Box';
import AvatarGroup from '@mui/material/AvatarGroup';

import { useShareDrawerStore } from '@worklist-2/patientPortal/src/stores/share-drawer';
import { mediaTablet, mediaMobile } from '@rs-core/utils/responsiveUtils';

import SharedAvatar from '@worklist-2/patientPortal/src/components/SharedHistory/SharedAvatar/SharedAvatar';
import SharedAvatarMobile from '@worklist-2/patientPortal/src/components/SharedHistory/SharedAvatarMobile/SharedAvatarMobile';

const commonStyle = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	overflow: 'hidden',
	minWidth: '130px',
	position: 'relative',
	scrollbarWidth: 'none',
	msOverflowStyle: 'none',
	'&::-webkit-scrollbar': {
		display: 'none',
	},
};

const mobileStyle = {
	flexDirection: 'row',
	alignItems: 'flex-start',
	backgroundColor: 'transparent',
	paddingTop: 0,
	overflow: 'auto',
};

const rowStyle = {
	...commonStyle,
	borderRadius: '0px',
	paddingBottom: 0,
	[mediaTablet]: {
		...mobileStyle,
		height: 'unset',
	},
	[mediaMobile]: {
		...mobileStyle,
		height: 'unset',
	},
};

const SharedUsers = ({ matches, users, study }) => {
	const initStudyDrawer = useShareDrawerStore(state => state.initStudyDrawer);

	const [anchorEl, setAnchorEl] = useState(null);
	const [measureRef, measuredBounds] = useMeasure();

	const maxUserCount = Math.floor(measuredBounds.width / 105);

	return (
		<Box ref={measureRef} className="shareUserList">
			<Box sx={rowStyle}>
				<AvatarGroup
					max={maxUserCount}
					sx={{
						width: '100%',
						height: '100%',
						justifyContent: 'flex-end',
						'& .MuiAvatarGroup-avatar': {
							backgroundColor: '#E3B269',
							width: '98px',
							height: '98px',
							borderRadius: '10px',
							ml: '5px !important',
						},
						'.MuiAvatar-root': {
							border: 0,
							marginLeft: 0,
						},
					}}
					variant="rounded"
					onClick={e => {
						initStudyDrawer({ study });
						setAnchorEl(anchorEl ? null : e.currentTarget);
					}}
				>
					{users.map(user =>
						matches ? (
							<SharedAvatarMobile
								key={user?.shareType === 'Facility' ? user?.facilityId : user.email}
								avatar={user.avatar}
								name={user.name}
							/>
						) : (
							<SharedAvatar
								key={user?.shareType === 'Facility' ? user?.facilityId : user.email}
								avatar={user.avatar}
								isFacility={user?.shareType === 'Facility'}
								name={user.name}
							/>
						)
					)}
				</AvatarGroup>
			</Box>
		</Box>
	);
};

export default SharedUsers;
