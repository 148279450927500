import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Icon, IconButton, Tooltip, Box, Skeleton } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import axios from 'axios';

// eslint-disable-next-line import/no-unresolved
import { useConfig } from '@rs-core/context/ConfigContext';
// eslint-disable-next-line import/no-unresolved
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useStudiesStore } from '@worklist-2/patientPortal/src/stores';
import sendAnalyticsEvent from '@worklist-2/patientPortal/src/analytics';
import downloadIcon from '@worklist-2/ui/src/assets/icons/imageViewer/topToolbar/download-round.svg';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import MobileStudyDetailCompleted from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailCompleted';
import MobileStudyDetailScheduled from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailScheduled';
import { STUDY_STATUS, STUDY_DOWNLOAD } from '@worklist-2/patientPortal/src/consts';
import useStudyQuery from '@worklist-2/patientPortal/src/queries/useStudyQuery';

import { STUDY_DATA } from '../../../__mocks__/mockData';

const MobileStudyDetail = ({ data, onShareClick, fullScreenMode, isShared }) => {
	const downloadStudy = useStudiesStore(state => state.downloadStudy);
	const studyDownloadStatus = useStudiesStore(useShallow(state => state.studyDownloadStatus));
	const [study, setStudy] = useState();
	const [studyAdditionalInfo, setStudyAdditionalInfo] = useState();
	const [isOldLoading, setIsOldLoading] = useState(true);
	const [menuOpen, setMenuOpen] = useState(false);

	const { data: _study, isLoading } = useStudyQuery({ data, isShared, studyId: data?.study?.id });
	const { t } = useTranslation('studyDetail');
	const phoenixBlumeMobileStudyDetailOptimization = useBooleanFlagValue(
		'phoenix-blume-mobile-study-detail-optimization'
	);
	const { setToastMsg } = useToastMessageContext();
	const __config = useConfig();

	const isStudyDownloading = useMemo(() => studyDownloadStatus[study?.id]?.loading, [study?.id, studyDownloadStatus]);

	useEffect(() => {
		if (!data || phoenixBlumeMobileStudyDetailOptimization) return null;

		loadStudy();
	}, [data, loadStudy, phoenixBlumeMobileStudyDetailOptimization]);

	const loadStudy = useCallback(async () => {
		const studyData = STUDY_DATA.filter(e => e.id === data?.study.id);

		if (studyData && studyData.length > 0) {
			setStudyAdditionalInfo({
				isShared: '',
				permission: '',
			});
			setStudy(studyData[0]);
		} else {
			const response = await axios.get(`${__config.data_sources.blume}Study?id=${data?.study.id}`);
			let studyPermission = '';
			if (!data?.study?.permission && isShared === 'withMe') {
				const email = sessionStorage.getItem('login_hint');
				const filteredContact = response.data.sharedInformation?.filter(contact => contact.email === email);
				studyPermission = filteredContact?.length > 0 ? filteredContact[0].permission : '';
			}
			setStudyAdditionalInfo({
				isShared: isShared || '',
				permission: data?.study?.permission || studyPermission,
			});
			setStudy(response.data);
		}

		setIsOldLoading(false);
	}, [__config.data_sources.blume, data?.study.id, data?.study?.permission, isShared]);

	const handleStudyDownload = async () => {
		const currStudy = phoenixBlumeMobileStudyDetailOptimization ? _study.study : study;

		await downloadStudy({
			study: currStudy,
			setToastMsg,
			t,
		});

		sendAnalyticsEvent(STUDY_DOWNLOAD, { studyId: currStudy, studyDescription: currStudy.description });
	};

	if (phoenixBlumeMobileStudyDetailOptimization ? isLoading : isOldLoading) {
		return (
			<Skeleton
				data-testid="mobile-study-detail-loader"
				sx={{ height: 600, borderRadius: '10px' }}
				variant="rectangular"
			/>
		);
	}

	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', margin: '0px 10px 10px 0px' }}>
				<Box
					data-testid="menu-button"
					sx={STYLES.ILAND_BOX(menuOpen)}
					onClick={() => setMenuOpen(bool => !bool)}
				>
					<Tooltip placement="bottom" title="Download Study">
						<IconButton
							data-testid="download-study"
							disabled={isStudyDownloading}
							sx={STYLES.ILAND_BUTTON(menuOpen)}
							onClick={handleStudyDownload}
						>
							<Icon component={downloadIcon} sx={STYLES.ILAND_ICON} />
						</IconButton>
					</Tooltip>
					{isStudyDownloading ? (
						<CircularProgress color={menuOpen ? 'primary' : 'info'} size={25} sx={{ mx: 0.5 }} />
					) : (
						<IconButton
							sx={{
								border: !menuOpen && '3px solid  #818181',
								height: '35px',
								width: '35px',
								color: menuOpen ? '#d1d1d1' : '#818181',
							}}
						>
							<MoreVert />
						</IconButton>
					)}
				</Box>
			</Box>
			{(
				phoenixBlumeMobileStudyDetailOptimization
					? _study.study?.status === STUDY_STATUS.SCHEDULED
					: study?.status === STUDY_STATUS.SCHEDULED
			) ? (
				<MobileStudyDetailScheduled
					appointmentDate={data?.appointmentDate || null}
					study={phoenixBlumeMobileStudyDetailOptimization ? _study.study : study}
					studyAdditionalInfo={
						phoenixBlumeMobileStudyDetailOptimization ? _study.studyAdditionalInfo : studyAdditionalInfo
					}
					onShareClick={onShareClick}
				/>
			) : (
				<MobileStudyDetailCompleted
					fullScreenMode={fullScreenMode}
					study={phoenixBlumeMobileStudyDetailOptimization ? _study.study : study}
					studyAdditionalInfo={
						phoenixBlumeMobileStudyDetailOptimization ? _study.studyAdditionalInfo : studyAdditionalInfo
					}
					onShareClick={onShareClick}
				/>
			)}
		</>
	);
};

export default MobileStudyDetail;

const STYLES = {
	ILAND_BOX: open => ({
		height: !open ? '35px' : '43px',
		width: open ? '80px' : '35px',
		borderRadius: open ? '80px' : '100px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: open && '#5d5d5d !important',
	}),
	ILAND_BUTTON: open => ({
		height: '35px',
		width: '35px',
		display: open ? 'block' : 'none',
		padding: '0px',
		marginLeft: '3px',
	}),
	ILAND_ICON: {
		boxSizing: 'border-box',
		flex: 'none',
		height: '35px',
		width: 'auto',
	},
};
