// Core
import React, { useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';

// MUI
import { Collapse, Grid, Icon, IconButton, Stack, Typography, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

//Utils
import { useIsTablet } from '@rs-core/utils/responsiveUtils';
import { useAuth } from '@rs-core/context/UserAuthContext';

// Custom
import { useTranslation } from 'react-i18next';
import { useChatContext } from './ChatContext';
import { ChatAvatarBadge, CHAT_AVATAR_SIZE } from './ChatAvatar';
import { CHAT_CONTACT_SPACING } from './ChatContact';
import ChatEmpty from './ChatEmpty';
import ChatList, {
	ChatListContainer,
	ChatListHeader,
	ChatListUnread,
	ChatListDatetime,
	ChatListContact,
	ChatListItem,
	ChatListMenu,
	ChatListCallItem,
} from './ChatList';
import ChatConversationDelete from './ChatConversationDelete';
import ChatSearch from './ChatSearch';
import { ChatHeading2, ChatHeading3 } from './ChatHeading';
import CHAT_SECTIONS, { CHAT_SECTIONS_titles } from './CHAT_SECTIONS';
import CHAT_CONVERSATION_TYPES from './CHAT_CONVERSATION_TYPES';

import noChatsIcon from '@worklist-2/ui/src/assets/img/empty/chats.svg';
import HelpIcon from '@worklist-2/ui/src/assets/icons/Chat/help.svg';
import WriteIcon from '@worklist-2/ui/src/assets/icons/Chat/write.svg';
import BlumeIcon from '@worklist-2/ui/src/assets/icons/Chat/blume.svg';
import ChatNoDataAvailable from './ChatNoDataAvailable';
import EmptyIllustration from '@worklist-2/patientPortal/src/components/NoDataAvailable/EmptyIllustration';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// List Item component

const ChatMainListItem = ({ data, current, menuItems, onMouseDown }) => {
	const { id } = data;

	return (
		<ChatListItem
			current={current}
			innerSx={{
				gap: '10px',
			}}
			sx={{
				'--element-border-offset-left': `${CHAT_AVATAR_SIZE + CHAT_CONTACT_SPACING}px`,

				'& .chat-list-menu-button': {
					opacity: 0,
				},

				'&:hover .chat-list-menu-button, &:focus-within .chat-list-menu-button, & .chat-list-menu-button--open':
					{
						opacity: 1,
					},
			}}
			onMouseDown={onMouseDown}
		>
			<ChatListContact
				isOnline={data.participantOnline}
				text={data.text}
				title={
					data.name?.length > 0
						? data.name
						: data.conversation?.attributes?.participantsLeft?.length == 1
						? data.conversation?.attributes?.participantsLeft[0].name
						: data.email
				}
				userCount={data.participantCount}
			/>

			<Stack alignItems="flex-end">
				{menuItems !== CHAT_SECTIONS.SUPPORT && (
					<Stack alignItems="center" direction="row" gap="10px">
						{data.datetime ? <ChatListDatetime value={data.datetime} /> : null}

						{data.isBlumeConversation ? (
							<Icon
								component={BlumeIcon}
								sx={{
									flex: 'none',
									height: '14px',
									width: '14px',
								}}
							/>
						) : null}

						{data.unread ? <ChatListUnread /> : null}
					</Stack>
				)}

				{Array.isArray(menuItems) ? (
					<ChatListMenu
						buttonId={`chat-list-item-menu-button-${id}`}
						iconButtonSx={{
							marginRight: '-5px',
							padding: 0,
						}}
						id={`chat-list-item-menu-${id}`}
						items={menuItems}
						onMouseDown={event => {
							event.stopPropagation();
						}}
					/>
				) : menuItems === CHAT_SECTIONS.SUPPORT ? (
					<ChatConversationDelete conversation={data.conversation} />
				) : null}
			</Stack>
		</ChatListItem>
	);
};

ChatMainListItem.propTypes = {
	data: PropTypes.object.isRequired,
	current: PropTypes.bool,
	menuItems: PropTypes.array,
	onClick: PropTypes.func,
};

const ChatMainEmpty = ({ searchString }) => {
	const { t } = useTranslation('chat');
	return (
		<ChatEmpty
			searchString={searchString}
			sx={{
				flex: 'auto',
				padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
			}}
		>
			<Typography
				component="div"
				sx={{
					color: 'var(--element-foreground-color)',
					fontSize: '20px',
					marginTop: '40px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{searchString ? `${t('chatSearch.noResult')} '${searchString}'` : t('chatMain.noChatInTheList')}
			</Typography>

			<Typography
				component="div"
				sx={{
					color: 'var(--element-foreground-color-secondary)',
					fontSize: '12px',
					marginTop: '5px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{searchString ? t('chatSearch.searchForAnotherTerm') : t('chatMain.startNewChat')}
			</Typography>
		</ChatEmpty>
	);
};

ChatMainEmpty.propTypes = {
	searchString: PropTypes.string,
};

// Component

const ChatMain = ({ className, sx, headerChildren = null, setIsClickAction }) => {
	const { t } = useTranslation('chat');
	const {
		state,
		setSection,
		showNavigation,
		showSearch,
		showHelp,
		search,
		expanded,
		showLoading,
		dispatch,
		isSupportClientInitialized,
		section,
		callData,
		setCallData,
		setConversationType,
	} = useChatContext();

	const proactEnableDeleteChatUac = useBooleanFlagValue('proact-enable-delete-chat-uac');

	const [helpTextVisible, setHelpTextVisible] = React.useState(false);
	const { setChatMsgReceived, chatMsgReceived, loggedInUser, globalPermissionsForLoggedUser } = useAuth();
	const previousConversation = React.useRef(null);
	const classList = ['chat-main', className].filter(Boolean);
	const [filterByUnRead, setFilterByUnRead] = React.useState(false);
	const [unReadConversationList, setUnReadConversationList] = React.useState([]);

	const canDeleteChat = globalPermissionsForLoggedUser?.Chat;

	const header = showNavigation || showSearch || headerChildren;
	const isTablet = useIsTablet();
	const filterResults = conversationType => {
		let sortedList = null;
		if (!!search && !!search.main?.searchString) {
			const filteredList = _.pickBy(
				state,
				value =>
					_.includes(value?.name?.toLowerCase(), search?.main?.searchString?.toLowerCase()) ||
					_.includes(value?.email?.toLowerCase(), search?.main?.searchString?.toLowerCase()) ||
					value?.messages?.some(message =>
						message?.state?.body?.toLowerCase().includes(search?.main?.searchString?.toLowerCase())
					)
			);
			sortedList = _.orderBy(filteredList, 'datetime', 'desc');
		} else {
			sortedList = _.orderBy(state, 'datetime', 'desc');
		}

		// Fix PRO-2862 - when the Blume user signs in OAI, the conversations between OAI and Blume users created by the OAI user should not be in the list
		return sortedList?.filter(item =>
			conversationType === 'OAI'
				? !(item.isSupportConversation || item.isBlumeConversation || item.isSupportConversation)
				: conversationType === 'blume'
				? item.isBlumeConversation &&
				  loggedInUser?.email?.toLowerCase() === item?.conversation?.createdBy?.toLowerCase()
				: conversationType === 'support'
				? item.isSupportConversation
				: []
		);
	};

	const doctors = [];
	const imagingCenter = [];
	const groups = [];

	const empty = Object.keys(state).length === 0;
	const conversationList = Object.keys(state).length == 0 ? [] : filterResults('OAI');
	const blumeConversations = Object.keys(state).length == 0 ? [] : filterResults('blume');
	const supportConversations = Object.keys(state).length == 0 ? [] : filterResults('support');

	const handleConversationStatusUpdate = async (conversation, unread) => {
		if (conversation != null) {
			if (unread) {
				await conversation.setAllMessagesRead();
				dispatch({
					msgtype: 'MESSAGE_READ',
					payload: conversation,
				});
			} else {
				await conversation.setAllMessagesUnread();
				// The setAllMessagesUnread() method sets unread messages count to null and not 1 or the number or recent messages recieved
				// and hence the UI for unread messages doesn't work when toggled using the pop up
				// Have made a work around for this in ChatContext.jsx, whenever getUnreadMessagesCount() method returns null, we are setting it to 1
				dispatch({
					msgtype: 'MESSAGE_UNREAD',
					payload: conversation,
				});
			}
		}
	};

	const filterUnreadConversation = () => {
		if (!filterByUnRead) {
			setFilterByUnRead(true);
			setSection({ name: CHAT_SECTIONS.UNREAD });
		} else {
			setFilterByUnRead(false);
			setSection({ name: CHAT_SECTIONS.MAIN });
		}
	};

	const handleConversationDelete = async conversation => {
		if (conversation) {
			const participantCount = await conversation.getParticipantsCount();
			if (participantCount > 1) {
				await conversation.leave();
			} else {
				// If this is the last participant who leaves the conversation, delete the conversation
				await conversation.delete();
			}

			dispatch({
				msgtype: 'CONVERSATION_LEFT',
				payload: conversation,
			});
		}
	};

	const handleChatWithSupportClick = () => {
		if (supportConversations.length > 0) {
			// Open the support conversation if it exists
			setSection({
				name: CHAT_SECTIONS.SUPPORT,
				conversation: supportConversations[0].conversation,
			});
		} else {
			// Open Chat With Support screen for creating a new support conversation
			setSection({
				name: CHAT_SECTIONS.SUPPORT,
			});
		}
	};

	const getMenuItems = item => {
		let itemsArray = [
			{
				text: `${item.unread ? t('chatMain.markAsRead') : t('chatMain.markAsUnread')}`,
				onMouseDown: event => handleConversationStatusUpdate(item.conversation, item.unread),
			},
		];

		if (proactEnableDeleteChatUac) {
			if (canDeleteChat) {
				itemsArray = [
					{
						text: t('chatMain.deleteChat'),
						onMouseDown: event => handleConversationDelete(item.conversation),
					},
					...itemsArray,
				];
			}
		} else {
			itemsArray = [
				{
					text: t('chatMain.deleteChat'),
					onMouseDown: event => handleConversationDelete(item.conversation),
				},
				...itemsArray,
			];
		}

		return itemsArray;
	};

	const renderConversationRow = (item, index, sectionName) =>
		item && !item.isAiChat ? (
			<ChatMainListItem
				key={index}
				data={item}
				menuItems={sectionName !== CHAT_SECTIONS.SUPPORT ? getMenuItems(item) : sectionName}
				onMouseDown={() => {
					setSection({
						name: sectionName,
						conversation: item.conversation,
					});
					checkAndSetConversationType(item);
				}}
			/>
		) : null;

	const checkAndSetConversationType = conversationItem => {
		callData?.isCall && callData?.conversation?.sid === conversationItem?.conversation?.sid
			? setConversationType(CHAT_CONVERSATION_TYPES.CALL)
			: setConversationType(CHAT_CONVERSATION_TYPES.MESSAGING);
	};

	useEffect(() => {
		// Show/hide blue dot on the chat icon on feature bar
		if (Object.entries(state).some(item => item[1].unread)) {
			!chatMsgReceived && setChatMsgReceived(true);
		} else {
			chatMsgReceived && setChatMsgReceived(false);
		}
	}, [state, chatMsgReceived]);

	useEffect(() => {
		if (filterByUnRead) {
			const unReadconversation = conversationList.filter(value => {
				if (value?.unread === true) {
					return value;
				}
			});
			setUnReadConversationList(unReadconversation);
		}
	}, [filterByUnRead]);

	// Delete if idle conversation
	useEffect(() => {
		async function asynchronousEffect() {
			if (expanded && previousConversation.current === null && section.conversation != null) {
				previousConversation.current = section.conversation;
			}

			if (previousConversation.current != null && previousConversation.current.sid != section.conversation?.sid) {
				const count = await previousConversation.current.getMessagesCount();
				if (expanded) {
					if (count === 0) {
						dispatch({
							msgtype: 'CONVERSATION_LEFT',
							payload: previousConversation.current,
						});
						previousConversation.current.delete();
						// Set conversation detail to be the first one on list
						const key = Object.keys(state)[1];
						setSection({
							name: section.name,
							conversation: state[key].conversation,
						});
						previousConversation.current = null;
					} else {
						previousConversation.current = section.conversation;
					}
				}
			} else {
				previousConversation.current = section.conversation;
			}
		}

		asynchronousEffect();
	}, [section]);

	useEffect(() => {
		setCallData(prev => {
			if (!callData?.conversation && callData?.callNotification) {
				const filteredConversation = Object.entries(state).filter(
					item => item[1]?.conversation?.sid === callData?.callNotification?.conversationSid
				);

				prev.conversation = filteredConversation.length > 0 ? filteredConversation[0][1]?.conversation : null;
			}

			return { ...prev };
		});
	}, [callData?.callNotification]);

	return (
		<Grid
			className={classList.join(' ')}
			flex="auto"
			sx={{
				display: 'grid',
				gridTemplateRows: header ? 'min-content 1fr' : '100%',
				position: 'relative',
				...sx,
			}}
		>
			{header ? (
				<Stack>
					{showNavigation ? (
						<Stack
							alignItems="center"
							direction="row"
							gap="10px"
							sx={{
								padding:
									'var(--element-content-spacing) var(--element-content-spacing-right) 5px var(--element-content-spacing-left)',
							}}
						>
							<ChatHeading2 sx={{ flex: 'auto' }}>
								{filterByUnRead
									? t(CHAT_SECTIONS_titles[CHAT_SECTIONS.UNREAD])
									: t(CHAT_SECTIONS_titles[CHAT_SECTIONS.MAIN])}
							</ChatHeading2>
							<Tooltip title={t('icon.newMessage')}>
								<IconButton
									sx={{ flex: 'none' }}
									onClick={() => setSection({ name: CHAT_SECTIONS.NEW })}
								>
									<Icon
										component={WriteIcon}
										sx={{
											boxSizing: 'border-box',
											flex: 'none',
											height: '24px',
											padding: '3px 0 0 5px',
											width: '24px',
										}}
									/>
								</IconButton>
							</Tooltip>
						</Stack>
					) : null}

					{showSearch ? (
						<ChatSearch
							isMain
							filter={Boolean(
								conversationList?.length > 0 ||
									blumeConversations?.length > 0 ||
									supportConversations?.length > 0
							)}
							filterByUnRead={filterByUnRead}
							filterUnreadConversation={filterUnreadConversation}
							placeholder={t('chatSearch.searchPlaceholder')}
							t={t}
						/>
					) : null}
					{headerChildren}
				</Stack>
			) : null}
			<ChatListContainer
				sx={{
					overflow: 'hidden',
					paddingBottom: 'var(--element-content-spacing)',
				}}
			>
				{!empty || callData?.status?.onCall ? (
					<ChatList sx={showHelp ? { paddingBottom: '40px' } : undefined}>
						{(callData?.status?.onCall || callData?.status?.calling || callData?.status?.incoming) && (
							<ChatListCallItem
								setIsClickAction={setIsClickAction}
								onMouseDown={() => {
									setSection({
										name: CHAT_SECTIONS.CONVERSATION,
										conversation: callData?.conversation ?? null,
									});
									setConversationType(CHAT_CONVERSATION_TYPES.CALL);
								}}
							/>
						)}
						<ChatListHeader>
							<ChatHeading3>{t('chatMain.myContactList')}</ChatHeading3>
						</ChatListHeader>
						{state != null &&
							conversationList != null &&
							Object.keys(conversationList).length > 0 &&
							Object.entries(!filterByUnRead ? conversationList : unReadConversationList).map(
								(item, index) => renderConversationRow(item[1], index, CHAT_SECTIONS.CONVERSATION)
							)}
						{conversationList?.length === 0 &&
							blumeConversations?.length === 0 &&
							supportConversations?.length === 0 && (
								<ChatMainEmpty searchString={search?.main?.searchString} />
							)}
						{filterByUnRead && unReadConversationList.length === 0 && (
							<ChatNoDataAvailable
								desktopHeight="650px"
								message={t('chatMain.noUnreadMessages')}
								mobileHeight="450px"
							/>
						)}

						{doctors.length > 0 && (
							<ChatListHeader>
								<ChatHeading3>{t('chatMain.doctors')}</ChatHeading3>
							</ChatListHeader>
						)}

						{doctors.map((item, index) => (
							<ChatMainListItem
								key={index}
								data={item}
								menuItems={[{ text: t('chatMain.deleteChat') }, { text: t('chatMain.markAsUnread') }]}
								onMouseDown={() => {
									setSection({
										name: CHAT_SECTIONS.CONVERSATION,
									});
									checkAndSetConversationType(item);
								}}
							/>
						))}

						{imagingCenter.length > 0 && (
							<ChatListHeader>
								<ChatHeading3>{t('chatMain.imagingCenter')}</ChatHeading3>
							</ChatListHeader>
						)}

						{imagingCenter.map((item, index) => (
							<ChatMainListItem
								key={index}
								data={item}
								menuItems={[{ text: t('chatMain.deleteChat') }, { text: t('chatMain.markAsUnread') }]}
								onMouseDown={() => {
									setSection({
										name: CHAT_SECTIONS.CONVERSATION,
									});
									checkAndSetConversationType(item);
								}}
							/>
						))}

						{groups.length > 0 && (
							<ChatListHeader>
								<ChatHeading3>{t('chatMain.allGroups')}</ChatHeading3>
							</ChatListHeader>
						)}

						{groups.map((item, index) => (
							<ChatMainListItem
								key={index}
								data={item}
								menuItems={[
									{ text: t('chatMain.leaveTheGroup') },
									{ text: t('chatMain.markAsUnread') },
								]}
								onMouseDown={() => {
									setSection({
										name: CHAT_SECTIONS.CONVERSATION,
										group: true,
									});
									checkAndSetConversationType(item);
								}}
							/>
						))}
						{blumeConversations?.length > 0 && (
							<>
								<ChatListHeader>
									<ChatHeading3>{t('chatMain.patients')}</ChatHeading3>
								</ChatListHeader>
								{Object.entries(blumeConversations).map((item, index) =>
									renderConversationRow(item[1], index, CHAT_SECTIONS.CONVERSATION)
								)}
							</>
						)}
						{supportConversations?.length > 0 && (
							<>
								<ChatListHeader>
									<ChatHeading3>{t('chatMain.support')}</ChatHeading3>
								</ChatListHeader>
								{Object.entries(supportConversations).map((item, index) =>
									renderConversationRow(item[1], index, CHAT_SECTIONS.SUPPORT)
								)}
							</>
						)}
					</ChatList>
				) : showLoading === false ? (
					<EmptyIllustration
						description={t('Start a new chat.')}
						image={noChatsIcon}
						imageHeight="210px"
						marginTop="150px"
						title={t('No chat in the list')}
					/>
				) : (
					<Skeleton
						sx={{
							height: '100%',
							borderRadius: '10px',
							marginX: isTablet ? undefined : '20px',
						}}
						variant="rectangular"
					/>
				)}

				{showHelp ? (
					<Stack
						alignItems="center"
						className="chat-main-help"
						direction="row"
						role="button"
						sx={{
							background: 'var(--element-background-color)',
							bottom: 'var(--element-content-spacing)',
							borderRadius: '20px',
							cursor: 'pointer',
							height: '40px',
							position: 'absolute',
							right: 'var(--element-content-spacing)',
							zIndex: 2,
						}}
						tabIndex={0}
						onBlur={() => setHelpTextVisible(false)}
						onClick={handleChatWithSupportClick}
						onFocus={() => setHelpTextVisible(true)}
						onMouseEnter={() => setHelpTextVisible(true)}
						onMouseLeave={() => setHelpTextVisible(false)}
					>
						<Collapse
							in={helpTextVisible}
							orientation="horizontal"
							sx={{
								overflow: 'hidden',
							}}
							onClick={() =>
								setSection({
									name: CHAT_SECTIONS.CONVERSATION,
									help: true,
								})
							}
						>
							<Typography
								sx={{
									color: 'var(--element-foreground-color)',
									fontSize: '11.5px',
									padding: '0 10px 0 20px',
									whiteSpace: 'nowrap',
								}}
							>
								{t('chatWithSupport.helpText1')}
								<br />
								{t('chatWithSupport.helpText2')}
							</Typography>
						</Collapse>
						{isSupportClientInitialized && (
							<IconButton
								data-testid="chat-with-support-button"
								sx={{
									'--element-background-color': 'var(--color-primary)',
									alignItems: 'stretch',
									background: 'var(--element-background-color) !important',
									color: '#ffffff',
									height: '40px',
									justifyContent: 'stretch',
									padding: 0,
									width: '40px',

									'&:hover, &:active': {
										'--element-background-color': 'var(--color-primary)',
									},
								}}
								tabIndex={-1}
							>
								<ChatAvatarBadge
									isOnline
									sx={{
										alignItems: 'center',
										height: '100%',
										justifyContent: 'center',
										width: '100%',
									}}
								>
									<Icon component={HelpIcon} sx={{ height: '16px', width: '18px' }} />
								</ChatAvatarBadge>
							</IconButton>
						)}
					</Stack>
				) : null}
			</ChatListContainer>
		</Grid>
	);
};

ChatMain.propTypes = {
	className: PropTypes.string,
	sx: PropTypes.object,
	headerChildren: PropTypes.node,
	setIsClickAction: PropTypes.func,
};

export default ChatMain;
