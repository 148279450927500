import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import getStudyReports from '@worklist-2/patientPortal/src/api/getStudyReports';
// eslint-disable-next-line import/no-unresolved
import { useConfig } from '@rs-core/context/ConfigContext';
// eslint-disable-next-line import/no-unresolved
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// eslint-disable-next-line import/extensions
import { REPORT_DATA } from '../__mocks__/mockData.js';

const fetchReports = async (studyId, __config) => {
	if (_.has(REPORT_DATA, studyId)) {
		return REPORT_DATA[studyId];
	}

	const result = await getStudyReports({ __config, id: studyId });
	if (!result?.entry) return [];

	return result.entry.map((elem, index) => ({
		id: index,
		selected: false,
		reportName: elem?.resource?.presentedForm[0]?.title || '',
		doctorName: elem?.resource?.resultsInterpreter?.[0]?.display || '',
		reportPath: `${__config.data_sources.blume}Study/report/${elem?.resource?.id}/content`,
		reportId: elem?.resource?.id || '',
	}));
};

const useStudyReportsQuery = ({ studyId }) => {
	const __config = useConfig();
	const phoenixBlumeMobileStudyDetailOptimization = useBooleanFlagValue(
		'phoenix-blume-mobile-study-detail-optimization'
	);

	return useQuery(['studyReports', studyId], () => fetchReports(studyId, __config), {
		enabled: phoenixBlumeMobileStudyDetailOptimization && !!studyId,
	});
};

export default useStudyReportsQuery;
