import { useContext } from 'react';
import { HelpCenterContext } from '../context/HelpCenterContext';

const useHelpCenter = () => {
	const helpCenterContext = useContext(HelpCenterContext);
	const {
		searchQuestion,
		handleQuestionChange,
		searchResults,
		setSearchResults,
		setView,
		keyword,
		setKeyword,
		togglePopover,
		showTip,
		dismissTip,
		Questions,
		searchKey,
		setSearchKey,
		showPopover,
		currentSideTab,
		setCurrentSideTab,
		currentPiPNav,
		setCurrentPiPNav,
		popOverContent,
		openMenu,
	} = helpCenterContext;

	return {
		searchQuestion,
		handleQuestionChange,
		searchResults,
		setSearchResults,
		setView,
		keyword,
		setKeyword,
		togglePopover,
		showTip,
		dismissTip,
		Questions,
		searchKey,
		setSearchKey,
		showPopover,
		currentSideTab,
		setCurrentSideTab,
		currentPiPNav,
		setCurrentPiPNav,
		popOverContent,
		openMenu,
	};
};
export default useHelpCenter;
