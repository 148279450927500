import styled from 'styled-components';
import { mediaTablet } from '@rs-core/utils/responsiveUtils';

export const mainBox = {
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'flex-end',
};

export const header = {
	opacity: 0.87,
	fontFamily: 'Roboto',
	fontSize: '40px',
	fontWeight: 'normal',
	fontStretch: 'normal',
	fontStyle: 'normal',
	marginBottom: 0,
	letterSpacing: '0.15px',
	textAlign: 'center',
	color: '#121212',
	marginTop: 0,
	[mediaTablet]: {
		marginTop: '25px',
		fontSize: '24px',
		lineHeight: '24px',
		letterSpacing: '1.25px',
		marginBottom: '5px',
	},
};

export const markdownContainer = {
	display: 'flex',
	flexDirection: 'column',
	overflow: 'hidden',

	'.md-header': {
		padding: '0px 20px',
		h2: {
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '35px',
			lineHeight: '40px',
			textAlign: 'center',
			letterSpacing: '1.25px',
			textTransform: 'capitalize',
			color: '#121212',
			opacity: 0.87,
			margin: '10px 0px',
		},

		h4: {
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '14px',
			lineHeight: '16px',
			textAlign: 'center',
		},
	},
};

export const subtitle = {
	opacity: 0.7,
	fontFamily: 'Roboto',
	fontSize: '14px',
	fontWeight: 'normal',
	fontStretch: 'normal',
	fontStyle: 'normal',
	lineHeight: 1.14,
	letterSpacing: '1.25px',
	color: '#121212',
	flex: 1,
	overflow: 'auto',
	margin: '0px 47px 47px 47px',

	[mediaTablet]: {
		fontSize: '12px',
		lineHeight: '16px',
		letterSpacing: '1.25px',
	},
};

export const BackDrop = styled.div`
	width: 100%;
	height: 100%;
	background-color: rgb(227 227 230 / 48%);
	backdrop-filter: blur(6px);
	display: flex;
	flex-direction: column;
`;

export const NewsBoxWrapper = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	width: 486px;
	margin-top: 100px;
`;

export const NewsBox = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: 'center;
    width: 100%;
    height: 80vh;
    margin-top: 0px;
    background: #FFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 0px;
`;

export const NewsContent = styled.div`
	max-height: 80%;
	margin-top: 20px;
	overflow-y: auto;
	padding-left: 43px;
	padding-right: 43px;
	padding-bottom: 10px;
	font-family: Roboto;
	font-weight: 300;
	font-stretch: normal;

	${mediaTablet} {
		padding: 0;
		color: rgba(18, 18, 18, 0.87);
	}

	small {
		height: 25px;
		opacity: 0.7;
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 1.25px;
		text-align: right;
		color: #121212;
		float: right;
	}

	h4 {
		font-size: 20px;
		font-weight: 500;
	}

	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}
	::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}
	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border: 0px none #c4c4c4;
		border-radius: 50px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #a39f9f;
	}
	::-webkit-scrollbar-thumb:active {
		background: #a39f9f;
	}
	::-webkit-scrollbar-track {
		background: rgb(196 196 196 / 17%);
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	::-webkit-scrollbar-track:hover {
		background: rgb(196 196 196 / 17%);
	}
	::-webkit-scrollbar-track:active {
		background: rgb(196 196 196 / 17%);
	}
	::-webkit-scrollbar-corner {
		background: transparent;
	}
`;

export const NewsBoxFooter = styled.div`
	display: flex;
	margin-top: 0px;
	z-index: 2;
	flex-direction: row;

	button {
		flex: 1;
		height: 55px;
		padding: 15px;
		border-width: 0px;
		border-radius: 0px;
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		box-shadow: 1px 1px 4px #b6b2b2;
		font-size: 18px;
		text-transform: capitalize;
		font-weight: 400;
		background-color: rgba(255, 255, 255, 0.5);

		&:hover {
			cusor: pointer;
		}

		&.active {
			color: #42a5f5;
			box-shadow: 5px 10px 7px -4px #42a5f56b;
			background-color: #fff;
			z-index: 2;
		}
	}
`;

export const ChangeLogItem = styled.div`
	margin-top: 30px;
	margin-bottom: 10px;

	.details {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 10px;
	}
`;
