import styled from 'styled-components';
import { mediaTablet } from '@rs-core/utils/responsiveUtils';

export const mainBox = {
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
};

export const boxStyle = {
	flexDirection: 'column',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minWidth: 299,
	width: 700,
	height: '100%',
	marginBottom: '100px',
	[mediaTablet]: {
		width: '100%',
	},
};

export const header = {
	width: '500px',
	opacity: 0.87,
	fontFamily: 'Roboto',
	fontSize: '20px',
	fontWeight: 'bold',
	fontStyle: 'normal',
	lineHeight: 1.2,
	letterSpacing: '0.15px',
	textAlign: 'center',
	color: '#000',
	marginTop: '25px',
	[mediaTablet]: {
		width: '100%',
		fontSize: '18px',
		fontWeight: '500',
		lineHeight: '21px',
		margin: '0 0 10px 0',
	},
};

export const BackDrop = styled.div`
	width: 100%;
	height: 100%;
	background-color: rgb(227 227 230 / 48%);
	backdrop-filter: blur(6px);
	display: flex;
	flex-direction: column;
`;
export const Hint = styled.p`
	opacity: 0.2;
	font-family: Roboto;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	text-align: center;
	letter-spacing: 0.4px;
	color: #121212;
	margin-bottom: 0px;
`;
