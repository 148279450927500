/**
 * These are the options rendered in the "Status" filter. This filter is special because values are filtered based on two
 * imaging study set properties:
 * - "PriorStatus": indicates the pre-authorization status for the respective line.
 * - "EligibilityStatus": indicates the eligibility status (regarding payments) for the respective line.
 *
 * The R4 API has custom logic to apply filtering for each option based on the "id" property. The selected option is passed
 * to the API like this:
 *
 * statusindicator=eligibility-Eligible
 *
 * The example above will filter all records that have statuses that represent "Pending Eligibility".
 */
export const statusIndicatorFilterOptions = [
	{
		id: 'eligibility-Eligible',
		display: 'Eligible',
		icon: 'eligible',
	},
	{
		id: 'eligibility-NotEligible',
		display: 'Not Eligible',
		icon: 'eligibleDenied',
	},
	{
		id: 'eligibility-Pending',
		display: 'Pending Eligibility',
		icon: 'eligiblePending',
	},
	{
		id: 'prior-ApprovedAuth',
		display: 'Approved Authorization',
		icon: 'preAuthAllCleared',
	},
	{
		id: 'prior-DeniedAuth',
		display: 'Denied Authorization',
		icon: 'preAuthDenied',
	},
	{
		id: 'prior-PendingAuthorization',
		display: 'Pending Authorization',
		icon: 'preAuthPending',
	},
	{
		id: 'charge-Posted',
		display: 'Charge Posted',
		icon: 'chargePosted',
	},
	{
		id: 'charge-PostFailed',
		display: 'Charge Post Failed',
		icon: 'chargePostFailed',
	},
	{
		id: 'charge-PostPending',
		display: 'Charge Post Pending',
		icon: 'chargePostPending',
	},
];
