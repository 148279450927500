import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { Svg } from '../Svg';
import {
	BackDrop,
	boxStyle,
	DateText,
	header,
	mainBox,
	NoScrollbar,
	PolicyWrapper,
	SideBarWrapper,
	sideStyle,
	StrongText,
} from './policyStyles';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { mediaMobile, mediaTablet, useIsMobile, useIsTablet } from '@rs-core/utils/responsiveUtils';
import useRouterHash from '@rs-core/hooks/useRouterHash';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const SideBarLinks = ({ onClick }) => {
	const { t } = useTranslation('term');
	const { hash, setHash } = useRouterHash();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const links = [
		{ label: 'Introduction' },
		{ label: 'Interpretation And Definitions' },
		{ label: 'Disclaimer' },
		{ label: 'Acknowledgement' },
		{
			label: 'Intellectual Property Rights',
			children: [{ label: 'Software' }, { label: 'Title' }],
		},
		{
			label: 'Third Party Links, Products, and Integrations',
			children: [
				{ label: 'Links to Other Websites' },
				{ label: 'Third Party Accounts' },
				{ label: 'Third Party Products' },
			],
		},
		{ label: 'Privacy Policy and AI Disclaimer' },
		{ label: 'Feedback' },
		{ label: 'Prohibited Uses' },
		{ label: 'Termination' },
		{ label: 'Limitation of liability' },
		{ label: 'Medical Decision Making and Clinical Disclaimer' },
		{ label: 'Dispute Resolution' },
		{
			label: 'Severability and Waiver',
			children: [{ label: 'Severability' }, { label: 'Waiver' }],
		},
		{ label: 'Indemnification' },
		{ label: 'Confidentiality' },
		{ label: 'Translation Interpretation' },
		{ label: 'Changes to these Terms and Conditions' },
		{ label: 'Acceptance of these Terms' },
		{ label: 'Contact Us' },
	];

	return (
		<SideBarWrapper>
			{links.map((link, i) => (
				<>
					<Box
						key={i}
						component="li"
						sx={{
							[mediaMobile]: {
								fontSize: '14px',
								lineHeight: '48px',
								color: '#121212',
								letterSpacing: '0.25px',
							},
						}}
						onClick={onClick}
					>
						<a
							className={`${
								hash ==
									link.label
										.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
										.replace(/\s/g, '-')
										.toLowerCase() && 'active'
							}`}
							href={`#${link.label
								.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
								.replace(/\s/g, '-')
								.toLowerCase()}`}
							onClick={() => {
								setHash(
									link.label
										.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
										.replace(/\s/g, '-')
										.toLowerCase()
								);
							}}
						>
							{t(link.label)}
						</a>
					</Box>
					{!isMobile && !isTablet && link.children && (
						<ul>
							{link.children.map((childTerm, j) => (
								<li key={j} className="unblur">
									<a
										className={`${
											hash == childTerm.label.replace(/\s/g, '-').toLowerCase() && 'active'
										}`}
										href={`#${childTerm.label.replace(/\s/g, '-').toLowerCase()}`}
										onClick={() => {
											setHash(childTerm.label.replace(/\s/g, '-').toLowerCase());
										}}
									>
										{t(childTerm.label)}
									</a>
								</li>
							))}
						</ul>
					)}
				</>
			))}
		</SideBarWrapper>
	);
};

const TermsModalContent = () => {
	const { t } = useTranslation('term');
	const { watchScroll } = useRouterHash();
	const handleScroll = _.debounce(e => watchScroll(e), 10);

	return (
		<PolicyWrapper onScroll={handleScroll}>
			<Box
				sx={{
					[mediaTablet]: {
						marginRight: '0',
					},
				}}
			>
				<DateText className="watched" id="introduction">
					{t('*Last updated: January 30, 2025')}
				</DateText>
				<p>
					{t(
						'Welcome to RamSoft, a pioneer in the digital transformation of radiology. We are proud to offer innovative cloud-based and cloud-native solutions such as OmegaAI®, Blume™, Gateway™ and PowerServer™ to meet the evolving needs of today’s imaging practices. Our software solutions are designed to optimize the speed of image transfer and retrieval, enhance security, and simplify imaging workflows, all while continuously striving to streamline operations and improve patient care.'
					)}
				</p>
				<p>
					{t(
						'We have developed these Terms and Conditions to ensure that you fully understand the scope of our Services. If you have any questions, concerns, or if you would like to better understand how we operate at RamSoft, please don’t hesitate to reach out to us a'
					)}{' '}
					<a href="mailto:legal@ramsoft.com">legal@ramsoft.com</a>.
				</p>
				<p>
					{t(
						'RamSoft’s Services may be classified as medical devices and are subject to regulatory requirements in certain geographies. Users are advised to refer to the specific indications for use and labels where applicable. RamSoft complies with applicable regulatory standards in jurisdictions where RamSoft is registered and regulated.'
					)}
				</p>
				<p>
					{t(
						'By accessing or using our Services, you agree to be bound by these terms and conditions, and you represent that you are capable of forming a binding contract with us and complying with all applicable laws and regulations. Please take the time to read each section of these Terms of Service carefully. If you do not agree with the terms, do not use our Services. These Terms apply to all visitors, users, and others who access or use our Services.'
					)}
				</p>
				<StrongText className="watched" id="interpretation-and-definitions">
					{t('INTERPRETATION AND DEFINITIONS')}
				</StrongText>
				<p>
					<strong>{t('Definitions')}</strong>
				</p>
				<p>{t('For the purposes of these Terms and Conditions:')}</p>
				<ul>
					<li>
						<strong>&quot;{t('Affiliate')}&quot;</strong>{' '}
						{t(
							'means any entity, including but not limited to subsidiaries, parent companies, joint venture partners, alliances, or any other companies or organizations that are directly or indirectly controlled, owned, or operated by RamSoft, Inc.'
						)}
					</li>
					<li>
						<strong>{t('Company')}</strong> {t('(referred to as either')} &quot;{t('the Company')}&quot;,
						&quot;{t('We')}&quot;, &quot;{t('Us')}&quot; {t('or')} &quot;{t('Our')}&quot;{' '}
						{t('in this Agreement) refers to RamSoft, Inc.')}
					</li>
					<li>
						<strong>&quot;{t('Services')}&quot;</strong>{' '}
						{t(
							'refers to RamSoft’s OmegaAI®, Blume™, PowerServer™, Gateway™, and the family of associated products, including their respective web and mobile-based applications, as well as RamSoft’s official website.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Software')}&quot;</strong>{' '}
						{t(
							'refers to any intellectual property developed, owned, licensed, trademarked, and copyrighted by the Company.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Terms and Conditions')}&quot;</strong>{' '}
						{t(
							'(also referred as “Terms”) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of these Services.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Third-party Social Media Service')}&quot;</strong>{' '}
						{t(
							'means any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included or made available by the Services.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Vendor')}&quot;</strong>
						&nbsp;{' '}
						{t(
							'refers to any third-party company which Company partners to integrate, resell, or distribute their software, service, or offering.'
						)}
					</li>
					<li>
						<strong>
							&quot;{t('You')}&quot; {t('or')} &quot;{t('User')}&quot;
						</strong>{' '}
						{t(
							'means the individual accessing or using this Service, or the company, or other legal entity on behalf of which such individual is accessing or using these Services, as applicable.'
						)}
					</li>
				</ul>

				<StrongText className="watched" id="disclaimer">
					{t('DISCLAIMER')}
				</StrongText>
				<p>
					{t(
						'This document defines certain use, rights, and obligations to Users not bound by any additional contractual agreements, initiated with the Company, for services outside of those outlined in these Terms and Conditions. Other contractual obligations initiated as a customer for the Company will take precedence over terms laid out here. If you are a customer of the Company under an existing agreement, including but not limited to a Master Sales Agreement, Proposal or Quote, or any other contractual arrangement with the Company, that agreement shall take precedence over these Terms of Use to the extent of any conflict. In such cases, the terms of the existing agreement will govern your use of the Services.This document defines certain use, rights, and obligations to Users not bound by any additional contractual agreements, initiated with the Company, for services outside of those outlined in these Terms and Conditions. Other contractual obligations initiated as a customer for the Company will take precedence over terms laid out here. If you are a customer of the Company under an existing agreement, including but not limited to a Master Sales Agreement, Proposal or Quote, or any other contractual arrangement with the Company, that agreement shall take precedence over these Terms of Use to the extent of any conflict. In such cases, the terms of the existing agreement will govern your use of the Services.'
					)}
				</p>

				<StrongText className="watched" id="acknowledgement">
					{t('ACKNOWLEDGEMENT')}
				</StrongText>
				<p>
					{t(
						'These are the Terms and Conditions governing the use of the Services and the agreement that operates between you and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of this Services. Your access to and use of the Services is also conditioned on your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of your personal information when you use the Services and tells you about your privacy rights and how the law protects you.'
					)}
				</p>
				<StrongText className="watched" id="intellectual-property-rights">
					{t('INTELLECTUAL PROPERTY RIGHTS')}
				</StrongText>
				<p>
					{t(
						'This Agreement does not transfer to you any intellectual property owned by the Company or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with the Company. All trademarks, service marks, graphics and logos used in connection with this Services, are trademarks or registered trademarks of the Company. Other trademarks, service marks, graphics and logos used in connection with our Services may be the trademarks of other third parties. Your use of the Services grants you no right or license to reproduce or otherwise use any Company or third-party trademarks.'
					)}
				</p>

				<p className="watched" id="software">
					<strong>{t('Software')}</strong>
				</p>
				<p>
					{t(
						'You agree to use the Software in compliance with all applicable laws and regulations, including but not limited to those governing medical use, copyright, and intellectual property. All rights not expressly granted are reserved. The Company makes no guarantees or warranties that the Software will operate uninterrupted or error-free.'
					)}
				</p>
				<p>
					{t(
						'Restrictions: You may not: (i) modify, translate, reverse engineer, decompile, disassemble, or create derivative works of the Software, except as expressly permitted by law; (ii) copy the Software; (iii) remove proprietary notices or labels. Any violation of these restrictions will result in immediate termination of your license. You acknowledge that the Services facilitate access to electronic Protected Health Information (“ePHI”) for both healthcare providers and patients. Healthcare providers may only access the ePHI of patients solely to the extent necessary for treatment and permissible under the law. Patients may access their own ePHI as permitted by applicable laws and regulations. All access to ePHI within the Software may be monitored and audited. Unauthorized access, use, or disclosure of ePHI is strictly prohibited. If it is determined that you have impermissibly accessed or misused ePHI, the operator reserves the right to restrict or terminate your access to the Software and take any necessary legal or regulatory action.'
					)}
				</p>
				<p className="watched" id="title">
					<strong>{t('Title')}</strong>
				</p>
				<p>
					{t(
						'The Services may be protected by the copyright laws in different countries. Aspects of the licensed materials, including the specific design and structure of individual programs, constitute trade secrets and/or copyrighted material of the Company. You agree not to disclose, provide, or otherwise make available such trade secrets or copyrighted material in any form to any third party without the prior written consent of the Company.'
					)}
				</p>

				<StrongText className="watched" id="third-party-links-products-and-integrations">
					{t('THIRD PARTY LINKS, PRODUCTS, AND INTEGRATIONS')}
				</StrongText>
				<p className="watched" id="links-to-other-websites">
					<strong>{t('Links to other Websites')}</strong>
				</p>
				<p>
					{t(
						'The Services may include links to third-party websites or services that are not owned, operated, or controlled by the Company. The Company does not endorse, and is not responsible for, the content, privacy policies, terms, or practices of any third-party websites or services. The Company disclaims all liability for any loss or damage, whether direct or indirect, arising from or related to your use of, or reliance on, any content, goods, or services available through such third-party websites. The inclusion of any link does not constitute an endorsement of the linked website, its content, or its sponsoring entity. You are encouraged to review the terms and privacy policies of any third-party websites You visit before engaging with such sites.'
					)}
				</p>
				<p className="watched" id="third-party-accounts">
					<strong>{t('Third Party Accounts')}</strong>
				</p>
				<p>
					{t(
						'By accessing third-party services through our platform, You acknowledge and agree to the following:'
					)}
					<ul style={{ listStyleType: 'none' }}>
						<li>
							<p>
								1.{' '}
								{t(
									'You are solely responsible for maintaining the confidentiality of your login credentials, including usernames, passwords, or any other access credentials required to use the Services. You assume full responsibility for all activities conducted under your credentials and for any data accessed using them. The Company and its vendors disclaim any liability for any loss, expense, or damage resulting from unauthorized use of your credentials. Accordingly, You must take appropriate measures to safeguard your credentials and monitor account activity.'
								)}
							</p>
						</li>
						<li>
							<p>
								2.{' '}
								{t(
									'The Company is not liable for any unauthorized access caused by a User’s failure to safeguard their credentials.'
								)}
							</p>
						</li>
						<li>
							<p>
								3.{' '}
								{t(
									'You are solely responsible for any fees associated with third-party accounts and transactions conducted outside our Services. Your use of such third-party accounts is governed by their respective terms and conditions, which remain separate and unaffected by these Terms. Any disputes or inquiries related to third-party transactions must be directed to the respective account provider.'
								)}
							</p>
						</li>
					</ul>
				</p>
				<p className="watched" id="third-party-products">
					<strong>{t('Third Party Products')}</strong>
				</p>
				<p>
					{t(
						'The Services may include third-party code, content, features, or components provided “AS IS” without any warranty from the Company. All rights and obligations related to such third-party products or services are governed solely by agreements between the Company and its suppliers. You hereby release the Company from all liability arising from their use.'
					)}
				</p>
				<p>
					{t(
						'Pursuant to Federal Trade Commission’s Guides Concerning the Use of Endorsements and Testimonials in Advertising, 16 C.F.R. Part 255 Sections 255.0 through 255.5, some of our links are affiliated links. This means that Company may receive payment if you purchase products or services through such links.'
					)}
				</p>
				<StrongText className="watched" id="privacy-policy-and-ai-disclaimer">
					{t('PRIVACY POLICY AND AI DISCLAIMER')}
				</StrongText>
				<p>
					{t(
						'Your access to and use of the Services is also conditioned on your acceptance of and compliance with the'
					)}{' '}
					<a href="https://www.ramsoft.com/privacy-policy">Privacy Policy</a> {t('of the Company and the')}{' '}
					<a href="https://www.ramsoft.com/ai-disclaimer">AI Disclaimer</a>.{' '}
					{t(
						'Please understand Our policies and procedures on the collection, use and disclosure of your personal information when You use the Services and tells You about your privacy rights and how the law protects You.'
					)}
				</p>
				<StrongText className="watched" id="feedback">
					{t('FEEDBACK')}
				</StrongText>
				<p>
					{t(
						'The Company shall have the right to use, without payment or restriction, any feedback that the User (or any End User) provides in any format regarding the functionality and performance of the Services or the content of the Documentation (including identifying potential errors and improvements). The Company may also approach Users for feedback.'
					)}
				</p>
				<StrongText className="watched" id="prohibited-uses">
					{t('PROHIBITED USES')}
				</StrongText>
				<p>
					{t(
						'You may not use the Services for any unlawful purpose, to violate any applicable laws or regulations, or to infringe on intellectual property rights. Prohibited activities include, but are not limited to: (a) soliciting unlawful acts; (b) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (c) to submit false or misleading information; (d) to upload or transmit viruses or any other type of malicious code; (e) to collect or track the personal information of others; (f) to spam, phish, pharm, pretext, spider, crawl, or scrape; (g) for any obscene or immoral purpose; or (h) to interfere with or circumvent the security features. We reserve the right to terminate your use of the Services or any related Company offering for violating any of the prohibited uses at our discretion.'
					)}
				</p>
				<StrongText className="watched" id="termination">
					{t('TERMINATION')}
				</StrongText>
				<p>
					{t(
						'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions, or no reason. Upon termination, your right to use the Services will cease immediately. However, the Company may retain certain data for compliance with legal, regulatory, or security obligations, archival and back up policies, in accordance with applicable data retention laws and confidentiality obligations. Any questions related to privacy or data retention must be sent in writing to'
					)}{' '}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.
				</p>

				<StrongText className="watched" id="limitation-of-liability">
					{t('LIMITATION OF LIABILITY')}
				</StrongText>
				<p>
					{t(
						'To the maximum extent permitted by law, the total liability of the Company and its Affiliates under these Terms shall be limited to the amount You actually paid for the Services in the last twelve (12) months. The Company, its Affiliates, and their officers, directors, employees, and agents shall not be liable for any special, incidental, indirect, exemplary, punitive, or consequential damages, including but not limited to loss of profits, data, business interruption, personal injury, or privacy breaches arising from or related to the use or inability to use the Services, third-party software, or hardware, even if advised of the possibility of such damages. Your sole remedy for dissatisfaction with the Services is to discontinue use. The Company provides no indemnification.'
					)}
				</p>
				<StrongText className="watched" id="medical-decision-making-and-clinical-disclaimer">
					{t('MEDICAL DECISION MAKING AND CLINICAL DISCLAIMER')}
				</StrongText>
				<p>
					{t(
						'You acknowledge and agree that the Company is not engaged in the practice of medicine. The Company does not represent or warrant that the use of any of the Services offered is appropriate for treating or diagnosing any medical condition. Neither the Company, nor the Services, makes any clinical, medical, or other professional decisions, and neither is it a substitute for licensed medical personnel applying their own professional judgment and analysis in every case. All recommendations and analysis generated by the Services must be reviewed by a qualified healthcare provider before making any recommendations to patients, making any diagnosis, or determining or prescribing any treatment based on such information. '
					)}
				</p>
				<StrongText className="watched" id="disputes-resolution">
					{t('DISPUTE RESOLUTION')}
				</StrongText>
				<p>
					{t(
						'These Terms shall be governed by and construed per the substantive and procedural laws applicable to the User’s location. For Users in the United States, these Terms shall be governed by the laws of the State of Delaware and applicable federal laws, without regard to conflict of law principles. Any dispute, controversy, or claim shall be resolved through binding arbitration administered by the American Arbitration Association in accordance with its then-prevailing Commercial Arbitration Rules, conducted virtually. For Users in India, these Terms shall be governed by the laws of India, and any dispute shall be resolved through arbitration in accordance with the Arbitration and Conciliation Act, 1996, and conducted virtually unless otherwise agreed. For Users in Canada and all other jurisdictions, these Terms shall be governed by the laws of Ontario, Canada, without regard to conflict of law principles, and disputes shall be resolved by arbitration administered by the Canadian Arbitration Association in accordance with its Arbitration Rules, conducted virtually. The parties waive any right to a jury trial or to bring disputes before a court, except as necessary to enforce an arbitral award. The United Nations Convention on Contracts for the International Sale of Goods shall not apply. '
					)}
				</p>

				<StrongText className="watched" id="severability-and-waiver">
					{t('SEVERABILITY AND WAIVER')}
				</StrongText>
				<p className="watched" id="severability">
					<strong>{t('Severability')}</strong>
				</p>
				<p>
					{t(
						'If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law (unless that modification is not permitted by law, in which case that provision will be disregarded) and the remaining provisions will continue in full force and effect. Any unenforceable provision will continue to apply in all circumstances where it is enforceable.'
					)}
				</p>

				<p className="watched" id="waiver">
					<strong>{t('Waiver')}</strong>
				</p>
				<p>
					{t(
						'Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party’s ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.'
					)}
				</p>

				<StrongText className="watched" id="indemnification">
					{t('INDEMNIFICATION')}
				</StrongText>
				<p>
					{t(
						'You agree to indemnify and hold harmless the Company and its Affiliates, directors, officers, employees, and agents from any liabilities, losses, damages, or costs, including attorneys’ fees, arising from third-party claims related to your content, use of the Service, or any willful misconduct. The risk of using the Services is solely yours. You also agree to indemnify the Company for any third-party claims arising from your use of the Services.'
					)}
				</p>

				<StrongText className="watched" id="confidentiality">
					{t('CONFIDENTIALITY')}
				</StrongText>
				<p>
					{t(
						'The parties agree to maintain the confidentiality of the Terms and all business-related information exchanged between them. Confidential information shall be used solely for the purpose of fulfilling these Terms or underlying agreements, if any, and shall not be disclosed to third parties without prior consent, except as reasonably required. This confidentiality obligation does not apply to information that is publicly available or becomes public through no breach of this Agreement, or to disclosures required by law, regulatory authorities, or auditors.'
					)}
				</p>

				<StrongText className="watched" id="translation-interpretation">
					{t('TRANSLATION INTERPRETATION')}
				</StrongText>
				<p>
					{t(
						'These Terms and Conditions may have been translated if we have made them available to you in such a translated format. You agree that the original English text shall prevail in the case of a dispute.'
					)}
				</p>

				<StrongText className="watched" id="changes-to-these-terms-and-conditions">
					{t('CHANGES TO THESE TERMS AND CONDITIONS')}
				</StrongText>
				<p>
					{t(
						'We reserve the right to modify or replace these Terms and Conditions at our discretion, with or without notice. If a revision is deemed material, we will make reasonable efforts to notify You at least 30 days before the new terms take effect. Whether a change is material will be determined at our sole discretion. By continuing to use the Service after the modified terms are in effect, you agree to be bound by them. If You do not agree with the new terms, you must stop using the Service. We encourage you to periodically review these Terms for updates.'
					)}
				</p>

				<StrongText className="watched" id="acceptance-of-these-terms">
					{t('ACCEPTANCE OF THESE TERMS')}
				</StrongText>
				<p>
					{t(
						'You acknowledge that You have read and agree to all its terms and conditions. By using this Service, You agree to be bound by these Terms and any underlying agreement(s). If you do not agree to abide by the terms of this Agreement, You are not authorized to use or access this Services. These Terms and Conditions may not be transferred or assigned by You but may be assigned by us without restriction.'
					)}
				</p>

				<StrongText className="watched" id="contact-us">
					{t('CONTACT US')}
				</StrongText>
				<p>
					{t('If you have any questions about the Terms and Conditions, please contact us via email:')}{' '}
					<a href="mailto:legal@ramsoft.com">legal@ramsoft.com</a>.{' '}
					{t('For support related questions, please visit ')}
					<a href="https://www.ramsoft.com/support">
						RamSoft Support - 24/7 Help via Phone, Chat, SMS & More
					</a>{' '}
					{t('Depending the request type, validation of your identity may be required.')}
				</p>
				<strong>
					{t(
						'Note: Email is not a secure method of transfer for Protected Health Information (PHI) or Personally Identifiable Information (PII).'
					)}
				</strong>
				<StrongText style={{ height: 200 }} />
			</Box>
		</PolicyWrapper>
	);
};

const TermsModal = ({ open, onClose }) => {
	const isTablet = useIsTablet();
	const { t } = useTranslation('term');
	const { i18n } = useTranslation();
	const [languageInitialized, setLanguageInitialized] = useState(false);

	function getQueryParamValue(paramName, url = window.location.href) {
		const queryString = url.split('#')[1] || url.split('?')[1] || '';
		const regex = new RegExp(`[?&]${paramName}=([^&#]*)`, 'i');
		const matches = regex.exec(queryString);

		if (matches && matches[1]) {
			return decodeURIComponent(matches[1]);
		}

		return '';
	}

	useEffect(() => {
		if (!languageInitialized) {
			const language = getQueryParamValue('languageSelected');

			if (language) {
				i18n.changeLanguage(language);
			}

			setLanguageInitialized(true); // State update happens after the language change
		}
	}, [languageInitialized, i18n]);
	return (
		<Modal
			BackdropComponent={() => (
				<BackDrop>
					<Box sx={mainBox}>
						<NoScrollbar style={sideStyle}>
							<IconButton
								sx={{
									position: 'absolute',
									top: 10,
									right: 20,
								}}
								onClick={onClose}
							>
								<CloseIcon sx={{ color: '#000' }} />
							</IconButton>
							<SideBarLinks />
						</NoScrollbar>
						<Box sx={boxStyle}>
							<Svg
								name="terms"
								style={{
									width: 75,
									height: 75,
									marginTop: isTablet ? 0 : 100,
								}}
								viewBox="-10 -10 75 75"
							/>
							<Box component="h3" sx={header}>
								{t('Terms of Service')}
							</Box>
							<TermsModalContent />
						</Box>
					</Box>
				</BackDrop>
			)}
			open={open}
			onClose={onClose}
		>
			<></>
		</Modal>
	);
};

export default TermsModal;
export { SideBarLinks, TermsModalContent };
