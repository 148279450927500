import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { Svg } from '@worklist-2/ui/src/components/Svg';
import {
	BackDrop,
	boxStyle,
	DateText,
	header,
	mainBox,
	NoScrollbar,
	PolicyWrapper,
	SideBarWrapper,
	sideStyle,
	StrongText,
} from './policyStyles';
import CloseIcon from '@mui/icons-material/Close';
import { mediaMobile, useIsMobile, useIsTablet } from '@rs-core/utils/responsiveUtils';
import useRouterHash from '@rs-core/hooks/useRouterHash';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const SideBarLinks = ({ onClick }) => {
	const { t } = useTranslation('privacyPolicy');
	const { hash, setHash } = useRouterHash();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const links = [
		{ label: 'Introduction' },
		{ label: 'Definitions' },
		{ label: 'Collecting and Using your Personal Data' },
		{ label: 'Use of Your Personal Data' },
		{ label: 'Retention of your Personal Data' },
		{ label: 'Transfer of your Personal Data' },
		{
			label: 'Disclosure of your Personal Data',
			children: [
				{ label: 'Business Transactions' },
				{ label: 'Law Enforcement' },
				{ label: 'Other Legal Requirements' },
			],
		},
		{ label: 'Security of Your Personal Data' },
		{ label: "Children's Privacy" },
		{ label: 'Additional Information for Certain Jurisdictions' },
		{
			label: 'California Consumer Privacy Act',
		},
		{
			label: 'California Online Privacy Protection Act',
		},
		{
			label: 'PIPEDA',
		},
		{
			label: 'European Economic Area',
		},
		{ label: 'Links to other Websites' },
		{ label: 'Changes to this Privacy Policy' },
		{ label: 'Contact Us' },
	];

	return (
		<SideBarWrapper>
			{links.map((link, i) => (
				<>
					<Box
						key={i}
						className="unblur"
						component="li"
						sx={{
							[mediaMobile]: {
								fontSize: '14px',
								lineHeight: '48px',
								color: '#121212',
								letterSpacing: '0.25px',
							},
						}}
						onClick={onClick}
					>
						<a
							className={`${hash == link.label.replace(/\s/g, '-').toLowerCase() && 'active'}`}
							href={`#${link.label.replace(/\s/g, '-').toLowerCase()}`}
							onClick={() => {
								setHash(link.label.replace(/\s/g, '-').toLowerCase());
							}}
						>
							{t(link.label)}
						</a>
					</Box>
					{!isMobile && !isTablet && link.children && (
						<ul>
							{link.children.map((childPrivacy, j) => (
								<li key={j} className="unblur">
									<a
										className={`${
											hash == childPrivacy.label.replace(/\s/g, '-').toLowerCase() && 'active'
										}`}
										href={`#${childPrivacy.label.replace(/\s/g, '-').toLowerCase()}`}
										onClick={() => {
											setHash(childPrivacy.label.replace(/\s/g, '-').toLowerCase());
										}}
									>
										{t(childPrivacy.label)}
									</a>
								</li>
							))}
						</ul>
					)}
				</>
			))}
		</SideBarWrapper>
	);
};

const PrivacyModalContent = ({ isBlumeInside }) => {
	const { watchScroll } = useRouterHash();
	const handleScroll = _.debounce(e => watchScroll(e), 10);
	const { t } = useTranslation('privacyPolicy');

	return (
		<PolicyWrapper isBlumeInside={Boolean(isBlumeInside)} onScroll={handleScroll}>
			<Box
				sx={{
					[mediaMobile]: {
						marginRight: '0',
					},
				}}
			>
				{isBlumeInside ? (
					<Box className="watched" id="introduction" sx={{ '& p': { lineHeight: '6px !important' } }}>
						<p>{t('Last updated on:')}</p>
						<p>{t('November 05, 2024')}</p>
					</Box>
				) : (
					<DateText className="watched" id="introduction">
						{t('*Last updated: November 05, 2024')}
					</DateText>
				)}
				{isBlumeInside && (
					<Divider
						sx={{
							margin: '20px 15px 15px 0',
							borderColor: 'rgba(100, 120, 192, 0.1)',
						}}
					/>
				)}
				<p>
					{t(
						'RamSoft is committed to protecting your privacy. This privacy policy describes our collection, use, and disclosure of your personal information when you utilize RamSoft offerings and products. By using RamSoft Services, you agree to the collection and use of information in accordance with this Privacy Policy.'
					)}
				</p>
				<p id="pleaseread">
					{t(
						'PLEASE READ THIS PRIVACY POLICY IN FULL. IF YOU DO NOT AGREE TO THE BELOW TERMS, THEN PLEASE DO NOT USE THIS SITE OR SERVICES'
					)}
				</p>
				<StrongText className="watched" id="definitions">
					{t('DEFINITIONS')}
				</StrongText>
				<p>{t('For the purposes of this Privacy Policy:')}</p>
				<p>
					<strong>{t('You')}</strong>{' '}
					{t(
						'means the individual or entity accessing or using the Service, whether in their own representative capacity or on behalf of another.'
					)}
				</p>
				<p className="watched">
					<strong>{t('Company')}</strong> {t('(referred to as either')} &quot;{t('the Company')}&quot;, &quot;
					{t('We')}&quot;, &quot;{t('Us')}&quot; {t('or')} &quot;{t('Our')}&quot;{' '}
					{t(
						'in this Privacy Policy) refers to RamSoft, Inc., and its subsidiaries, alliances and affiliates.'
					)}
				</p>
				<ul>
					<li>
						<strong>{t('Affiliate')}</strong>{' '}
						{t(
							'means any entity, subsidiaries, joint venture partners, or other companies under the control of RamSoft, Inc.'
						)}
					</li>
					<li>
						<strong>{t('Account')}</strong>{' '}
						{t('means a unique account created for you to access our Service or parts of our Service.')}
					</li>
					<li>
						<strong>{t('Service')}</strong>{' '}
						{t(
							'refers to OmegaAI™ & Blume™ web and mobile-based applications along with the family of associated products.'
						)}
					</li>
					<li>
						<strong>{t('Service Provider')}</strong>{' '}
						{t(
							'means any natural person or legal entity who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.'
						)}
					</li>
					<li>
						<strong>{t('Third-party Social Media Service')}</strong>{' '}
						{t(
							'refers to any website or any social network website through which a User can log in or create an account to use the Service.'
						)}
					</li>
					<li>
						<strong>{t('Personal Data')}</strong>{' '}
						{t('is any information that relates to an identified or identifiable individual.')}
					</li>
					<li>
						<strong>{t('Cookies')}</strong>{' '}
						{t(
							'are small files that are placed on your computer, mobile device, or any other device by a website, containing the details of your browsing history on that website among its many uses.'
						)}
					</li>
					<li>
						<strong>{t('Usage Data')}</strong>{' '}
						{t(
							'refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself'
						)}{' '}
						{t('(for example, the duration of a page visit).')}
					</li>
					<li>
						<strong>{t('Website')}</strong> {t('refers to the publicly available online websites for')}{' '}
						OmegaAI™{t(' found at ')}
						<strong>
							<a href="https://www.omegaai.com/" rel="noreferrer" target="_blank">
								https://www.omegaai.com/
							</a>
						</strong>{' '}
						& Blume™
						{t(' found at ')}
						<strong>
							<a href="https://blume.omegaai.com/" rel="noreferrer" target="_blank">
								https://blume.omegaai.com/
							</a>
						</strong>{' '}
						{t('and RamSoft Communities at')}{' '}
						<strong>
							<a href="https://rs.force.com/customers/s/login/" rel="noreferrer" target="_blank">
								https://rs.force.com/customers/s/login/.
							</a>
						</strong>
					</li>
				</ul>
				<StrongText className="watched" id="collecting-and-using-your-personal-data">
					{t('COLLECTING AND USING YOUR PERSONAL DATA')}
				</StrongText>
				<p>
					<strong>{t('Categories of Information Collected')}</strong>
				</p>
				<p>
					<strong>{t('Personal Data')}</strong>
				</p>
				<p>
					{t(
						'While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:'
					)}
				</p>
				<ul>
					<li>{t('Email address')}</li>
					<li>{t('First name and last name')}</li>
					<li>{t('Phone number')}</li>
					<li>{t('Mailing Address including State, Province, ZIP/Postal code, City')}</li>
					<li>{t('Usage Data')}</li>
				</ul>
				<p>
					<strong>{t('Usage Data')}</strong>
				</p>
				<p>{t('Usage Data is collected automatically when using the Service.')}</p>
				<p>
					{t(
						'Usage Data may include information such as your device type, browser type (desktop, mobile phone, tablet, or other), the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.'
					)}
				</p>
				<p>
					<strong>{t('Tracking Technologies and Cookies')}</strong>
				</p>
				<p>
					{t(
						'We use Cookies and other similar tracking technologies to track your activity on our Service and store certain information. You can instruct your browser to limit the types of Cookies used while using our Service. However, if you do not accept Cookies, you may not be able to use some parts of our Service.'
					)}
				</p>
				<p>
					{t(
						'For more information about the cookies we use and your choices regarding cookies, please refer to our '
					)}
					<strong>
						<a href="https://www.ramsoft.com/cookies-policy/" rel="noreferrer" target="_blank">
							{t('Cookies policy.')}
						</a>
					</strong>
				</p>
				<StrongText className="watched" id="use-of-your-personal-data">
					{t('USE OF YOUR PERSONAL DATA')}
				</StrongText>
				<p>{t('The Company may use Personal Data for the following purposes:')}</p>
				<ul>
					<li>
						<strong>{t('To provide and maintain our Service,')}</strong>,{' '}
						{t('including to monitor your usage of our Service.')}
					</li>
					<li>
						<strong>{t('For the performance of a contract, including')} </strong>{' '}
						{t(
							'the development, compliance and undertaking of the purchase contract for the products, items or services you have purchased or of any other contract with us through the Service.'
						)}
					</li>
					<li>
						<strong>{t('To contact you, including')} </strong>{' '}
						{t(
							'by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.'
						)}
					</li>
					<li>
						<strong>{t('To provide you')}</strong>{' '}
						{t(
							'with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.'
						)}
					</li>
					<li>
						<strong>{t('To manage your requests:')}</strong>{' '}
						{t('To attend and manage your requests to us.')}
					</li>
				</ul>
				<p>{t('We may share your personal information in the following situations:')}</p>
				<ul>
					<li>
						<strong>{t('With Service Providers:')}</strong>{' '}
						{t(
							'We may share your personal information with Service Providers to monitor and analyze your use of our Service, to show advertisements to you to help support and maintain our Service, to contact you, to advertise on third party websites to you after you visited our Service or for payment processing.'
						)}
					</li>
					<li>
						<strong>{t('With Affiliates:')}</strong>{' '}
						{t(
							'We may share your information with our Affiliates, in which case we will require those Affiliates to comply with this Privacy Policy.'
						)}
					</li>
					<li>
						<strong>{t('With Business partners:')}</strong>{' '}
						{t(
							'We may share your information with our business partners to offer you certain products, services, or promotions.'
						)}
					</li>
				</ul>
				<StrongText className="watched" id="retention-of-your-personal-data">
					{t('RETENTION OF YOUR PERSONAL DATA')}
				</StrongText>
				<p>
					{t(
						'We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our agreements and policies.'
					)}
				</p>
				<p>
					{t(
						'Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.'
					)}
				</p>
				<StrongText className="watched" id="transfer-of-your-personal-data">
					{t('TRANSFER OF YOUR PERSONAL DATA')}
				</StrongText>
				<p>
					{t(
						'Your information, including Personal Data, is processed by the Company in any place where the parties involved in the processing are located. This means that your information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction and subject to data protection laws that may differ from those in your jurisdiction.'
					)}
				</p>
				<p>
					{t(
						'Your consent to this Privacy Policy followed by your submission of personal information represents your agreement to that transfer.'
					)}
				</p>
				<p>
					{t(
						'The Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy. The Company will not transfer your Personal Data to another organization, or a country, unless the Company determines there are adequate controls over the privacy and security of such Personal data in place.'
					)}
				</p>
				<StrongText className="watched" id="disclosure-of-your-personal-data">
					{t('DISCLOSURE OF YOUR PERSONAL DATA')}
				</StrongText>
				<i className="watched" id="business-transactions">
					{t('Business Transactions')}
				</i>
				<p>
					{t(
						'If the Company is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice to you before your Personal Data is transferred. It is possible your data may become subject to a different Privacy Policy as a result of a merger, acquisition, or asset sale.'
					)}
				</p>
				<i className="watched" id="law-enforcement">
					{t('Law Enforcement')}
				</i>
				<p>
					{t(
						'Under certain circumstances, the Company may be required to disclose your Personal Data, if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).'
					)}
				</p>
				<i className="watched" id="other-legal-requirements">
					{t('Other Legal Requirements')}
				</i>
				<p>
					{t(
						'The Company may disclose your Personal Data based on the good faith belief that such action is necessary to:'
					)}
				</p>
				<ul>
					<li>{t('Comply with a legal obligation')}</li>
					<li>{t('Protect and defend the rights or property of the Company')}</li>
					<li>{t('Prevent or investigate possible wrongdoing in connection with the Service')}</li>
					<li>{t('Protect the personal safety of users of the Service or the public')}</li>
					<li>{t('Protect against legal liability')}</li>
				</ul>
				<p>
					{t(
						'When necessary, the Company will limit the disclosures to the minimum extent required to comply with the above.'
					)}
				</p>
				<StrongText className="watched" id="security-of-your-personal-data">
					{t('SECURITY OF YOUR PERSONAL DATA')}
				</StrongText>
				<p>
					{t(
						'The security of your Personal Data is important to us. No method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security from hackers and cyberattacks. The Company maintains suitable cyber insurance coverage to supplement the potential of such an event occurring.'
					)}
				</p>
				<StrongText className="watched" id="children's-privacy">
					{t('CHILDREN’S PRIVACY')}
				</StrongText>
				<p>
					{t(
						'Our service collects data from children under the age of 13, but consent will be obtained from a parent or guardian. We do not knowingly collect personally identifiable information from anyone under the age of 13 without their parent/ guardian consent. If you are a parent or guardian and you are aware that your child has provided us with Personal Data without your consent, please contact us at'
					)}{' '}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.
				</p>
				<StrongText className="watched" id="additional-information-for-certain-jurisdictions">
					{t('ADDITIONAL INFORMATION FOR CERTAIN JURISDICTIONS')}
				</StrongText>
				<p>
					{t(
						'Below provides additional information about the collection, use, and sharing of privacy data in various regions of the world. These separate provisions may be applicable to you based on location of you or the data.'
					)}
				</p>
				<StrongText className="watched" id="california-consumer-privacy-act">
					{t('CALIFORNIA CONSUMER PRIVACY ACT')} (CCPA)
				</StrongText>
				<StrongText>{t('DEFINITIONS')}</StrongText>
				<p>{t('For the purposes of this section of this Privacy Policy:')}</p>
				<ul>
					<li>
						<strong>{t('Personal Data')},</strong>{' '}
						{t(
							'for the purposes of the CCPA (California Consumer Privacy Act), Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with you. Personal data includes, but is not limited to, the following if it identifies, relates to, describes, is reasonably capable of being associated with, or could be reasonably linked, directly or indirectly, with you:'
						)}
						<p>
							i.{' '}
							{t(
								'Identifiers, such as real name, alias, postal address, unique personal identifier, online identifier, internet protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers.'
							)}
						</p>
						<p>ii. {t('Characteristics of protected classification under California or federal law.')}</p>
						<p>
							iii.{' '}
							{t(
								'Commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.'
							)}
						</p>
						<p>iv. {t('Biometric information.')}</p>
						<p>
							v.{' '}
							{t(
								'Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet website, application, or advertisement.'
							)}
						</p>
						<p>vi. {t('Geolocation data.')}</p>
						<p>vii. {t('Audio, electronic, visual, thermal, olfactory, or similar information.')}</p>
						<p>viii. {t('Professional or employment-related information.')}</p>
						<p>ix. {t('Education information')}</p>
						<p>
							x.{' '}
							{t(
								'Inferences drawn from any of the information identified above to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.'
							)}
						</p>
						<p>
							o {t('Personal data')} <strong>{t('does not')},</strong>{' '}
							{t(
								'include your information that is deidentified or aggregate information or publicly available information.'
							)}
						</p>
					</li>
					<li>
						<strong>{t('Business')},</strong>{' '}
						{t('for the purpose of the CCPA (California Consumer Privacy Act),')}{' '}
						{t(
							'refers a legal entity that collects consumers’ personal information and determines the purposes and means of the processing of consumers’ personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers’ personal information, that does business in the State of California.'
						)}
					</li>
					<li>
						<strong>{t('Consumer')},</strong>{' '}
						{t('for the purpose of the CCPA (California Consumer Privacy Act),')}{' '}
						{t('means a natural person who is a California resident.')}
					</li>
					<li>
						<strong>{t('Sale')},</strong>{' '}
						{t('for the purpose of the CCPA (California Consumer Privacy Act),')}{' '}
						{t(
							'means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer’s Personal Information to another business or a third party for monetary or other valuable consideration.'
						)}
					</li>
				</ul>
				<StrongText>{t('YOUR RIGHTS UNDER THE CCPA')}</StrongText>
				<p>
					{t(
						'Under this Privacy Policy, and by law if you are a resident of California, you may exercise the following rights:'
					)}
				</p>
				<ul>
					<li>
						<strong>{t('The right to notice.')}</strong>{' '}
						{t(
							'You must be properly notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.'
						)}
					</li>
					<li>
						<strong>{t('The right to access / the right to request.')}</strong>{' '}
						{t(
							'The CCPA may permit you to request and obtain from us information regarding the disclosure of your Personal Data that has been collected in the past 12 months by us or our subsidiaries to a third-party for the third party’s direct marketing purposes.'
						)}
					</li>
					<li>
						<strong>{t('The right to say no to the sale of Personal Data.')}</strong>{' '}
						{t(
							'You may also have the right to ask us any not to sell your Personal Data to third parties.'
						)}
					</li>
					<li>
						<strong>{t('The right to know about your Personal Data.')}</strong>{' '}
						{t(
							'You may have the right to request and obtain from us information regarding the disclosure of the following:'
						)}
						<p>o {t('The categories of Personal Data collected')}</p>
						<p>o {t('The sources from which the Personal Data was collected')}</p>
						<p>o {t('The business or commercial purpose for collecting or selling the Personal Data')}</p>
						<p>o {t('Categories of third parties with whom we share Personal Data')}</p>
						<p>o {t('The specific pieces of Personal Data we collected about you')}</p>
					</li>
					<li>
						<strong>{t('Further Questions on Personal Data.')}</strong> {t('You can contact us ')}
						<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>{' '}
						{t('or contact us using the information found under the ')}
						<a href="https://www.ramsoft.com/contact-us/" rel="noreferrer" target="_blank">
							{t('Contact Us')}
						</a>{' '}
						{t('section of this website')}.
					</li>
					<li>
						<strong>{t('The right not to be discriminated against.')}</strong>{' '}
						{t(
							'We do not discriminate against you for exercising any of your Consumer’s rights, including by:'
						)}
						<p>o {t('Denying goods or services to you')}</p>
						<p>
							o{' '}
							{t(
								'Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties'
							)}
						</p>
						<p>o {t('Providing a different level or quality of goods or services to you')}</p>
						<p>
							o{' '}
							{t(
								'Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services.'
							)}
						</p>
					</li>
				</ul>
				<StrongText>{t('DO NOT SELL MY PERSONAL INFORMATION')}</StrongText>
				<p>
					{t(
						'We do not sell personal information. However, if you wish to further ensure your personal information is not sold, you may send us an email at '
					)}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>{' '}
					{t('or contact us using the information found under the ')}
					<a href="https://www.ramsoft.com/contact-us/" rel="noreferrer" target="_blank">
						{t('Contact Us')}
					</a>{' '}
					{t('section of this website')}
				</p>
				<StrongText>{t('WEBSITE')}</StrongText>
				<p>
					{t(
						'You can opt out of receiving advertisements that are personalized and served by our Service Providers by indicating this on our “Cookie Settings” banner.'
					)}
				</p>
				<p>
					{t(
						'The opt out will place a cookie on your computer that is unique to the browser you use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again.'
					)}
				</p>
				<StrongText>{t('EXERCISING YOUR CCPA DATA PROTECTION RIGHTS')}</StrongText>
				<p>
					{t(
						'In order to exercise any of your rights under the CCPA, and if you are a California resident, you may reach out using the information provided in the '
					)}
					<a href="https://www.ramsoft.com/contact-us/" rel="noreferrer" target="_blank">
						{t('Contact Us')}
					</a>{' '}
					{t('section of this policy or by sending us an email at ')}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.
				</p>
				<p>
					{t(
						'The Company will endeavor to disclose and deliver the required information free of charge within 45 days of receiving your verifiable request. We may extend the period to provide the required information to you once by an additional 45 days when reasonable, necessary, and with prior notice to you.'
					)}
				</p>
				<StrongText className="watched" id="california-online-privacy-protection-act">
					{t('CALIFORNIA ONLINE PRIVACY PROTECTION ACT')} (CalOPPA)
				</StrongText>
				<StrongText>{t('DEFINITIONS')}</StrongText>
				<p>{t('For the purposes of this section of this Privacy Policy:')}</p>
				<ul>
					<li>
						<strong>{t('Do Not Track (DNT)')}</strong>{' '}
						{t(
							'is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission'
						)}{' '}
						(FTC),{' '}
						{t(
							'for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.'
						)}
					</li>
				</ul>
				<StrongText>{t('“DO NOT TRACK” POLICY')}</StrongText>
				<p>
					{t(
						'Our Service does not respond to Do Not Track signals or other mechanisms that provide you the ability to exercise choice regarding the collection of personally identifiable information about your online activities over time.'
					)}
				</p>
				<p>
					{t(
						'However, some third-party websites located on our sites may keep track of your browsing activities and collect personally identifiable information about your online activities over time and across different websites. If you are visiting such websites, you must set your preferences in your web browser to inform websites that you do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of your web browser.'
					)}
				</p>
				<StrongText>{t('REVIEW OR REQUESTS ABOUT DATA COLLECTION')}</StrongText>
				<p>
					{t(
						'We understand that you may have preferences about how your data is used. If you would like to review or request changes to any of your personally identifiable information that is collected by us, please email us at '
					)}{' '}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.{' '}
				</p>
				<StrongText className="watched" id="pipeda">
					{t('PIPEDA')}
				</StrongText>
				<StrongText>{t('Statement')}</StrongText>
				<p>
					{t(
						'The privacy of personal information is governed by the Personal Information Protection and Electronic Documents Act (PIPEDA). This Policy is based on the standards required by PIPEDA as interpreted by the Company.'
					)}
				</p>
				<p>
					{t(
						'The Company recognizes Individuals’ right to privacy with respect to their Personal Information. This Policy describes the way that the Company collects, uses, safeguards, discloses, and disposes of Personal Information.'
					)}
				</p>
				<p>
					{t(
						'This Policy applies to all Stakeholders and Individuals in connection with personal information that is collected, used or disclosed during Company’s activity.'
					)}
				</p>
				{t('In addition to fulfilling the legal obligations required by PIPEDA, the Company will not:')}
				<p>
					o{' '}
					{t(
						'Publish, communicate, divulge, or disclose to any unauthorized person, firm, corporation, or third party any Personal Information without the express written consent of the Individual Knowingly place themselves in a position where they are under obligation to any organization to disclose Personal Information'
					)}
				</p>
				<p>
					o{' '}
					{t(
						'In the performance of their official duties, disclose Personal Information to family members, friends, colleagues, or organizations in which their family members, friends, or colleagues have an interest Derive personal benefit from Personal Information that they have acquired during the course of fulfilling their duties with the Company'
					)}
				</p>
				<p>
					o{' '}
					{t(
						'Accept any gift or favor that could be construed as being given in anticipation of, or in recognition for, the disclosure of Personal Information.'
					)}
				</p>
				<StrongText>{t('Collection of Personal Information')}</StrongText>
				<p>
					<strong>{t('Identifiers')}</strong>
					{' - '}
					{t(
						`A real name or alias, postal address, signature, home phone number or mobile phone number, bank account number, credit card number, debit card number or other financial information, physical characteristics or description, e-mail address; account name, Social Security Number (SSN), driver's license number or state identification card number, passport number, or other similar identifiers.`
					)}
				</p>
				<p>
					<strong>{t('Protected Classification Characteristics')}</strong>
					{' - '}
					{t(
						`Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth, and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).`
					)}
				</p>
				<p>
					<strong>{t('Commercial Information')}</strong>
					{' - '}
					{t(
						`Records of personal property, products or services purchased, obtained, considered, or other purchasing or consuming histories or tendencies.`
					)}
				</p>
				<p>
					<strong>{t('Inferences Drawn From Other Personal Information')}</strong>
					{' - '}
					{t(
						`Profile reflecting a person’s preference, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.`
					)}
				</p>
				<p>
					<strong>{t('Biometric Information')}</strong>
					{' - '}
					{t(
						`Genetic, physiological, behavioral, and biological characteristics or activity patterns used to extract a template or other identifier or identifying information, such as fingerprints, faceprints, voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.`
					)}
				</p>
				<p>
					<strong>{t('Internet or Other Electronic Network Activity Information')}</strong>
					{' - '}
					{t(
						`Browsing history, search history, and information on a consumer's interaction with a website, application, or advertisement.`
					)}
				</p>
				<p>
					<strong>{t('Geolocation Data')}</strong>
					{' - '}
					{t(
						`Physical location or movements. For example, city, state, country, and ZIP code associated with your IP address or derived through Wi-Fi triangulation; and, with permission in on your mobile device settings, and precise geolocation information from GPS based functionality on your mobile devices.`
					)}
				</p>
				<p>
					<strong>{t('Sensory Data')}</strong>
					{' - '}
					{t(`Audio, electronic, visual, thermal, olfactory, or similar information.`)}
				</p>
				<p>
					<strong>{t('Professional or Employment Related Information')}</strong>
					{' - '}
					{t(
						`Current or past job history, performance evaluations, disciplinary records, workplace injury records, disability accommodations, and complaint records.`
					)}
				</p>
				<p>
					<strong>{t('Emergency Contact Information')}</strong>
					{' - '}
					{t(
						`Such as the name, phone number, address, and e-mail address of another person in the context of having an emergency contact on file, Personal information necessary for us to collect and retain to administer benefits for you and another person relating to you (e.g., your spouse, domestic partner, and dependents), such as their name, Social Security Number, date of birth, telephone number, e-mail, and address.`
					)}
				</p>
				<p>
					<strong>{t('Using the website')}</strong>
					{' - '}
					{t(
						`We collect certain information from your activity on our website, starting when you first arrive and accessing it on an electronic device. We may collect your IP address, device ID, advertising identifiers, browser type, operating system, internet service provider, pages visited (including clicks and duration), and other related log information. For mobile phones, we may collect your device’s GPS signal or other information about nearby Wi-Fi access points and cell towers.`
					)}
				</p>
				<p>
					<strong>{t('Creating a User Profile or Account')}</strong>
					{' - '}
					{t(
						`We may collect information directly from you or an agent authorized to act on your behalf. For example, if you, or someone acting on your behalf, provides your name and e-mail to create a profile or an account. We also collect information indirectly from you or your authorized agent. This can be done through information we collect from you while providing content, products, or services.`
					)}
				</p>
				<StrongText>{t('REVIEW OR REQUESTS ABOUT DATA COLLECTION')}</StrongText>
				<p>
					{t(
						`We understand that you may have preferences about how your data is used. If you would like to review or request changes to any of your personally identifiable information that is collected by us, please email us at `
					)}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.
				</p>
				<StrongText className="watched" id="european-economic-area">
					{t('EUROPEAN ECONOMIC AREA')}
				</StrongText>
				<StrongText>{t('Regulation (GDPR)')}</StrongText>
				<StrongText>A. {t('General')}</StrongText>
				<p>
					{t(
						'This Data Protection Policy establishes consistent data protection standards across the company for:'
					)}
				</p>
				<ul>
					<li>
						{t(
							'Processing personal data within the European Union and the European Economic Area (EU/EEA).'
						)}
					</li>
					<li>{t('Transferring personal data to company locations outside the EU/EEA.')}</li>
					<li>
						{t(
							'To safeguard data transferred outside the EU/EEA, this policy implements binding rules referred to as “Binding Corporate Rules for Controllers (BCR-C)” for the company.'
						)}
					</li>
				</ul>
				<StrongText>1. {t('Contact')}</StrongText>
				<ul>
					<li>
						<strong>{t('Data controller')}</strong>
						{': '}
						{t(
							'The data controller responsible for data processing pursuant to the GDPR, respectively other applicable regulations relating to data protection is:'
						)}
						<p>o RamSoft Inc.</p>
						<p>o 20 Adelaide St. East, Suite 1105</p>
						<p>o Toronto, ON M5H 1L6 Canada</p>
						<p>
							o {t('E-mail:')}{' '}
							<strong>
								<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>
							</strong>
						</p>
					</li>
					<li>
						<strong>{t('EU-GDPR Representative:')}</strong>{' '}
						{t('Our EU-GDPR representative is the Law firm:')}
						<p>o Rickert Rechtsanwaltsgesellschaft mbH</p>
						<p>o Colmantstraße 15</p>
						<p>o 53115 Bonn</p>
						<p>o Germany</p>
						<p>
							o {t('E-Mail:')}{' '}
							<strong>
								<a href="mailto:art-27-rep-ramsoft@rickert.law">art-27-rep-ramsoft@rickert.law</a>
							</strong>
						</p>
					</li>
				</ul>
				<StrongText>2. {t('Scope of Data Protection')}</StrongText>
				<p>
					{t(
						'Data protection applies to personal data, i.e. as defined by the GDPR, all information relating to an identified or identifiable natural person. An identifiable natural person is deemed to be a natural person who can be identified directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or to one or more specific attributes.'
					)}
				</p>
				<p>
					{t('If you have any questions about this Privacy Policy, please contact us via e-mail:')}{' '}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.{' '}
					{t('Depending on the type of request, validation of your identity may be required.')}
				</p>
				<StrongText>3. {t('Your Rights')}</StrongText>
				<p>
					{t(
						'The following rights are granted by European Union directives and regulations. If you wish to exercise any of the rights listed below, please contact us at the above address.'
					)}
				</p>
				<ul>
					<li>
						{t(
							'Right to access – We will gladly confirm whether we are processing any of your personal data, which data we are processing, and for what purpose we are processing it.'
						)}
					</li>
					<li>
						{t(
							'Right to rectification – If any of the data we have stored is incorrect, we would certainly be happy to correct it.'
						)}
					</li>
					<li>
						{t(
							'Right to erasure – Should you wish your personal data to be deleted, we will comply with your request as far as legally possible.'
						)}
					</li>
					<li>
						{t(
							'Right to restriction of processing – Should you wish to restrict use, we will comply with your request as far as legally possible.'
						)}
					</li>
					<li>
						{t(
							'Right to withdraw your consent – Should you wish to revoke any previously granted consent, we will comply with your request. Revocation does not affect the permissibility of the processing of your data up to now.'
						)}
					</li>
				</ul>
				<StrongText>4. {t('Retention period')}</StrongText>
				<p>
					{t(
						'The data processed by us will be in compliance with the statutory provisions, in particular in accordance with Art. 17 and 18 GDPR.'
					)}
				</p>
				<StrongText>5. {t('SSL or TLS encryption')}</StrongText>
				<p>
					{t(
						'For security reasons and to protect the transmission of confidential content, such as orders or requests that you send to us as the site operator, this site uses SSL or TLS encryption to protect your data in transit. You can recognize an encrypted connection by the fact that the address line of the browser changes from “http://” to “https://” and by the lock symbol in your browser line.'
					)}
				</p>
				<StrongText>6. {t('Children')}</StrongText>
				<p>
					{t(
						'Our service collects data from children under the age of 13, but consent will be obtained from a parent or guardian. We do not knowingly collect personally identifiable information from anyone under the age of 13 without their parent/ guardian consent. If you are a parent or guardian and you are aware that your child has provided us with Personal Data without your consent, please contact us at '
					)}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.
				</p>
				<StrongText>7. {t('Transfer')}</StrongText>
				<p>
					{t(
						'The Company may only transfer personal data from within the EU/EEA to entities located outside of this region (including granting access from a third country) under specific conditions:'
					)}
				</p>
				<ul>
					<li>
						{t(
							'The third country must be recognized by the EU Commission as providing an equivalent level of data protection.'
						)}
					</li>
					<li>
						{t(
							`The transfer must adhere to the EU’s standard contractual clauses. The Company is responsible for assessing the third country’s data protection standards to determine if these clauses can be effectively applied. If not, additional safeguardsmust be implemented to achieve an EU/EEA- equivalent level of protection.`
						)}
					</li>
					<li>{t('Other approved safeguards as outlined in Article 46(2) of the GDPR may be used.')}</li>
					<li>
						{t(
							'In limited cases where the above options are not feasible, transfers may be permitted under specific exemptions (e.g., legal claims).'
						)}
					</li>
				</ul>
				<StrongText>8. {t('Monitoring and Reporting on the Regulations of Third Countries')}</StrongText>
				<p>
					{t(
						'The Company operating in jurisdictions outside of the company’s primary region must immediately inform the Data Protection Officer if local laws impede the company’s ability to fulfil data protection obligations or significantly impact the data protection guarantees provided under this policy.'
					)}
				</p>
				<p>
					{t(
						`The Company will assess the situation to identify practical solutions that align with the policy's objectives.`
					)}
				</p>
				<p>
					{t(
						`If the Company is compelled by a public authority to withhold information about data disclosures from the supervisory authority, it must actively seek to overturn this restriction and provide annual summaries of disclosure requests to the relevant authorities (including the number of requests, data types, and requesting entities where possible).`
					)}
				</p>
				<p>
					{t(
						'Transfers of personal data to public authorities must be justified, proportionate, and limited to what is necessary in an open society.'
					)}
				</p>
				<p>{t('Data subjects have the right to enforce this provision.')}</p>
				<StrongText>B. {t('Data processing when using our website')}</StrongText>
				<StrongText>1. {t('Technical provision of website')}</StrongText>
				<p>
					{t(
						'Regardless of whether you use services on our website, your device automatically transmits certain data for technical reasons when you access our website '
					)}
					<strong>
						<a href="https://www.ramsoft.com" rel="noreferrer" target="_blank">
							https://www.ramsoft.com.
						</a>
					</strong>
				</p>
				<p>{t('The following data that you may send us will be stored:')}</p>
				<ul>
					<li>{t('Date and time of access')}</li>
					<li>{t('Browser type and version')}</li>
					<li>{t('Operating system')}</li>
					<li>{t('URL of the website previously visited')}</li>
					<li>{t('Volume of data transmitted')}</li>
					<li>{t('Requested domain')}</li>
					<li>{t('Notification of successful data retrieval')}</li>
					<li>{t('Search term when using a web browser')}</li>
					<li>{t('Abbreviated/anonymized IP')}</li>
					<li>{t('Full IP address')}</li>
					<li>{t('Diagnostic information in the event of errors')}</li>
				</ul>
				<p>
					{t(
						'The processing is carried out in accordance with Art. 6 (1) lit. f) GDPR based on our legitimate interest in improving the stability and functionality of our website. The data is stored and processed for purely technical reasons to operate the website and to provide you with access to it. Website access data is used for error analysis and ensuring system security. Based on your IP address, we also use geolocation to determine the region from which you are visiting our website. We use this information to check whether we can offer you service in your region and to provide you our website in your local language, which corresponds to our legitimate interests pursuant to Art. 6 (1) lit. f) GDPR. The storage of the full IP address for a maximum period of 7 days is also justified by our legitimate interest in achieving the listed purposes, Art. 6 (1) lit. f) GDPR.'
					)}
				</p>
				<StrongText>
					2. {t('Data processing in countries outside the European Union or European Economic Area')}
				</StrongText>
				<p>
					{t(
						'We use different service providers to provide and optimize our website as well as for the provision of our services and several functions. This means regularly that your personal data will be transferred to servers of our service providers to be stored and processed by them on our behalf.'
					)}
				</p>
				<p>
					{t(
						'Generally, we only process the personal data that you actively and knowingly provide to us, for example in the context of concluding a contract or registering a customer account. In cases where the processing is based on consent, we will inform you below about the respectively personal data processed by us as well as the used service provider to provide you full transparency and information for your decision on granting consent.'
					)}
				</p>
				<p>{t('Unless otherwise stated, we use service providers of the following categories:')}</p>
				<ul>
					<li>{t('(Cloud) Service provider (e.g., customer relationship service provider)')}</li>
					<li>{t('Tool provider (e.g., newsletter, contact form),')}</li>
					<li>{t('Marketing- and analysis service provider.')}</li>
				</ul>
				<p>
					{t(
						'We select our service providers after careful consideration and compliance with the legal framework, i.e., by concluding a data processing agreement in accordance with Art. 28 of the GDPR. Insofar as our service providers are not located in the EU or the EEA, they are either located in a country that has a so-called adequacy decision according to the GDPR or, when there is no adequacy decision, we have agreed suitable guarantees pursuant to the GDPR with them to secure the data transfer and to ensure an adequate level of data protection (e.g., Standard Contractual Clauses, with which providers undertake to comply with the European data protection law). Our currently used service providers are based in Canada (adequacy decision) and the US (Standard Contractual Clauses). For more information, please contact us through the email address'
					)}{' '}
					<strong>
						<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.
					</strong>
				</p>
				<StrongText>a. {t('RamSoft Community Portal, Account')}</StrongText>
				<p>
					{t(
						'On our website we provide you with the option to register an account to our RamSoft Community Portal. Your registration is required for the use of certain functions, offers and support services on our websites as well as for the fulfillment of a contract and/or for the implementation of pre-contractual measures (e.g., to book additional services later).'
					)}
				</p>
				<p>
					{t(
						'In case of registering, we will send you an e-mail to your e-mail-address used during registration in which we ask you to confirm your registration (so-called double-opt-in procedure). In the event of important changes to our offers, services or functions, for example regarding the scope of the offer or in the event of technically necessary changes, we will use the e-mail address provided during registration to inform you about this.'
					)}
				</p>
				<StrongText>b. {t('Customer Support, CRM')}</StrongText>
				<p>
					{t(
						'For customer support we provide you with multiple channels to get the support you need. We provide a general support hotline for all and several support channels (Support tickets, WhatsApp, SMS) that can only be used when you have a RamSoft Community Portal Account.'
					)}
				</p>
				<StrongText>i. {t('CRM')}</StrongText>
				<p>
					{t(
						'For handling customer requests and providing customer support we use a so-called Customer Relationship Management tool (“CRM”) from service provider that processes the data on our behalf. Correspondingly, your contact data (e.g., name, e-mail address, phone number) may be stored in said system.'
					)}
				</p>
				<StrongText>ii. {t('Support Hotline')}</StrongText>
				<p>
					{t(
						'If you do not have an account to our RamSoft Community Portal, we provide you with a support hotline via phone (+1 (888) 343-9146 option 2 for support). When calling the support hotline, we will ask you to provide us with certain information on the services you booked (e.g., Company name, Name of contact person/caller, Contract number, Service booked) to verify you represent the company you act on behalf of and information on the support case (e.g., Description of issue, Technical information on the system used.). Also, we will process your phone number used for calling the support hotline.'
					)}
				</p>
				<p>
					{t(
						'The aforementioned data processing is necessary to provide you with the support needed and concluded in our service contract with you. Legal basis is the fulfilling of the contract respectively to implement pre-contractual measures according to Art. 6 (1) lit. b) GDPR. We process your data at least until the support request is fully completed. After that, we will delete your data, unless there are further legal or statutory retention periods that permit or make further data storage necessary.'
					)}
				</p>
				<StrongText>3. {t('Contact, Contact form')}</StrongText>
				<p>
					{t(
						'The processing of your data in the context of contacting us via contact form, e-mail or support is carried out, depending on the content of the inquiry, in the case of purely informational inquiries on the basis of your (presumed) consent pursuant to Art. 6 (1) lit. a) GDPR, or pursuant to Art. 6 (1) lit. b) GDPR, insofar as the contact is in connection with (pre-) contractual performance obligations.'
					)}
				</p>
				<p>
					{t(
						'For contacting us via our form please fill in all mandatory fields, we use these for the proper operation and assigning of your request. Customer requests are processed and stored in our CRM portal, detailed information on CRM under 2.a.i. For providing you with the contact form, we use the service of the service provider Salesforce Marketing Cloud, 10 Bay Street Suite 400, Toronto, ON M5J 2R8, Canada, which processes the data on our behalf.'
					)}
				</p>
				<p>
					{t(
						'Additionally, we use Salesforce Marketing Cloud for analysis and marketing. For this purposes Salesforce Marketing Cloud uses cookies. The information processed with cookies will be merged with your personal data you provide when using our contact form. You can find further information under No. 3 section c “Salesforce Marketing Cloud Analysis and Tracking/Marketing”.'
					)}
				</p>
				<p>
					{t(
						'The legal basis of the processing regarding the cookies is your consent pursuant to Art. 6 (1) lit. a) GDPR. If you do not want the data to be collected and processed via Salesforce Marketing Cloud, you can refuse your consent or revoke it at any time with effect for the future (Cookie-Settings).'
					)}
				</p>
				<p>
					{t(
						'In the context of processing via Salesforce Marketing Cloud, data may be transferred to the USA. The security of the transfer is secured via so-called standard contractual clauses, which ensure that the processing of personal data is subject to a level of security that corresponds to that of the GDPR. If the standard contractual clauses are not sufficient to establish an adequate level of security, your consent pursuant to Art. 49 (1) lit. a) GDPR may serve as the legal basis for the transfer to third countries.'
					)}
				</p>
				<p>
					{t('More information about ')}{' '}
					<a href="https://www.salesforce.com/company/privacy/full_privacy/" rel="noreferrer" target="_blank">
						{t('Salesforce Marketing Cloud’s privacy policy.')}
					</a>
				</p>
				<p>
					{t('More information from ')}{' '}
					<a href="https://www.salesforce.com/privacy/overview/" rel="noreferrer" target="_blank">
						{t('Salesforce Marketing Cloud regarding EU data protection regulations.')}
					</a>
				</p>
				<p>
					{t('More information about the cookies used by Salesforce Marketing Cloud can be found ')}{' '}
					<a
						href="https://help.salesforce.com/s/articleView?id=sf.networks_cookies.htm&type=5"
						rel="noreferrer"
						target="_blank"
					>
						{t('here.')}
					</a>
				</p>
				<p>
					{t(
						'We have concluded a Data Processing Agreement with Salesforce Marketing Cloud, so that the processing by Salesforce Marketing Cloud is carried out exclusively on our instructions.'
					)}
				</p>
				<StrongText>a. {t('Support via RamSoft Community Portal')}</StrongText>
				<StrongText>i. {t('SMS/WhatsApp')}</StrongText>
				<p>
					{t(
						'When having registered an account to our Community Portal, we offer our customers the possibility to contact us via SMS (+1 909 318-0751) and the messenger service WhatsApp (+1 909 359-9170, Instant-Messaging-Service provided by Meta Platforms, Inc., 1601 Willow Road Menlo Park, CA 94025, USA).'
					)}
				</p>
				<p>
					{t(
						'When you contact us via SMS or the messenger service WhatsApp, you automatically transmit your mobile number to us. For WhatsApp, additionally the terms of use and privacy policy of WhatsApp apply'
					)}{' '}
					<strong>
						<a href="www.whatsapp.com/legal" rel="noreferrer" target="_blank">
							www.whatsapp.com/legal.
						</a>
					</strong>
				</p>
				<p>
					{t(
						'The legal basis for the communication for support requests via SMS/WhatsApp is the consent given by you upon conclusion of the contract pursuant to Art. 6 (1) lit. a), Art. 7 GDPR.'
					)}
				</p>
				<StrongText>ii. {t('Chat & Support Ticket via Community Portal')}</StrongText>
				<p>
					{t(
						'You can receive our support via live chat with one of our customer employees or Open a Support Ticket to start the conversation. For the latter you must log in to the '
					)}
					<strong>
						<a href="https://www.ramsoft.com" rel="noreferrer" target="_blank">
							{t('Community Portal.')}
						</a>
					</strong>
				</p>
				<StrongText>iii. {t('Ticket system Live Agent')}</StrongText>
				<p>
					{t(
						'We also use the “Live Agent” function (service ticket system) of a service provider, which processes the data on our behalf. It also collects pseudonymized technical information related to the request (e.g. time, IP address, browser, operating system). A ticket is then created for you, which is sent to you by e-mail. We can use this to process customer inquiries in chronological order of the inquiry date. You will receive the answer to your request by e-mail. The purpose of this processing is our legitimate interest in providing user-friendly and effective customer service and responding to customer inquiries from you. The legal basis is Art. 6 (1) lit. a), f) GDPR.'
					)}
				</p>
				<StrongText>4. {t('Newsletter')}</StrongText>
				<p>
					{t(
						'We provide you with a newsletter you can register to on our website on news related to RamSoft, e.g., on new services and offers or functions as well as non-RamSoft related news, e.g., on general radiology and healthcare topics, medical imaging software as well as events and happenings. To send you the newsletter, we process your e-mail address you provided when registering for the newsletter. The legal basis for the processing is your consent in accordance with Art. 6 (1) lit. a) GDPR. After registering your e-mail address, you will receive an e-mail from us confirming that you have successfully subscribed to the newsletter. We are also entitled to keep your IP addresses as well as the registration times in order to check your registration status and to adequately clarify any possible misuse of your data. The legal basis for this is Art. 6 (1) lit. a), lit. c), Art. 7 GDPR.'
					)}
				</p>
				<p>
					{t(
						'Your data will be stored in an electronic newsletter system for the duration of your subscription. For this purpose and for sending the newsletter, we use the newsletter service of WordPress of the processor WP Engine, Irongate House, 22-30 Duke’s Place, London, EC3A 7LP United Kingdom, which processes the data on our behalf. We have concluded a Data Processing Agreement according to Art. 28 GDPR with WP Engine.'
					)}
				</p>
				<p>
					{t(
						'Since we base our processing on your consent, this means that you have the right to revoke your consent at any time or to object to the processing of your personal data for the purpose of sending the newsletter. In this case, we will immediately remove you from our newsletter distribution list to comply with your request. You may withdraw your consent at any time by sending an e-mail to '
					)}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.{' '}
					{t(
						'or by following the instructions at the bottom of a newsletter e-mail to click “Unsubscribe” and adjust your email preferences. If you send us an e-mail, please let us know what you want your revocation to refer to so that we can match your request.'
					)}
				</p>
				<StrongText>5. {t('Usage of cookies, statistics, advertising, tracking and retargeting')}</StrongText>
				<p>
					{t(
						'We use “cookies” to provide you with a variety of features and improve your user experience. Cookies are small text files that are temporarily stored on your computer via your browser.'
					)}
				</p>
				<p>
					{t(
						'If you do not want us to use cookies, you can change your browser settings accordingly. Please note that if you completely disable the use of cookies, the functionality and scope of the website may be impaired.'
					)}
				</p>
				<p>
					{t(
						'You can find further information on cookies, in particular the categories of cookies as well as the respective cookies in our '
					)}
					<strong>
						<a href="https://www.ramsoft.com/cookies-policy/" rel="noreferrer" target="_blank">
							{t('Cookies policy.')}
						</a>
					</strong>
				</p>
				<p>
					{t(
						'Also, you can change the settings of your cookies via the respective change button in the cookie banner of the website.'
					)}
				</p>
				<StrongText>a. {t('Google Analytics')}</StrongText>
				<p>
					{t(
						'The “Google Analytics” service is used on this website. The operator of this service is Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland.'
					)}
				</p>
				<p>
					{t(
						'Google Analytics is a web analysis service and enables us to draw conclusions about user behavior on our website by setting cookies and the information thus obtained. The information generated by the cookies is sent to a Google server in the USA and stored there. Google Analytics sets cookies in your browser for the duration of two years from your last visit. On our website, the Google Analytics service is used exclusively pseudonymously. Your IP address is only recorded in abbreviated form and thus anonymized.'
					)}
				</p>
				<p>{t('With the help of Google Analytics, the following data is collected and processed:')}</p>
				<ul>
					<li>{t('IP address (anonymized)')}</li>
					<li>{t('Usage data')}</li>
					<li>{t('Click path')}</li>
					<li>{t('App updates')}</li>
					<li>{t('Browser information')}</li>
					<li>{t('Device information')}</li>
					<li>{t('JavaScript support')}</li>
					<li>{t('Visited pages')}</li>
					<li>{t('Referrer URL')}</li>
					<li>{t('Downloads')}</li>
					<li>{t('Flash version')}</li>
					<li>{t('Location information')}</li>
					<li>{t('Purchase activity')}</li>
					<li>{t('Widget interactions')}</li>
					<li>{t('Date and time of the visit')}</li>
				</ul>
				<p>
					{t(
						'The legal basis of the processing is your consent pursuant to Art. 6 (1) lit. a) GDPR. When first visiting our website, we ask you for your consent. If you do not want Google Analytics to collect and process the data, you can refuse your consent or revoke it at any time with effect for the future (Cookie-Settings).'
					)}
				</p>
				<p>
					{t(
						'The data may be transferred to the following recipients in addition to Google Ireland Limited as part of the processing:'
					)}
				</p>
				<p>– {t('Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.')}</p>
				<p>– {t('Alphabet Inc, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.')}</p>
				<p>
					{t(
						'Within the scope of processing by Google Analytics, data may be transmitted to the USA. Between Google Ireland Limited and Google LLC, the security of the transmission is secured by so-called standard contractual clauses, which ensure that the processing of personal data is subject to a level of security that corresponds to that of the GDPR. If the standard contractual clauses are not sufficient to establish an adequate level of security, consent will be obtained from you in advance as part of the consent management system in accordance with Art. 49 (1) lit. a) GDPR.'
					)}
				</p>
				<StrongText>b. {t('Google Web Fonts')}</StrongText>
				<p>
					{t(
						'The Google Web Fonts service is used on this website. The service is provided by Google Ireland Limited Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland. With the help of Google Web Fonts, we can load and display external fonts, so-called Google Fonts, on our website.'
					)}
				</p>
				<p>
					{t(
						'As part of the processing via Google Web Fonts, the following personal data is collected and processed by Google:'
					)}
				</p>
				<ul>
					<li>{t('IP address')}</li>
				</ul>
				<p>
					{t(
						'The legal basis for this processing is Art. 6 (1) lit. f) GDPR – a legitimate interest. Our legitimate interest in processing is to present the website in an attractive and user-friendly manner. Local hosting ensures that no data is transmitted to Google, no corresponding data transfer takes place.'
					)}
				</p>
				<StrongText>c. {t('Google reCAPTCHA')}</StrongText>
				<p>
					{t(
						'We use the reCAPTCHA function of Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland (“Google”) on this website. This function is primarily used to distinguish whether an entry is made by a natural person or is misused by machine and automated processing.'
					)}
				</p>
				<p>{t('As a rule, the following data is collected and processed:')}</p>
				<ul>
					<li>{t('IP address')}</li>
					<li>{t('Date')}</li>
					<li>{t('Referrer URL')}</li>
					<li>{t('Complete screenshot of the browser window')}</li>
					<li>{t('Device information')}</li>
					<li>{t('Browser Plugins')}</li>
					<li>{t('Cookies, if applicable')}</li>
				</ul>
				<p>
					{t('Further information on Google reCAPTCHA as well as Google’s privacy policy can be viewed at:')}{' '}
					<a href="https://www.google.com/intl/de/policies/privacy/  ">
						https://www.google.com/intl/de/policies/privacy/
					</a>
				</p>
				<p>
					{t(
						'The legal basis of the processing is your consent pursuant to Art. 6 (1) lit. a GDPR. If you do not want the data to be collected and processed via Google reCAPTCHA, you can refuse your consent or revoke it at any time with effect for the future (Cookie-Settings).'
					)}
				</p>
				<p>
					{t(
						'In the context of processing via reCAPTCHA, data may be transmitted to the USA. The security of the transfer is secured via so-called standard contractual clauses, which ensure that the processing of personal data is subject to a level of security that corresponds to that of the GDPR. If the standard contractual clauses are not sufficient to establish an adequate level of security, consent will be obtained from you in advance as part of the consent management system in accordance with Art. 49 (1) lit. a) GDPR.'
					)}
				</p>
				<StrongText>d. {t('Salesforce Marketing Cloud Analysis and Tracking/Marketing')}</StrongText>
				<p>
					{t(
						'On this website, we use the service Salesforce Marketing Cloud for the purposes of analysis of the usage of our web services and for tracking/marketing measures to provide you with advertising that really interests you.'
					)}
				</p>
				<p>
					{t(
						'For the purpose of analysis and as part of the optimization of our marketing measures, the following data may be collected and processed via Salesforce Marketing Cloud:'
					)}
				</p>
				<ul>
					<li>{t('Geographic position')}</li>
					<li>{t('Browser type')}</li>
					<li>{t('Navigation information')}</li>
					<li>{t('Referral URL')}</li>
					<li>{t('Performance data')}</li>
					<li>{t('Information about how often the application is used')}</li>
					<li>{t('Mobile apps data')}</li>
					<li>{t('Salesforce Marketing Cloud subscription service credentials')}</li>
					<li>{t('Files that are displayed on site')}</li>
					<li>{t('Domain names')}</li>
					<li>{t('Pages viewed')}</li>
					<li>{t('Aggregated usage')}</li>
					<li>{t('Operating system version')}</li>
					<li>{t('Internet service provider')}</li>
					<li>{t('IP address')}</li>
					<li>{t('Device identifier')}</li>
					<li>{t('Duration of visit')}</li>
					<li>{t('Where the application was downloaded from')}</li>
					<li>{t('Operating system')}</li>
					<li>{t('Events that occur within the application')}</li>
					<li>{t('Access times')}</li>
					<li>{t('Clickstream data')}</li>
					<li>{t('Device model and version')}</li>
				</ul>
				<p>
					{t(
						'The legal basis of the processing is your consent pursuant to Art. 6 (1) lit. a) GDPR. If you do not want the data to be collected and processed via Salesforce Marketing Cloud, you can refuse your consent or revoke it at any time with effect for the future (Cookie-Settings).'
					)}
				</p>
				<p>
					{t(
						'In the context of processing via Salesforce Marketing Cloud, data may be transferred to the USA. The security of the transfer is secured via so-called standard contractual clauses, which ensure that the processing of personal data is subject to a level of security that corresponds to that of the GDPR. If the standard contractual clauses are not sufficient to establish an adequate level of security, your consent pursuant to Art. 49 (1) lit. a) GDPR may serve as the legal basis for the transfer to third countries.'
					)}
				</p>
				<p>
					{t('More information about ')}{' '}
					<a href="https://www.salesforce.com/company/privacy/full_privacy/" rel="noreferrer" target="_blank">
						{t('Salesforce Marketing Cloud’s privacy policy.')}
					</a>
				</p>
				<p>
					{t('More information from ')}{' '}
					<a href="https://www.salesforce.com/privacy/overview/" rel="noreferrer" target="_blank">
						{t('Salesforce Marketing Cloud regarding EU data protection regulations.')}
					</a>
				</p>
				<p>
					{t('More information about the cookies used by Salesforce Marketing Cloud can be found ')}{' '}
					<a
						href="https://help.salesforce.com/s/articleView?id=sf.networks_cookies.htm&type=5"
						rel="noreferrer"
						target="_blank"
					>
						{t('here.')}
					</a>
				</p>
				<StrongText>e. {t('LinkedIn')}</StrongText>
				<p>
					{t('We use the')}{' '}
					<a
						href="https://business.linkedin.com/marketing-solutions/ad-targeting/retargeting"
						rel="noreferrer"
						target="_blank"
					>
						{t('Retargeting Tool')}
					</a>{' '}
					{t(
						'as well as the conversion tracking of LinkedIn Ireland, Wilton Plaza, Wilton Place, Dublin 2, Ireland (“LinkedIn”). For this purpose, the'
					)}{' '}
					<a
						href="https://business.linkedin.com/marketing-solutions/insight-tag"
						rel="noreferrer"
						target="_blank"
					>
						{t('LinkedIn Insight Tag')}
					</a>{' '}
					{t(
						'is integrated on our website, which enables LinkedIn to collect statistical data about your visit and use of our website and to provide us with corresponding aggregated statistics on this basis. In addition, the service is used to be able to show you interest-specific and relevant offers and recommendations after you have found out about certain services, information and offers on the website. The information in this regard is stored in a cookie. Lastly, LinkedIn is used for'
					)}{' '}
					<a
						href="https://business.linkedin.com/marketing-solutions/generate-leads?trk=lms-36b"
						rel="noreferrer"
						target="_blank"
					>
						{t('Lead Generation')}
					</a>{' '}
					{t(
						'and marketing outreach to further match corporate interests to the correct audience. Further information on data processing can be found in the LinkedIn’s'
					)}{' '}
					<a href="https://www.linkedin.com/legal/privacy-policy" rel="noreferrer" target="_blank">
						{t('Privacy Policy')}
					</a>
					.
				</p>
				<p>{t('As a rule, the following data is collected and processed:')}</p>
				<ul>
					<li>{t('First and Last Name')}</li>
					<li>{t('Email Address')}</li>
					<li>{t('Phone Number')}</li>
					<li>{t('IP address')}</li>
					<li>{t('Device information')}</li>
					<li>{t('Browser information')}</li>
					<li>{t('Referrer URL')}</li>
					<li>{t('Timestamp')}</li>
					<li>{t('Any other data such as personal preferences, requirements, or comments')}</li>
				</ul>
				<p>
					{t(
						'The legal basis for the processing is your consent pursuant to Art. 6 (1) lit. a) GDPR. If you do not want the data to be collected and processed via LinkedIn, you can refuse your consent or revoke it at any time with effect for the future (Cookie-Settings).'
					)}
				</p>
				<p>
					{t(
						'In the context of processing via LinkedIn, data may be transferred to the USA and Singapore. The security of the transfer is regularly secured via so-called standard contractual clauses, which ensure that the processing of personal data is subject to a level of security that corresponds to that of the GDPR. If the standard contractual clauses are not sufficient to establish an adequate level of security, consent will be obtained from you in advance as part of the consent management system in accordance with Art. 49 (1) lit. a) GDPR.'
					)}
				</p>
				<StrongText>f. {t('Qualified Chatbot')}</StrongText>
				<p>
					{t(
						'On this website, we use the service Qualified Chatbot for the purposes of improving user interaction, providing customer support, and enhancing the overall user experience. Qualified Chatbot enables automated communication with visitors to our website, allowing us to address inquiries and offer assistance in real-time.'
					)}
				</p>
				<p>
					{t(
						'For the purpose of analysis and optimization of our customer support measures, the following data may be collected and processed via Qualified Chatbot:'
					)}
				</p>
				<ul>
					<li>{t('Geographic position')}</li>
					<li>{t('Browser type')}</li>
					<li>{t('Navigation information')}</li>
					<li>{t('Referral URL')}</li>
					<li>{t('Performance data')}</li>
					<li>{t('Information about how often the chatbot is used')}</li>
					<li>{t('Mobile apps data')}</li>
					<li>{t('Qualified Chatbot subscription service credentials')}</li>
					<li>{t('Files that are displayed on site')}</li>
					<li>{t('Domain names')}</li>
					<li>{t('Pages viewed')}</li>
					<li>{t('Aggregated usage')}</li>
					<li>{t('Operating system version')}</li>
					<li>{t('Internet service provider')}</li>
					<li>{t('IP address')}</li>
					<li>{t('Device identifier')}</li>
					<li>{t('Duration of visit')}</li>
					<li>{t('Where the application was downloaded from')}</li>
					<li>{t('Operating system')}</li>
					<li>{t('Events that occur within the application')}</li>
					<li>{t('Access times')}</li>
					<li>{t('Clickstream data')}</li>
					<li>{t('Device model and version')}</li>
				</ul>
				<p>
					{t(
						'The legal basis of the processing is your consent pursuant to Art. 6 (1) lit. a) GDPR. If you do not want the data to be collected and processed via Qualified Chatbot, you can refuse your consent or revoke it at any time with effect for the future (Cookie-Settings).'
					)}
				</p>
				<p>
					{t(
						'In the context of processing via Qualified Chatbot, standard contractual clauses, which ensure that the processing of personal data is subject to a level of security that corresponds to that of the GDPR. If the standard contractual clauses are not sufficient to establish an adequate level of security, your consent pursuant to Art. 49 (1) lit. a) GDPR may serve as the legal basis for the transfer to third countries.'
					)}
				</p>
				<ul>
					<li>
						<a href="https://www.qualified.com/legal/privacy" rel="noreferrer" target="_blank">
							{t('More information about Qualified Chatbot’s privacy policy')}
						</a>
					</li>
					<li>
						<a
							href="https://www.qualified.com/legal/data-processing-addendum"
							rel="noreferrer"
							target="_blank"
						>
							{t('More information from Qualified Chatbot regarding data processing')}
						</a>
					</li>
					<li>
						<a href="https://cookiepedia.co.uk/giving-consent-to-cookies" rel="noreferrer" target="_blank">
							{t('More information about the cookies used by Qualified Chatbot can be found here')}
						</a>
					</li>
				</ul>
				<StrongText>C. {t('Data processing when using our Service')}</StrongText>
				<StrongText>1. {t('Technical provision of the Web Application')}</StrongText>
				<p>
					{t(
						'We require technical operating data to enable the secure operation of our website and app and to be able to provide the contractually agreed services.'
					)}
				</p>
				<p>
					{t(
						'Therefore, we only collect and process the data that your internet browser or the mobile device you use (e.g., smartphone, tablet) automatically transmits to us. This happens every time you visit our website unless you have suppressed the transmission of this data by configuring your browser accordingly. Processed are:'
					)}
				</p>
				<ul>
					<li>{t('IP address used')}</li>
					<li>{t('Host name of the device/device ID used')}</li>
					<li>{t('Time of access and time zone')}</li>
					<li>{t(`The client’s file request (domain name, file name, and URL)`)}</li>
					<li>{t('The HTTP response code')}</li>
					<li>{t('The website from which you linked to us')}</li>
					<li>{t('The App and version you are using')}</li>
					<li>
						{t(
							'The amount of data transferred and the access status (file transferred, file not found, etc.)'
						)}
					</li>
					<li>{t('Operating system used')}</li>
					<li>{t('Internet browser used')}</li>
					<li>{t('First and last name / Username')}</li>
					<li>{t('Country')}</li>
					<li>{t('Language Settings')}</li>
				</ul>
				<p>
					{t(
						'The processing is carried out in accordance with Art. 6 (1) lit. f) GDPR based on our legitimate interest in improving the stability and functionality of Our Service. The data is stored and processed for purely technical reasons to operate the Services and to provide you with access to it. Web Browser Application is used for error analysis and ensuring system security. Based on your IP address, we also use geolocation to determine the region from which you are visiting our Web Browser Application. We use this information to check whether we can offer our Web Browser Application in your region and to provide you services in your local language, which corresponds to our legitimate interests pursuant to Art. 6 (1) lit. f) GDPR. The storage of the full IP address is also justified by our legitimate interest in achieving the listed purposes, Art. 6 (1) lit. f) GDPR and to maintain security of the Service.'
					)}
				</p>
				<p>
					{t(
						'For the technical provision of Web Browser Application, we use the hosting services of Microsoft Azure to process meta and communication data.'
					)}
				</p>
				<StrongText>2. {t('Technical Provision of the Mobile Application')}</StrongText>
				<StrongText>{t('Download of the Application')}</StrongText>
				<p>
					{t(
						'In order to download the app, personal data is transmitted to the App Store/Play Store, in particular username, email address and customer number of the account, time of download and the individual device ID. We have no influence on this data collection and are not responsible for it. We only process this data to the extent necessary for the download of the application.'
					)}
				</p>
				<StrongText>{t('Application Provision')}</StrongText>
				<p>
					{t(
						'We require technical operating data to enable the secure operation of our Mobile Application and to be able to provide the contractually agreed services.'
					)}
				</p>
				<p>
					{t(
						'Therefore, we only collect and process the data that your internet browser or the mobile device you use (e.g., smartphone, tablet) automatically transmits to us. This happens every time you visit our Website unless you have suppressed the transmission of this data by configuring your browser accordingly. Processed are:'
					)}
				</p>
				<ul>
					<li>{t('IP address used')}</li>
					<li>{t('Host name of the device/device ID used')}</li>
					<li>{t('Unique Device ID')}</li>
					<li>{t('MAC-Address')}</li>
					<li>{t('Time of access and time zone')}</li>
					<li>{t('The client’s file request (domain name, file name, and URL)')}</li>
					<li>{t('The HTTP response code')}</li>
					<li>
						{t(
							'The amount of data transferred and the access status (file transferred, file not found, etc.)'
						)}
					</li>
					<li>{t('Operating system used')}</li>
					<li>{t('Internet browser used')}</li>
					<li>{t('First and last name / Username')}</li>
					<li>{t('Country')}</li>
					<li>{t('Language Settings')}</li>
				</ul>
				<p>
					{t(
						'The processing is carried out in accordance with Art. 6 (1) lit. f) GDPR based on our legitimate interest in improving the stability and functionality of our Mobile Application. The data is stored and processed for purely technical reasons to operate Our Services and to provide you with access to it. Access data to Our Services is used for error analysis and ensuring system security. Based on your IP address, we also use geolocation to determine the region from which you are visiting our Mobile Application. We use this information to check whether we can offer you service in your region and to provide you our Mobile Application in your local language, which corresponds to our legitimate interests pursuant to Art. 6 (1) lit. f) GDPR. The storage of the full IP address is also justified by our legitimate interest in achieving the listed purposes, Art. 6 (1) lit. f) GDPR.'
					)}
				</p>
				<StrongText>3. {t('Registration of a Blume user account')}</StrongText>
				<p>
					{t(
						'When you want to use Blume, you must register an account, either via Web Browser Application or Mobile Application. Your registration is required for the use of the functions, offers and support services within Blume (e.g., to book doctors’ appointments). Legal basis is the fulfilment of a contract between you and RamSoft (Agreement to the Terms of Use).'
					)}
				</p>
				<StrongText>{t('Register via your email address')}</StrongText>
				<p>
					{t(
						'You can register an account with your email address. We will send you an e-mail to your email-address used during registration in which we ask you to confirm your registration (so-called double-opt-in procedure). In the event of important changes to our offers, services, or functions, for example regarding the scope of the offer or in the event of technically necessary changes, we will use the e-mail address provided during registration to inform you about this.'
					)}
				</p>
				<StrongText>
					{t('Register with a third-party provider’s account (Facebook, Apple, Microsoft, or Google)')}
				</StrongText>
				<p>
					{t(
						'You can also register an account with your e-mail address in your third-party provider’s account.'
					)}
				</p>
				<ul>
					<li>
						Facebook:{' '}
						<a href="https://developers.facebook.com/docs/facebook-login/ios/">
							https://developers.facebook.com/docs/facebook-login/ios/
						</a>
						,
					</li>
					<li>
						Apple:{' '}
						<a href="https://developer.apple.com/sign-in-with-apple/">
							https://developer.apple.com/sign-in-with-apple/
						</a>
						,
					</li>
					<li>
						Google:{' '}
						<a href="https://developers.google.com/identity/sign-in/ios/sign-in">
							https://developers.google.com/identity/sign-in/ios/sign-in
						</a>
						,
					</li>
					<li>
						Microsoft:{' '}
						<a href="https://support.microsoft.com/en-us/account-billing/sign-in-and-start-apps-from-the-my-apps-portal">
							https://support.microsoft.com/en-us/account-billing/sign-in-and-start-apps-from-the-my-apps-portal
						</a>
						,
					</li>
				</ul>
				<p>
					{t(
						'The legal basis for processing your personal data is our legitimate interest in a secure login process and increasing the convenience of Blume. If you no longer wish to log in through the provider into Our Service, you must remove this connection in your user account with the respective provider and log out of Blume. In this case, you will no longer be able to access Blume via the provider. '
					)}
					<strong>
						{t('Please note that this does not lead to an automatic deletion of your personal data.')}
					</strong>
				</p>
				<StrongText>4. {t('User account data')}</StrongText>
				<p>
					{t(
						'While using Our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:'
					)}
				</p>
				<ul>
					<li>{t('First name and last name')}</li>
					<li>{t('E-Mail Address')}</li>
					<li>{t('Password (encrypted)')}</li>
				</ul>
				<p>
					{t(
						'The processing of data is carried out to fulfil the contract between RamSoft and the user (Agreement to the Terms of Use) in accordance with Art. 6 (1) lit. b) GDPR.'
					)}
				</p>
				<p>{t('You can provide us with the following additional information if you want to.')}</p>
				<ul>
					<li>{t('Middle Name')}</li>
					<li>{t('Date of Birth')}</li>
					<li>{t('Language')}</li>
					<li>{t('Social Security Number  (or relevant Identification Number)')}</li>
					<li>{t('Health Status')}</li>
					<li>{t('Mother’s Maiden Name')}</li>
					<li>{t('Mailing Address including State, Province, ZIP/Postal code, City, Country')}</li>
					<li>{t('Phone number')}</li>
					<li>{t('Emergency Contact')}</li>
				</ul>
				<StrongText>5. {t('Manage and share documents')}</StrongText>
				<p>
					{t(
						'You can upload your documents, such as historical reports, medical image data, and other document types involved in patient care. If you want, you can share the personal information you uploaded with your healthcare provider. We do not decide on the purpose of the usage of the data, we only host and store the data for you.'
					)}
				</p>
				<p>
					{t(
						'For this purpose, we may process the following data: Your last name (and maiden name), First name and documents shared by you. The legal basis for this purpose is your consent according to Art. 6 (1) lit. a), Art. 9 (2) lit. a) GDPR.'
					)}
				</p>
				<StrongText>6. {t('Communication and Notifications')}</StrongText>
				<p>
					{t(
						'For communication and notification services in Our Service, we use the services provided by Twilio Inc., 375 Beale Street, Suite 300, San Francisco CA 94105, USA . Twilio is a provider of communication services and allows us to receive and/or reply to your text messages or send you notifications. The purpose is to enhance your user experience and help you make the most of the Service’s features. You have the option to manage, enable or disable these notifications at any time through the settings on your mobile device.'
					)}
				</p>
				<p>
					{t('For more information, please see:')}{' '}
					<a href="https://www.twilio.com/en-us/legal/privacy" rel="noreferrer" target="_blank">
						https://www.twilio.com/en-us/legal/privacy
					</a>
				</p>
				<p>
					{t(
						'Legal basis for this is your consent according to Art. 6 (1) lit. a) GDPR. Consent may be withdrawn at any time. Your data will be deleted immediately after withdrawal unless there is a legal obligation to retain the data. Twilio acts as data processor for us and is bound to our instruction by a data processing agreement (e.g., according to Art. 28 GDPR) and so-called standard contractual clauses, which ensure that the processing of personal data is subject to a level of security that corresponds to that of the GDPR '
					)}
					<a
						href="https://www.twilio.com/en-us/legal/data-protection-addendum"
						rel="noreferrer"
						target="_blank"
					>
						https://www.twilio.com/en-us/legal/data-protection-addendum
					</a>
					.{' '}
					{t(
						'If the standard contractual clauses are not sufficient to establish an adequate level of security, your consent pursuant to Art. 49 (1) lit. a) GDPR may serve as the legal basis for the transfer to third countries.'
					)}
				</p>
				<StrongText>7. {t('Data subject requests, contacting us, and bug tracking')}</StrongText>
				<p>
					{t(
						'We use Jira ticketing system, a platform from Atlassian Inc. (San Francisco, Harrison Street Location), 1098 Harrison Street, San Francisco, California 94103, USA to handle your claims and inquiries via email and contact forms. Jira also acts as the primary bug tracking tool in support of the Service and is used to field application specific errors reported by application users. Legal basis is our legitimate interest to process your requests as soon as possible.'
					)}
				</p>
				<p>
					{t('For more information see:')}{' '}
					<a
						href="https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers"
						rel="noreferrer"
						target="_blank"
					>
						https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers
					</a>{' '}
					{t('and')}{' '}
					<a
						href="https://www.atlassian.com/legal/data-transfer-impact-assessment"
						rel="noreferrer"
						target="_blank"
					>
						https://www.atlassian.com/legal/data-transfer-impact-assessment
					</a>
					.
				</p>
				<StrongText>8. {t('Search functionality ')}</StrongText>
				<p>
					{t(
						'We use Elasticsearch the search technology service of the provider Elasticsearch, 800 West El Camino Real, Suite 350, Mountain View, California 94040, USA.'
					)}
				</p>
				<p>
					{t(
						'For the provision of the search function via the search box and for navigation and filters, the provider collects and indexes application data for performance and availability purposes.'
					)}
				</p>
				<p>
					{t(
						'If personal data is also processed in this context, the processing is based on our legitimate interest in providing an error tolerant search.'
					)}
				</p>
				<p>
					{t(
						'Elasticsearch acts as data processor for us and is bound to our instruction by a data processing agreement (e.g., according to Art. 28 GDPR) and standard contractual clauses, which provides an adequate level of data protection. If the standard contractual clauses are not sufficient to establish an adequate level of security, your consent pursuant to Art. 49 (1) lit. a) GDPR may serve as the legal basis for the transfer to third countries.'
					)}
				</p>
				<StrongText>9. {t('Crash reporting and Troubleshooting')}</StrongText>
				<p>
					{t(
						'We use Datadog, a service of the service provider Datadog Inc. 620 8th Avenue, Floor 45, New York, NY 10018 United States   for Cloud Monitoring. The service is used for the collection of application logs, crash reports, and records of full user interactions of Our Service to report incident data with session replay.'
					)}
				</p>
				<p>
					{t(
						'Datadog records information about the behavior of website visitors, allowing us to analyze it and improve the user experience of our application . Datadog processes information on clicks, mouse movements, inputs (with the exception of sensitive information), scrolling movements, browser used, device type, IP address, pages visited and session duration.'
					)}
				</p>
				<p>
					{t(
						'The legal basis for data processing is your consent Art. 6 (1) lit. a) GDPR. You can withdraw your consent for data processing at any time.'
					)}
				</p>
				<p>
					{t('For more information see:')}{' '}
					<a href="https://www.datadoghq.com/legal/privacy/" rel="noreferrer" target="_blank">
						https://www.datadoghq.com/legal/privacy/
					</a>{' '}
					{t('and')} <a href="mailto:privacy@ramsoft.com">{t('Data Subject Request Form')}</a>
				</p>
				<p>
					{t(
						'Datadog ensures an adequate level of data protection via the standard contractual clauses. If the standard contractual clauses are not sufficient to establish an adequate level of security, your consent pursuant to Art. 49 (1) lit. a) GDPR may serve as the legal basis for the transfer to third countries.'
					)}
				</p>
				<StrongText> 10. {t('Browser Refresh within the App')}</StrongText>
				<p>
					{t(
						'We use the service Twilio Sync of the service provider Twilio Inc, 375 Beale Street, Suite 300, San Francisco CA 94105, USA in order to service to refresh pages in our App. Twilio Sync acts as a passthrough service to update changes made in the application and reflect those changes in the web browser or mobile app session of the user. Twilio Sync stores metadata of the processes performed for 120 days before being deleted and does not store any personal or sensitive information generated from the Service.'
					)}
				</p>
				<p>
					{t('For more information, please see:')}{' '}
					<a href="https://www.twilio.com/en-us/legal/privacy" rel="noreferrer" target="_blank">
						https://www.twilio.com/en-us/legal/privacy
					</a>
				</p>
				<StrongText>D. {t('Data processing on behalf of healthcare facility')}</StrongText>
				<StrongText>{t('Booking appointment')}</StrongText>
				<p>
					{t(
						'In the context of booking appointments via the calendar function, we process personal data on behalf of the healthcare facility to manage user accounts, to enable functions around appointment booking, to provide customer support and to share with the user relevant information around appointment booking. For this purpose, we process the following personal data, first and last name, as well as the appointment history, including reason for appointment, doctor’s specialty, date of appointment, place of appointment, status of appointment (upcoming, past, cancelled, confirmed), Details and documents related to the appointment.'
					)}
				</p>
				<StrongText className="watched" id="links-to-other-websites">
					{t('LINKS TO OTHER WEBSITES')}
				</StrongText>
				<p>
					{t(
						'Our Service may contain links to other websites that are neither operated nor controlled in any way by us. If you click on a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.'
					)}
				</p>
				<p>
					{t(
						'We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. We do not guarantee the accuracy, timeliness, or suitability of any content located on a third-party website. Further, any links to third-party websites should not be construed as endorsements of the products, services, or sponsoring organizations of that linked website.'
					)}
				</p>
				<StrongText className="watched" id="changes-to-this-privacy-policy">
					{t('CHANGES TO THIS PRIVACY POLICY')}
				</StrongText>
				<p>
					{t(
						'We may update our Privacy Policy from time to time. We will notify you of any material changes to this policy by posting the new Privacy Policy on this page.'
					)}
				</p>
				<p>
					{t(
						'We will let you know via email or other prominent notice, prior to the change becoming effective. We will update the “Last updated” date at the top of this Privacy Policy to reflect the effective dates of the updates.'
					)}
				</p>
				<p>
					{t(
						'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.'
					)}
				</p>
				<StrongText className="watched" id="contact-us">
					{t('CONTACT US')}
				</StrongText>
				<p>
					{t(
						'If you have any questions about this Privacy Policy or wish to exercise any of the rights outlined in this policy, please contact us via email:'
					)}{' '}
					<a href="mailto:privacy@ramsoft.com">privacy@ramsoft.com</a>.{' '}
					{t('Depending on the request type, validation of your identity may be required.')}
				</p>
				<StrongText style={{ height: 200 }} />
			</Box>
		</PolicyWrapper>
	);
};

const PrivacyModal = ({ open, onClose }) => {
	const isTablet = useIsTablet();
	const { t } = useTranslation('privacyPolicy');
	const { i18n } = useTranslation();
	const [languageInitialized, setLanguageInitialized] = useState(false);

	function getQueryParamValue(paramName, url = window.location.href) {
		const queryString = url.split('#')[1] || url.split('?')[1] || '';
		const regex = new RegExp(`[?&]${paramName}=([^&#]*)`, 'i');
		const matches = regex.exec(queryString);

		if (matches && matches[1]) {
			return decodeURIComponent(matches[1]);
		}

		return '';
	}

	useEffect(() => {
		if (!languageInitialized) {
			const language = getQueryParamValue('languageSelected');
			console.log(language, 'languageSelected');

			if (language) {
				i18n.changeLanguage(language);
			}

			setLanguageInitialized(true); // State update happens after the language change
		}
	}, [languageInitialized, i18n]);
	return (
		<Modal
			BackdropComponent={() => (
				<BackDrop>
					<Box sx={mainBox}>
						<NoScrollbar style={sideStyle}>
							<IconButton
								sx={{
									position: 'absolute',
									top: 10,
									right: 20,
								}}
								onClick={onClose}
							>
								<CloseIcon sx={{ color: '#000' }} />
							</IconButton>
							<SideBarLinks />
						</NoScrollbar>
						<Box sx={boxStyle}>
							<Svg
								name="privacy"
								style={{
									width: 75,
									height: 75,
									marginTop: isTablet ? 0 : 100,
								}}
								viewBox="-10 -10 75 75"
							/>
							<Box component="h3" sx={header}>
								{t('Privacy Policy')}
							</Box>
							<PrivacyModalContent />
						</Box>
					</Box>
				</BackDrop>
			)}
			open={open}
			onClose={onClose}
		>
			<></>
		</Modal>
	);
};

export default PrivacyModal;
export { SideBarLinks, PrivacyModalContent };
