import styled from 'styled-components';

export const Title = styled.h1`
	font-family: Roboto;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
	margin-bottom: 36px;
`;

export const Wrapper = styled.div`
	padding: 5px 20px 20px;
`;

export const NavLinkItem = styled.div`
	font-family: Roboto;
	width: 100%;
	margin-top: 21px;
	height: 56px;
	opacity: 0.5;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 15px;

	span {
		color: #fff !important;
		margin-left: 18px;
	}

	&.active,
	&:hover {
		background-color: #272727;
		cursor: pointer;

		span {
			color: #42a5f5;
		}
	}
`;

export const H3 = styled.h3`
	font-family: Roboto;
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	margin-bottom: 14px;
	margin-top: 8px;
`;

export const Hint = styled.span`
	opacity: 0.6;
	font-family: Roboto;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
`;

export const PrimaryButton = styled.button`
	width: 262px;
	height: 49px;
	margin: 14px 0 0;
	padding: 16px 55px 10px 56px;
	border-radius: 5px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	background-color: #42a5f5;
	border-width: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px;
	cursor: pointer;

	span {
		font-family: Roboto;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 1.25px;
		text-align: center;
		color: #fff;
	}
`;
