import {
	extractEmergencyContactsFromProfile,
	extractEmergencyContactsFromProfileWithAdd,
	extractEmergencyContactsFromProfileWithDelete,
	extractEmergencyContactsFromProfileWithUpdate,
} from '../../utils/extractEmergencyContactsFromProfile';
import { extractAddressFromProfile } from '../../utils/extractAddressFromProfile';

export const EMERGENCY_CONTACT_MUTATION_TYPES = {
	DELETE: 'delete',
	ADD: 'add',
	UPDATE: 'update',
};

export const getUpdatedEmergencyContacts = (profile, payload, type) => {
	if (type === EMERGENCY_CONTACT_MUTATION_TYPES.ADD) {
		return extractEmergencyContactsFromProfileWithAdd(profile, payload);
	}

	if (type === EMERGENCY_CONTACT_MUTATION_TYPES.DELETE) {
		return extractEmergencyContactsFromProfileWithDelete(profile, payload);
	}

	if (type === EMERGENCY_CONTACT_MUTATION_TYPES.UPDATE) {
		return extractEmergencyContactsFromProfileWithUpdate(profile, payload);
	}

	return extractEmergencyContactsFromProfile(profile);
};

export const getUserDataFromProfile = profile => {
	return {
		firstName: profile.firstName || '',
		middleName: profile.middleName || '',
		lastName: profile.lastName || '',
		img: profile.profilePictureUrl,
		height: profile.height || '',
		weight: profile.weight || '',
		birthDate: profile.birthDate || '',
		gender: profile.gender?.toUpperCase() || '',
		race: profile.race || '',
		ethnicity: profile.ethnicity || '',
		birthSex: profile.birthSex || '',
		language: profile.language || '',
		ssn: profile.ssn || '',
		maritalStatus: profile.maritalStatus || '',
		smokingStatus: profile.smokingStatus || '',
		alcoholUse: profile.alcoholUse || '',
		motherMaidenName: profile.motherMaidenName || '',
		abha: profile.abha || {},
		isPrimary: profile.isPrimary,
		...extractAddressFromProfile(profile.address),
	};
};
