import React from 'react';

import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileStudyDetail from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetail';
import { useDrawersStore } from '@worklist-2/patientPortal/src/stores/drawers';
// eslint-disable-next-line import/no-unresolved
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const StudyInfoDrawer = () => {
	const studyInformationDetails = useDrawersStore(state => state.studyInformationDetails);
	const setStudyInformationDetails = useDrawersStore(state => state.setStudyInformationDetails);
	const phoenixBlumeMobileStudyDetailOptimization = useBooleanFlagValue(
		'phoenix-blume-mobile-study-detail-optimization'
	);

	if (!phoenixBlumeMobileStudyDetailOptimization) return null;

	return (
		<MobileBottomDrawer
			background="#ffffff"
			open={Boolean(studyInformationDetails)}
			onClose={() => setStudyInformationDetails(null)}
		>
			<MobileStudyDetail data={studyInformationDetails} />
		</MobileBottomDrawer>
	);
};

export default StudyInfoDrawer;
