import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppModeContext, EventEmitter } from '@worklist-2/core/src';
import { ArticleWrapper } from '@worklist-2/patientPortal/src/views/HelpView/HelpCenter/patientHelpStyles';

const HelpContent = ({ style }) => {
	const [html, setHtml] = useState('<div></div>');
	const { appMode } = useAppModeContext();

	useEffect(() => {
		setContent();
		EventEmitter.subscribe('update-popover-content', () => setContent());
	}, []);

	const setContent = () => {
		const ele = document.querySelector('.content-container');
		if (ele) setHtml(ele?.outerHTML);
	};

	const attrs = useMemo(
		() => ({
			dangerouslySetInnerHTML: { __html: html || '<div></div>' },
		}),
		[html]
	);

	return appMode === 'patientPortal' ? (
		<ArticleWrapper data-testid="HelpContentBlume" style={style}>
			<div {...attrs} />
		</ArticleWrapper>
	) : (
		<Box
			{...attrs}
			data-testid="HelpContent"
			sx={{
				'& .content-container': {
					margin: '0px !important',
					padding: '0px !important',
				},
			}}
		/>
	);
};
export default HelpContent;
