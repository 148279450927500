import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { getInitials } from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/utils';
import Tooltip from '@mui/material/Tooltip';
import { mediaMobile } from '@rs-core/utils/responsiveUtils';
import ShareFacilityIcon from '@worklist-2/ui/src/assets/icons/ShareFacilityIcon.svg';

const SharedAvatar = ({ name, avatar, isFacility }) => (
	<Tooltip title={name || ''}>
		<Button
			sx={{
				width: '100px',
				flexDirection: 'column',
				border: '0',
				textTransform: 'none',
				padding: '0',
				position: 'relative',
				minWidth: '100px',
				marginLeft: '5px',
				'&:hover': {
					'.bottomText': {
						visibility: avatar ? 'visible' : 'hidden',
					},
					'.boxClass': {
						backgroundColor: avatar ? 'rgba(0, 0, 0, 0.6)' : undefined,
					},
				},

				[mediaMobile]: {
					marginBottom: '0',
					marginRight: '16px',
					minHeight: '130px',
				},
			}}
		>
			<Avatar
				src={avatar}
				sx={{
					width: '98px',
					height: '98px',
					borderRadius: avatar ? '10px' : '10px 10px 5px 5px',
					backgroundColor: 'rgba(220, 220, 253, 1)',
					color: 'rgba(91, 91, 178, 1)',
					border: avatar ? '1px solid rgba(191, 191, 191, 1)' : undefined,
				}}
				variant="rounded"
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						height: '100%',
						borderRadius: '10px 10px 5px 5px',
					}}
				>
					<Box
						style={{
							height: isFacility ? '77%' : '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						{isFacility ? (
							<ShareFacilityIcon />
						) : (
							<Typography
								sx={{
									fontSize: '34px',
								}}
							>
								{getInitials(name)}
							</Typography>
						)}
					</Box>
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: 'white',
							color: 'black',
							borderRadius: '5px',
							border: '1px solid rgba(0, 0, 0, 0.1)',
							borderTopWidth: '0px',
						}}
					>
						<Typography
							noWrap
							sx={{
								lineHeight: '22px',
								fontSize: '12px',
								padding: '0 5px',
							}}
						>
							{name}
						</Typography>
					</Box>
				</Box>
			</Avatar>
			<Box
				className="boxClass"
				sx={{
					width: '100%',
					height: '100%',
					position: 'absolute',
					zIndex: '1',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: '10px',
				}}
			>
				<Typography
					className="bottomText"
					component="div"
					sx={{
						visibility: 'hidden',
						color: 'rgba(255, 255, 255, 1)',
						fontSize: '14px',
						fontWeight: '700',
						padding: '14px',
						textTransform: 'uppercase',
					}}
				>
					{name}
				</Typography>
			</Box>
		</Button>
	</Tooltip>
);

SharedAvatar.propTypes = {
	/**
	 * Name
	 */
	name: PropTypes.string,
	/**
	 * Image src
	 */
	avatar: PropTypes.string,
	/**
	 * if thumbnail is clickable or not
	 */
	isClickable: PropTypes.bool,
};

export default SharedAvatar;
