import {
	CONSTANTS,
	getEnabledElement,
	VolumeViewport,
	utilities as csUtils,
	triggerEvent,
	eventTarget,
} from '@cornerstonejs/core';
import { Enums } from '@cornerstonejs/tools';
import { vec3 } from 'gl-matrix';
import { getCalibratedAreaUnits, getCalibratedScale } from '@cornerstonejs/tools/dist/esm/utilities/getCalibratedUnits';
import { roundNumber } from '@cornerstonejs/tools/dist/esm/utilities';
import { polyline } from '@cornerstonejs/tools/dist/esm/utilities/math';
import { filterAnnotationsForDisplay } from '@cornerstonejs/tools/dist/esm/utilities/planar';
import throttle from '@cornerstonejs/tools/dist/esm/utilities/throttle';
import { getViewportIdsWithToolToRender } from '@cornerstonejs/tools/dist/esm/utilities/viewportFilters';
import triggerAnnotationRenderForViewportIds from '@cornerstonejs/tools/dist/esm/utilities/triggerAnnotationRenderForViewportIds';
import registerDrawLoop from '@cornerstonejs/tools/dist/esm/tools/annotation/planarFreehandROITool/drawLoop';
import registerEditLoopCommon from '@cornerstonejs/tools/dist/esm/tools/annotation/planarFreehandROITool/editLoopCommon';
import registerClosedContourEditLoop from '@cornerstonejs/tools/dist/esm/tools/annotation/planarFreehandROITool/closedContourEditLoop';
import registerOpenContourEditLoop from '@cornerstonejs/tools/dist/esm/tools/annotation/planarFreehandROITool/openContourEditLoop';
import registerOpenContourEndEditLoop from '@cornerstonejs/tools/dist/esm/tools/annotation/planarFreehandROITool/openContourEndEditLoop';
import registerRenderMethods from '@cornerstonejs/tools/dist/esm/tools/annotation/planarFreehandROITool/renderMethods';
import { drawLinkedTextBox } from '@cornerstonejs/tools/dist/esm/drawingSvg';
import { getTextBoxCoordsCanvas } from '@cornerstonejs/tools/dist/esm/utilities/drawing';
import { getLineSegmentIntersectionsCoordinates } from '@cornerstonejs/tools/dist/esm/utilities/math/polyline';
import pointInShapeCallback from '@cornerstonejs/tools/dist/esm/utilities/pointInShapeCallback';
import { isViewportPreScaled } from '@cornerstonejs/tools/dist/esm/utilities/viewport/isViewportPreScaled';
import { getModalityUnit } from '@cornerstonejs/tools/dist/esm/utilities/getModalityUnit';
import { BasicStatsCalculator } from '@cornerstonejs/tools/dist/esm/utilities/math/basic';
import ContourSegmentationBaseTool from '@cornerstonejs/tools/dist/esm/tools/base/ContourSegmentationBaseTool';
import { KeyboardBindings, ChangeTypes } from '@cornerstonejs/tools/dist/esm/enums';
import { getSUVTextLines } from '../cornerstoneTools/utils/calculateSUV';
import { CORNERSTONE_EVENTS } from '../contexts/consts/consts';
const { pointsAreWithinCloseContourProximity } = polyline;
const { pointCanProjectOnLine } = polyline;
const { EPSILON } = CONSTANTS;

const PARALLEL_THRESHOLD = 1 - EPSILON;

function triggerAnnotationModified(annotation, element, changeType = ChangeTypes.HandlesUpdated) {
	const enabledElement = getEnabledElement(element);
	const { viewportId, renderingEngineId } = enabledElement;
	const eventType = Enums.Events.ANNOTATION_COMPLETED;
	const eventDetail = {
		annotation,
		viewportId,
		renderingEngineId,
		changeType,
	};
	triggerEvent(eventTarget, eventType, eventDetail);
}

class PlanarFreehandROITool extends ContourSegmentationBaseTool {
	toolName;

	touchDragCallback;

	mouseDragCallback;

	_throttledCalculateCachedStats;

	commonData;

	isDrawing = false;

	isEditingClosed = false;

	isEditingOpen = false;

	activateDraw;

	activateClosedContourEdit;

	activateOpenContourEdit;

	activateOpenContourEndEdit;

	cancelDrawing;

	cancelClosedContourEdit;

	cancelOpenContourEdit;

	renderContour;

	renderContourBeingDrawn;

	renderClosedContourBeingEdited;

	renderOpenContourBeingEdited;

	newAnnotation = false;

	completeDrawClosedContour;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				shadow: true,
				preventHandleOutsideImage: false,
				/**
				 * Specify which modifier key is used to add a hole to a contour. The
				 * modifier must be pressed when the first point of a new contour is added.
				 */
				contourHoleAdditionModifierKey: KeyboardBindings.Shift,
				alwaysRenderOpenContourHandles: {
					// When true, always render end points when you have an open contour, rather
					// than just rendering a line.
					enabled: false,
					// When enabled, use this radius to draw the endpoints whilst not hovering.
					radius: 2,
				},
				allowOpenContours: false,
				// Proximity in canvas coordinates used to join contours.
				closeContourProximity: 10,
				// The proximity at which we fallback to the simplest grabbing logic for
				// determining what index of the contour to start editing.
				checkCanvasEditFallbackProximity: 6,
				// For closed contours, make them clockwise
				// This can be useful if contours are compared between slices, eg for
				// interpolation, and does not cause problems otherwise so defaulting to true.
				makeClockWise: true,
				// The relative distance that points should be dropped along the polyline
				// in units of the image pixel spacing. A value of 1 means that nodes must
				// be placed no closed than the image spacing apart. A value of 4 means that 4
				// nodes should be placed within the space of one image pixel size. A higher
				// value gives more finesse to the tool/smoother lines, but the value cannot
				// be infinite as the lines become very computationally expensive to draw.
				subPixelResolution: 4,
				/**
				 * Smoothing is used to remove jagged irregularities in the polyline,
				 * as opposed to interpolation, which is used to create new polylines
				 * between existing polylines.
				 */
				smoothing: {
					smoothOnAdd: false,
					smoothOnEdit: false, // used for edit only
					knotsRatioPercentageOnAdd: 40,
					knotsRatioPercentageOnEdit: 40,
				},
				/**
				 * Interpolation is the creation of new segmentations in between the
				 * existing segmentations/indices.  Note that this does not apply to
				 * ROI values, since those annotations are individual annotations, not
				 * connected in any way to each other, whereas segmentations are intended
				 * to be connected 2d + 1 dimension (time or space or other) volumes.
				 */
				interpolation: {
					enabled: false,
					// Callback to update the annotation or perform other action when the
					// interpolation is complete.
					onInterpolationComplete: null,
				},
				/**
				 * The polyline may get processed in order to reduce the number of points
				 * for better performance and storage.
				 */
				decimate: {
					enabled: false,
					/** A maximum given distance 'epsilon' to decide if a point should or
					 * shouldn't be added the resulting polyline which will have a lower
					 * number of points for higher `epsilon` values.
					 */
					epsilon: 0.1,
				},
				calculateStats: false,
				getTextLines: defaultGetTextLines,
				statsCalculator: BasicStatsCalculator,
			},
		}
	) {
		super(toolProps, defaultToolProps);

		// Register event loops and rendering logic, which are stored in different
		// Files due to their complexity/size.
		registerDrawLoop(this);
		registerEditLoopCommon(this);
		registerClosedContourEditLoop(this);
		registerOpenContourEditLoop(this);
		registerOpenContourEndEditLoop(this);
		registerRenderMethods(this);

		// override mouseUpDrawCallback
		this.mouseUpDrawCallback = this.#mouseUpDrawCallback.bind(this);

		this._throttledCalculateCachedStats = throttle(this._calculateCachedStats, 100, { trailing: true });
	}

	#mouseUpDrawCallback = evt => {
		const { allowOpenContours } = this.configuration;
		const { canvasPoints, contourHoleProcessingEnabled } = this.drawData;
		const firstPoint = canvasPoints[0];
		const lastPoint = canvasPoints[canvasPoints.length - 1];
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const { annotation } = this.commonData;
		if (
			allowOpenContours &&
			!pointsAreWithinCloseContourProximity(firstPoint, lastPoint, this.configuration.closeContourProximity)
		) {
			this.completeDrawOpenContour(element, { contourHoleProcessingEnabled });
		} else {
			this.completeDrawClosedContour(element, { contourHoleProcessingEnabled });
		}

		if (this.newAnnotation && annotation?.data?.suvText) {
			triggerEvent(eventTarget, CORNERSTONE_EVENTS.CORNERSTONE_ADD_ANNOTATION, {
				annotation,
				currentPoints: evt.detail?.currentPoints,
			});
			this.newAnnotation = false;
		}
	};

	addNewAnnotation = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;
		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		const annotation = this.createAnnotation(evt);

		this.addAnnotation(annotation, element);

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.activateDraw(evt, annotation, viewportIdsToRender);

		this.newAnnotation = true;

		evt.preventDefault();
		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		return annotation;
	};

	handleSelectedCallback = (evt, annotation, handle) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.activateOpenContourEndEdit(evt, annotation, viewportIdsToRender, handle);
	};

	toolSelectedCallback = (evt, annotation) => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		if (annotation.data.contour.closed) {
			this.activateClosedContourEdit(evt, annotation, viewportIdsToRender);
		} else {
			this.activateOpenContourEdit(evt, annotation, viewportIdsToRender);
		}
	};

	isPointNearTool = (element, annotation, canvasCoords, proximity) => {
		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		const { polyline: points } = annotation.data.contour;

		// NOTE: It is implemented this way so that we do not double calculate
		// points when number crunching adjacent line segments.
		let previousPoint = viewport.worldToCanvas(points[0]);

		for (let i = 1; i < points.length; i++) {
			const p1 = previousPoint;
			const p2 = viewport.worldToCanvas(points[i]);
			const canProject = pointCanProjectOnLine(canvasCoords, p1, p2, proximity);

			if (canProject) {
				return true;
			}

			previousPoint = p2;
		}

		if (!annotation.data.contour.closed) {
			// Contour is open, don't check last point to first point.
			return false;
		}

		// check last point to first point
		const pStart = viewport.worldToCanvas(points[0]);
		const pEnd = viewport.worldToCanvas(points[points.length - 1]);

		return pointCanProjectOnLine(canvasCoords, pStart, pEnd, proximity);
	};

	cancel = element => {
		const isDrawing = this.isDrawing;
		const isEditingOpen = this.isEditingOpen;
		const isEditingClosed = this.isEditingClosed;

		if (isDrawing) {
			this.cancelDrawing(element);
		} else if (isEditingOpen) {
			this.cancelOpenContourEdit(element);
		} else if (isEditingClosed) {
			this.cancelClosedContourEdit(element);
		}
	};

	filterInteractableAnnotationsForElement(element, annotations) {
		if (!annotations || !annotations.length) {
			return;
		}

		const enabledElement = getEnabledElement(element);
		const { viewport } = enabledElement;

		let annotationsToDisplay;

		if (viewport instanceof VolumeViewport) {
			const camera = viewport.getCamera();

			const { spacingInNormalDirection } = csUtils.getTargetVolumeAndSpacingInNormalDir(viewport, camera);

			// Get data with same normal and within the same slice
			annotationsToDisplay = this.filterAnnotationsWithinSlice(annotations, camera, spacingInNormalDirection);
		} else {
			// Use the default `filterAnnotationsForDisplay` utility, as the stack
			// path doesn't require handles.
			annotationsToDisplay = filterAnnotationsForDisplay(viewport, annotations);
		}

		return annotationsToDisplay;
	}

	filterAnnotationsWithinSlice(annotations, camera, spacingInNormalDirection) {
		const { viewPlaneNormal } = camera;

		const annotationsWithParallelNormals = annotations.filter((td: Annotation) => {
			const annotationViewPlaneNormal = td.metadata.viewPlaneNormal;

			const isParallel = Math.abs(vec3.dot(viewPlaneNormal, annotationViewPlaneNormal)) > PARALLEL_THRESHOLD;

			return annotationViewPlaneNormal && isParallel;
		});

		// No in plane annotations.
		if (!annotationsWithParallelNormals.length) {
			return [];
		}

		// Annotation should be within the slice, which means that it should be between
		// camera's focalPoint +/- spacingInNormalDirection.

		const halfSpacingInNormalDirection = spacingInNormalDirection / 2;
		const { focalPoint } = camera;

		const annotationsWithinSlice = [];

		for (const annotation of annotationsWithParallelNormals) {
			const data = annotation.data;
			const point = data.contour.polyline[0];

			if (!annotation.isVisible) {
				continue;
			}

			// A = point
			// B = focal point
			// P = normal

			// B-A dot P  => Distance in the view direction.
			// this should be less than half the slice distance.

			const dir = vec3.create();

			vec3.sub(dir, focalPoint, point);

			const dot = vec3.dot(dir, viewPlaneNormal);

			if (Math.abs(dot) < halfSpacingInNormalDirection) {
				annotationsWithinSlice.push(annotation);
			}
		}

		return annotationsWithinSlice;
	}

	isContourSegmentationTool() {
		// Disable contour segmentation behavior because it shall be activated only
		// for PlanarFreehandContourSegmentationTool
		return false;
	}

	createAnnotation(evt) {
		const worldPos = evt.detail.currentPoints.world;
		const contourAnnotation = super.createAnnotation(evt);

		const onInterpolationComplete = annotation => {
			// Clear out the handles because they aren't used for straight freeform
			annotation.data.handles.points.length = 0;
		};

		return csUtils.deepMerge(contourAnnotation, {
			data: {
				contour: {
					polyline: [[...worldPos]],
				},
				label: '',
				cachedStats: {},
			},
			onInterpolationComplete,
		});
	}

	getAnnotationStyle(context) {
		// This method exists only because `super` cannot be called from
		// _getRenderingOptions() which is in an external file.
		return super.getAnnotationStyle(context);
	}

	renderAnnotationInstance(renderContext) {
		const { enabledElement, targetId, svgDrawingHelper } = renderContext;
		const annotation = renderContext.annotation;

		let renderStatus = false;
		const { viewport, renderingEngine } = enabledElement;

		const isDrawing = this.isDrawing;
		const isEditingOpen = this.isEditingOpen;
		const isEditingClosed = this.isEditingClosed;

		if (!(isDrawing || isEditingOpen || isEditingClosed)) {
			// No annotations are currently being modified, so we can just use the
			// render contour method to render all of them
			this.renderContour(enabledElement, svgDrawingHelper, annotation);
		} else {
			// The active annotation will need special rendering treatment. Render all
			// other annotations not being interacted with using the standard renderContour
			// rendering path.
			const activeAnnotationUID = this.commonData.annotation.annotationUID;

			if (annotation.annotationUID === activeAnnotationUID) {
				if (isDrawing) {
					this.renderContourBeingDrawn(enabledElement, svgDrawingHelper, annotation);
				} else if (isEditingClosed) {
					this.renderClosedContourBeingEdited(enabledElement, svgDrawingHelper, annotation);
				} else if (isEditingOpen) {
					this.renderOpenContourBeingEdited(enabledElement, svgDrawingHelper, annotation);
				} else {
					throw new Error(`Unknown ${this.getToolName()} annotation rendering state`);
				}
			} else {
				this.renderContour(enabledElement, svgDrawingHelper, annotation);
			}

			// Todo: return boolean flag for each rendering route in the planar tool.
			renderStatus = true;
		}

		if (!this.configuration.calculateStats) {
			return;
		}

		this._calculateStatsIfActive(annotation, targetId, viewport, renderingEngine, enabledElement);

		this._renderStats(annotation, viewport, enabledElement, svgDrawingHelper);

		return renderStatus;
	}

	_calculateStatsIfActive(annotation, targetId, viewport, renderingEngine, enabledElement) {
		const activeAnnotationUID = this.commonData?.annotation.annotationUID;

		if (annotation.annotationUID === activeAnnotationUID && !this.commonData?.movingTextBox) {
			return;
		}

		if (!this.commonData?.movingTextBox) {
			const { data } = annotation;
			if (!data.cachedStats[targetId] || data.cachedStats[targetId].areaUnit == null) {
				data.cachedStats[targetId] = {
					Modality: null,
					area: null,
					max: null,
					mean: null,
					stdDev: null,
					areaUnit: null,
				};

				this._calculateCachedStats(annotation, viewport, renderingEngine, enabledElement);
			} else if (annotation.invalidated) {
				this._throttledCalculateCachedStats(annotation, viewport, renderingEngine, enabledElement);
			}
		}
	}

	_calculateCachedStats = (annotation, viewport, renderingEngine, enabledElement) => {
		const { data } = annotation;
		const { cachedStats } = data;
		const { polyline: points } = data.contour;

		const targetIds = Object.keys(cachedStats);

		for (let i = 0; i < targetIds.length; i++) {
			const targetId = targetIds[i];
			const image = this.getTargetIdImage(targetId, renderingEngine);

			// If image does not exists for the targetId, skip. This can be due
			// to various reasons such as if the target was a volumeViewport, and
			// the volumeViewport has been decached in the meantime.
			if (!image) {
				continue;
			}

			const { imageData, metadata } = image;
			const canvasCoordinates = points.map(p => viewport.worldToCanvas(p));

			// Using an arbitrary start point (canvasPoint), calculate the
			// mm spacing for the canvas in the X and Y directions.
			const canvasPoint = canvasCoordinates[0];
			const originalWorldPoint = viewport.canvasToWorld(canvasPoint);
			const deltaXPoint = viewport.canvasToWorld([canvasPoint[0] + 1, canvasPoint[1]]);
			const deltaYPoint = viewport.canvasToWorld([canvasPoint[0], canvasPoint[1] + 1]);

			const deltaInX = vec3.distance(originalWorldPoint, deltaXPoint);
			const deltaInY = vec3.distance(originalWorldPoint, deltaYPoint);

			const scale = getCalibratedScale(image);
			let area = polyline.getArea(canvasCoordinates) / scale / scale;
			// Convert from canvas_pixels ^2 to mm^2
			area *= deltaInX * deltaInY;

			const worldPosIndex = csUtils.transformWorldToIndex(imageData, points[0]);
			worldPosIndex[0] = Math.floor(worldPosIndex[0]);
			worldPosIndex[1] = Math.floor(worldPosIndex[1]);
			worldPosIndex[2] = Math.floor(worldPosIndex[2]);

			let iMin = worldPosIndex[0];
			let iMax = worldPosIndex[0];

			let jMin = worldPosIndex[1];
			let jMax = worldPosIndex[1];

			let kMin = worldPosIndex[2];
			let kMax = worldPosIndex[2];

			for (let j = 1; j < points.length; j++) {
				const worldPosIndex = csUtils.transformWorldToIndex(imageData, points[j]);
				worldPosIndex[0] = Math.floor(worldPosIndex[0]);
				worldPosIndex[1] = Math.floor(worldPosIndex[1]);
				worldPosIndex[2] = Math.floor(worldPosIndex[2]);
				iMin = Math.min(iMin, worldPosIndex[0]);
				iMax = Math.max(iMax, worldPosIndex[0]);

				jMin = Math.min(jMin, worldPosIndex[1]);
				jMax = Math.max(jMax, worldPosIndex[1]);

				kMin = Math.min(kMin, worldPosIndex[2]);
				kMax = Math.max(kMax, worldPosIndex[2]);
			}

			// Expand bounding box
			const iDelta = 0.01 * (iMax - iMin);
			const jDelta = 0.01 * (jMax - jMin);
			const kDelta = 0.01 * (kMax - kMin);

			iMin = Math.floor(iMin - iDelta);
			iMax = Math.ceil(iMax + iDelta);
			jMin = Math.floor(jMin - jDelta);
			jMax = Math.ceil(jMax + jDelta);
			kMin = Math.floor(kMin - kDelta);
			kMax = Math.ceil(kMax + kDelta);

			const boundsIJK = [
				[iMin, iMax],
				[jMin, jMax],
				[kMin, kMax],
			];

			const worldPosEnd = imageData.indexToWorld([iMax, jMax, kMax]);
			const canvasPosEnd = viewport.worldToCanvas(worldPosEnd);

			let curRow = 0;
			let intersections = [];
			let intersectionCounter = 0;
			const pointsInShape = pointInShapeCallback(
				imageData,
				(pointLPS, pointIJK) => {
					let result = true;
					const point = viewport.worldToCanvas(pointLPS);
					if (point[1] != curRow) {
						intersectionCounter = 0;
						curRow = point[1];
						intersections = getLineSegmentIntersectionsCoordinates(canvasCoordinates, point, [
							canvasPosEnd[0],
							point[1],
						]);
						intersections.sort(
							(function (index) {
								return function (a, b) {
									return a[index] === b[index] ? 0 : a[index] < b[index] ? -1 : 1;
								};
							})(0)
						);
					}
					if (intersections.length && point[0] > intersections[0][0]) {
						intersections.shift();
						intersectionCounter++;
					}
					if (intersectionCounter % 2 === 0) {
						result = false;
					}
					return result;
				},
				this.configuration.statsCalculator.statsCallback,
				boundsIJK
			);

			const modalityUnitOptions = {
				isPreScaled: isViewportPreScaled(viewport, targetId),
				isSuvScaled: this.isSuvScaled(viewport, targetId, annotation.metadata.referencedImageId),
			};

			const modalityUnit = getModalityUnit(
				metadata.Modality,
				annotation.metadata.referencedImageId,
				modalityUnitOptions
			);

			const stats = this.configuration.statsCalculator.getStatistics();

			cachedStats[targetId] = {
				Modality: metadata.Modality,
				area,
				mean: stats.mean?.value,
				max: stats.max?.value,
				stdDev: stats.stdDev?.value,
				statsArray: stats.array,
				pointsInShape: pointsInShape,
				areaUnit: getCalibratedAreaUnits(null, image),
				modalityUnit,
			};

			if (data.cachedStats?.[targetId]?.modalityUnit === 'SUV') {
				getSUVTextLines(data, viewport, targetId);
			}
		}

		triggerAnnotationModified(annotation, enabledElement.viewport.element, ChangeTypes.StatsUpdated);

		annotation.invalidated = false;

		return cachedStats;
	};

	_renderStats = (annotation, viewport, enabledElement, svgDrawingHelper) => {
		const { data } = annotation;
		const targetId = this.getTargetId(viewport);

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: enabledElement.viewport.id,
		};

		const options = this.getLinkedTextBoxStyle(styleSpecifier, annotation);
		if (!options.visibility) {
			return;
		}

		const modality = data.cachedStats?.[targetId]?.Modality;
		const textLines =
			this.configuration.wonIvPetSuv && modality === 'PT' && data.cachedStats?.[targetId]?.modalityUnit === 'SUV'
				? getSUVTextLines(data, viewport, targetId)
				: this.configuration.getTextLines(data, targetId);
		if (!textLines || textLines.length === 0) {
			return;
		}

		const canvasCoordinates = data.contour.polyline.map(p => viewport.worldToCanvas(p));

		if (!data.handles.textBox.hasMoved && canvasCoordinates?.length > 1) {
			const canvasTextBoxCoords = getTextBoxCoordsCanvas(canvasCoordinates);

			data.handles.textBox.worldPosition = viewport.canvasToWorld(canvasTextBoxCoords);
		}

		const textBoxPosition = viewport.worldToCanvas(data.handles.textBox.worldPosition);

		const textBoxUID = '1';
		const boundingBox = drawLinkedTextBox(
			svgDrawingHelper,
			annotation.annotationUID ?? '',
			textBoxUID,
			textLines,
			textBoxPosition,
			canvasCoordinates,
			{},
			options
		);

		const { x: left, y: top, width, height } = boundingBox;

		data.handles.textBox.worldBoundingBox = {
			topLeft: viewport.canvasToWorld([left, top]),
			topRight: viewport.canvasToWorld([left + width, top]),
			bottomLeft: viewport.canvasToWorld([left, top + height]),
			bottomRight: viewport.canvasToWorld([left + width, top + height]),
		};
	};
}

function defaultGetTextLines(data, targetId) {
	const cachedVolumeStats = data.cachedStats[targetId];
	const { area, mean, stdDev, max, isEmptyArea, areaUnit, modalityUnit } = cachedVolumeStats || {};

	const textLines = [];

	if (area) {
		const areaLine = isEmptyArea ? `Area: Oblique not supported` : `Area: ${roundNumber(area)} ${areaUnit}`;
		textLines.push(areaLine);
	}

	if (mean) {
		textLines.push(`Mean: ${roundNumber(mean)} ${modalityUnit}`);
	}

	if (max) {
		textLines.push(`Max: ${roundNumber(max)} ${modalityUnit}`);
	}

	if (stdDev) {
		textLines.push(`Std Dev: ${roundNumber(stdDev)} ${modalityUnit}`);
	}

	return textLines;
}

PlanarFreehandROITool.toolName = 'PlanarFreehandROI';
export default PlanarFreehandROITool;
