import { v4 as uuid } from 'uuid';
import { useImageViewerLayoutContext } from '../contexts/ImageViewerLayoutContext';
import { useImageViewerCornerstoneContext } from '../contexts/ImageViewerCornerstoneContext';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { useImageViewerLayoutStoreSelector } from '../features/CustomToolbar/context/zustand/ImageViewerLayoutZustandProvider';
import { getEnabledElements } from '@cornerstonejs/core';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useOnViewportDoubleClick = () => {
	const { highlightedViewport, setHighlightedViewport, setLayoutItems, multiViewportState, setMultiViewportState } =
		useImageViewerLayoutContext();
	const { resizeViewports } = useImageViewerCornerstoneContext();
	const setActiveViewport = useImageViewerLayoutStoreSelector(state => state.setActiveViewport);
	const setActiveViewportId = useImageViewerLayoutStoreSelector(state => state.setActiveViewportId);
	const won4004PersistViewportState = useBooleanFlagValue('won-4004-persit-viewport-state');
	const wonIvFixLinkedSeriesRotation = useBooleanFlagValue('won-iv-fix-linked-series-rotation');

	const getViewportSettings = () => {
		const vp = getEnabledElements();
		return vp.map(x => {
			const { viewport } = x;
			return {
				viewportId: x.viewportId,
				cameraPreset: {
					pan: viewport.getPan(),
					properties: viewport.getProperties(),
					zoom: viewport.getZoom(),
					camera: viewport.getCamera(),
				},
				initialInstanceIndex: viewport.getCurrentImageIdIndex() || 0,
			};
		});
	};

	const getCameraPresetByViewport = (renderingEngine, viewportId) => {
		if (!renderingEngine || !viewportId) return null;

		const cornerstoneViewport = renderingEngine.getViewport(viewportId);
		if (!cornerstoneViewport) return null;

		return {
			cameraPreset: {
				pan: cornerstoneViewport.getPan(),
				properties: cornerstoneViewport.getProperties(),
				zoom: cornerstoneViewport.getZoom(),
				camera: cornerstoneViewport.getCamera(),
			},
			initialInstanceIndex: cornerstoneViewport.getCurrentImageIdIndex() || 0,
		};
	};

	const onViewportDoubleClick = layoutItem => {
		const renderingEngine = getDefaultRenderingEngine();
		// Switch from Highlighted-Viewport to Multi-Viewport
		if (highlightedViewport) {
			setLayoutItems(prevState =>
				prevState.map(l => {
					if (l.id === layoutItem.id) {
						const viewportSettings = getCameraPresetByViewport(
							renderingEngine,
							layoutItem.viewports?.[0]?.id
						);

						return {
							...l,
							viewports: l.viewports.map(v => ({
								...v,
								cameraPreset: wonIvFixLinkedSeriesRotation ? null : viewportSettings?.cameraPreset,
							})),
							initialInstanceIndex: viewportSettings?.initialInstanceIndex,
						};
					}

					if (won4004PersistViewportState) {
						const matchingViewport = multiViewportState?.find(
							viewportState => viewportState.viewportId === l.viewports?.[0]?.id
						);

						if (matchingViewport) {
							return {
								...l,
								viewports: l.viewports.map(v => ({
									...v,
									cameraPreset: wonIvFixLinkedSeriesRotation ? null : matchingViewport.cameraPreset,
								})),
								initialInstanceIndex: matchingViewport.initialInstanceIndex,
							};
						}
					}

					return l;
				})
			);
			return setHighlightedViewport(undefined);
		}

		// Switch from Multi-viewport to Highlighted-Viewport
		resizeViewports();
		if (won4004PersistViewportState) {
			setMultiViewportState(getViewportSettings()); // Store previous state of user modifications for all viewports
		}

		const newItem = { ...layoutItem };
		if (newItem.viewports?.length) {
			const viewportSettings = getCameraPresetByViewport(renderingEngine, layoutItem.viewports?.[0]?.id);
			// To Preserve the lastViewportId for comparison with the new highlighted viewport
			const lastViewportId = newItem?.viewports[0]?.id;
			newItem.initialInstanceIndex = viewportSettings?.initialInstanceIndex;

			newItem.viewports = newItem.viewports.map(viewport => ({
				...viewport,
				cameraPreset: wonIvFixLinkedSeriesRotation ? null : viewportSettings?.cameraPreset,
				lastViewportId,
				id: uuid(),
			}));
		}

		setHighlightedViewport(newItem);
		setActiveViewportId(newItem.viewports[0].id);
		setActiveViewport(newItem);
	};

	return {
		onViewportDoubleClick,
	};
};

export default useOnViewportDoubleClick;
