import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles } from 'tss-react/mui';
import { InputAdornment, Autocomplete, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';

import OverflowTooltip from '@worklist-2/ui/src/components/OverflowTooltip';
import { useAuth } from '@worklist-2/core/src/context/UserAuthContext';

const useStyles = makeStyles()((_, { fullMenuWidth }) => ({
	root: {},
	menuPaper: fullMenuWidth
		? {
				width: '100%',
				overflow: 'hidden',
				'& .MuiAutocomplete-listbox': {
					maxHeight: 260,
				},
		  }
		: {
				maxWidth: 550,
				minWidth: 250,
				overflow: 'hidden',
				'& .MuiAutocomplete-listbox': {
					maxHeight: 260,
				},
		  },
}));

const defaultSelectedValue = [];

const SuggestMultipleValueInfiniteScroll = ({
	dataLoader,
	onSelectItem,
	searchParams,
	label,
	renderOptionlist,
	renderTag,
	excludedOption = [],
	debounceDelay = 1000,
	countPerPage = 20,
	startAdornment = <SearchIcon />,
	placeHolderText = 'Search multiple',
	compareOption = null,
	selectedValue = defaultSelectedValue,
	limitTag = 1,
	renderTags = true,
	disableClearable = false,
	width,
	size,
	onFocus,
	onBlur,
	onClose,
	showLabel = true,
	openMenuOnload = false,
	fullMenuWidth = false,
	searchExistingOptions = false,
}) => {
	const [optionList, setOptionList] = useState([]);
	const [selectedItem, setSelectedItem] = useState([]);
	const [itemSearchParam, setItemSearchParam] = useState({ page: 1, [searchParams.search]: null });
	const [isLoading, setIsLoading] = useState(false);
	const [disableScroll, setDisableScroll] = useState(false);
	const inputRef = useRef(null);
	const itemsRef = useRef(null);
	const [isOpen, setIsOpen] = useState(openMenuOnload);
	const [inputValue, setInputValue] = useState('');
	const [isListFullyLoaded, setIsListFullyLoaded] = useState(searchExistingOptions);
	const [localFilteredList, setLocalFilteredList] = useState([]);

	const { loggedInUser } = useAuth();

	const { classes } = useStyles({ fullMenuWidth });
	const { t } = useTranslation('root');

	useEffect(() => {
		setIsOpen(openMenuOnload);
		if (openMenuOnload) {
			inputRef.current.focus();
		}
	}, [openMenuOnload]);

	// called when user types
	const onSearch = useCallback(
		_.debounce((newValue, reason) => {
			if (reason == 'input') {
				if (newValue != itemSearchParam[searchParams.search]) {
					if (isListFullyLoaded) {
						setLocalFilteredList(
							optionList.filter(
								d =>
									d.tagDisplay.toLowerCase().includes(newValue.toLowerCase()) ||
									d?.nameString?.toLowerCase()?.includes(newValue.toLowerCase())
							)
						);
					} else {
						setOptionList([]);
						setItemSearchParam(() => ({
							page: 1,
							[searchParams.search]: newValue === '' ? null : newValue,
						}));
						setDisableScroll(false);
					}
				}
			} else if (reason === 'clear') {
				if (itemSearchParam[searchParams.search] !== null) {
					setOptionList([]);
					setItemSearchParam(() => ({ page: 1, [searchParams.search]: null }));
					setDisableScroll(false);
				} else if (isListFullyLoaded) {
					setLocalFilteredList(optionList);
				}
			}
		}, debounceDelay),
		[itemSearchParam, isListFullyLoaded, optionList]
	);

	useEffect(() => {
		const filteredValues = selectedValue.filter(v => typeof v === 'object'); // remove incorrect value
		setSelectedItem(filteredValues);
	}, [selectedValue]);

	// called when user select items from list
	const onChangeItem = (event, values, reason) => {
		const filteredValues = values.filter(v => typeof v === 'object'); // remove incorrect value
		setSelectedItem(filteredValues);
		if (onSelectItem) {
			onSelectItem({
				selectedOptions: filteredValues.map(fv => fv.tagDisplay),
				fullOptionsList: optionList,
				filteredValues,
			});
			if (reason === 'clear' && itemSearchParam[searchParams.search] !== null) {
				setItemSearchParam({ ...itemSearchParam, [searchParams.search]: null });
				setInputValue('');
			}
		}
		if (values.length > selectedItem.length && !!itemsRef?.current) {
			setTimeout(() => itemsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' }, 200));
		}
	};

	const onLoadOptions = useCallback(
		searchParam => {
			if (searchParam[searchParams.search] !== '' && dataLoader) {
				setIsLoading(true);

				const params =
					searchParam[searchParams.search] || searchParam.email
						? { ...searchParam }
						: { page: searchParam.page };

				dataLoader
					.load({
						...params,
						sort: searchParams.sort,
						count: countPerPage,
						extraValue: searchParams.extraParam,
					})
					.then(data => {
						if (data.length > 0) {
							if (excludedOption.length > 0) {
								data = data.filter(d => !excludedOption.includes(d.id));
							}
							const processedData = data.map(d => ({ ...d, ...renderTag(d) }));
							setOptionList(prev => {
								if (searchParam.page > 1) {
									const combinedUniqueData = _.uniqBy(
										[...(prev || []), ...(processedData || [])],
										'tagDisplay'
									);

									if (
										processedData.length < countPerPage &&
										searchParam[searchParams.search] === null
									) {
										setIsListFullyLoaded(true);
										setLocalFilteredList(combinedUniqueData);
									}
									return combinedUniqueData;
								}

								const combinedUniqueData = _.uniqBy([...(processedData || [])], 'tagDisplay');
								if (isListFullyLoaded) {
									setLocalFilteredList(combinedUniqueData);
								}
								return combinedUniqueData;
							});
							setDisableScroll(false);
						} else {
							setDisableScroll(true);
						}

						setIsLoading(false);
					})
					.catch(e => console.log(e));
			}
		},
		[dataLoader, searchParams.extraParam]
	);

	useEffect(() => {
		if (loggedInUser) {
			onLoadOptions(itemSearchParam);
		}
	}, [itemSearchParam]);

	return (
		<Autocomplete
			disableCloseOnSelect
			freeSolo
			multiple
			ListboxProps={{
				onScroll: event => {
					const listboxNode = event.currentTarget;
					listboxNode.role = 'list-box';
					if (
						!disableScroll &&
						!isLoading &&
						listboxNode.scrollTop + listboxNode.clientHeight > listboxNode.scrollHeight - 1 &&
						!isListFullyLoaded
					) {
						setItemSearchParam(prev => ({
							...prev,
							page: prev.page + 1,
						}));
					}
				},
			}}
			componentsProps={{ paper: { sx: classes.menuPaper } }}
			data-testid="suggest-infinite-scroll-multiple"
			disableClearable={disableClearable}
			filterOptions={options => options}
			getOptionLabel={option => option?.tagDisplay || option?.display}
			inputValue={inputValue}
			isOptionEqualToValue={(option, value) =>
				compareOption ? compareOption(option, value) : option?.id === value?.id
			}
			limitTags={limitTag}
			loading={isLoading}
			open={isOpen}
			options={isListFullyLoaded ? localFilteredList : optionList}
			renderInput={params => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						startAdornment: renderTags ? (
							<>
								{(selectedItem?.length < 2 || (selectedItem?.length >= 2 && !isOpen)) && (
									<InputAdornment position="start" sx={{ marginRight: '5px' }}>
										{startAdornment}
									</InputAdornment>
								)}
								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										overflow: 'auto',
										height: 'fit-content',
										maxHeight: '75px',
									}}
								>
									{params.InputProps.startAdornment}
								</div>
							</>
						) : (
							<></>
						),
						endAdornment:
							!renderTags && !!inputValue ? (
								<Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
									<ClearIcon
										sx={{
											fontSize: '24px',
											cursor: 'pointer',
										}}
										onClick={e => {
											e.stopPropagation();
											setInputValue('');
											onSearch('', 'clear');
											setLocalFilteredList(optionList);
										}}
									/>
								</Box>
							) : (
								<>{params.InputProps.endAdornment}</>
							),
					}}
					inputRef={inputRef}
					label={showLabel ? label : ''}
					placeholder={selectedItem?.length === 0 ? t(placeHolderText) : t('Search')}
					sx={{
						'.MuiOutlinedInput-input': {
							padding: width ? '' : '3.5px 3px 3.5px 0px !important',
							width: !renderTags ? 'calc(100% - 36px) !important' : 'auto !important',
							marginLeft: '0px',
							display: selectedItem.length == 0 || !renderTags ? 'block' : 'none',
							flexBasis: !renderTags ? 'unset' : 'fit-content',
						},
						'&:focus-within .MuiOutlinedInput-input, &:hover .MuiOutlinedInput-input': {
							display: 'block !important',
						},
					}}
					onBlur={() => (onBlur ? onBlur() : '')}
					onChange={e => {
						setInputValue(e.target.value);
					}}
					onClick={e => {
						setIsOpen(true);
						inputRef.current.focus();
					}}
					onFocus={() => (onFocus ? onFocus() : '')}
				/>
			)}
			renderOption={(props, option, { selected }) =>
				renderOptionlist ? (
					renderOptionlist(props, selected, option)
				) : (
					<li {...props}>
						<OverflowTooltip text={option.label} />
					</li>
				)
			}
			size={size || 'medium'}
			sx={{
				width,
			}}
			value={selectedValue}
			onChange={onChangeItem} // select values from dropdown menu
			onClose={(e, reason) => {
				if (reason !== 'toggleInput') {
					setIsOpen(false);
					onClose && onClose();
				}
			}}
			onInputChange={(event, value, reason) => {
				// when typing
				if (reason === 'clear') {
					setInputValue('');
				}
				onSearch(value, reason);
			}}
		/>
	);
};

SuggestMultipleValueInfiniteScroll.propTypes = {
	/**
	 * Dataloader for loading options
	 */
	dataLoader: PropTypes.object.isRequired,
	/**
	 * function to be called when an item is selected to pass
	 * selected value to parent component
	 */
	onSelectItem: PropTypes.func.isRequired,
	/**
	 * Lifted up state - list of Favorite Apps
	 */
	searchParams: PropTypes.shape({
		/**
		 * search field name
		 */
		search: PropTypes.string,
		/**
		 * extra paramters such as count, _element
		 */
		extraParam: PropTypes.object,
	}).isRequired,
	/**
	 * translated label for display, like 'username' 'organization'
	 */
	label: PropTypes.string.isRequired,
	/**
	 *  debounce delay for searching
	 */
	debounceDelay: PropTypes.number,
	/**
	 * number of options to load
	 */
	countPerPage: PropTypes.number,
	/**
	 * render option list
	 */
	renderOptionlist: PropTypes.func,
	/**
	 * render tag
	 */
	renderTag: PropTypes.func,
	/**
	 * excluded option
	 */
	excludedOption: PropTypes.array,
	/**
	 * compare option
	 */
	compareOption: PropTypes.func,
	/**
	 * selected value
	 */
	selectedValue: PropTypes.array,
	/**
	 * limit tag
	 */
	limitTag: PropTypes.number,
	/**
	 * start adornment
	 */
	startAdornment: PropTypes.node,
	/**
	 * place holder text
	 */
	placeHolderText: PropTypes.string,
};

export default SuggestMultipleValueInfiniteScroll;
