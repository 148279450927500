import React, { createContext, useState, useContext } from 'react';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import { useBlumeHelpCenter } from '@worklist-2/patientPortal/src/context/BlumeHelpCenterContext';

const GlobalSearchHelpCenterPPContent = createContext({
	searchData: [],
	searchText: '',
	isGlobalSearch: false,
	loadingResult: false,
	searchDataResult: [],
	searchTextResult: '',
});

export const GlobalSearchHelpCenterPPProvider = ({ children }) => {
	const [searchText, setSearchText] = useState('');
	const [searchData, setSearchData] = useState([]);
	const [isGlobalSearch, setIsGlobalSearch] = useState(false);
	const [loadingResult, setLoadingResult] = useState(false);
	const [searchDataResult, setSearchDataResult] = useState([]);
	const [searchTextResult, setSearchTextResult] = useState('');
	const { helpArticles } = useBlumeHelpCenter();

	const handleSaveRecentlyViewed = (item, isApiResource) => {
		item.eTag = undefined; //reset highlight value
		const recentViewed = localStorage.getItem('recentViewed');
		const resourceType = 'helpcenter';
		let content = '';
		let category = '';
		let article = '';
		let itemDetails = {};
		if (isApiResource) {
			category = item.resource?.Category;
			article = item.resource?.Article;
			itemDetails = {
				...item,
				viewType: searchScopes.patientPortalHelp,
			};
		} else {
			content = item.textContent.join(' ');
			category = item.path.split('/')[0];
			article = item.name.toLowerCase();
			const newItem = {
				resource: {
					Content: content,
					Category: category,
					Article: article,
					ResourceType: resourceType,
				},
			};
			itemDetails = {
				...newItem,
				viewType: searchScopes.patientPortalHelp,
			};
		}

		if (recentViewed) {
			let arrays = JSON.parse(recentViewed);
			let isContain;
			const arraysByType = _.filter(arrays, elem => elem.viewType === itemDetails.viewType) || [];

			if (arraysByType.length > 0) {
				isContain = _.find(
					arraysByType,
					elem =>
						elem.resource?.Category === category &&
						elem.resource?.Article === article &&
						elem.resource?.ResourceType === resourceType
				);
			}

			if (!isContain) {
				if (arraysByType.length > 3) {
					arraysByType.pop();
				}
				arraysByType.unshift(itemDetails);
				_.remove(arrays, elem => elem.viewType === itemDetails.viewType);
				arrays = _.union(arrays, arraysByType);
				localStorage.setItem('recentViewed', JSON.stringify(arrays));
			}
		} else {
			const arrays = [itemDetails];
			localStorage.setItem('recentViewed', JSON.stringify(arrays));
		}
	};

	const buildHelpCenterUrl = item => {
		const category = item.Category;
		const article = item.Article;
		const reg = /\D+/g;
		let url = '/help/category';

		if (category && article) {
			const articleIndex = article.replace(reg, '') - 1;
			url = `${url}/${category}/${articleIndex}`;
		}

		return url;
	};

	const buildHelpCenterArticle = item => {
		const category = helpArticles.find(cat => cat.name === item.Category);
		const article = item.Article;
		const reg = /\D+/g;

		if (category && article) {
			const articleIndex = article.replace(reg, '') - 1;

			return category.articleListMenu[Math.min(articleIndex, category.articleListMenu.length - 1)];
		}
	};

	return (
		<GlobalSearchHelpCenterPPContent.Provider
			value={{
				searchText,
				searchData,
				isGlobalSearch,
				loadingResult,
				searchDataResult,
				searchTextResult,
				setSearchData,
				setSearchText,
				setIsGlobalSearch,
				setLoadingResult,
				setSearchDataResult,
				setSearchTextResult,
				handleSaveRecentlyViewed,
				buildHelpCenterUrl,
				buildHelpCenterArticle,
			}}
		>
			{children}
		</GlobalSearchHelpCenterPPContent.Provider>
	);
};

export const useGlobalSearchHelpCenterPPContent = () => useContext(GlobalSearchHelpCenterPPContent);
export default GlobalSearchHelpCenterPPProvider;
