import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { InputWrapper } from '../../../Help/helpStyles';
import ChatBoxInput from './ChatBoxInput';

export const ChatBox = ({ send, sending }) => {
	const [message, setMessage] = useState('');

	const handleSubmit = e => {
		e.preventDefault();
		if (message.trim().length > 0) {
			send({
				message,
				isUser: true,
			});
			setMessage('');
		} else {
			console.error('no message', message);
		}
	};

	return (
		<form onSubmit={handleSubmit} className="chat-box">
			<IconButton type="button" style={{ width: 40, height: 40 }} className="share">
				<DriveFolderUploadOutlinedIcon />
			</IconButton>
			<InputWrapper className="input-wrapper">
				<ChatBoxInput
					style={{ flex: 1 }}
					placeholder="Enter your message here"
					value={message}
					onChange={e => setMessage(e.target.value)}
				/>
				<IconButton
					type="submit"
					aria-label="toggle password visibility"
					edge="end"
					sx={{ m: 0 }}
					style={{ width: 40, height: 40 }}
					disabled={sending}
					className="send"
				>
					<SendIcon style={{ color: '#42a5f5' }} />
				</IconButton>
			</InputWrapper>
		</form>
	);
};
