import { create } from 'zustand';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';

const minCardHeightOnlyInfo = 248;
const minCardHeight = 378;
const minCardHeightWithNotes = 408;

const initialConfiguration = {
	enablePatientNavigation: true,
	enableOverflowMenu: false,
	enableCollapse: true,
	expandOnInit: false,
};

export const usePatientInfoCardStore = create(set => ({
	// TODO: Remove the "cardOrder" at the time of "galaxy-dv-patient-chart-refactor" FF removal
	// Store variables with default values
	selectedCard: {
		idx: 2, // Default selected card
	},
	cardOrder: [0, 1, 2], // Mandatory prop to preserve the selected card order
	patientInfoCardsList: [],
	isCardCollapsed: false,
	selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
	refHeight: minCardHeight,
	minHeight: minCardHeight,
	selectedPatientDocument: null,
	initConfig: initialConfiguration,
	// Default UAC is true
	patientPermission: { read: true },
	// ==================== Setters ====================
	setSelectedCard: card => set({ selectedCard: card }),
	setCardOrder: order => set({ cardOrder: order }),
	setPatientInfoCardsList: updatedCards => set({ patientInfoCardsList: updatedCards }),
	setIsCardCollapsed: status => set({ isCardCollapsed: status }),
	setSelectedDocFilter: filter => set({ selectedDocFilter: filter }),
	setRefHeight: height => set({ refHeight: height }), // Root height of the cards
	setMinHeight: height => set({ minHeight: height }), // Minimum height to set for the cards on resize
	setSelectedPatientDocument: id => set({ selectedPatientDocument: id }),
	setPatientPermission: permission => set({ patientPermission: permission }),
	setInitConfig: config => set({ initConfig: config }),
	// Initialize the card in collapsed state; 90 indicates that the height of each card is 30px in the collapsed state (3 cards x 30px = 90px)
	// TODO: This function has been deprecated, remove the function at the time of "galaxy-dv-patient-chart-refactor" removal
	initAsCollapsed: (galaxyDvGlobalNotes, newInitConfig, patientPermission) => {
		let shouldEnablePatientNavigation = true;
		if (patientPermission) {
			shouldEnablePatientNavigation = patientPermission?.read;
			set(state => {
				return {
					initConfig: { ...state.initConfig, enablePatientNavigation: shouldEnablePatientNavigation },
					patientPermission: patientPermission,
				};
			});
		}
		const collapsedState = !shouldEnablePatientNavigation
			? { refHeight: 30, minHeight: 30, cardOrder: [2], selectedCard: { idx: 2 } }
			: galaxyDvGlobalNotes
			? { refHeight: 90, minHeight: 90, cardOrder: [0, 1, 2], selectedCard: { idx: 2 } }
			: { refHeight: 60, minHeight: 60, cardOrder: [0, 1], selectedCard: { idx: 1 } };

		set(collapsedState);
		// Update initConfig
		if (newInitConfig) {
			set(state => {
				return { initConfig: { ...state.initConfig, ...newInitConfig } };
			});
		}
	},

	// Initialize the card in collapsed state; 90 indicates that the height of each card is 30px in the collapsed state (3 cards x 30px = 90px)
	initPatientCardConfig: (newInitConfig, isGalaxyDvGlobalNotesEnabled = false, hasPatientPermission = false) => {
		let hasPatientChartReadPermission = true;
		let cardsConfiguration = initialConfiguration;
		let collapsedState = isGalaxyDvGlobalNotesEnabled
			? { refHeight: 90, minHeight: 90 }
			: { refHeight: 60, minHeight: 60 };

		if (hasPatientPermission) {
			hasPatientChartReadPermission = hasPatientPermission?.read;
			cardsConfiguration = {
				...initialConfiguration,
				enablePatientNavigation: hasPatientChartReadPermission,
			};
		}

		// If the read permission is false, display only the "Patient Info card" in collapsed state
		if (!hasPatientChartReadPermission) {
			collapsedState = { refHeight: 30, minHeight: 30 };
		}

		if (newInitConfig) {
			cardsConfiguration = { ...cardsConfiguration, ...newInitConfig };
		}

		const expandedState = {
			minHeight: isGalaxyDvGlobalNotesEnabled ? minCardHeightWithNotes : minCardHeight,
			refHeight: isGalaxyDvGlobalNotesEnabled ? minCardHeightWithNotes : minCardHeight,
		};

		if (!newInitConfig?.expandOnInit) {
			return set({
				initConfig: cardsConfiguration,
				minHeight: collapsedState?.minHeight,
				refHeight: collapsedState?.refHeight,
			});
		}

		set({
			initConfig: cardsConfiguration,
			minHeight: expandedState?.minHeight,
			refHeight: expandedState?.refHeight,
		});
	},

	// ==================== Re-setters ====================
	resetPatientInfoCardStore: galaxyDvGlobalNotes => {
		const defaultState = {
			selectedCard: { idx: galaxyDvGlobalNotes ? 2 : 1 }, // default selected card
			cardOrder: galaxyDvGlobalNotes ? [0, 1, 2] : [0, 1],
			patientInfoCardsList: [],
			isCardCollapsed: false,
			selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
			initConfig: {
				enablePatientNavigation: true,
				enableOverflowMenu: false,
				enableCollapse: true,
				expandOnInit: false,
			},
		};
		set(defaultState);
	},
	resetPatientDoc: () =>
		set({
			selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
		}),
}));
