import { useQuery } from '@tanstack/react-query';

import getStudy from '@worklist-2/patientPortal/src/api/getStudy';
import { STUDY_DATA } from '@worklist-2/patientPortal/src/__mocks__/mockData';
// eslint-disable-next-line import/no-unresolved
import { useConfig } from '@rs-core/context/ConfigContext';
// eslint-disable-next-line import/no-unresolved
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const fetchStudy = async ({ studyId, isShared, data, __config }) => {
	const studyData = STUDY_DATA.filter(e => e.id === studyId);

	if (studyData && studyData.length > 0) {
		return {
			study: studyData[0],
			studyAdditionalInfo: {
				isShared: '',
				permission: '',
			},
		};
	}

	const response = await getStudy({ __config, id: studyId });
	let studyPermission = '';
	if (!data?.study?.permission && isShared === 'withMe') {
		const email = sessionStorage.getItem('login_hint');
		const filteredContact = response.sharedInformation.filter(contact => contact.email === email);
		studyPermission = filteredContact.length > 0 ? filteredContact[0].permission : '';
	}

	return {
		study: response,
		studyAdditionalInfo: {
			isShared: isShared || '',
			permission: data?.study?.permission || studyPermission,
		},
	};
};

const useStudyQuery = ({ studyId, isShared, data }) => {
	const __config = useConfig();
	const phoenixBlumeMobileStudyDetailOptimization = useBooleanFlagValue(
		'phoenix-blume-mobile-study-detail-optimization'
	);

	return useQuery(['study', studyId], () => fetchStudy({ studyId, isShared, data, __config }), {
		enabled: phoenixBlumeMobileStudyDetailOptimization && !!studyId,
	});
};

export default useStudyQuery;
