import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const ProgressAlert = ({ sx }) => {
	const { t } = useTranslation('homepage');

	return (
		<Box
			data-testid="progress-alert"
			sx={{
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'start',
				gap: '8px',
				height: '26px',
				borderRadius: '4px',
				padding: '4px 8px',
				border: '1px solid #fedf89',
				background: '#FFFAEB',
				color: '#DC6803',
				fontSize: '12px',
				lineHeight: '18px',
				fontWeight: '500',
				...sx,
			}}
		>
			<ErrorOutlineOutlinedIcon />
			<Typography>{t('The study is still in progress and Reports will be available soon.')}</Typography>
		</Box>
	);
};

export default ProgressAlert;
