import React from 'react';
import { useSearchParams } from 'react-router-dom';

// libraries
import classnames from 'classnames';

// mui
import Box from '@mui/material/Box';

import { useAuth } from '@rs-core/context/UserAuthContext';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { useGlobalStore } from '@rs-core/store/globalStore';

import GlobalSearchV2 from '@rs-ui/components/GlobalSearch/GlobalSearchV2/GlobalSearchV2';

import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { isDvInMultiMonitor } from '@worklist-2/worklist/src/DocumentViewerV3/utils';
import { useRecognitionContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/RecognitionContext';

const GlobalHeader = ({ className, children, additionalSearchParams, setParentData, initialValue }) => {
	const galaxyDvEnableDvFullScreen = useBooleanFlagValue('galaxy-dv-enable-dv-full-screen');
	const galaxyDvEnableAugnitoSdk = useBooleanFlagValue('galaxy-dv-enable-augnito-sdk');
	const { isHiddenGlobalSearch } = useAppModeContext();
	const { globalPermissionsForLoggedUser } = useAuth();

	const { vrViaDvToolbar: vrViaDvToolbarFromContext } = useRecognitionContext();
	const vrViaDvToolbarFromStore = useGlobalStore(state => state.vrViaDvToolbar);

	const vrViaDvToolbar = galaxyDvEnableAugnitoSdk ? vrViaDvToolbarFromStore : vrViaDvToolbarFromContext;

	const [searchParams] = useSearchParams();
	const screenNumber = searchParams.get('screenNumber');

	const shouldHideHeader = () => galaxyDvEnableDvFullScreen && isDvInMultiMonitor(screenNumber) && !vrViaDvToolbar;

	if (shouldHideHeader()) {
		return null;
	}

	return (
		<div
			className={classnames(className)}
			id="page-header"
			style={{ display: `${isHiddenGlobalSearch ? 'none' : 'flex'}` }}
		>
			{globalPermissionsForLoggedUser?.Search ? (
				<GlobalSearchV2
					additionalSearchParams={additionalSearchParams}
					initialValue={initialValue}
					setParentData={setParentData}
				/>
			) : (
				<Box sx={{ height: '61px' }} />
			)}
			{/* only render VR Speech Icon after FF is loaded */}
			{children}
		</div>
	);
};

GlobalHeader.propTypes = {};

export default GlobalHeader;
