// @flow

// should be an enum, but enabling enums in Flow is way too complex
export const ERoutes = {
	DEFAULT_ROOT: '/',
	NOT_FOUND: '*',

	HOME: '/home',
	HOME_ID: '/home/:id', // #TODO: verify, id is `worklistLayoutId`, when is it used ?

	BILLING_PER_ORDER: '/order/:id/billing/:screenName',
	BILLING_PER_STUDY: '/order/:id/billing/:studyId/:screenName',

	CUSTOMIZE_OVERLAYS: '/customizeOverlays',

	DOCUMENT_VIEWER: '/document-viewer-v3',
	DOCUMENT_TEMPLATE_MANAGER: 'template',
	DOCUMENT_ORG_HEADER_FOOTER: 'header-footer',

	ENCOUNTER: '/encounter/:id/*',

	HANGING_PROTOCOL: '/hangingProtocol/*',

	IMAGE_VIEWER: '/imageViewer',
	IMAGE_VIEWER_3D: '/imageViewer3d',
	IMAGE_VIEWER_EXTERNAL: '/iv',
	OLD_IMAGE_VIEWER_EXTERNAL: '/imageviewerexternal',

	IMPORT: '/import',

	LICENSE: '/licence/*',
	LOG: '/log',
	LOGIN: '/login',

	MARKETPLACE: '/marketplace',
	MARKETPLACE_APP_DIRECTORY: '/marketplace/appdirectory',
	MARKETPLACE_APP_DETAIL: '/marketplace/apps/:id/*',

	ORDER: '/order/:id/*',
	ORGS: '/organization',
	ORG_ADD: '/organization/add',
	ORG_DETAILS: '/organization/:id/*',
	ORG_HEALTHCARE_SERVICE: '/organization/:id/healthcare-services/:healthcareServiceId',
	ORG_RIS_FEE_SCHEDULE: '/organization/:id/ris/fee-schedule/:scheduleId',
	ORG_RIS_INSURANCE_ORDER: '/organization/:id/ris/order-sets/:orderSetId',
	ORG_ROLES: '/organization/:id/roles/:roleId',
	ORG_USERS: '/organization/:id/users',
	ORG_USER_DETAIL: '/organization/:id/users/:userId',

	PROFILE: '/profile',
	PATIENT_DETAIL: '/patient/:patientId', // for the default PatientInfo.jsx
	PATIENT_DETAIL_SCREEN: '/patient/:patientId/:screen', // for both PatientInfo.jsx and subscreens that are different like PatientActivity and PatientStudyHistory, this will be reworked later
	// PATIENT_ACTIVITY: '/patient/:patientId/activity',
	// PATIENT_STUDY_HISTORY: '/patient/:patientId/study-history',

	// PATIENT_INFO_SCREEN: '/patientinfo/:id/:screenName',
	// PATIENT_INFO_SUBSCREEN: '/patientInfo/:id/:screenName/:subScreenName',
	// PATIENT_INFO: '/patientinfo/:id',
	PATIENT_FORM: '/patient-form/*',

	ROOT: '/root',
	ROOT_DETAIL: '/root/:id/*',
	ROOT_NEW: '/root/new',
	ROOT_NEW_BLANK: '/root/new/blank',
	ROOT_PREMIUM_STUDY: '/root/premium/StudyReport',
	ROOT_PREMIUM_CORAZONE_DATA_DUMP: '/root/premium/CorazoneDataDump',
	ROOT_PREMIUM_CORAZONE_DATA_DUMP_ORDER: '/root/premium/CorazoneDataDumpOrder',
	ROOT_PREMIUM_DATA_DUMP: '/root/premium/DataDump',
	ROOT_PREMIUM_DATA_STORAGE_REPORT: '/root/premium/DataStorageReport',
	ROOT_PREMIUM_LEAN_ON_ME: '/root/premium/LeanOnMe',
	ROOT_PREMIUM_PATIENT_REPORT: '/root/premium/PatientReport',
	ROOT_PREMIUM_REF_PHYSICIAN_REPORT: '/root/premium/ReferringPhysicianReport',

	SEARCH_RESULTS: '/searchresults',
	SCHEDULER: '/scheduler',

	USER_INFO: '/userinfo/*',

	TEACHING_FOLDER: '/teaching-folder',

	EMAIL_TEMPLATES: '/email-templates',
};

// Marketplace

export type MarketplaceAppDirectoryQuery = {
	author?: string,
	name?: string,
};

export type MarketplaceDetailParams = {
	id: string, // #TODO: rename to `appId`
};

// Order

export type OrderDetailParams = {
	id: string, // #TODO: rename to `orderId`
};

// Org details

export type OrgDetailParams = {
	id: string, // #TODO: rename to `orgId`
};
export type OrgQuery = {
	affiliated: boolean,
	parent: string,
	parentName: string,
};

export type OrgUsersParams = {
	id: string, // #TODO: rename to `orgId`
};
export type OrgUserDetailParams = {
	id: string, // #TODO: rename to `orgId`
	userId: string,
};

// Patient

export type TPatientDetailScreen =
	| 'allergy'
	| 'info' //                     default patient info page
	| 'insurance' //                this is current V2
	| 'insurance-information' //    this is older, V1
	| 'medical-history'
	| 'patient-needs'
	| 'prescriptions'
	| 'study-history';

export type PatientDetailParams = {
	patientId: string,
	/**
	 * Active tab within PatientInfo
	 * @default 'info'
	 */
	screen: TPatientDetailScreen,
};
