import { logInfo } from '@rs-core/utils/logger';

// slices/currentStudyInfoSlice.js
const createCurrentStudyInfoSlice = set => ({
	currentStudyInfo: {},

	// ==================== Setters ====================
	setCurrentStudyInfo: studyInfo => {
		logInfo('globalStore', 'setCurrentStudyInfo', {
			studyId: studyInfo?.id,
		});
		set(() => ({
			currentStudyInfo: studyInfo,
		}));
	},

	// ==================== Re-setters ====================
	resetCurrentStudyInfo: () => {
		logInfo('globalStore', 'resetCurrentStudyInfo');
		set(() => ({
			currentStudyInfo: {},
		}));
	},
});

export default createCurrentStudyInfoSlice;
