import React, { useState } from 'react';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
//MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PictureAsPdfOutlinedIcon from '@worklist-2/ui/src/assets/icons/uploadProgress/pdf.svg';
import ImageOutlinedIcon from '@worklist-2/ui/src/assets/icons/uploadProgress/jpeg.svg';
import VideocamIcon from '@worklist-2/ui/src/assets/icons/uploadProgress/mov.svg';
import StudyIcon from '@worklist-2/ui/src/assets/icons/UserProfile/StudyIcon.svg';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useTranslation } from 'react-i18next';
import PauseIcon from './pause.svg';
import CancelIcon from './cancel.svg';

function LinearProgressWithLabel(props) {
	const { t } = useTranslation('profile');
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '90%', paddingLeft: '55px' }}>
				<LinearProgress
					color="success"
					sx={{
						borderRadius: '2px',
						backgroundColor: '#00000033',
					}}
					value={props.value}
					variant="determinate"
				/>
			</Box>
			{props.status === 'inprogress' || props.status === 'paused' ? (
				<Box sx={{ minWidth: 35, paddingLeft: '20px' }}>
					<Typography color="text.secondary" variant="body2">{`${Math.round(props.value)}%`}</Typography>
				</Box>
			) : props.status === 'cancelled' ? (
				<Box sx={{ minWidth: 35, paddingLeft: '6px' }}>
					<Typography color="#E11B1B" variant="body2">
						{t('Cancelled')}
					</Typography>
				</Box>
			) : props.status === 'failed' ? (
				<Box sx={{ minWidth: 35, paddingLeft: '6px' }}>
					<Typography color="#E11B1B" variant="body2">
						{t('Failed')}
					</Typography>
				</Box>
			) : props.status === 'completed' ? (
				<Box sx={{ minWidth: 35, paddingLeft: '6px' }}>
					<Typography color="#00A455" variant="body2">
						{props.isDownload ? 'Downloaded' : 'Uploaded'}
					</Typography>
				</Box>
			) : (
				<Box sx={{ minWidth: 35, paddingLeft: '6px' }}>
					<Typography color="#00A455" variant="body2" />
				</Box>
			)}
		</Box>
	);
}

const UploadProgressFile = ({ document, progress, isDownload }) => {
	const { t } = useTranslation('profile');
	const [cancelled, setCancelled] = useState(false);
	const { importDispatcher } = window;
	const { appMode } = useAppModeContext();
	function bytesToSize(bytes) {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
	}
	function playUpload() {
		importDispatcher.resumeFileUpload(document.name);
	}
	function pauseUpload() {
		importDispatcher.pauseFileUpload(document.path);
	}
	function cancelUpload() {
		if (document.status === 'failed') {
			setCancelled(true);
		} else {
			importDispatcher.cancelFileUpload(document.name);
		}
	}
	let fileType = '';
	if (document?.type === 'application/pdf') {
		fileType = <PictureAsPdfOutlinedIcon sx={{ color: ' #6478C0' }} />;
	} else if (document?.type === 'image/png' || document?.type === 'image/jpeg' || document?.type === 'image/jpg') {
		fileType = <ImageOutlinedIcon sx={{ color: '#6478C0' }} />;
	} else if (document?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
		fileType = (
			<TextSnippetIcon
				sx={{
					color: appMode === 'patientPortal' ? '#6478C0' : '#FFFFFF',
					opacity: '87%',
				}}
			/>
		);
	} else if (document?.type === 'video/mp4') {
		fileType = <VideocamIcon sx={{ color: ' #6478C0' }} />;
	} else {
		fileType = <StudyIcon />;
	}

	if (cancelled) {
		return <></>;
	}

	return (
		<>
			{document ? (
				<>
					<List
						sx={{
							bgcolor: appMode === 'patientPortal' ? 'Background.paper' : '#272727',
							paddingBottom: '1px',
						}}
					>
						<ListItem
							secondaryAction={
								<>
									{document.progress < 100 && (
										<>
											{document.status === 'paused' ? (
												<IconButton
													edge="end"
													sx={{ opacity: '60%' }}
													onClick={() => playUpload()}
												>
													<PlayArrowIcon />
												</IconButton>
											) : document.status === 'failed' ? (
												<IconButton
													edge="end"
													sx={{ opacity: '60%' }}
													onClick={() => playUpload()}
												>
													<RefreshIcon />
												</IconButton>
											) : document.status === 'cancelled' ? (
												<IconButton disabled edge="end" sx={{ opacity: '60%' }}>
													<CancelIcon />
												</IconButton>
											) : (
												<IconButton
													edge="end"
													sx={{ opacity: '60%' }}
													onClick={() => pauseUpload()}
												>
													<PauseIcon />
												</IconButton>
											)}
											{document.status !== 'cancelled' && (
												<IconButton
													edge="end"
													sx={{ opacity: '60%' }}
													onClick={() => cancelUpload()}
												>
													<CancelIcon />
												</IconButton>
											)}
										</>
									)}
								</>
							}
							sx={{
								paddingTop: '1px',
								paddingLeft: '1px',
							}}
						>
							<ListItemAvatar>
								<Avatar
									style={{ borderRadius: '10px' }}
									sx={{
										bgcolor: 'transparent',
										opacity: '100%',
										width: 'unset',
										height: 'unset',
									}}
									variant="rounded"
								>
									{fileType}
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={appMode === 'patientPortal' ? document.path : document.name}
								secondary={`${bytesToSize(document.size)} ${
									isDownload ? t('Downloading') : t('Uploading')
								}`}
								sx={{
									color: appMode === 'patientPortal' ? '#121212' : '#FFFFFF',
								}}
							/>
						</ListItem>
					</List>
					<Box sx={{ width: '100%' }}>
						<LinearProgressWithLabel
							isDownload={isDownload}
							status={document.status}
							value={document.progress}
						/>
					</Box>
				</>
			) : (
				<Box sx={{ height: '50px', marginTop: '20px' }}>
					<LinearProgressWithLabel isDownload={isDownload} status="inprogress" value={progress} />
				</Box>
			)}
		</>
	);
};
export default UploadProgressFile;
