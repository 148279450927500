// Core
import React from 'react';

// libraries
import PropTypes from 'prop-types';

// Custom
import CHAT_SECTIONS, { CHAT_SECTIONS_default, CHAT_SECTIONS_titles, DEFAULT_SUPPORTER } from './CHAT_SECTIONS';
import { useChatGlobalContext } from './ChatGlobalContext';

import { useAuth } from '@worklist-2/core/src/context/UserAuthContext';
import { useAppModeContext } from '@worklist-2/core/src/context/AppModeContext';
import { useConfig } from '@worklist-2/core/src/context/ConfigContext';

import { addCacheBuster } from '@worklist-2/core/src/utils/url';
import { getSubscribedConversations, getMessages } from './ConversationHelper';
import axios from 'axios';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { logInfo } from '@rs-core/utils/logger';

// Context
const ChatContext = React.createContext({
	resolveSectionTitle: null,
	resolveBackClickHandler: null,
	showNavigation: null,
	showSearch: null,
	showHeaderInCall: null,
	showFormSubmitButtonInField: null,
	showAvatarsInNormalMessagingView: null,
	showAvatarsInExpandedMessagingView: null,
	showSelectedUsers: null,
	showNewChatCopyLink: null,
	showNewChatInvite: null,
	showHelp: null,
	MessagingIcon: null,
	search: null,
	setSearch: null,
	selectedContacts: null,
	setSelectedContacts: null,
	showFiles: null,
});

// Hook

const useChatContext = () => React.useContext(ChatContext);

// Component

const ChatContextProvider = ({
	showNavigation = true,
	showSearch = true,
	showHeaderInCall = true,
	showFormSubmitButtonInField = true,
	showAvatarsInNormalMessagingView = false,
	showAvatarsInExpandedMessagingView = false,
	showSelectedUsers = true,
	showNewChatCopyLink = false,
	showNewChatInvite = false,
	showHelp = false,
	MessagingIcon = null,
	showFiles = true,
	children,
}) => {
	const {
		section,
		setSection,
		conversationType,
		setConversationType,
		callData,
		setCallData,
		callParticipants,
		setCallParticipants,
		callRoom,
		setCallRoom,
		expanded,
		setExpanded,
		state,
		dispatch,
		screenShare,
		setScreenShare,
		smallView,
		setSmallView,
		appBlumeMode,
		nonUsEntities,
	} = useChatGlobalContext();

	const { appMode } = useAppModeContext();
	const [showLoading, setShowLoading] = React.useState(true);

	const [search, setSearch] = React.useState(null);
	const [selectedContacts, setSelectedContacts] = React.useState([]);
	const __config = useConfig();
	const { t } = useTranslation('chat');
	const proactEnableOaiBlumeChat = useBooleanFlagValue('proact-enable-oai-blume-chat');
	const proactChatImpersonationChanges = useBooleanFlagValue('proact-chat-impersonation-changes');

	const mavenAiChat = useBooleanFlagValue('maven-help-manual-chatbot');
	const { isPatientPortalMode } = useAppModeContext();
	const isBlumeMode = isPatientPortalMode();
	const [chatContacts, setChatContacts] = React.useState([]);

	showAvatarsInExpandedMessagingView = appMode !== 'patientPortal';
	React.useEffect(() => {
		// Primitive redirect to user chat section when on fullscreen, needs better handling with backend (first chat available)

		if (section?.name === CHAT_SECTIONS.MAIN && expanded) {
			if (Object.keys(state).length > 0) {
				const conversationStates = Object.values(state);
				const recent = _.orderBy(conversationStates, 'datetime', 'desc')[0];
				setSection({
					name: CHAT_SECTIONS.CONVERSATION,
					conversation: recent.conversation,
				});
			}
		}
	}, [section, expanded]);

	const {
		client,
		loggedInUser,
		contacts,
		isClientInitialized,
		supportClient,
		isSupportClientInitialized,
		blumeClient,
		isBlumeClientInitialized,
	} = useAuth();

	React.useEffect(() => {
		if (contacts?.length > 0) {
			const contactList =
				proactEnableOaiBlumeChat || isBlumeMode
					? contacts
					: _.filter(contacts, contact => contact.isBlumeUser === false);

			updateChatContacts(contactList);
		}
	}, [contacts, proactEnableOaiBlumeChat]);

	const updateChatContacts = async contactList => {
		await getTwilioUsersAndUpdateContacts(contactList).then(result => setChatContacts([...result]));
	};

	const getTwilioUsersAndUpdateContacts = async contactList => {
		const promises = await contactList.map(async contact => {
			try {
				const twilioClient = contact?.isBlumeUser ? blumeClient : client;
				const twilioUser = await twilioClient.getUser(contact.email);
				twilioUser.on('updated', ({ user, updateReasons }) => {
					if (updateReasons.includes('reachabilityOnline')) {
						setChatContacts(prev => {
							const index = prev?.findIndex(item => item.text === user.identity);
							if (index > -1) {
								prev[index].isOnline = user.isOnline || false;
							}
							return [...prev];
						});
					}
				});

				return {
					user: {
						name: contact.name,
						internalId: contact?.internalId,
						role: contact?.role,
					},
					text: contact.email,
					isOnline: twilioUser?.isOnline || false,
					isBlumeUser: contact?.isBlumeUser || false,
				};
			} catch {
				// Can not find user ${contact.email} on ${contact.isBlumeUser ? 'Blume' : 'OAI'} Twilio conversation
			}

			return {
				user: { name: contact.name },
				text: contact.email,
				isOnline: false,
				internalId: contact?.internalId,
				role: contact?.role,
				isBlumeUser: contact?.isBlumeUser || false,
			};
		});

		return Promise.all(promises);
	};

	// Reducer

	const getConversationName = async (conversation, loggedUserEmail) => {
		loggedUserEmail =
			loggedUserEmail ?? loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase();
		let ppString = '';
		let ppEmail = '';

		const participants = conversation.attributes?.participants ?? [];
		const hasGroupName =
			participants.filter(participant => participant.name === conversation.friendlyName).length > 0;
		if (participants.length > 2 && !hasGroupName) {
			ppString = _.startCase(_.toLower(conversation?.friendlyName?.trim()));
		} else {
			const ppList = [];
			participants.forEach(pp => {
				if (pp.email !== loggedUserEmail) {
					ppEmail = pp.email;
					if (participants.length > 2) {
						ppList.push(_.capitalize(pp.name.trim().split(' ')[0]));
					} else {
						ppList.push(_.startCase(_.toLower(pp.name.trim())));
					}
				}
			});
			ppString = ppList.join(', ');
		}

		return {
			name: ppString,
			email: ppEmail,
		};
	};

	const fetchConversationDetail = async (conversation, clientMode, loggedUserEmail) => {
		let participantOnline = false;
		loggedUserEmail =
			loggedUserEmail ?? loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase();
		conversation.getParticipants().then(participants => {
			participants.forEach(p => {
				if (p.identity !== loggedUserEmail) {
					p.getUser().then(u => {
						participantOnline = participantOnline || u.isOnline;
						u.on('updated', ({ user, updateReasons }) => {
							if (updateReasons.includes('reachabilityOnline')) {
								userStatusUpdatedCallback({
									identity: user.identity,
									isOnline: user.isOnline,
									conversationSid: conversation.sid,
								});
							}
						});
					});
				}
			});
		});

		const res = await getConversationName(conversation, loggedUserEmail);
		// Get Last updated Message
		const messages = await getMessages(conversation);
		let unreadNotificationCount = await conversation.getUnreadMessagesCount();
		// The setAllMessagesUnread() method  that is being used in ChatMain.jsx sets unread messages count to null and not 1 or the number or recent messages recieved
		// and hence the UI for unread messages doesn't work when toggled using the pop up
		// Have made a work around for this here, whenever getUnreadMessagesCount() method returns null, we are setting it to 1
		if (unreadNotificationCount === null) unreadNotificationCount = 1;
		conversation.participantOnline = participantOnline;
		const participantCount = await conversation.getParticipantsCount();
		const convAttr = conversation.attributes;
		return {
			conversation,
			name: clientMode === 'support' && participantCount == 1 ? t('chatMain.support') : res.name,
			email: res.email.length == 0 && clientMode === 'support' ? DEFAULT_SUPPORTER.email : res.email,
			lastUpdated:
				messages.items.length > 0
					? messages.items[messages.items.length - 1].dateUpdated
					: conversation.dateUpdated,
			text: messages.items.length > 0 ? messages.items[messages.items.length - 1].body : '',
			datetime:
				messages.items.length > 0
					? messages.items[messages.items.length - 1].dateUpdated
					: conversation.dateUpdated,
			messages: messages.items,
			unread: unreadNotificationCount > 0 && messages.items.length > 0, // Checking number of messages is to fix PRO-1888
			participantOnline,
			isSupportConversation: clientMode === 'support' ?? false,
			isBlumeConversation: clientMode?.toLowerCase() === 'blume' ?? false,
			participantCount,
			senderFrom: convAttr?.from,
			senderPhone: convAttr?.phone,
			senderOrganization: convAttr?.organization,
			senderWebsite: convAttr?.website,
		};
	};

	const getConversationDetails = async (conversations, clientMode) =>
		Promise.all(
			conversations.map(async conv => {
				// For one-one conversation, participantsCount = 1 when a paticipant left,
				// still need to retrieve the conversation for the remaining participant
				if ((await conv.getParticipantsCount()) > 0) {
					return fetchConversationDetail(conv, clientMode);
				}
			})
		);

	const getConversations = async (chatClient, isInitialized, clientMode) => {
		const conversationArr = {};

		if (chatClient != null && isInitialized && loggedInUser != null) {
			if (clientMode === 'AI') {
				const aiUid = `omegaAIChat-${loggedInUser.id}` ?? 'ramsoft';
				const aiConvo = {
					isAiChat: true,
					conversation: {
						sid: aiUid,
						getParticipants: () => Promise.resolve([]),
						setAllMessagesRead: () => {},
						_participants: [],
					},
					datetime: new Date(),
					isBlumeConversation: false,
					isSupportConversation: false,
					lastUpdated: new Date(),
					messages: [
						{
							author: 'ChatBot',
							body:
								'Welcome! Discover how to use OmegaAI with help manual ChatBot, powered by AI.\n\n' +
								'Simply type your question using clear and concise natural language. Avoid jargon or ambiguous questions. \n\n' +
								"Example: How to change a patient's first name.",
							dateCreated: new Date(),
							dateUpdated: new Date(),
							index: 0,
							sid: `omegaAIChatNewMessage${Date.now()}`,
							getMediaByCategory: () => [],
							conversation: {
								_participants: [],
								participant: [],
							},
						},
					],
					name: 'Help Manual ChatBot',
				};

				conversationArr[aiUid] = aiConvo;
			} else {
				const conversations = await getSubscribedConversations(chatClient);
				const conversationList = await getConversationDetails(conversations, clientMode);

				conversationList.forEach(node => {
					if (node) {
						conversationArr[node.conversation.sid] = node;
					}
				});
			}

			dispatch({
				msgtype: 'INIT',
				payload: conversationArr,
			});

			setShowLoading(false);
		}
	};

	React.useEffect(() => {
		// Get OmegaAI/Blume conversations
		isClientInitialized && getConversations(client, isClientInitialized, 'OAI');
	}, [client, isClientInitialized, contacts, loggedInUser]);

	React.useEffect(() => {
		// Get support conversation (Breeze conversation)
		isSupportClientInitialized && getConversations(supportClient, isSupportClientInitialized, 'support');
	}, [supportClient, isSupportClientInitialized, loggedInUser]);

	React.useEffect(() => {
		// Get AI CHAT Conversation
		if (mavenAiChat) {
			isClientInitialized && getConversations(client, isClientInitialized, 'AI');
		}
	}, [client, isClientInitialized, loggedInUser, mavenAiChat]);

	const messageAddedCallback = React.useCallback(message => {
		if (message.author === (loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase())) {
			message.conversation.updateLastReadMessageIndex(message.index);
		}
		dispatch({
			msgtype: 'MESSAGE_ADDED',
			payload: message,
		});
	}, []);

	const userStatusUpdatedCallback = React.useCallback(user => {
		if (user.identity !== (loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase())) {
			dispatch({
				msgtype: 'USER_UPDATED',
				payload: user,
			});
		}
	}, []);

	const updateTypingIndicator = React.useCallback(participant => {
		if (participant.identity !== (loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase())) {
			dispatch({
				msgtype: 'USER_TYPING',
				payload: participant,
			});
		}
	});

	const conversationAddedCallback = React.useCallback(async (conv, clientMode, loggedUserEmail) => {
		const convDetail = await fetchConversationDetail(conv, clientMode, loggedUserEmail);
		dispatch({
			msgtype: 'CONVERSATION_ADDED',
			payload: convDetail,
		});
	}, []);

	const conversationUpdatedCallback = React.useCallback(async (conv, clientMode, loggedUserEmail) => {
		const convDetail = await fetchConversationDetail(conv, clientMode, loggedUserEmail);
		dispatch({
			msgtype: 'CONVERSATION_UPDATED',
			payload: convDetail,
		});
	}, []);

	const tokenRefetchCallback = React.useCallback(
		(twilioClient, clientMode) => {
			const twilioConnectionState = twilioClient?.connectionState;
			if (twilioConnectionState !== 'connected') {
				logInfo(
					'ChatContext',
					'tokenRefetchCallback - renewing twilio token will be stopped because Twilio is not connected',
					{
						clientMode,
						twilioConnectionState,
					}
				);
				return;
			}
			const constructUrl = (mode, twilioUrl, identity) => {
				if (mode === 'support') {
					return `${twilioUrl}/Conversation/token?identity=${identity}`;
				}
				if (mode === 'Blume') {
					if (proactChatImpersonationChanges) {
						return `${__config.data_sources.fhir}/Conversation/blume/token?identity=${identity}`;
					}
					return `${__config.data_sources.fhir}/Conversation/blume/token`;
				}
				if (appBlumeMode) {
					return `${__config.data_sources.blume}User/Conversation/token`;
				}
				if (proactChatImpersonationChanges) {
					return `${__config.data_sources.fhir}/Conversation/token?identity=${identity}`;
				}
				return `${__config.data_sources.fhir}/Conversation/token`;
			};

			let twilioUrl = __config.data_sources.breeze;
			let identity = loggedInUser?.email;
			if (nonUsEntities.includes(__config.resource_group)) {
				twilioUrl = __config.data_sources.pous01_breeze_twilio;
				identity = `${__config.resource_group}-${loggedInUser?.email}`;
			}

			const url = constructUrl(clientMode, twilioUrl, identity);

			axios.get(addCacheBuster(url)).then(result => {
				if (result?.data) {
					switch (clientMode) {
						case 'support':
							supportClient?.updateToken(result.data);
							break;
						case 'Blume':
							blumeClient?.updateToken(result.data);
							break;
						default:
							client?.updateToken(result.data);
					}
				}
			});
		},
		[
			__config,
			loggedInUser,
			nonUsEntities,
			appBlumeMode,
			proactChatImpersonationChanges,
			supportClient,
			blumeClient,
			client,
		]
	);

	const addListeners = (twilioClient, isTwilioClientInitialized, clientType) => {
		if (twilioClient != null && isTwilioClientInitialized) {
			twilioClient.on('tokenAboutToExpire', () => tokenRefetchCallback(twilioClient, clientType));
			twilioClient.on('tokenExpired', () => tokenRefetchCallback(twilioClient, clientType));

			twilioClient.on('typingStarted', participant => {
				updateTypingIndicator(participant);
			});

			twilioClient.on('typingEnded', participant => {
				updateTypingIndicator(participant);
			});

			if (clientType?.toLowerCase() === 'support') {
				twilioClient.on('conversationAdded', async conversation => {
					await conversationAddedCallback(
						conversation,
						clientType,
						loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase()
					);

					conversation.on('messageAdded', message => {
						messageAddedCallback(message);
					});
				});
			} else {
				// client type = OAI or Blume
				twilioClient.on('conversationJoined', conversation => {
					if (conversation.status === 'joined') {
						conversationAddedCallback(
							conversation,
							clientType,
							loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase()
						);
					}
				});

				twilioClient.on('participantJoined', participant => {
					conversationUpdatedCallback(
						participant.conversation,
						clientType,
						loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase()
					);
				});

				twilioClient.on('conversationUpdated', conversation => {
					if (conversation.updateReasons?.includes('attributes')) {
						conversationUpdatedCallback(
							conversation.conversation,
							clientType,
							loggedInUser?.email?.toLowerCase() ?? loggedInUser?.userName?.toLowerCase()
						);
					}
				});

				twilioClient.on('conversationRemoved', conversation => {
					dispatch({
						msgtype: 'CONVERSATION_LEFT',
						payload: conversation,
					});
				});

				twilioClient.on('messageAdded', message => {
					messageAddedCallback(message);
				});
			}
		}
	};

	React.useEffect(() => {
		client && addListeners(client, isClientInitialized, 'OAI');
	}, [isClientInitialized]);

	React.useEffect(() => {
		blumeClient && addListeners(blumeClient, isBlumeClientInitialized, 'blume');
	}, [isBlumeClientInitialized]);

	React.useEffect(() => {
		supportClient && addListeners(supportClient, isSupportClientInitialized, 'support');
	}, [isSupportClientInitialized]);

	const resolveSectionTitle = React.useCallback(() => {
		if (!section) {
			return '';
		}

		if (section.addUsers) {
			return t(CHAT_SECTIONS_titles.addUsers);
		}

		if (section.group) {
			return t(CHAT_SECTIONS_titles.group);
		}

		return CHAT_SECTIONS_titles[section.name] || '';
	}, [section]);

	const resolveBackClickHandler = React.useCallback(() => {
		if (!section || section.name === CHAT_SECTIONS.MAIN) {
			return;
		}

		if (section.addUsers) {
			return () => {
				setSection(value => ({ ...value, addUsers: false }));
			};
		}
		if (section.name === CHAT_SECTIONS.NEW_GROUP_CREATE) {
			return () => {
				setSection(CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS);
			};
		}

		if (section === CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS) {
			setSection(CHAT_SECTIONS_default);
		}

		return () => {
			setSection(CHAT_SECTIONS_default);
		};
	}, [section, setSection]);

	return (
		<ChatContext.Provider
			value={{
				section,
				setSection,
				conversationType,
				setConversationType,
				callData,
				setCallData,
				callParticipants,
				setCallParticipants,
				callRoom,
				setCallRoom,
				expanded,
				setExpanded,
				state,
				dispatch,
				resolveSectionTitle,
				resolveBackClickHandler,
				showNavigation,
				showSearch,
				showHeaderInCall,
				showFormSubmitButtonInField,
				showAvatarsInNormalMessagingView,
				showAvatarsInExpandedMessagingView,
				showSelectedUsers,
				showNewChatCopyLink,
				showNewChatInvite,
				showHelp,
				MessagingIcon,
				search,
				setSearch,
				showLoading,
				fetchConversationDetail,
				supportClient,
				isSupportClientInitialized,
				selectedContacts,
				setSelectedContacts,
				showFiles,
				screenShare,
				setScreenShare,
				smallView,
				setSmallView,
				appBlumeMode,
				chatContacts,
				setChatContacts,
				nonUsEntities,
			}}
		>
			{children}
		</ChatContext.Provider>
	);
};

ChatContextProvider.propTypes = {
	showNavigation: PropTypes.bool,
	showSearch: PropTypes.bool,
	showHeaderInCall: PropTypes.bool,
	showFormSubmitButtonInField: PropTypes.bool,
	showAvatarsInNormalMessagingView: PropTypes.bool,
	showAvatarsInExpandedMessagingView: PropTypes.bool,
	showSelectedUsers: PropTypes.bool,
	showNewChatCopyLink: PropTypes.bool,
	showNewChatInvite: PropTypes.bool,
	showHelp: PropTypes.bool,
	search: PropTypes.object,
	setSearch: PropTypes.bool,
	MessagingIcon: PropTypes.element,

	onConversationTypeChange: PropTypes.func,

	children: PropTypes.node,
};

export default ChatContextProvider;
export { useChatContext, ChatContext };
