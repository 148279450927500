// Core

import React, { useState, useMemo, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AddIcon from '@mui/icons-material/Add';

// Custom
import { useChatContext } from './ChatContext';
import ChatList, { ChatListHeader, ChatListItem, ChatListItemUser } from './ChatList';
import ChatSearch from './ChatSearch';
import { CHAT_CONTACT_SPACING } from './ChatContact';
import CHAT_SECTIONS, { CHAT_SECTIONS_default, CHAT_SECTIONS_titles } from './CHAT_SECTIONS';

import InviteIcon from '@worklist-2/ui/src/assets/icons/Chat/invite.svg';

import ChatNavigation from './ChatNavigation';
import ChatContactList from './ChatContactList';

import { useAuth } from '@rs-core/context/UserAuthContext';
import { useIsTablet, useIsMobile } from '@rs-core/utils/responsiveUtils';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { useConfig } from '@rs-core/context/ConfigContext';
import {
	createConversation,
	addParticipant,
	removeParticipant,
	getSubscribedConversations,
} from './ConversationHelper';

import axios from 'axios';
import ChatEmpty from './ChatEmpty';
import _ from 'lodash';

import ShareContactNew from '@worklist-2/patientPortal/src/components/ShareContact/ShareContactNew';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import { GenericDrawer } from '../Drawers';
import ChatRemoveButton from './ChatRemoveButton';

// Defaults
const OPTION_ICON_SIZE = 36;
const HEIGHT = 76;

// New Group component

const ChatAddParticipants = ({ frequentContactList, contactList, className, sx, addTwilioParticipantUser }) => {
	const { t } = useTranslation('chat');
	const { section, setSection } = useChatContext();
	const [frequentContacts, setFrequentContacts] = useState([]);
	const [convContacts, setConvContacts] = useState([]);
	const classList = ['chat-new-group', className].filter(Boolean);
	const backClickHandler = () => {
		if (section.name === CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS) {
			setSection({
				name: CHAT_SECTIONS.CONVERSATION,
				conversation: section.conversation,
			});
		} else {
			setSection({ name: CHAT_SECTIONS.NEW });
		}
	};

	useEffect(() => {
		async function asynchronousEffect() {
			let participantList = [];
			if (section.name === CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS && (frequentContactList || contactList)) {
				const participants = await section?.conversation.getParticipants();
				participantList = participants.map(x => x.identity);
			}
			setFrequentContacts(frequentContactList.filter(obj => !participantList.includes(obj.text)));
			setConvContacts(contactList.filter(obj => !participantList.includes(obj.text)));
		}

		asynchronousEffect();
	}, [section, frequentContactList, contactList]);

	return (
		<Box
			className={classList.join(' ')}
			flex="auto"
			sx={{
				boxSizing: 'border-box',
				display: 'grid',
				gridTemplateRows: 'min-content 1fr',
				paddingBottom: 'var(--element-content-spacing)',
				...sx,
			}}
		>
			<ChatNavigation
				addTwilioParticipantUser={addTwilioParticipantUser}
				heading={t(
					`chatSectionTitle.${_.camelCase(CHAT_SECTIONS_titles[CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS])}`
				)}
				onClick={backClickHandler}
			/>
			<ChatContactList buttonText="Create" contactList={convContacts} frequentContactList={frequentContacts} />
		</Box>
	);
};

ChatAddParticipants.propTypes = {
	frequentContactList: PropTypes.array,
	contactList: PropTypes.array,
	className: PropTypes.string,
	sx: PropTypes.object,
	addTwilioParticipantUser: PropTypes.func,
};

const ChatRemoveParticipants = ({ contactList, className, sx, isContactClicked, setIsContactClicked }) => {
	const { section, setSection, showNavigation, search } = useChatContext();
	const [convContacts, setConvContacts] = useState([]);
	const { t } = useTranslation('chat');
	const classList = ['chat-new-group', className].filter(Boolean);
	const { loggedInUser } = useAuth();
	const loggedEmail = loggedInUser?.email?.toLowerCase();

	const backClickHandler = () => {
		setSection({
			name: CHAT_SECTIONS.CONVERSATION,
			conversation: section.conversation,
		});
	};

	const removeParticipantHandler = async participant => {
		await removeParticipant(participant.text, section?.conversation);
		setConvContacts(prev => prev.filter(obj => obj !== participant));
	};

	useEffect(() => {
		async function asynchronousEffect() {
			const filterResults = () => {
				let sortedList = [];
				if (search != null && search.new?.searchString !== '') {
					sortedList = contactList.filter(
						value =>
							_.startsWith(value.user.name?.toLowerCase(), search.new.searchString.toLowerCase()) ||
							_.startsWith(value.text.toLowerCase(), search.new.searchString.toLowerCase())
					);
				} else {
					sortedList = contactList;
				}
				return sortedList;
			};
			if (contactList) {
				const filteredContactList = filterResults();
				const participants = await section?.conversation.getParticipants();
				const participantList = participants
					.filter(participant => participant.identity !== loggedEmail)
					.map(x => {
						const contact = filteredContactList.find(obj => obj.text === x.identity);
						if (contact) {
							return contact;
						}
						if (search === null || search?.new?.searchString === '') {
							return { text: x.identity, isOnline: false };
						}
						return false;
					});

				setConvContacts(participantList);
			}
		}

		asynchronousEffect();
	}, [search, section, contactList]);

	return (
		<Grid
			className="chat-conversation"
			flex="auto"
			sx={{
				display: 'grid',
				gridTemplateRows: 'min-content 1fr',
				overflow: 'hidden',
				position: 'relative',

				'.chat-layout--expanded &': {
					borderRadius: 'var(--border-radius-base)',
				},
				...sx,
			}}
		>
			{showNavigation && (
				<Box
					className="chat-header"
					sx={{
						'--element-background-color': 'var(--color-primary)',
						'--element-foreground-color': '#ffffff',

						background: 'var(--element-background-color)',
						boxSizing: 'border-box',
						color: 'var(--element-foreground-color)',
						display: 'grid',
						height: `${HEIGHT}px`,
						padding:
							'10px var(--element-content-spacing-right) 10px calc(var(--element-content-spacing-left) - 10px)',
						'&.chat-header--conversation-type-messaging.chat-header--call': {
							'--element-background-color': '#25d366',
							'--element-foreground-color': '#ffffff',
						},
					}}
				>
					<Stack
						alignItems="center"
						direction="row"
						sx={{
							overflow: 'hidden',
						}}
					>
						<IconButton
							className="chat-header-button"
							sx={{
								color: 'var(--element-foreground-color)',
								flex: 'none',
							}}
							onClick={backClickHandler}
						>
							<Icon component={ChevronLeftIcon} />
						</IconButton>
						<Typography
							className="chat-header-text"
							component="div"
							sx={{
								color: 'currentColor',
								fontSize: '16px',
								fontWeight: '500',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						>
							{t('newChat.allParticipants')}
						</Typography>
					</Stack>
				</Box>
			)}
			<Box
				className={classList.join(' ')}
				flex="auto"
				sx={{
					display: 'grid',
					gridTemplateRows: '100%',
					overflow: 'hidden',
					position: 'relative',

					'.chat-layout--expanded &': {
						borderRadius: 'var(--border-radius-base)',
					},
				}}
			>
				<Stack>
					<ChatSearch
						isClickAction={isContactClicked}
						placeholder={t('chatSearch.searchContactsPlaceholder')}
						setIsClickAction={setIsContactClicked}
						t={t}
					/>

					<ChatList>
						{convContacts.map((item, index) => (
							<>
								{item?.text && (
									<ChatListItemUser
										key={index}
										data={item}
										isOnline={item?.isOnline}
										text={item.text}
									>
										<ChatRemoveButton onClick={() => removeParticipantHandler(item)} />
									</ChatListItemUser>
								)}
							</>
						))}
					</ChatList>
				</Stack>
			</Box>
		</Grid>
	);
};

ChatRemoveParticipants.propTypes = {
	contactList: PropTypes.array,
	className: PropTypes.string,
	sx: PropTypes.object,
	removeTwilioParticipantUser: PropTypes.func,
	isContactClicked: PropTypes.bool,
	setIsContactClicked: PropTypes.func,
};

// New Group Create component

const ChatNewGroupCreate = ({ className, sx, handleConversation }) => {
	const { setSection } = useChatContext();
	const { t } = useTranslation('chat');

	const classList = ['chat-new-group', className].filter(Boolean);

	return (
		<Box
			className={classList.join(' ')}
			flex="auto"
			sx={{
				boxSizing: 'border-box',
				display: 'grid',
				gridTemplateRows: 'min-content 1fr',
				paddingBottom: 'var(--element-content-spacing)',
				...sx,
			}}
		>
			<ChatNavigation
				handleConversation={handleConversation}
				heading={
					CHAT_SECTIONS_titles[CHAT_SECTIONS.NEW_GROUP_CREATE] === 'New Group'
						? t('chatSectionTitle.newGroup')
						: CHAT_SECTIONS_titles[CHAT_SECTIONS.NEW_GROUP_CREATE]
				}
				onClick={() =>
					setSection({
						name: CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS,
					})
				}
			/>
		</Box>
	);
};

ChatNewGroupCreate.propTypes = {
	className: PropTypes.string,
	sx: PropTypes.object,
};

// Option component

const ChatHeading = ({ heading, sx }) => (
	<Typography component="h2" sx={{ ...sx }}>
		{heading}
	</Typography>
);

const ChatNewOption = ({ title, className, IconComponent, onClick }) => {
	const classList = ['chat-option', className];
	const { t } = useTranslation('chat');

	return (
		<ChatListItem
			className={classList.join(' ')}
			innerSx={{ paddingBottom: '5px', paddingTop: '5px' }}
			sx={{
				'--element-border-offset-left': `${OPTION_ICON_SIZE + CHAT_CONTACT_SPACING}px`,
			}}
			onClick={onClick}
		>
			<Box
				className="chat-option-icon"
				sx={{
					alignItems: 'center',
					backgroundColor: 'var(--element-background-color)',
					borderRadius: `${OPTION_ICON_SIZE / 2}px`,
					display: 'flex',
					justifyContent: 'center',
					height: `${OPTION_ICON_SIZE}px`,
					marginRight: '10px',
					width: `${OPTION_ICON_SIZE}px`,
				}}
			>
				<IconComponent component={IconComponent} fontSize="small" sx={{ color: 'currentColor' }} />
			</Box>

			<Typography sx={{ color: 'currentColor', fontSize: '14px' }}>{t(title)}</Typography>
		</ChatListItem>
	);
};

ChatNewOption.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	IconComponent: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
};

// Component

const ChatNew = ({ className, sx }) => {
	const {
		section,
		setSection,
		showNavigation,
		showNewChatCopyLink,
		showNewChatInvite,
		//subscribedUsers,
		dispatch,
		search,
		state,
		setSelectedContacts,
		chatContacts,
	} = useChatContext();
	const { t } = useTranslation('chat');

	const { loggedInUser, client, blumeClient } = useAuth();
	const { isPatientPortalMode } = useAppModeContext();
	const isBlumeMode = isPatientPortalMode();
	const [frequentContactList, setFrequentContactList] = useState([]);
	const [inviteVisible, setInviteVisible] = useState(false);
	const [inviteSubmitEnabled, setInviteSubmitEnabled] = useState(false);
	const [showLoading, setShowLoading] = useState(true);
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const [isOpenAddContact, setIsOpenAddContact] = useState(false);
	const [isContactClicked, setIsContactClicked] = useState(false);

	const _inviteFieldChangeHandler = useMemo(() => {
		if (!showNewChatInvite) {
			return;
		}

		return event => {
			const { currentTarget } = event;

			if (!currentTarget) {
				return;
			}

			if (typeof currentTarget.checkValidity !== 'function') {
				setInviteSubmitEnabled(true);
			}

			setInviteSubmitEnabled(!!currentTarget.value && currentTarget.checkValidity());
		};
	}, [showNewChatInvite, setInviteSubmitEnabled]);

	const __config = useConfig();
	const addTwilioParticipantUser = async (
		email,
		name,
		conversation,
		internalId,
		role,
		isBlumeUser,
		clientMode = 'OAI'
	) => {
		try {
			await addParticipant(email, name, true, conversation, internalId, role, isBlumeUser);
		} catch (e) {
			// User didn't exist on twilio
			// Create User and retry adding
			const payload = {
				identity: email,
			};
			const customConfig = {
				headers: {
					'Content-Type': 'application/json',
				},
			};
			const data = JSON.stringify(JSON.stringify(payload));
			const url = isBlumeMode
				? `${__config.data_sources.blume}User/Conversation/user`
				: clientMode === 'Blume'
				? `${__config.data_sources.fhir}/Conversation/blume/user`
				: `${__config.data_sources.fhir}/Conversation/user`;
			await axios.post(url, data, customConfig).then(async result => {
				await addParticipant(email, name, true, conversation, internalId, role, isBlumeUser);
			});
		}
	};

	const handleConversation = async (item, groupName) => {
		const conversationType = item.length > 1 ? 'group' : 'single';
		let conversation = null;
		const participantEmail = item.map(each => each.text?.toLowerCase());
		const tempParticipants = [loggedInUser.email?.toLowerCase(), ...participantEmail];
		// Check if we should create new convo or not
		const existingConversations = await getSubscribedConversations(item[0].isBlumeUser ? blumeClient : client);

		const existingConversationsMatch = await Promise.all(
			existingConversations.map(async conversation => {
				const participants = await conversation.getParticipants();
				const participantList = participants.map(x => x.identity);
				const isMatching = _.isEmpty(_.xor(tempParticipants, participantList));
				return {
					isMatching,
					conversation,
				};
			})
		);

		const matchBoolArr = existingConversationsMatch.filter(x => x.isMatching);

		if (matchBoolArr == null || matchBoolArr.length === 0) {
			conversation = await createConversation(
				item[0].isBlumeUser ? blumeClient : client,
				conversationType === 'group' ? groupName : item[0].user.name,
				loggedInUser.email?.toLowerCase(),
				loggedInUser.fullName,
				loggedInUser.id,
				loggedInUser.role,
				loggedInUser?.isBlumeUser
			);

			for (const contact of item) {
				await addTwilioParticipantUser(
					contact.text,
					contact.user.name,
					conversation,
					contact.internalId ?? contact.user?.internalId,
					contact.user?.role,
					item[0].isBlumeUser,
					item[0].isBlumeUser ? 'Blume' : 'OAI'
				);
			}

			const payload = {
				conversation,
				name: conversation.friendlyName,
				lastUpdated: conversation.dateUpdated,
				text: '',
				datetime: conversation.dateUpdated,
				messages: [],
				blume: false,
				unread: false,
				participantOnline: conversationType === 'group ' ? false : item.isOnline,
			};
			dispatch({
				msgtype: 'CONVERSATION_ADDED',
				payload,
			});
		} else {
			conversation = matchBoolArr[0].conversation;
		}
		// Set State back to required window
		setSection({
			name: CHAT_SECTIONS.CONVERSATION,
			conversation,
		});
	};

	const filteredContacts = useMemo(() => {
		if (search != null && search.new?.searchString !== '') {
			return chatContacts.filter(value => {
				const valueText = value.text || '';
				return (
					_.startsWith(value.user.name?.toLowerCase().trim(), search.new.searchString.toLowerCase().trim()) ||
					_.startsWith(valueText.toLowerCase(), search.new.searchString.toLowerCase())
				);
			});
		}
		return chatContacts;
	}, [search, chatContacts]);

	useEffect(() => {
		setFrequentContactList(() => {
			if (filteredContacts.length > 0 && Object.keys(state).length > 0) {
				let sortedConversationList = _.orderBy(state, 'lastUpdated', 'desc');

				// Get frequent contacts from the first 5 lastest updated conversations
				if (sortedConversationList?.length > 5) {
					sortedConversationList = sortedConversationList.slice(0, 5);
				}

				return filteredContacts.filter(contact =>
					// Use user ID to filter contacts (email is not unique - this is the case when the same user exists in both Blume and OmegaAI)
					sortedConversationList.find(item =>
						item?.conversation?.attributes?.participants?.some(
							participant =>
								participant?.internalId &&
								participant?.internalId === (contact?.internalId ?? contact?.user?.internalId)
						)
					)
				);
			}
			return [];
		});
		if (showLoading) {
			setShowLoading(false);
		}
	}, [filteredContacts]);

	if (
		section.name === CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS ||
		section.name === CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS
	) {
		return (
			<ChatAddParticipants
				addTwilioParticipantUser={addTwilioParticipantUser}
				className={className}
				contactList={chatContacts}
				frequentContactList={frequentContactList}
				sx={sx}
			/>
		);
	}
	if (section.name === CHAT_SECTIONS.NEW_GROUP_CREATE) {
		return <ChatNewGroupCreate className={className} handleConversation={handleConversation} sx={sx} />;
	}
	if (section.name === CHAT_SECTIONS.GROUP_REMOVE_PARTICIPANTS) {
		return (
			<ChatRemoveParticipants
				className={className}
				contactList={chatContacts}
				isContactClicked={isContactClicked}
				setIsContactClicked={setIsContactClicked}
				sx={sx}
			/>
		);
	}

	const classList = ['chat-new', className].filter(Boolean);

	const contactSection = (heading, contactArr) => (
		<>
			<ChatListHeader>
				<ChatHeading
					heading={heading}
					sx={{
						color: 'var(--element-foreground-color-secondary, #717171)',
						fontSize: '16px',
						fontWeight: 400,
					}}
				/>
			</ChatListHeader>

			{contactArr.map((item, index) => (
				<ChatListItemUser
					key={index}
					data={item}
					isOnline={item.isOnline}
					text={item.text}
					onMouseDown={async event => {
						setIsContactClicked(true);
						await handleConversation([item]);
					}}
				/>
			))}
		</>
	);

	return (
		<Box
			className={classList.join(' ')}
			flex="auto"
			sx={{
				boxSizing: 'border-box',
				display: 'grid',
				gridTemplateRows: 'min-content 1fr',
				paddingBottom: 'var(--element-content-spacing)',
				overflow: 'hidden',
				position: 'relative',

				'.chat-layout--expanded &': {
					borderRadius: 'var(--border-radius-base)',
				},
				...sx,
			}}
		>
			<Stack>
				{showNavigation ? (
					<Stack
						alignItems="center"
						direction="row"
						gap="10px"
						sx={{
							padding:
								'var(--element-content-spacing) var(--element-content-spacing-right) 5px var(--element-content-spacing-left)',
						}}
					>
						<IconButton sx={{ flex: 'none' }} onClick={() => setSection(CHAT_SECTIONS_default)}>
							<Icon component={ChevronLeftIcon} />
						</IconButton>

						<ChatHeading
							data-testid="heading"
							heading={t('chatSectionTitle.newChat')}
							sx={{
								flex: 'auto',
								color: 'var(--element-heading-foreground-color, #121212)',
								fontSize: '18px',
								fontWeight: 500,
							}}
						/>
					</Stack>
				) : null}

				<ChatSearch
					isClickAction={isContactClicked}
					placeholder={t('chatSearch.searchContactsPlaceholder')}
					setIsClickAction={setIsContactClicked}
					t={t}
				/>

				<ChatList
					overflow="auto"
					sx={{
						padding:
							'0 var(--element-content-spacing-right) var(--element-content-spacing) var(--element-content-spacing-left)',
					}}
				>
					<ChatNewOption
						IconComponent={GroupAddOutlinedIcon}
						title={t('chatSectionTitle.newGroup')}
						onClick={() => {
							setSelectedContacts([]);
							setSection({
								name: CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS,
							});
						}}
					/>

					{/* {showNewChatCopyLink ? (
						<ChatNewOption
							IconComponent={LinkOutlinedIcon}
							title={t('newChat.copyLinkToInvitePeople')}
							onClick={() => {}}
						/>
					) : null} */}

					{isBlumeMode && (
						<ChatNewOption
							IconComponent={AddIcon}
							title={t('newChat.addNewContact')}
							onClick={() =>
								isMobile || isTablet
									? setIsOpenAddContact(true)
									: setSection({
											name: CHAT_SECTIONS.ADD_CONTACT,
									  })
							}
						/>
					)}

					{showNewChatInvite ? (
						<ChatNewOption
							IconComponent={AddIcon}
							className={inviteVisible ? 'chat-option--active' : ''}
							title={t('newChat.inviteExternalPeople')}
							onClick={() => setInviteVisible(!inviteVisible)}
						/>
					) : null}

					{showNewChatInvite && inviteVisible ? (
						<Stack
							className="chat-new-invite"
							component="li"
							sx={{
								background: 'var(--element-background-color)',
								borderRadius: 'var(--border-radius-base)',
								overflow: 'hidden',
								marginTop: '10px',
							}}
						>
							<Stack
								alignItems="center"
								justifyContent="flex-end"
								sx={{
									backgroundColor: '#ffffff',
									height: '130px',
								}}
							>
								<Icon component={InviteIcon} sx={{ height: '123px', width: '176px' }} />
							</Stack>

							<Stack
								sx={{
									padding: 'var(--element-content-spacing)',
								}}
							>
								<Typography
									component="h4"
									sx={{
										color: 'var(--element-foreground-color)',
										fontSize: '16px',
										fontWeight: 500,
										marginBottom: '5px',
									}}
								>
									{t('newChat.addSomeoneExternal')}
								</Typography>

								<Typography
									component="p"
									sx={{
										color: 'var(--element-foreground-color-secondary)',
										fontSize: '12px',
										fontWeight: 500,
									}}
								>
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
									Ipsum has been the industry
								</Typography>

								<Stack
									alignItems="flex-end"
									component="form"
									sx={{
										marginTop: '10px',
									}}
								>
									<TextField
										fullWidth
										InputProps={{
											sx: {
												backgroundColor: 'var(--element-field-background-color)',
												borderRadius: 'var(--border-radius-base)',
											},
										}}
										defaultValue=""
										placeholder={t('newChat.addEmailAddress')}
										size="small"
										sx={{
											marginBottom: '10px',
										}}
										type="email"
										onChange={_inviteFieldChangeHandler}
									/>

									<Button
										disableElevation
										disabled={!inviteSubmitEnabled}
										size="small"
										sx={{
											'--element-background-color': 'var(--color-primary)',
											'--element-foreground-color': '#ffffff',
											backgroundColor: 'var(--element-background-color)',
											borderRadius: 'var(--border-radius-base)',
											color: 'var(--element-foreground-color)',
											textTransform: 'none',

											'&:disabled': {
												backgroundColor: 'var(--element-background-color)',
												color: 'var(--element-foreground-color)',
												opacity: 0.4,
											},
										}}
										type="submit"
										variant="contained"
									>
										{t('newChat.sendInvitation')}
									</Button>
								</Stack>
							</Stack>
						</Stack>
					) : null}
				</ChatList>
			</Stack>
			{chatContacts.length > 0 && (
				<ChatList>
					{frequentContactList.length > 0 &&
						contactSection(t('newChat.frequentContacts'), frequentContactList)}
					{filteredContacts.filter(it => !frequentContactList.includes(it)).length > 0 &&
						contactSection(
							t('newChat.contacts'),
							filteredContacts.filter(it => !frequentContactList.includes(it))
						)}
					{frequentContactList.length === 0 && filteredContacts.length === 0 && (
						<ChatEmpty
							searchString={search?.new?.searchString}
							sx={{
								flex: 'auto',
								padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
							}}
						>
							<Typography
								component="div"
								sx={{
									color: 'var(--element-foreground-color)',
									fontSize: '20px',
									textAlign: 'center',
								}}
							>
								{t('chatSearch.noContactFound')}
							</Typography>

							<Typography
								component="div"
								sx={{
									color: 'var(--element-foreground-color-secondary)',
									fontSize: '12px',
									textAlign: 'center',
								}}
							>
								{t('chatSearch.searchForAnotherTerm')}
							</Typography>
						</ChatEmpty>
					)}
				</ChatList>
			)}
			{!chatContacts.length && !showLoading && (
				<ChatEmpty
					sx={{
						flex: 'auto',
						padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
					}}
				>
					<Typography
						component="div"
						sx={{
							color: 'var(--element-foreground-color)',
							fontSize: '20px',
							marginTop: '40px',
						}}
					>
						{t('newChat.noContacts')}
					</Typography>

					<Typography
						component="div"
						sx={{
							color: 'var(--element-foreground-color-secondary)',
							fontSize: '12px',
							marginTop: '5px',
						}}
					>
						{t('newChat.goAheadAndAddNewContacts')}
					</Typography>
				</ChatEmpty>
			)}
			{isMobile ? (
				<MobileBottomDrawer
					background="#ffffff"
					open={isOpenAddContact}
					sx={{ height: '380px' }}
					onClose={() => setIsOpenAddContact(false)}
				>
					<ShareContactNew closeDrawer={() => setIsOpenAddContact(false)} isOpen={isOpenAddContact} />
				</MobileBottomDrawer>
			) : isTablet ? (
				<GenericDrawer
					headerFlag
					showBackBtn
					drawerColor="rsSecondary.medium"
					drawerOpen={isOpenAddContact}
					setDrawerOpen={setIsOpenAddContact}
					title={t('newChat.addNewContact')?.toUpperCase()}
					toggleDrawerButtonFlag={false}
					width={422}
				>
					<ShareContactNew isOpen={isOpenAddContact} />
				</GenericDrawer>
			) : (
				''
			)}
			{showLoading === true && (
				<Skeleton
					sx={{
						height: '100%',
						borderRadius: '10px',
						marginLeft: isTablet ? '5px' : '15px',
					}}
					variant="rectangular"
				/>
			)}
		</Box>
	);
};

ChatNew.propTypes = {
	className: PropTypes.string,
	sx: PropTypes.object,
};

export default ChatNew;
