import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useImageViewerStudiesContext } from './ImageViewerStudiesContext';
import { getStudyInstanceUID, getStudyDate, isStudyEqual } from '../utils/utils';
import { useSeries } from '../hooks/useSeries';
import { useStudies } from '../hooks/useStudies';
import { useImport } from '../hooks/useImport';
import { useAssignImageToLayout } from '../hooks/useAssignImageToLayout';
import { REPARENTING, VIEWPORT, IMPORT } from '../consts/dragDropRules';
import { BROADCAST_EVENTS } from './consts/consts';
import { useImageViewerMultiMonitorContext } from './ImageViewerMultiMonitorContext';
import { useImageViewerPermissionsContext } from './ImageViewerPermissionsContext';
import { useImageViewerLayoutContext } from './ImageViewerLayoutContext';
import { ImportDialog } from '../components/ImportDialog/ImportDialog';
import {
	groupDicomFiles,
	groupFilesByStudyUid,
	groupFilesByType,
	normalizeImageFiles,
	normalizeRegularFiles,
	generateImportCompleteMessage,
} from '../features/Import/utils/importUtils';
import { WORKER_MESSAGES } from '../features/Import/consts/consts';
import { useCallbackPrompt } from '../hooks/useCallbackPrompt';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import loadSeriesWithMetadata from '../api/loadSeriesWithMetadata';
import { useConfig } from '@worklist-2/core/src';
import { useCache } from '@rs-core/context/CacheContext';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import {
	QC_MODULE_PERMISSIONS,
	PERMISSIONS_MODULES,
} from '@worklist-2/worklist/src/DocumentViewerV3/consts/imageViewerPermissionTags';
import { getKeyImages } from '../api/keyImage';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue, useNumberFlagValue } from '@rs-core/hooks/useFlags';

const ImageViewerSeriesDragDropContext = createContext({});

const ImageViewerSeriesDragDropContextProvider = ({ children }) => {
	const __config = useConfig();
	const { cacheLinks } = useCache();

	const wonIvFiledrops2Qc = useBooleanFlagValue('WON-IV-FILEDROPS2QC');
	const wonIvSeriesSplitter = useBooleanFlagValue('won-iv-series-splitter');
	const wonIvPlaneSplitter = useBooleanFlagValue('won-iv-plane-splitter');
	const wonIvDisablexasplit = useBooleanFlagValue('WON-IV-DISABLEXASPLIT');
	const wonIvSeriesSplitterUpperLimit = useNumberFlagValue('won-iv-series-splitter-upper-limit');
	const wonIvEnhancedcurvedsort = useBooleanFlagValue('WON-IV-ENHANCEDCURVEDSORT');
	const wonIvStillsfirst = useBooleanFlagValue('WON-IV-STILLSFIRST');
	const wonIvRefactorsorting = useBooleanFlagValue('WON-IV-REFACTORSORTING');
	const wonIvUploadEnhancement = useBooleanFlagValue('WON-IV-UPLOAD-ENHANCEMENT');
	const wonIvLiveSignalR = useBooleanFlagValue('won-iv-live-signalr');
	const { t } = useTranslation('imageViewer', { keyPrefix: 'importModal' });
	const { t: tSeries } = useTranslation('imageViewer', { keyPrefix: 'series' });
	const { seriesRef, studies, setStudies, setCurrentStudy, setSeries, setKeyImages } = useImageViewerStudiesContext();
	const { setDraggingElementData } = useImageViewerLayoutContext();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { reparentSeries } = useSeries();
	const { loadStudies } = useStudies();
	const { onDragDrop } = useAssignImageToLayout();
	const { postMessage } = useImageViewerMultiMonitorContext();
	const { importFiles, handleInputFiles, retryImportFiles, stopImportFiles, stopImport } = useImport();
	const [importInProgress, setImportInProgress] = useState(false);
	const [showImportDialog, setShowImportDialog] = useState(false);
	const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(importInProgress);
	const [fileList, setFileList] = useState([]);
	const [droppableStudy, setDroppableStudy] = useState(null);
	const [importingInstances, setImportingInstances] = useState([]);
	const [importingDicomFiles, setImportingDicomFiles] = useState([]);
	const [searchParams] = useSearchParams();
	const internalManagingOrganizationID = searchParams.get('internalManagingOrganizationID');
	const studyInstanceUID = searchParams.get('StudyInstanceUIDs');
	const { imageViewerPermission } = useImageViewerPermissionsContext();

	useEffect(() => {
		if (showPrompt && importInProgress) {
			const snackbarId = enqueueSnackbar(t('Attention Ongoing Data Import Process'), {
				variant: 'default',
				action: (
					<>
						<Button
							color="rsPrimary"
							sx={{
								textTransform: 'none',
							}}
							onClick={() => {
								confirmNavigation();
								stopImport();
							}}
						>
							{t('Leave Page and Cancel Import')}
						</Button>
						<Button
							color="rsPrimary"
							sx={{
								textTransform: 'none',
							}}
							onClick={() => {
								cancelNavigation();
								closeSnackbar(snackbarId);
							}}
						>
							{t('Stay on Page')}
						</Button>
					</>
				),
				autoHideDuration: 5000,
			});
		}
	}, [showPrompt, importInProgress]);

	const handleClose = () => {
		setShowImportDialog(false);
		setFileList([]);
	};
	const onDragStartHandler = activeElData => {
		setDraggingElementData(activeElData);
		postMessage({
			event: BROADCAST_EVENTS.DRAG_SERIES_START,
			value: activeElData,
		});
	};

	const onDragEndHandler = () => {
		setDraggingElementData(null);
		postMessage({
			event: BROADCAST_EVENTS.DRAG_SERIES_COMPLETE,
		});
	};

	const onDragComplete = (droppableData, elementData) => {
		if (!elementData) return;

		const hasImportSeriesPerms =
			imageViewerPermission?.[PERMISSIONS_MODULES.QC_MODULE]?.[QC_MODULE_PERMISSIONS.IMPORT_SERIES]?.read ===
			true;
		const { type } = droppableData;
		setDraggingElementData(null);
		switch (type) {
			case VIEWPORT:
				onAssignSeriesToViewport(droppableData, elementData);
				break;
			case REPARENTING:
				onReparent(droppableData, elementData);
				break;
			case IMPORT:
				wonIvFiledrops2Qc && hasImportSeriesPerms && onImportFiles(droppableData, elementData);
				break;
			default:
				break;
		}
	};

	const onDeleteItem = id => {
		setFileList(prev => {
			if (prev.length === 1) handleClose();
			return prev.filter(i => i.id !== id);
		});
	};

	const onAssignSeriesToViewport = (droppableData, elementData) => {
		const targetSeries = seriesRef.current.find(
			i =>
				i.uniqueId === elementData.uniqueId &&
				i.managingOrganizationId === elementData.managingOrganizationId &&
				i.studyInstanceUID === elementData.studyUid
		);
		onDragDrop({
			layoutItemId: droppableData.layoutItemId,
			imageIndex: elementData.imageIndex,
			draggableSeries: targetSeries,
		});
	};

	const onReparent = async (droppableData, elementData) => {
		const { studyUid } = elementData;
		const seriesIds = elementData.selectedItems;
		const sourceManagingId = elementData.managingOrganizationId;
		const targetStudy = droppableData.studyUid;
		const destManagingId = droppableData.managingOrganizationId;
		if (studyUid !== droppableData.studyUid || sourceManagingId !== destManagingId) {
			try {
				const startRepa = enqueueSnackbar(
					tSeries('ReparentStart', {
						seriesCount: seriesIds?.length,
					}),
					'info',
					{ autoHideDuration: 5000 }
				);
				const result = await reparentSeries(
					studyUid,
					seriesIds,
					targetStudy,
					sourceManagingId,
					destManagingId,
					wonIvLiveSignalR
				);
				if (result === true) {
					enqueueSnackbar(
						tSeries('SucessSeriesReparent', {
							seriesCount: seriesIds?.length,
						}),
						'success',
						{ autoHideDuration: 5000 }
					);
				} else if (result === false) {
					enqueueSnackbar(
						tSeries('FailReparent', {
							seriesCount: seriesIds?.length,
						}),
						'error',
						{ autoHideDuration: 5000 }
					);
				} else if (result?.alreadyRunning === true) {
					enqueueSnackbar(tSeries('SeriesAlreadyBeingReparented'), 'warning', { autoHideDuration: 5000 });
				}

				closeSnackbar(startRepa);
			} catch (error) {}
		}
	};

	const listenUploadProgress = e => {
		if (e.data) {
			const { type, payload } = e.data;
			let fileId;
			let fileType;
			let uploadProgress;
			switch (type) {
				case WORKER_MESSAGES.post_success:
					fileId = payload.uploadingFileId;
					fileType = payload.uploadingFileType;
					assignProgressToUploadingFiles(fileId, fileType);
					break;
				case WORKER_MESSAGES.post_error:
					const { uploadingFileId } = payload;
					fileId = payload.uploadingFileId;
					fileType = payload.uploadingFileType;
					assignErrorToUploadingFiles(uploadingFileId, fileId, fileType);
					break;
				case WORKER_MESSAGES.import_in_progress:
					setImportInProgress(true);
					break;
				case WORKER_MESSAGES.import_completed:
					setImportInProgress(false);
					if (wonIvUploadEnhancement) {
						enqueueSnackbar(generateImportCompleteMessage(importingInstances, importingDicomFiles, t), {
							variant: 'default',
						});
					} else {
						enqueueSnackbar(t('Upload Complete'), {
							variant: 'default',
						});
					}
					break;
				case WORKER_MESSAGES.upload_progress:
					fileId = payload.id;
					uploadProgress = payload.uploadProgress;
					setImportingInstances(prev => prev.map(i => (i.id === fileId ? { ...i, uploadProgress } : i)));
					break;
				default:
					break;
			}
		}
	};

	const onImportFiles = async (droppableData, elementData) => {
		const files = await handleInputFiles(elementData);
		const { pass: allowedFiles } = files;

		if (allowedFiles?.length > 0) {
			setFileList(allowedFiles);
			setDroppableStudy(droppableData);
			setShowImportDialog(true);
		}
	};

	const assignProgressToUploadingFiles = (fileId, fileType) => {
		if (fileType === 'dcm') {
			setImportingDicomFiles(prev => {
				const updatedDicom = prev.map(i => ({
					...i,
					file: i.file.map(f => (f.id === fileId ? { ...f, uploaded: true } : f)) || [],
				}));
				return updatedDicom.map(i => {
					const uploadedDicoms = i.file.filter(f => f.uploaded);
					const progress = (uploadedDicoms.length / i.file.length) * 100;
					return { ...i, uploadProgress: progress, uploaded: progress === 100 };
				});
			});
		} else {
			setImportingInstances(prev =>
				prev.map(i => {
					if (fileId === i.id) {
						return { ...i, uploaded: true };
					}
					return i;
				})
			);
		}
	};

	useEffect(() => {
		const uploadedRegularInstances = importingInstances.filter(i => i.uploaded);
		const uploadedDicomInstances = importingDicomFiles.filter(i => i.uploaded);

		debouncedAccumulator([...uploadedRegularInstances, ...uploadedDicomInstances], async results => {
			if (results.length > 0) {
				const ids = results.map(i => i.id);
				const groupedInstances = groupFilesByStudyUid(results);

				Object.keys(groupedInstances).map(async uid => {
					const resp = await loadSeriesWithMetadata({
						__config,
						managingOrganizationId: internalManagingOrganizationID,
						studyInstanceUID: uid,
						wonIvSeriesSplitter,
						wonIvDisablexasplit,
						wonIvSeriesSplitterUpperLimit,
						cacheLinks,
						wonIvPlaneSplitter,
						wonIvEnhancedcurvedsort,
						wonIvRefactorsorting,
						wonIvStillsfirst,
						is3D: true,
					});

					const filterSeries = [...seriesRef.current.filter(p => p.studyInstanceUID !== uid), ...resp];
					setSeries(filterSeries);

					setImportingInstances(prev => prev.filter(i => !ids.includes(i.id)));
					setImportingDicomFiles(prev => prev.filter(i => !ids.includes(i.id)));

					const keyImages = await getKeyImages({
						__config,
						studyInstanceUID: uid,
						internalManagingId: internalManagingOrganizationID,
					});

					if (keyImages?.length > 0) {
						setKeyImages(prevState => [
							...prevState.filter(i => !keyImages.find(ko => ko.ResourceID === i.ResourceID)),
							...keyImages,
						]);
					}
				});
				const updatedStudies = await loadStudies({}).then(async studiesValue => {
					const linkedStudies = await loadStudies({ isLinkedPatient: true });

					const studiesArr = [...studiesValue, ...linkedStudies];
					studiesArr?.sort((a, b) => new Date(getStudyDate(b)) - new Date(getStudyDate(a)));
					return studiesArr;
				});
				const updatedCurrentStudy = updatedStudies.find(s =>
					isStudyEqual(s, { studyInstanceUID, managingOrganizationId: internalManagingOrganizationID })
				);
				updatedCurrentStudy && setCurrentStudy(updatedCurrentStudy);
				setStudies(updatedStudies);
			}
		});
	}, [importingInstances, importingDicomFiles]);

	const accumulator = (e, callback) => callback(e);

	const debouncedAccumulator = useMemo(
		() =>
			debounce((request, callback) => {
				accumulator(request, callback);
			}, 400),
		[]
	);

	const assignErrorToUploadingFiles = (uploadingFileId, fileId, fileType) => {
		if (fileType === 'dcm') {
			setImportingDicomFiles(prev =>
				prev.map(i => {
					if (i.file.some(f => f.id === fileId)) {
						return {
							...i,
							file: i.file.map(f => (f.id === fileId ? { ...f, error: true } : f)),
							error: true,
						};
					}
					return i;
				})
			);
		} else {
			setImportingInstances(prev =>
				prev.map(i =>
					uploadingFileId === i.id ? { ...i, file: i.file.map(f => ({ ...f, error: true })), error: true } : i
				)
			);
		}
	};

	useEffect(
		() => {
			addEventListener('message', listenUploadProgress);
			return () => {
				removeEventListener('message', listenUploadProgress);
			};
		},
		wonIvUploadEnhancement ? [importingInstances, importingDicomFiles] : []
	);

	const onSubmitImport = () => {
		setImportInProgress(true);

		const groupedFilesByTypes = groupFilesByType(fileList);
		setFileList([]);
		const uploadingFiles = Object.keys(groupedFilesByTypes)
			.reduce((acc, ext) => {
				let normalizedInstances = [];
				switch (ext) {
					case 'dcm':
						const dicomFiles = groupDicomFiles(
							groupedFilesByTypes[ext],
							seriesRef.current,
							droppableStudy.studyUid
						);
						setImportingDicomFiles(prev => {
							const filtered = prev.filter(i => {
								const isDuplicated = dicomFiles.find(
									f => f.studyUid === i.studyUid && f.seriesUid === i.seriesUid
								);
								return !isDuplicated;
							});
							return [...filtered, ...dicomFiles];
						});
						normalizedInstances = normalizeRegularFiles(groupedFilesByTypes[ext]);
						break;
					case 'jpg':
					case 'jpeg':
						normalizedInstances = normalizeImageFiles(groupedFilesByTypes[ext]);
						break;
					default:
						normalizedInstances = normalizeRegularFiles(groupedFilesByTypes[ext]);
						break;
				}
				return [...acc, ...normalizedInstances];
			}, [])
			.map(f => ({ ...f, customName: 'IMPORTED', studyUid: droppableStudy.studyUid }));

		setImportingInstances(prev => [...prev, ...uploadingFiles.filter(f => f.fileType !== 'dcm')]);

		setShowImportDialog(false);
		importFiles({
			currentStudy: studies.find(st => getStudyInstanceUID(st) === droppableStudy.studyUid),
			files: uploadingFiles,
		});
	};

	const onUngroupHandler = (id, value) => {
		setFileList(prev => prev.map(i => (i.id === id ? { ...i, isGrouped: value } : i)));
	};

	const onChangeImportCapabilities = (id, value) => {
		setFileList(prev => prev.map(i => (i.id === id ? { ...i, encapsulate: value } : i)));
	};

	const stopImportFile = id => {
		let targetInstance;
		let filesIds;
		targetInstance = importingDicomFiles.find(i => i.id === id);
		if (targetInstance) {
			setImportingDicomFiles(prev =>
				prev.map(i =>
					i.id === id ? { ...i, file: i.file.map(f => ({ ...f, aborted: true })), aborted: true } : i
				)
			);
			filesIds = targetInstance.file.map(f => f.id);
		} else {
			targetInstance = importingInstances.find(i => i.id === id);
			setImportingInstances(prev => prev.map(i => (i.id === id ? { ...i, aborted: true } : i)));
			filesIds = [targetInstance.id];
		}

		stopImportFiles(filesIds);
	};

	const retryUpload = id => {
		let targetInstance;
		let failedFilesIds;
		targetInstance = importingDicomFiles.find(i => i.id === id);
		if (targetInstance) {
			setImportingDicomFiles(prev =>
				prev.map(i =>
					i.id === id ? { ...i, error: false, file: [...i.file.map(f => ({ ...f, error: false }))] } : i
				)
			);
			const failedFiles = targetInstance.file.filter(f => f.error);
			failedFilesIds = failedFiles.map(f => f.id);
		} else {
			targetInstance = importingInstances.find(i => i.id === id);
			setImportingInstances(prev => prev.map(i => (i.id === id ? { ...i, error: false } : i)));
			failedFilesIds = [targetInstance.id];
		}
		retryImportFiles(failedFilesIds);
	};
	return (
		<ImageViewerSeriesDragDropContext.Provider
			value={{
				onDragStartHandler,
				onDragEndHandler,
				onDragComplete,
				importingInstances,
				importingDicomFiles,
				stopImportFile,
				retryUpload,
				setImportingDicomFiles,
			}}
		>
			<ImportDialog
				fileList={fileList}
				showImportDialog={showImportDialog}
				onChangeImportCapabilities={onChangeImportCapabilities}
				onClose={handleClose}
				onDeleteItem={onDeleteItem}
				onSubmit={onSubmitImport}
				onUngroup={onUngroupHandler}
			/>
			{children}
		</ImageViewerSeriesDragDropContext.Provider>
	);
};

const useImageViewerSeriesDragDropContext = () => useContext(ImageViewerSeriesDragDropContext);

export {
	useImageViewerSeriesDragDropContext,
	ImageViewerSeriesDragDropContextProvider,
	ImageViewerSeriesDragDropContext,
};
